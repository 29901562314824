import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";
import { useDisputeConfigValue } from "backoffice/pages/disputes/hooks/use-dispute-config-value";
import { DISPUTE_CONFIG_KEYS } from "shared/constants";

export function LogDispute(props) {
  const { addNotification } = useNotification();
  const { value: disputeReasons } = useDisputeConfigValue(
    DISPUTE_CONFIG_KEYS.GET_DISPUTE_REASONS
  );
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues] = useState({
    reason: "",
  });
  const [validationSchemaObject] = useState({
    reason: Yup.string().required("Required"),
  });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const [configuredDisputeReasons, setConfiguredDisputeReasons] = useState([]);

  useEffect(() => {
    if (disputeReasons) {
      setConfiguredDisputeReasons(disputeReasons?.split(","));
    }
  }, [disputeReasons]);

  const handleResponse = () => {
    let notification = {
      type: "success",
      message: `Dispute successfully logged!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const logDispute = async (payload) => {
    resetToast();
    payload = {
      ...payload,
      transactionSearchKey: props.transactionDetails.transactionSearchKey,
    };
    const url = BACK_OFFICE_API.DISPUTES.LOG_DISPUTE;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const submit = async (payload) => {
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      logDispute(payload);
    } else {
      const { rrn, maskedPan, terminalId, stan, cardAcceptorId } =
        props.transactionDetails;
      let confirmationPayload = {
        reason: payload.reason,
        rrn,
        maskedPan,
        terminalId,
        stan,
        cardAcceptorId,
      };
      setFormValues(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  return (
    <div>
      <p className="modal-title p-text-left mb-1">Log Dispute</p>
      <p className="modal-subtitle mt-0 mb-1">
        Fill the form below to log a dispute against a transaction.
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
              <AppFormDropdown
                value={values.reason}
                onchange={(e) => {
                  const value = e?.target?.value;
                  setFieldValue("reason", value);
                }}
                options={configuredDisputeReasons}
                placeholder="Reason"
                label="Reason"
                error={errors?.reason}
              />
            </Optional>
            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <FormattedDetails details={formValues} />
            </Optional>
            {message && (
              <CustomToast
                title={toastType === "error" ? "Error" : "Success"}
                description={message}
                type={toastType}
                closeable={false}
                inApp={true}
              />
            )}
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text={`${
                  currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                }`}
                onclick={handleClose}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                text={`${
                  currentFormIndex === FORM_VIEW_INDEX ? "Confirm" : "Submit"
                }`}
                loading={loading}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
