import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { Optional } from "../../../shared/components/optional/optional";
import { HELPER } from "../../../core/helper/helper";
import "./secret-key.css";
import useSecretKeyFeatures from "./use-secret-key-features";

export function ManageSecretKey() {
  const { generateSecretKey, loading, updatedAt, secretKey } =
    useSecretKeyFeatures();

  return (
    <ErrorBoundary>
      <div className="integration-details-wrapper">
        <div className="page-title text-left">Integration Details</div>
        <div className="mt-2">
          <CustomBreadcrumb page={"Integration Details"} />
        </div>
        <div className="card mt-5">
          <div className="form-container w-30rem">
            <div>
              <div
                style={{ position: "relative" }}
                className="mt-3 d-flex align-items-center "
              >
                <div className="w-full">
                  <label>Client Secret:</label>
                  <FormInput
                    value={secretKey || ""}
                    required={true}
                    type="INPUT"
                    disabled={true}
                    placeholder=""
                    inputType="password"
                    id="client-secret"
                    noFloatingLabel
                  />
                </div>
                <Optional
                  showIf={HELPER.HAS_AUTHORITY("switch_generate_secret_key")}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "-6rem",
                      bottom: "-0.1rem",
                    }}
                  >
                    <button
                      disabled={loading}
                      className="bare-button ml-3"
                      onClick={generateSecretKey}
                    >
                      <i
                        className={`pi mr-1 ${
                          loading ? "pi-spin pi-spinner" : "pi-refresh"
                        }`}
                        style={{ paddingBottom: "0", fontSize: "1rem" }}
                      />
                      Generate
                    </button>
                  </div>
                </Optional>
              </div>
              <br />
              <div>
                <label>Date Updated:</label>
                <FormInput
                  value={updatedAt || ""}
                  type="INPUT"
                  error={false}
                  disabled={true}
                  placeholder=""
                  id="updated-at"
                  noFloatingLabel
                />
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}
