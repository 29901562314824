import React from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { Optional } from "../../../shared/components/optional/optional";
import BulkMerchantTerminalFilter from "./bulk-merchant-terminal-filter";
import BulkMerchantTerminalForm from "./bulk-merchant-terminal-form";
import BulkMerchantTerminalDetails from "./bulk-merchant-terminal-details";
import { useBulkMerchantTerminal } from "./hooks/use-bulk-merchant-terminal";

export function BulkMerchantTerminal() {
  const { api, page, data, pageActions } = useBulkMerchantTerminal();
  const { getBulkMerchantTerminals } = api;

  function modalContent() {
    switch (page.modalIndex) {
      case 0:
        return (
          <BulkMerchantTerminalForm
            title={"Create Bulk Merchant Terminals"}
            subtitle={"Fields with * are required"}
            closeModal={pageActions.closeModal}
            onSuccess={getBulkMerchantTerminals}
            isUpdate={false}
          />
        );
      case 2:
        return (
          <BulkMerchantTerminalFilter
            title={"Filter Bulk Merchant Terminals"}
            closeModal={pageActions.closeModal}
            onGetBulkMerchantTerminals={pageActions.handleFilter}
          />
        );
      default:
        break;
    }
  }

  const renderTableView = () => {
    if (api.loading) {
      return (
        <div className="loading-container">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={page.tableAuthorities}
          isReload={true}
          numberOfElements={data.pagination?.numberOfElements}
          totalPages={data.pagination?.totalPages}
          totalItems={data.pagination?.totalElements}
          currentPage={page.pageNo + 1}
          emptyText={"No bulk merchant terminals found!"}
          search={true}
          reload={pageActions.reload}
          error={api.error}
          items={data.bulkMerchantTerminals}
          headers={page.tableHeaders}
          nextPage={pageActions.incrementPageNo}
          prevPage={pageActions.decrementPageNo}
          goToFirstPage={() => pageActions.goToPage(0)}
          goToLastPage={() =>
            pageActions.goToPage(data.pagination?.totalPages - 1)
          }
          goToPage={(pageNo) => pageActions.goToPage(pageNo)}
          actions={["OPEN"]}
          onPerformAction={pageActions.handleTableAction}
        />
      );
    }
  };

  const currentPageView = () => {
    switch (page.currentIndex) {
      case 0:
        return renderTableView();
      case 1:
        const {
          createdBy,
          updatedAt,
          financialInstitutionCode,
          financialInstitutionName,
          active,
          terminalOwnerAccount,
          ...rest
        } = data.details;
        return (
          <BulkMerchantTerminalDetails
            details={{ ...rest, status: active }}
            goBack={pageActions.goBack}
          />
        );
      default:
        break;
    }
  };

  return (
    <div>
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={pageActions.closeModal}
          onHide={pageActions.closeModal}
          visible={page.isModalVisible}
          modalContent={modalContent}
        />
      </>
      <PageTitle text="Bulk Merchant Terminals" />
      <CustomBreadcrumb page="Manage Bulk Merchant Terminals" />
      <Optional
        showIf={
          HELPER.HAS_AUTHORITY("switch_upload_bulk_merchant_terminal") &&
          page.currentIndex === 0
        }
      >
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => {
              pageActions.toggleModal(2);
            }}
          />
          <AppButton
            text="New Bulk Merchant Creation"
            type="button"
            buttonStyle="primary"
            onclick={() => {
              pageActions.toggleModal(0);
            }}
          />
        </PageActions>
      </Optional>
      <>{currentPageView()}</>
    </div>
  );
}
