import { useField } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";
import { ToolTip } from "../tooltip/tooltip";

import "./form-inputs.css";
import { Optional } from "../optional/optional";

export function AppFormTextArea({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div style={{ marginBottom: "1rem" }}>
      <label
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          marginBottom: "0.5rem",
          display: "block",
          color: "#566a7f",
          textAlign: "left",
          // display: "flex",
        }}
        htmlFor={props.id || props.name}
      >
        <p style={{ position: "relative" }}>
          {label}
          {props.showtooltip === "true" ? (
            <ToolTip tip={props.tooltiptext} />
          ) : null}
          : {props.required ? <span style={{ color: "red" }}> * </span> : null}
        </p>
      </label>
      <textarea
        className={`text-input${meta.touched && meta.error ? " error" : ""}`}
        {...field}
        {...props}
      />
      <Optional showIf={meta.touched && meta.error}>
        <p className="error text-left">{meta.error}</p>
      </Optional>
      <Optional showIf={props.loading}>
        {/* <div style={{ float: "right" }}> */}
        <ProgressSpinner
          style={{
            width: "15px",
            height: "15px",
            marginLeft: ".1em",
            position: "relative",
            top: ".35em",
          }}
          strokeWidth="5"
          animationDuration=".5s"
        />
        {/* </div> */}
      </Optional>
    </div>
  );
}
