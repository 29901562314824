import React, { useCallback, useEffect, useState } from "react";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { API_SERVICE } from "../../../api/service";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { AcquirerThirdPartyFilter } from "./acquirer-third-party-filter";
import { PageActions } from "shared/components/page-actions/page-actions";
import { AppButton } from "shared/components/app-button/app-button";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { useNotification } from "core/hooks/useNotification";

export function AcquirerThirdParties(props) {
  const { addNotification } = useNotification();
  const [currentIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [tableHeaders] = useState([
    { label: "Third Party Name", value: "thirdPartyName" },
    { label: "Category", value: "thirdPartyCategoryName" },
    { label: "Status", value: "active" },
    { label: "Created At", value: "createdAt" },
  ]);
  const [thirdParties, setThirdParties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInfo] = useState({
    title: "Filter Third Party",
  });
  const [filterParams, setFilterParams] = useState(null);

  const getThirdParties = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING(
        HELPER.TRIM_OBJECT({
          page: pageNo,
          size: DEFAULT_PAGE_SIZE,
          ...filter,
          acquirerCode: props.acquirerCode,
        })
      );
      const url = `${BACK_OFFICE_API.THIRD_PARTY_ACQUIRERS.GET_ACQUIRER_THIRD_PARTIES}?${params}`;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(url);
        setPagination(response.result);
        const thirdParties = response?.result?.content;
        setThirdParties(thirdParties);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, props.acquirerCode, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getThirdParties();
    } else {
      getThirdParties(filterParams);
    }
  }, [getThirdParties, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getThirdParties();
    }
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }
  const handleAcquirerFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  const modalContent = () => {
    switch (modalIndex) {
      case 0:
        return (
          <AcquirerThirdPartyFilter
            onGetAcquirerThirdParties={handleAcquirerFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  const renderTable = () => {
    if (loading) {
      return (
        <div className=" text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No acquirer third parties found!"}
          search={true}
          reload={reload}
          error={error}
          items={thirdParties}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE"]}
          // onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-acquirer-third-parties">
      <CustomModal
        closeModal={closeModal}
        onHide={closeModal}
        visible={isModalVisible}
        modalContent={modalContent}
        title={modalInfo.title}
        subtitle={modalInfo.subtitle}
      />
      <PageActions>
        <AppButton
          icon="filter"
          text="Filter"
          type="button"
          buttonStyle="bare"
          margin={"mr-2"}
          onclick={() => {
            toggleModal(0);
          }}
        />
      </PageActions>
      <>{currentPageView()}</>
    </div>
  );
}
