import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../api/service";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";

export function IssuerProcessorForm(props) {
  const [issuers, setIssuers] = useState([]);
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [initialValues, setInitialValues] = useState({
    issuerCode: "",
    binGroupId: "",
  });
  const validationSchemaObject = {
    issuerCode: Yup.string().required("Required"),
    binGroupId: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
      .max(15, "Bin Group ID cannot exceed 15 characters!"),
  };
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;

  useEffect(() => {
    //not in use -- for now
    if (props.isUpdate) {
      const { issuerCode, binGroupId } = props.issuerProcessor;
      setInitialValues({
        issuerCode,
        binGroupId,
      });
    }
  }, [props.isUpdate, props.issuerProcessor]);

  const handleResponse = (isUpdate) => {
    const message = `Issuer Processor successfully ${
      isUpdate ? "updated" : "created"
    }!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  // not set up -- for now
  const updateIssuerProcessor = async (payload) => {
    const url = `${BACK_OFFICE_API.ISSUER_PROCESSORS.ISSUER_PROCESSOR}/${props.issuerProcessor.code};`;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      const isUpdate = true;
      handleResponse(isUpdate);
    } catch (error) {
      handleError(error);
    }
  };

  const createIssuerProcessor = async (payload) => {
    const url = BACK_OFFICE_API.ISSUER_PROCESSORS.ISSUER_PROCESSOR;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async (payload) => {
    setToastType("");
    setMessage("");
    const { status, ...rest } = payload;
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      let formattedPayload = {
        ...rest,
        thirdPartyCode: props.thirdPartyCode,
        // active: status,
      };
      if (props.isUpdate) {
        updateIssuerProcessor(formattedPayload);
      } else {
        createIssuerProcessor(formattedPayload);
      }
    } else {
      const confirmationPayload = props.isUpdate
        ? HELPER.TRIM_OBJECT({
            ...payload,
            status: status ? "Active" : "Inactive",
          })
        : HELPER.TRIM_OBJECT(payload);
      setDetails(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  useEffect(() => {
    const getIssuersList = () => {
      API_SERVICE.MAKE_GET_REQUEST(BACK_OFFICE_API.ISSUERS.GET_ISSUERS_LIST)
        .then((response) => {
          if (response.success) {
            setIssuers(response?.result);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getIssuersList();
  }, []);

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
          onclick={handleClose}
        />
        <AppButton
          loading={loading}
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
        />
      </FormActions>
    );
  };
  const renderFormAlert = () => {
    return message ? (
      <CustomToast
        title={toastType === "error" ? "Error" : "Success"}
        description={message}
        type={toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };
  const renderViewThirdParty = () => {
    return (
      <Optional showIf={currentFormIndex === 1}>
        <p className="modal-title p-text-left mb-1">Issuer Processor Details</p>
        <div className="custom-modal-item">
          <FormattedDetails details={details} />
        </div>
      </Optional>
    );
  };
  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
        <p className="modal-title p-text-left ">{props.title}</p>
        <p className="modal-subtitle -mt-3">{props.subtitle}</p>
        <div className="custom-modal-item">
          <AppFormDropdown
            value={values.issuerCode}
            onchange={(e) => {
              setFieldValue("issuerCode", e?.target?.value);
            }}
            options={issuers}
            optionLabel="name"
            optionValue="code"
            placeholder="Select issuer"
            label="Issuer"
            error={errors?.issuerCode}
            field="issuerCode"
            required
          />
          <AppFormInput
            label="Bin Group ID"
            name="binGroupId"
            type="text"
            placeholder="Input bin group ID"
            required
          />
        </div>
      </Optional>
    );
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderViewThirdParty()}
            {renderFormAlert()}
            {renderFormActions()}
          </Form>
        )}
      </Formik>
    </div>
  );
}
