import { PageNotFound } from "../../shared/components/404/404-page";
import { Dashboard } from "../../shared/components/dashboard/dashboard";
import { ChangePassword } from "../../shared/pages/change-password/change-password";
import { PublicHolidays } from "../../shared/pages/public-holidays/public-holidays";
import { ManageFinancialInstitution } from "../pages/financial-institutions/financial-institutions";
import { GlobalConfigurations } from "../pages/global-configurations/global-configurations";
import { FeeRules } from "../pages/fee-rules/fee-rules";
import { Fees } from "../pages/fees/fees";
import { ThirdPartyCategory } from "../pages/third-party-category/third-party-category";
import { ThirdParties } from "../pages/third-parties/third-parties";
import { ManageIssuers } from "../pages/issuers/issuers";
import { Merchants } from "../pages/merchants/merchants";
import { ManageNetworks } from "../pages/networks/networks";
import { SettlementRules } from "../pages/settlement-rules/settlement-rules";
import { MonnifySettlements } from "../pages/settlements/monnify-settlement";
import { NibbsSettlement } from "../pages/settlements/nibbs-settlement";
import { Transactions } from "../pages/transactions/transactions";
import { UserManagement } from "../pages/user-management/user-management";
import { AllWorkflowRequests } from "../pages/workflow/all-workflow-requests";
import { SelfWorkflowRequests } from "../pages/workflow/self-workflow-requests";
import { ManageAcquirers } from "../pages/acquirers/acquirers";
import { FeeSources } from "backoffice/pages/fee-sources/fee-sources";
import { FeeReports } from "backoffice/pages/fee-reports/fee-reports";
import { PartyUsers } from "backoffice/pages/party-users/party-users";
import { ManageSecretKey } from "backoffice/pages/secret-key/secret-key";
import PartyTransactions from "backoffice/pages/transactions/party-transactions";
import TeamaptDisputes from "backoffice/pages/disputes/teamapt-disputes";
import PartyDisputes from "backoffice/pages/disputes/party-disputes";
import { Terminals } from "backoffice/pages/terminal/terminal";
import { DisputeConfiguration } from "backoffice/pages/dispute-config/dispute-config";
import { NssConfigurations } from "backoffice/pages/nss-configurations/nss-configurations";
import { BulkMerchantTerminal } from "backoffice/pages/bulk-merchant-terminal/bulk-merchant-terminal";
import { NssSettlementSessions } from "backoffice/pages/nss-settlement-sessions/nss-settlement-sessions";
import { TransactionReports } from "backoffice/pages/transaction-reports/transaction-reports";

export const backofficeRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/issuers",
    element: <ManageIssuers />,
  },
  {
    path: "/financial-institutions",
    element: <ManageFinancialInstitution />,
  },
  {
    path: "/financial-institutions/acquirers",
    element: <ManageAcquirers />,
  },
  {
    path: "/user-management",
    element: <UserManagement />,
  },
  {
    path: "/party-users",
    element: <PartyUsers />,
  },
  {
    path: "/settlements/monnify-settlements",
    element: <MonnifySettlements />,
  },
  {
    path: "/settlements/nibbs-settlements",
    element: <NibbsSettlement />,
  },
  {
    path: "/third-parties/third-party-categories",
    element: <ThirdPartyCategory />,
  },
  {
    path: "/third-parties/third-parties",
    element: <ThirdParties />,
  },

  {
    path: "/third-parties/merchant",
    element: <Merchants />,
  },
  {
    path: "/third-parties/bulk-merchant-terminal",
    element: <BulkMerchantTerminal />,
  },
  {
    path: "/fee-configuration/fee-rule",
    element: <FeeRules />,
  },
  {
    path: "/fee-reports",
    element: <FeeReports />,
  },
  {
    path: "/settlement-configuration/networks",
    element: <ManageNetworks />,
  },
  {
    path: "/settlement-configuration/settlement-rules",
    element: <SettlementRules />,
  },
  {
    path: "/configurations",
    element: <GlobalConfigurations />,
  },
  {
    path: "/workflow-requests/all",
    element: <SelfWorkflowRequests />,
  },
  {
    path: "/workflow-requests/self",
    element: <AllWorkflowRequests />,
  },
  {
    path: "/fee-configuration/fees",
    element: <Fees />,
  },
  {
    path: "/fee-configuration/fee-source",
    element: <FeeSources />,
  },
  {
    path: "/account/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/dispute-configuration",
    element: <DisputeConfiguration />,
  },
  {
    path: "/transactions",
    element: <Transactions />,
  },
  {
    path: "/transaction-reports",
    element: <TransactionReports />,
  },
  {
    path: "/third-party-transactions",
    element: <PartyTransactions />,
  },
  {
    path: "/public-holidays",
    element: <PublicHolidays />,
  },
  {
    path: "/terminal",
    element: <Terminals />,
  },
  {
    path: "/integration-details",
    element: <ManageSecretKey />,
  },
  {
    path: "/disputes",
    element: <TeamaptDisputes />,
  },
  {
    path: "/party-disputes",
    element: <PartyDisputes />,
  },
  {
    path: "/nss-configurations",
    element: <NssConfigurations />,
  },
  {
    path: "/nss-settlement-sessions",
    element: <NssSettlementSessions />,
  },
  {
    path: "/*",
    element: <PageNotFound />,
  },
];
