import { CONFIG } from "core/configs/configs";

const GET_PARTY_USERS = `/management/api/${CONFIG.API_VERSION}/party-office/users`;
const CREATE_PARTY_USER = `/management/api/${CONFIG.API_VERSION}/party-office/user`;
const GET_ROLES = `/management/api/${CONFIG.API_VERSION}/party-office/user/roles`;
const RESET_PASSWORD = `/management/api/${CONFIG.API_VERSION}/party-office/user/reset-password`;
const UPDATE_USER_STATUS = `/management/api/${CONFIG.API_VERSION}/party-office/user/status`;
const GET_SINGLE_PARTY_USER = `/management/api/${CONFIG.API_VERSION}/party-office/user`;

export const PARTY_USER_ROUTES = {
  GET_PARTY_USERS,
  CREATE_PARTY_USER,
  GET_ROLES,
  RESET_PASSWORD,
  UPDATE_USER_STATUS,
  GET_SINGLE_PARTY_USER,
};
