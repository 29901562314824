import { CONFIG } from "core/configs/configs";

const GET_THIRD_PARTY_CATEGORY_LIST = `/management/api/${CONFIG.API_VERSION}/third-party-category/list`;
const GET_THIRD_PARTY_CATEGORY = `/management/api/${CONFIG.API_VERSION}/third-party-category`;
const CREATE_THIRD_PARTY_CATEGORY = `/management/api/${CONFIG.API_VERSION}/third-party-category`;
const UPDATE_THIRD_PARTY_CATEGORY = `/management/api/${CONFIG.API_VERSION}/third-party-category`;

export const THIRD_PARTY_CATEGORY_ROUTES = {
  GET_THIRD_PARTY_CATEGORY,
  GET_THIRD_PARTY_CATEGORY_LIST,
  CREATE_THIRD_PARTY_CATEGORY,
  UPDATE_THIRD_PARTY_CATEGORY,
};
