import { Icon } from "../icons/icon";

export function ErrorView({ onReload, error }) {
  return (
    <div className="text-center">
      <div className="empty-container">
        <Icon icon="error-message-icon" />
        <div className="text-center">
          <p className="empty-text notification-tex">{error}</p>
        </div>
        <div className="mt-3">
          <button onClick={onReload} className="primary-button">
            Reload
          </button>
        </div>
      </div>
    </div>
  );
}
