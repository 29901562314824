import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { useNotification } from "core/hooks/useNotification";
import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { FEE_RULE_IDENTIFIER_LOGICAL_OPERATORS } from "shared/constants";
import { API_SERVICE } from "api/service";

export function FeeRuleIdentifiersForm(props) {
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    key: "",
    value: "",
    status: "",
    logicalOperator: "",
  });
  const [validationSchemaObject, setValidationSchemaObject] = useState({
    key: Yup.string()
      .max(100, `Too long, maximum length is 100 characters`)
      // .matches(/^[^\s_]+$/, "No spaces or underscores allowed")
      .required("Required"),
    value: Yup.string()
      .max(100, `Too long, maximum length is 200 characters`)
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
      .required("Required"),
  });
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [formValues, setFormValues] = useState(null);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (props.isUpdate) {
      setValidationSchemaObject({
        value: Yup.string()
          .max(100, `Too long, maximum length is 200 characters`)
          .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
          .required("Required"),
        status: Yup.boolean().required("Required"),
      });
      const { value, active, logicalOperator, key } = props.identifier;
      setInitialValues({
        value,
        logicalOperator,
        key,
        status: active,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isUpdate]);

  const handleResponse = (response, resetForm, isCreate) => {
    const message = `Fee rule identifier successfully ${
      isCreate ? "created" : "updated"
    }!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
    if (!props.isUpdate) {
      resetForm({
        values: {
          key: "",
          value: "",
        },
      });
    }
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const createFeeRule = async (payload, resetFormFuntion) => {
    const url = BACK_OFFICE_API.FEE_RULE_IDENTIFIER.FEE_IDENTIFIER;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(response, resetFormFuntion, isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateFeeRule = async (payload, resetFormFuntion) => {
    const url = BACK_OFFICE_API.FEE_RULE_IDENTIFIER.FEE_IDENTIFIER;
    try {
      const response = await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.identifier?.code}`,
        payload
      );
      handleResponse(response, resetFormFuntion);
    } catch (error) {
      handleError(error);
    }
  };

  const processSubmission = ({ status, ...payload }, resetFormFuntion) => {
    setLoading(true);
    let formattedPayload = {
      feeRuleCode: props.feeRuleCode,
      ...payload,
    };
    if (props.isUpdate) {
      updateFeeRule({
        ...formattedPayload,
        active: status,
      });
    } else {
      createFeeRule(formattedPayload, resetFormFuntion);
    }
  };

  const processConfirmationViewData = ({ status, ...payload }) => {
    if (props.isUpdate) {
      setFormValues({
        ...payload,
        status: status ? "active" : "inactive",
      });
    } else {
      setFormValues({
        ...payload,
      });
    }
  };

  const submit = async (payload, resetFormFuntion) => {
    setToastType("");
    setMessage("");
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      processSubmission(payload, resetFormFuntion);
    } else {
      processConfirmationViewData(payload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  useEffect(() => {
    const getFeeRuleIdentifierKeys = () => {
      API_SERVICE.MAKE_GET_REQUEST(
        BACK_OFFICE_API.FEE_RULE_IDENTIFIER.FEE_IDENTIFIER_ALL
      )
        .then((response) => {
          setKeys(response);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getFeeRuleIdentifierKeys();
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
              <AppFormDropdown
                value={values.key}
                onchange={(e) => {
                  setFieldValue("key", e?.target?.value);
                }}
                options={keys}
                optionLabel="name"
                optionValue="code"
                placeholder="Select key"
                label="Fee Rule Identifier Key"
                error={errors?.key}
                field="key"
                required
                filter
                disabled={props.isUpdate}
              />

              <AppFormDropdown
                value={values.logicalOperator}
                onchange={(e) => {
                  setFieldValue("logicalOperator", e?.target?.value);
                }}
                options={FEE_RULE_IDENTIFIER_LOGICAL_OPERATORS}
                optionLabel="name"
                optionValue="code"
                placeholder="Select a logical operator"
                label="Logical Operator"
                error={errors?.key}
                field="logicalOperator"
                disabled={props.isUpdate}
                required
              />
              <AppFormInput
                required
                label="Value"
                name="value"
                type="text"
                placeholder="Enter value"
                disabled={props.isUpdate}
              />
              <Optional showIf={props.isUpdate}>
                <div>
                  <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    Status:
                  </p>
                  <div className="flex default p-mb-4">
                    <FormRadioInput
                      id="active"
                      value={true}
                      name="Status"
                      checked={values.status === true}
                      handleChange={(e) => setFieldValue("status", true)}
                      label="Active"
                    />
                    <FormRadioInput
                      id="Inactive"
                      value={false}
                      name="Status"
                      checked={values.status === false}
                      handleChange={(e) => setFieldValue("status", false)}
                      label="Inactive"
                    />
                  </div>
                </div>
              </Optional>
            </Optional>
            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <div className="custom-modal-item">
                <FormattedDetails details={formValues} />
              </div>
            </Optional>
            {message && (
              <CustomToast
                title={toastType === "error" ? "Error" : "Success"}
                description={message}
                type={toastType}
                closeable={false}
                inApp={true}
              />
            )}
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
                onclick={handleClose}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
                loading={loading}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
