import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { Optional } from "../../../shared/components/optional/optional";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { PartySourceInfoForm } from "../party-source-info/party-source-info-form";
import { THIRD_PARTY_ENUMS } from "shared/constants";
import { Steps } from "primereact/steps";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import useNetworkForm from "./use-network-form";

export function NetworkForm(props) {
  const { actions, api, formInfo } = useNetworkForm(
    props,
    THIRD_PARTY_ENUMS.NETWORK
  );
  const { submit, handleClose, goBack } = actions;
  const {
    PRIMARY_INFO_INDEX,
    CONNECTION_INFO_INDEX,
    initialValues,
    validationObject,
    stepWizardModel,
    currentFormIndex,
    closeBackButtonText,
    submitNextButtonText,
  } = formInfo;
  const { loading, toast, networkInfo, partySourceInfo } = api;
  const { message, type: toastType } = toast;

  const renderFormActions = (errors) => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={closeBackButtonText}
          onclick={handleClose}
        />
        <AppButton
          loading={loading}
          type="submit"
          buttonStyle="primary"
          text={submitNextButtonText}
        />
      </FormActions>
    );
  };

  const renderFormAlert = () => {
    return message ? (
      <CustomToast
        title={toastType === "error" ? "Error" : "Success"}
        description={message}
        type={toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };

  const renderStepWizard = (values, setFieldValue, errors) => {
    switch (currentFormIndex) {
      case PRIMARY_INFO_INDEX:
        return (
          <div className="custom-modal-item">
            <AppFormInput
              label="Network Name"
              name="name"
              type="text"
              placeholder="Input network name"
              required={true}
            />

            <Optional showIf={props.isUpdate}>
              <div>
                <p
                  className="m-0 text-sm font-bold mb-2 block text-left"
                  style={{ color: "#566a7f" }}
                >
                  Status: <span style={{ color: "red" }}> * </span>
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="Status"
                    checked={values.status === true}
                    handleChange={(e) => setFieldValue("status", true)}
                    label="Active"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="Status"
                    checked={values.status === false}
                    handleChange={(e) => setFieldValue("status", false)}
                    label="Inactive"
                  />
                </div>
              </div>
            </Optional>
          </div>
        );
      case CONNECTION_INFO_INDEX:
        return (
          <PartySourceInfoForm
            partySourceInfo={partySourceInfo}
            isUpdate={props.isUpdate}
            partyName={networkInfo.name}
            partyTypeId={networkInfo.code}
            partyType={THIRD_PARTY_ENUMS.NETWORK}
            isConnectionStep={true}
            goBack={() => goBack()}
            closeModal={props.closeModal}
          />
        );
      default:
        break;
    }
  };

  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <>
        <p className="modal-title p-text-left ">{props.title}</p>
        <p className="modal-subtitle mt-0 -mt-3">{props.subtitle}</p>
              <Steps activeIndex={currentFormIndex} model={stepWizardModel} />
        {loading ? (
          <div className="loading-container text-center">
            <CustomLoader loadingText="Loading..." />
          </div>
        ) : (
          renderStepWizard(values, setFieldValue, errors)
        )}
      </>
    );
  };

  const renderFormView = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderFormAlert()}
            {currentFormIndex === PRIMARY_INFO_INDEX
              ? renderFormActions(errors)
              : null}
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderFormView()}</div>;
}
