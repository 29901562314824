import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { PartyTypeFormObserver } from "./party-type-form-observer";
import { useState } from "react";
import { PARTY_TYPES, THIRD_PARTY_ENUMS } from "shared/constants";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";
import { Optional } from "shared/components/optional/optional";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";

export function PartyTypePartyIdentifierFormInputs({
  values,
  setFieldValue,
  errors,
  partyTypes,
  identifierField = "partyIdentifier",
  partyTypeField = "partyType",
  partyTypePlaceholder = "Select party type",
  identiferPlaceholder = "Select identifier",
  partyTypeLabel = "Party Type",
  identifierLabel = "Party Identifier",
  isRequiredField = true,
}) {
  const [partyIdentifiers, setPartyIdentifiers] = useState([]);
  const [identiferMeta, setIdentifierMeta] = useState({
    optionLabel: "name",
    optionValue: "code",
  });
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const formattedThirdPartiesOptions = filteredThirdParties?.length
    ? filteredThirdParties
    : partyIdentifiers;

  return (
    <>
      <AppFormDropdown
        value={values[partyTypeField]}
        onchange={(e) => {
          const value = e?.target?.value;
          setFieldValue(partyTypeField, value);
        }}
        options={partyTypes || PARTY_TYPES}
        optionLabel="name"
        optionValue="value"
        placeholder={partyTypePlaceholder}
        label={partyTypeLabel}
        error={errors[partyTypeField]}
        field={partyTypeField}
        required={isRequiredField}
      />
      <PartyTypeFormObserver
        onSetPartyIdentifiersData={setPartyIdentifiers}
        onSetIdentifierMeta={setIdentifierMeta}
      />
      <Optional
        showIf={values[partyTypeField] === THIRD_PARTY_ENUMS.THIRD_PARTY}
      >
        <ThirdPartiesDropdown
          values={values}
          onFilter={filterThirdPartiesByName}
          filter={true}
          label={identifierLabel}
          placeholder={identiferPlaceholder}
          isRequired={isRequiredField}
          field={identifierField}
          thirdParties={formattedThirdPartiesOptions}
          filtering={filtering}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </Optional>
      <Optional
        showIf={values[partyTypeField] !== THIRD_PARTY_ENUMS.THIRD_PARTY}
      >
        <AppFormDropdown
          value={values[identifierField]}
          onchange={(e) => {
            setFieldValue(identifierField, e?.target?.value);
          }}
          options={partyIdentifiers}
          optionLabel={identiferMeta.optionLabel}
          optionValue={identiferMeta.optionValue}
          placeholder={identiferPlaceholder}
          label={identifierLabel}
          error={errors[identifierField]}
          field={identifierField}
          required={isRequiredField}
          filter
          disabled={!values[partyTypeField]}
        />
      </Optional>
    </>
  );
}
