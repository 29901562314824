import React, { useCallback, useEffect, useState } from "react";
import { AppButton } from "shared/components/app-button/app-button";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { Optional } from "shared/components/optional/optional";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { HELPER } from "core/helper/helper";
import { API_SERVICE } from "api/service";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { ImageView } from "shared/components/image-view/image-view";
import { convertObjectToText } from "./transaction-details-helpers";
import { ErrorView } from "shared/components/error-view/error-view";
import { EmptyView } from "shared/components/empty-view/empty-view";
import TransactionDisputeHistoryLog from "./dispute-history-log";

const TransactionDispute = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [disputeDetails, setDisputeDetails] = useState({});
  const [showReceiptData, setShowReceiptData] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [statusLogInfo, setStatusLogInfo] = useState([]);
  const shouldShowDispute =
    HELPER.HAS_AUTHORITY("switch_log_dispute") &&
    Object.keys(disputeDetails).length === 0;

  const getDisputeDetails = useCallback(async () => {
    try {
      const { statusLogInfo, receiptData, ...response } =
        await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.DISPUTES.GET_DISPUTE}/${props.transactionSearchKey}`
        );
      const formattedResponse = {
        ...response,
        receiptData: {
          type: "hyperlink",
          name: "receiptData",
          userFriendlyText: "View",
          value: receiptData,
          fn: () => viewReceiptData(),
        },
        status: {
          type: "button",
          value: response.status,
          name: "status",
          fn: () => {
            showHistoryLog();
          },
          buttonText: "History log",
        },
      };
      setDisputeDetails(formattedResponse);
      const formattedStatusLogInfo = statusLogInfo
        ? JSON.parse(statusLogInfo)
        : [];
      setStatusLogInfo(formattedStatusLogInfo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
    }
  }, [props.transactionSearchKey]);

  const handleCopyToClipboard = () => {
    const formattedDetailsToCopy = {
      ...disputeDetails,
      status: disputeDetails.status.value,
      receiptData: disputeDetails.receiptData.value,
    };
    const disputeDetailsText = convertObjectToText(formattedDetailsToCopy);
    navigator.clipboard.writeText(disputeDetailsText).then(
      () => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      },
      () => {
        console.error("Failed to copy");
      }
    );
  };

  useEffect(() => {
    getDisputeDetails();
  }, [props.transactionSearchKey, getDisputeDetails]);

  const reload = () => {
    getDisputeDetails();
  };

  const goBack = () => {
    setCurrentIndex(0);
    reload();
  };
  const viewReceiptData = () => {
    setShowReceiptData(true);
  };
  const closeReceiptData = () => {
    setShowReceiptData(false);
  };

  const showHistoryLog = () => {
    setCurrentIndex(1);
  };

  const renderErrorView = () => {
    return (
      <Optional showIf={!loading && error}>
        <div className="mt-5 text-center">
          <ErrorView onReload={reload} error={error} />
        </div>
      </Optional>
    );
  };

  const renderEmptyView = () => {
    return (
      <Optional
        showIf={Object.keys(disputeDetails).length === 0 && !loading && !error}
      >
        <div className="mt-5 text-center">
          <EmptyView
            emptyText={
              "No dispute information is available for this transaction"
            }
          />
        </div>
      </Optional>
    );
  };

  const renderCurrentView = () => {
    switch (currentIndex) {
      case 0:
        return renderDisputeDetailsView();
      case 1:
        return renderDisputeHistoryLog();
      default:
        break;
    }
  };

  const renderDisputeHistoryLog = () => {
    return (
      <TransactionDisputeHistoryLog logs={statusLogInfo} goBack={goBack} />
    );
  };

  const renderDisputeDetailsView = () => {
    return (
      <Optional
        showIf={!loading && !error && disputeDetails.transactionSearchKey}
      >
        <div className="p-3 d-flex flex-column ">
          <div className="align-self-end">
            <AppButton
              disabled={isCopied}
              text={isCopied ? "Copied to clipboard!!" : "Copy to clipboard"}
              type="button"
              buttonStyle={`bare ${isCopied ? "text-green-400" : ""}`}
              onclick={() => handleCopyToClipboard()}
              icon={isCopied ? "check" : "clone"}
            />
            <Optional showIf={shouldShowDispute}>
              <AppButton
                text="Log Dispute"
                type="button"
                buttonStyle="primary"
              />
            </Optional>
          </div>
          <div>
            <p className="pt-2 text-lg">Dispute Information</p>
            <div className="p-grid p-mt-2">
              <div className="p-col-8" />
            </div>
            <FormattedDetails details={disputeDetails} />
            <Optional showIf={showReceiptData}>
              <ImageView
                close={closeReceiptData}
                image={disputeDetails?.receiptData?.value}
              />
            </Optional>
          </div>
        </div>
      </Optional>
    );
  };

  return (
    <div className="text-left">
      <Optional showIf={loading}>
        <div className="mt-5 text-center">
          <CustomLoader loadingText="Loading transaction details..." />
        </div>
      </Optional>
      {renderErrorView()}
      {renderEmptyView()}
      {renderCurrentView()}
    </div>
  );
};

export default TransactionDispute;
