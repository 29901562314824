import { CONFIG } from "../../core/configs/configs";
import { api, getAuthHeaders } from "../client";

const LOGIN_URL = `/management/api/${CONFIG.API_VERSION}/common/authentication/login`;
const CHANGE_PASSWORD_URL = `/management/api/${CONFIG.API_VERSION}/common/authentication/change-password`;

const LOGIN = (payload) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    api
      .post(LOGIN_URL, payload, { headers: headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
};

const CHANGE_PASSWORD = (token, payload) => {
  const headers = token
    ? {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    : getAuthHeaders();
  return new Promise((resolve, reject) => {
    api
      .put(CHANGE_PASSWORD_URL, payload, { headers: headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
};

export const AUTH_SERVICE = {
  LOGIN,
  CHANGE_PASSWORD,
};
