import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { ParticipantForm } from "./participant-form";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { ParticipantsFilter } from "./participants-filter";
import { API_SERVICE } from "api/service";
import { MODAL_TYPES } from "shared/constants";

export function ManageParticipants(props) {
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Participant Type", value: "partyType" },
    { label: "Participant Name", value: "name" },
    { label: "Credit Participant", value: "isCredit" },
    { label: "Fee Name", value: "feeName" },
    { label: "Status", value: "active" },
    { label: "Creation Date", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { addNotification } = useNotification();
  const [loadingFee, setLoadingFee] = useState(false);
  const [feeDetails, setFeeDetails] = useState({});
  const [filterParams, setFilterParams] = useState(null);

  const getFeeDetails = async (selectedFee) => {
    const relatedParticipant = participants.find(
      (e) => e.feeName === selectedFee
    );
    const feeCode = relatedParticipant.feeCode;
    setLoadingFee(true);
    toggleModal(MODAL_TYPES.OTHER_MODAL);
    const url = BACK_OFFICE_API.FEES.FEES;
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(`${url}/${feeCode}`);
      const {
        type,
        percentage,
        createdAt,
        updatedAt,
        active,
        code,
        flat,
        ...formattedResult
      } = HELPER.changeNullValuesToEmptyStrings(response);
      setFeeDetails({
        ...formattedResult,
        feeType: type?.desc,
        percentage: percentage ? `${percentage}%` : percentage,
        flatFee: flat,
      });
      setLoadingFee(false);
    } catch (error) {
      closeModal();
      setLoadingFee(false);
      addNotification({
        message: HELPER.PROCESS_ERROR(error, "TOAST"),
        type: "error",
      });
    }
  };

  const getParticipants = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        feeRule: props.feeRuleCode,
        ...filter,
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.PARTICIPANT.PARTICIPANT}?${params}`
        );
        setPagination(response);
        const formattedData = response?.content?.map((participant) => {
          return {
            ...participant,
            isCredit: participant.isDebit ? "no" : "yes",
          };
        });
        setParticipants(formattedData);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error, "TOAST"),
          type: "error",
        });
      }
    },
    [pageNo, addNotification, props.feeRuleCode]
  );

  useEffect(() => {
    if (!filterParams) {
      getParticipants();
    } else {
      getParticipants(filterParams);
    }
  }, [getParticipants, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getParticipants();
    }
  }

  const handleTableAction = (data, action) => {
    const { active, isCredit, updatedAt, createdBy, createdAt, ...rest } = data;

    switch (action) {
      case "UPDATE":
        setSelectedParticipant({
          ...rest,
          updatedAt,
          createdAt,
          status: active,
          creditParticipant: isCredit,
        });
        toggleModal(MODAL_TYPES.UPDATE_MODAL);
        break;
      case "VIEW":
        const {
          participantId,
          partyTypeId,
          feeRuleCode,
          isDebit,
          feeCode,
          participantName,
          ...others
        } = rest;
        setSelectedParticipant({
          ...others,
          createdAt,
          status: active,
          creditParticipant: isCredit,
        });
        toggleModal(MODAL_TYPES.VIEW_MODAL);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  const handleParticipantsFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  const renderViewFeeModalContent = () => {
    if (loadingFee) {
      return (
        <div className="flex align-items-center justify-content-center h-full">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    }
    return (
      <>
        <p className="modal-title p-text-left mb-1">Fee Details</p>
        <div className="custom-modal-item">
          <FormattedDetails details={feeDetails} />
          <div className="flex align-items-center justify-content-center">
            <AppButton
              type="button"
              buttonStyle="primary"
              text="Dismiss"
              onclick={closeModal}
            />
          </div>
        </div>
      </>
    );
  };

  function modalContent() {
    switch (modalIndex) {
      case MODAL_TYPES.CREATE_MODAL:
        return (
          <ParticipantForm
            title={"Create Participant"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isCreate={true}
            {...props}
          />
        );
      case MODAL_TYPES.UPDATE_MODAL:
        return (
          <ParticipantForm
            title={"Update Participant"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isUpdate={true}
            {...props}
            participant={selectedParticipant}
          />
        );
      case MODAL_TYPES.VIEW_MODAL:
        return (
          <>
            <p className="modal-title p-text-left mb-1">Participant Details</p>
            <div className="custom-modal-item">
              <FormattedDetails details={selectedParticipant} />
              <div className="flex align-items-center justify-content-center">
                <AppButton
                  type="button"
                  buttonStyle="primary"
                  text="Dismiss"
                  onclick={closeModal}
                />
              </div>
            </div>
          </>
        );
      case MODAL_TYPES.FILTER_MODAL:
        return (
          <ParticipantsFilter
            onGetParticipants={handleParticipantsFilter}
            closeModal={closeModal}
          />
        );
      case MODAL_TYPES.OTHER_MODAL:
        return renderViewFeeModalContent();
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          clickableItems={[{ name: "feeName", fn: getFeeDetails }]}
          authorities={[
            { label: "UPDATE", value: "switch_update_participant" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No participants found!"}
          search={true}
          reload={reload}
          error={error}
          items={participants}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "VIEW"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-participants">
      <>
        <CustomModal
          hasOwnModalHeader={false}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
        />
      </>
      <PageActions>
        <AppButton
          icon="filter"
          text="Filter"
          type="button"
          buttonStyle="bare"
          margin={"mr-2"}
          onclick={() => toggleModal(MODAL_TYPES.FILTER_MODAL)}
        />
        <Optional showIf={HELPER.HAS_AUTHORITY("switch_create_participant")}>
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Participant"
            onclick={() => {
              toggleModal(MODAL_TYPES.CREATE_MODAL);
            }}
          />
        </Optional>
      </PageActions>
      <>{currentPageView()}</>
    </div>
  );
}
