import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";

export function ThirdpartyFilter(props) {
  const [initialValues] = useState({
    name: "",
  });
  const validationSchemaObject = {
    name: Yup.string()
      .max(50, `Too long, maximum length is 50 characters`)
      // .required("Required"),
  };

  const submit = async (payload) => {
    props.onGetThirdparties(payload.name);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <AppFormInput
              label="Name"
              name="name"
              type="text"
              placeholder="Enter name"
              required={true}
            />
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

