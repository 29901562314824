import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { LOCAL_STORAGE_SERVICE } from "core/services/storage-service";
import { useCallback, useEffect, useState } from "react";

const useSecretKeyFeatures = () => {
  const [thirdPartyGlobalId, setThirdPartyGlobalId] = useState("");
  const [sourceNodeId, setSourceNodeId] = useState("");
  const { addNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  const handleSuccess = useCallback(
    (result, isGenerate) => {
      if (isGenerate) {
        addNotification({
          message: "Secret key generated successfully!",
          type: "success",
        });
      }
      setSecretKey(result.secretKey);
      setUpdatedAt(HELPER.FORMAT_DATE(result.updatedAt));
      setLoading(false);
    },
    [addNotification]
  );

  const handleError = useCallback(
    (error, isSourceNodeIdEmpty) => {
      setLoading(false);
      addNotification({
        message: isSourceNodeIdEmpty ? error : HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    },
    [addNotification]
  );

  const getSecretKey = useCallback(
    async (globalId) => {
      setLoading(true);
      const path = BACK_OFFICE_API.THIRD_PARTY_CRED.THIRD_PARTY_CRED;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${path}/${globalId}`
        );
        const result = response?.result;
        handleSuccess(result);
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, handleSuccess]
  );

  const generateSecretKey = async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      thirdPartyGlobalId,
      sourceNodeId,
    });
    const path = BACK_OFFICE_API.THIRD_PARTY_CRED.THIRD_PARTY_CRED;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(`${path}?${params}`);
      const isGenerate = true;
      const result = response?.result;
      handleSuccess(result, isGenerate);
    } catch (error) {
      handleError(error);
    }
  };

  const getSourceNodeId = useCallback(
    async (partyUserId, partyType) => {
      const params = HELPER.TO_URL_STRING({
        partyType,
        partyTypeId: partyUserId,
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.PARTY_SOURCE_INFO.PARTY_SOURCE_INFO}?${params}`
        );
        const result = response?.result?.content[0]?.sourceNodeId;
        if (result) {
          setSourceNodeId(result);
        } else {
          const isSourceNodeIdEmpty = true;
          handleError("No source node ID found!", isSourceNodeIdEmpty);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [handleError]
  );

  const getThirdPartyGlobalId = useCallback(
    async (partyUserId) => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES}/${partyUserId}`
        );
        const result = response?.result?.thirdPartyGlobalId;
        getSecretKey(response?.result?.thirdPartyGlobalId);
        setThirdPartyGlobalId(result);
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, getSecretKey]
  );

  const retrieveThirdPartyParams = useCallback(
    async (partyUserId, partyType) => {
      try {
        await getThirdPartyGlobalId(partyUserId);
        await getSourceNodeId(partyUserId, partyType);
      } catch (error) {
        handleError(error);
      }
    },
    [handleError, getThirdPartyGlobalId, getSourceNodeId]
  );

  useEffect(() => {
    const partyUserId = LOCAL_STORAGE_SERVICE.GET_PARTY_USER_ID();
    const partyType = LOCAL_STORAGE_SERVICE.GET_PARTY_TYPE();
    retrieveThirdPartyParams(partyUserId, partyType);
  }, [retrieveThirdPartyParams]);

  return { generateSecretKey, loading, updatedAt, secretKey };
};

export default useSecretKeyFeatures;
