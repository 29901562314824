import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";

export function ThirdPartiesDropdown({
  values,
  field = "thirdPartyCode",
  setFieldValue,
  placeholder = "Select Third Party",
  label = "Third Party",
  isRequired,
  filter,
  thirdParties,
  onFilter,
  errors,
  filtering,
  disabled,
}) {
  return (
    <AppFormDropdown
      value={values[field]}
      onchange={(e) => {
        const value = e?.target?.value;
        setFieldValue(field, value);
      }}
      options={thirdParties}
      optionLabel="name"
      optionValue="code"
      placeholder={filtering ? "Loading..." : placeholder}
      label={label}
      error={errors[field]}
      field={field}
      required={isRequired}
      filter={filter}
      loading={filtering}
      disabled={disabled}
      onFilter={(e) => onFilter(e.filter)}
    />
  );
}
