import React from "react";
import { AppFormInput } from "shared/components/form-inputs/form-input";

const DisputeDateSelectors = (props) => {
  return (
    <div className="grid">
      <div className="col-6 p-1">
        <AppFormInput
          label="Start"
          name={`${props.dateType}From`}
          type="date"
          placeholder="Start"
        />
      </div>
      <div className="col-6 p-1">
        <AppFormInput
          label="End"
          name={`${props.dateType}To`}
          type="date"
          placeholder="End"
        />
      </div>
    </div>
  );
};

export default DisputeDateSelectors;
