import { BACK_OFFICE_API } from "api/backofffice/index";
import { HELPER } from "core/helper/helper";
import { useCallback, useEffect, useState } from "react";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { Accordion, AccordionTab } from "primereact/accordion";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { ErrorView } from "shared/components/error-view/error-view";
import { API_SERVICE } from "api/service";

export function TransactionFeeInformation({ transactionSearchKey }) {
  const [loading, setLoading] = useState(true);
  const [feeInformation, setFeeInformation] = useState(null);
  const [participantsFeeInformation, setParticipantsFeeInformation] =
    useState(null);
  const [feeInformationError, setFeeInformationError] = useState(null);
  const [participantFeeInformationError, setParticipantFeeInformationError] =
    useState(null);
  const [
    gettingParticipantFeeInformation,
    setGettingParticipantFeeInformation,
  ] = useState(true);

  const handleError = useCallback((error, type) => {
    const processedError = HELPER.PROCESS_ERROR(error);
    if (type === "participantFee") {
      setParticipantFeeInformationError(processedError);
    } else {
      setFeeInformationError(processedError);
    }
  }, []);

  const getFeeInformation = useCallback(async () => {
    setLoading(true);
    setFeeInformationError(null);
    const url = `${BACK_OFFICE_API.FEE_RULES.FEE_RULE}/transaction/${transactionSearchKey}`;
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(url);
      setFeeInformation(response?.feeRuleResponse);
      setLoading(false);
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  }, [handleError, transactionSearchKey]);

  const getParticipantFeeInformation = useCallback(async () => {
    setGettingParticipantFeeInformation(true);
    setParticipantFeeInformationError(null);
    const query = HELPER.TO_URL_STRING({
      transactionSearchKey,
    });
    const url = `${BACK_OFFICE_API.FEES.PARTICIPANT_FEES}?${query}`;
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(url);
      setParticipantsFeeInformation(response);
      setGettingParticipantFeeInformation(false);
    } catch (error) {
      const errorType = "participantFee";
      handleError(error, errorType);
      setGettingParticipantFeeInformation(false);
    }
  }, [handleError, transactionSearchKey]);

  useEffect(() => {
    getFeeInformation();
    getParticipantFeeInformation();
  }, [getFeeInformation, getParticipantFeeInformation]);

  const getTrimmedParticipantObject = (participant) => {
    const {
      participantName,
      partyType,
      amount,
      active,
      isDebit,
      createdAt,
      updatedAt,
    } = participant;
    return {
      participantName,
      partyType,
      amount,
      active,
      isDebit,
      createdAt,
      updatedAt,
    };
  };

  const renderParticipantInformation = () => {
    return participantsFeeInformation?.map((participant, index) => (
      <div key={participant?.participantId}>
        <h3>{index + 1}.</h3>
        <FormattedDetails details={getTrimmedParticipantObject(participant)} />
      </div>
    ));
  };

  return (
    <div className="transaction-details-accordion p-3">
      <Accordion className="my-4" multiple activeIndex={[0]}>
        <AccordionTab
          header={<span className="text-base">Fee Rule Applied</span>}
        >
          {loading && (
            <CustomLoader loadingText="Fetching participant' information" />
          )}
          {feeInformationError && (
            <ErrorView
              onReload={getFeeInformation}
              error={feeInformationError}
            />
          )}
          {!feeInformationError && !loading && (
            <FormattedDetails details={feeInformation} />
          )}
        </AccordionTab>
        <AccordionTab header={<span className="text-base">Fee Split</span>}>
          {gettingParticipantFeeInformation && (
            <CustomLoader loadingText="Fetching participant' information" />
          )}
          {participantFeeInformationError && (
            <ErrorView
              onReload={getParticipantFeeInformation}
              error={participantFeeInformationError}
            />
          )}
          {!participantFeeInformationError &&
            !gettingParticipantFeeInformation &&
            renderParticipantInformation()}
        </AccordionTab>
      </Accordion>
    </div>
  );
}
