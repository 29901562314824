import React from "react";
import { Transactions } from "./transactions";
import { LOCAL_STORAGE_SERVICE } from "core/services/storage-service";
import { BACK_OFFICE_API } from "api/backofffice/index";

const PartyTransactions = () => {
  const partyId = LOCAL_STORAGE_SERVICE.GET_PARTY_USER_ID();
  const partyType = LOCAL_STORAGE_SERVICE.GET_PARTY_TYPE();

  const url = BACK_OFFICE_API.TRANSACTIONS.GET_PARTY_TRANSACTION_RECORDS;

  const transactionReportGenerationUrl =
    BACK_OFFICE_API.TRANSACTION_REPORT.GENERATE_THIRD_PARTY_TRANSACTION_REPORTS;

  return (
    <Transactions
      url={url}
      partyId={partyId}
      partyType={partyType}
      partyTransactionReportGenerationUrl={transactionReportGenerationUrl}
    />
  );
};

export default PartyTransactions;
