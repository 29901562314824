import { CONFIG } from "core/configs/configs";

const GET_PAGED_ISSUERS = `/management/api/${CONFIG.API_VERSION}/issuer`;
const CREATE_ISSUER = `/management/api/${CONFIG.API_VERSION}/issuer`;
const UPDATE_ISSUER = `/management/api/${CONFIG.API_VERSION}/issuer`;
const GET_ISSUERS_LIST = `/management/api/${CONFIG.API_VERSION}/issuer/list`;

export const ISSUERS_ROUTES = {
  GET_PAGED_ISSUERS,
  GET_ISSUERS_LIST,
  CREATE_ISSUER,
  UPDATE_ISSUER,
};
