import { CONFIG } from "core/configs/configs";

const GET_THIRD_PARTIES = `/management/api/${CONFIG.API_VERSION}/third-party`;
const CREATE_THIRD_PARTY = `/management/api/${CONFIG.API_VERSION}/third-party`;
const UPDATE_THIRD_PARTY = `/management/api/${CONFIG.API_VERSION}/third-party`;

export const THIRD_PARTY_ROUTES = {
  GET_THIRD_PARTIES,
  CREATE_THIRD_PARTY,
  UPDATE_THIRD_PARTY,
};
