import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useEffect, useState } from "react";

export function useList(url) {
  const [list, setList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();

  useEffect(() => {
    const getList = () => {
      setLoading(true);
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((response) => {
          setList(response?.result);
          setError(null);
          setLoading(false);
        })
        .catch((error) => {
          addNotification({
            message: HELPER.PROCESS_ERROR(error),
            type: "error",
          });
          setError(HELPER.PROCESS_ERROR(error));
        });
    };
    getList();
  }, [addNotification, url]);
  return { list, loading, error };
}
