import { CONFIG } from "core/configs/configs";

const GET_PAGED_NETWORKS = `/management/api/${CONFIG.API_VERSION}/network`;
const GET_NETWORKS_FILLER = `/management/api/${CONFIG.API_VERSION}/network/filler`;
const GET_NETWORK = `/management/api/${CONFIG.API_VERSION}/network`;
const UPDATE_NETWORK = `/management/api/${CONFIG.API_VERSION}/network`;
const CREATE_NETWORK = `/management/api/${CONFIG.API_VERSION}/network`;

export const NETWORK_ROUTES = {
  GET_PAGED_NETWORKS,
  GET_NETWORKS_FILLER,
  GET_NETWORK,
  UPDATE_NETWORK,
  CREATE_NETWORK,
};
