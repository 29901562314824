export function TransactionDisputeNotification({
  resolutionStatus,
  logCode,
  onShowDisputeDetails,
}) {
  return (
    <div className="col-12">
      <div className={`info-card ${resolutionStatus?.toLowerCase()}`}>
        <div>
          <span className="info-card-text">
            Dispute Resolution Status: <b> {resolutionStatus}</b>
          </span>
        </div>
        <div className="">
          <span className="info-card-text">
            Dispute Log Code:{" "}
            <button className="bare-button" onClick={onShowDisputeDetails}>
              {logCode}
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}
