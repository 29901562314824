import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { DEFAULT_PAGE_SIZE } from "core/configs/configs";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";

const useThirdParties = () => {
  const { addNotification } = useNotification();
  const [modalFeatures, setModalFeatures] = useState({
    index: 0,
    isVisible: false,
    info: { title: "", subtitle: "" },
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [thirdParty, setThirdParty] = useState([]);
  const [selectedThirdParty, setSelectedThirdParty] = useState(null);
  const [pagination, setPagination] = useState({ pageNo: 0 });
  const [thirdPartyCode, setThirdPartyCode] = useState();
  const [details, setDetails] = useState();
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Category", value: "thirdPartyCategoryName" },
    { label: "Status", value: "active" },
    { label: "Creation Date", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [filterParams, setFilterParams] = useState(null);

  const getThirdParties = useCallback(
    async (name) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pagination.pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...name,
      });

      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES}?${params}`
        );
        setPagination({ ...response.result, pageNo: pagination.pageNo });
        const thirdParties = response?.result?.content;
        setThirdParty(thirdParties);
        setLoading(false);
        setError(null);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pagination.pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getThirdParties();
    } else {
      getThirdParties(filterParams);
    }
  }, [getThirdParties, filterParams]);

  function reload() {
    setFilterParams(null);
    setPagination({ ...pagination, pageNo: 0 });
  }

  function toggleModal(index) {
    setModalFeatures({
      ...modalFeatures,
      index: index,
      isVisible: !modalFeatures.isVisible,
    });
  }

  function closeModal(shouldReload) {
    if (!modalFeatures.index) setPagination({ ...pagination, pageNo: 0 });
    setModalFeatures({
      ...modalFeatures,
      isVisible: false,
    });
    if (shouldReload === true) {
      getThirdParties();
    }
  }
  const handleThirdpartyFilter = (filters) => {
    setFilterParams(filters);
    setModalFeatures({
      ...modalFeatures,
      isVisible: false,
    });
  };

  const handleTableAction = (data, action) => {
    const { active, updatedAt, createdBy, createdAt, code, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setSelectedThirdParty({
          ...rest,
          code,
          active,
          updatedAt,
          createdAt,
          status: active,
        });
        toggleModal(1);
        break;
      case "OPEN":
        setThirdPartyCode(data.code);
        setDetails({ ...rest, status: active });
        setCurrentIndex(1);
        break;
      default:
        break;
    }
  };
  function goBack() {
    setCurrentIndex(0);
  }

  const incrementPageNo = () => {
    setPagination({ ...pagination, pageNo: pagination.pageNo + 1 });
  };
  const decrementPageNo = () => {
    setPagination({ ...pagination, pageNo: pagination.pageNo - 1 });
  };
  const goToPage = (number) => {
    setPagination({ ...pagination, pageNo: number });
  };

  const updateModalInfo = (info) => {
    setModalFeatures({ ...modalFeatures, info });
  };

  const actions = {
    goBack,
    handleTableAction,
    handleThirdpartyFilter,
    closeModal,
    reload,
    toggleModal,
    incrementPageNo,
    decrementPageNo,
    goToPage,
    getThirdParties,
    updateModalInfo,
  };

  const page = {
    loading,
    error,
    currentIndex,
    thirdParty,
    selectedThirdParty,
    thirdPartyCode,
    details,
    pagination,
    modalFeatures,
    tableHeaders,
  };

  return { page, actions };
};

export default useThirdParties;
