import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";
import { HELPER } from "core/helper/helper";

export function FeeSourcesFilter(props) {
  const [initialValues] = useState({
    name: "",
    sourceNodeId: "",
  });
  const validationSchemaObject = {
    name: Yup.string().max(50, `Too long, maximum length is 50 characters`),
    sourceNodeId: Yup.string().max(
      128,
      `Too long, maximum length is 128 characters`
    ),
  };

  const submit = async (payload) => {
    props.onGetFeeSources(HELPER.TRIM_OBJECT(payload));
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <AppFormInput
              label="Name"
              name="name"
              type="text"
              placeholder="Enter name"
              required={false}
            />
            <AppFormInput
              label="Source Node Id"
              name="sourceNodeId"
              type="text"
              placeholder="Enter source node Id"
              required={false}
            />
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
