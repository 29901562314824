import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "../../../api/service";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { IssuerProcessorForm } from "./issuer-processor-form";
import { useNotification } from "core/hooks/useNotification";

export function IssuerProcessors(props) {
  const { addNotification } = useNotification();
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Issuer Name", value: "issuerName" },
    { label: "Bin Group", value: "binGroupId" },
    { label: "Creation Date", value: "createdAt" },
  ];
  const [issuerProcessors, setIssuerProcessors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  // const [details, setDetails] = useState();
  const [selectedIssuerProcessor, setSelectedIssuerProcessor] = useState({});

  const getIssuerProcessors = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
      thirdPartyCode: props.thirdPartyCode,
    });
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.ISSUER_PROCESSORS.ISSUER_PROCESSOR}?${params}`
      );
      setPagination(response.result);
      const issuerProcessors = response?.result?.content;
      setIssuerProcessors(issuerProcessors);
      setError(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({ message: HELPER.PROCESS_ERROR(error), type: "error" });
    }
  }, [pageNo, props.thirdPartyCode, addNotification]);

  useEffect(() => {
    getIssuerProcessors();
  }, [getIssuerProcessors]);

  function reload() {
    getIssuerProcessors();
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getIssuerProcessors();
    }
  }

  const handleTableAction = (data, action) => {
    const {
      formattedStatus,
      active,
      updatedAt,
      createdBy,
      createdAt,
      ...rest
    } = data;
    switch (action) {
      case "UPDATE":
        setSelectedIssuerProcessor({
          ...rest,
          active,
          updatedAt,
          createdAt,
          status: active,
        });
        toggleModal(1);
        break;
      case "VIEW":
        // setDetails({ ...rest, status: formattedStatus });
        // setCurrentIndex(1);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <IssuerProcessorForm
            title={"Create Issuer Processor"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            thirdPartyCode={props.thirdPartyCode}
          />
        );
      case 1:
        return (
          <IssuerProcessorForm
            title={"Update Issuer Processor"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isUpdate={true}
            thirdPartyCode={props.thirdPartyCode}
            issuerProcessor={selectedIssuerProcessor}
          />
        );
      case 2:
        const { createdBy, updatedAt, active, formattedStatus, ...rest } =
          selectedIssuerProcessor;
        return (
          <>
            <p className="modal-title p-text-left mb-1">
              Issuer Processor Details
            </p>
            <div className="custom-modal-item">
              <FormattedDetails
                allCaps={true}
                details={{
                  ...rest,
                }}
              />
            </div>
          </>
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_update_issuer_processor" },
            { label: "VIEW", value: "all" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No issuer processors found!"}
          search={true}
          reload={reload}
          error={error}
          items={issuerProcessors}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "VIEW"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <ErrorBoundary>
      <div className="manage-issuer-processors">
        <>
          <CustomModal
            hasOwnModalHeader={true}
            closeModal={closeModal}
            onHide={closeModal}
            visible={isModalVisible}
            modalContent={modalContent}
          />
        </>
        <Optional
          showIf={
            HELPER.HAS_AUTHORITY("switch_create_issuer_processor") &&
            currentIndex === 0
          }
        >
          <PageActions>
            {/* <AppButton
          icon="filter"
          text="Filter"
          type="button"
          buttonStyle="primary"
          margin={"mr-2"}
          onclick={() => toggleModal(3)}
        /> */}
            <AppButton
              type="button"
              buttonStyle="primary"
              text="New Issuer Processor"
              onclick={() => {
                toggleModal(0);
              }}
            />
          </PageActions>
        </Optional>
        <>{currentPageView()}</>
      </div>
    </ErrorBoundary>
  );
}
