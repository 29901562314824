import { CONFIG } from "core/configs/configs";

const GET_THIRD_PARTY_ACQUIRER = `/management/api/${CONFIG.API_VERSION}/third-party-acquirer/acquirers`;
const GET_ACQUIRER_THIRD_PARTIES = `/management/api/${CONFIG.API_VERSION}/third-party-acquirer/third-parties`;
const CREATE_THIRD_PARTY_ACQUIRER = `/management/api/${CONFIG.API_VERSION}/third-party-acquirer`;
const UPDATE_THIRD_PARTY_ACQUIRER = `/management/api/${CONFIG.API_VERSION}/third-party-acquirer`;

export const THIRD_PARTY_ACQUIRER_ROUTES = {
  GET_THIRD_PARTY_ACQUIRER,
  CREATE_THIRD_PARTY_ACQUIRER,
  UPDATE_THIRD_PARTY_ACQUIRER,
  GET_ACQUIRER_THIRD_PARTIES,
};
