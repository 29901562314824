import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";
import { useFinancialInstitutionsList } from "api/hooks/use-financial-institutions-list";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";

export function NssConfigurationForm(props) {
  const { addNotification } = useNotification();
  const { financialInstitutions } = useFinancialInstitutionsList();
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    financialInstitutionCode: "",
    name: "",
    accountNumber: "",
    active: "",
  });
  const validationSchemaObject = {
    financialInstitutionCode: Yup.string().required("Required"),
    name: Yup.string()
      .max(50, "Name cannot exceed 50 characters!")
      .required("Required"),
    accountNumber: Yup.string()
      .max(50, "Account number cannot exceed 50 characters!")
      .required("Required"),
    active: Yup.boolean(),
  };
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (props.isUpdate) {
      const { financialInstitutionCode, name, accountNumber, active } =
        props.nssConfig;
      setInitialValues({
        financialInstitutionCode,
        name,
        accountNumber,
        active,
      });
      console.log("initial", active);
    }
  }, [props.isUpdate, props.nssConfig]);

  const handleResponse = (isCreate = false) => {
    let notification = {
      type: "success",
      message: `NSS Configuration successfully ${
        isCreate ? "created" : "updated"
      }!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const createNssConfig = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.NSS_CONFIGURATIONS.NSS_CONFIGURATIONS_BASE_URL;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateNssConfig = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.NSS_CONFIGURATIONS.NSS_CONFIGURATIONS_BASE_URL;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.nssConfig?.code}`,
        payload
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const submit = async (payload) => {
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      if (props.isUpdate) {
        updateNssConfig(payload);
      } else {
        createNssConfig(payload);
      }
    } else {
      setFormValues(payload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const renderForm = () => {
    return (
      <div>
        <p className="modal-title p-text-left mb-1">
          {props.isUpdate ? "Update" : "Create"} NSS Config
        </p>
        <p className="modal-subtitle mt-0 mb-1">Fields marked * are required</p>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchemaObject)}
          onSubmit={(values) => {
            submit(values);
          }}
          enableReinitialize={true}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <Optional
                showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
              >
                <FormattedDetails details={formValues} />
              </Optional>
              <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
                <AppFormDropdown
                  value={values.financialInstitutionCode}
                  onchange={(e) => {
                    setFieldValue("financialInstitutionCode", e?.target?.value);
                  }}
                  options={financialInstitutions}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select Financial Institution"
                  label="Financial Institution"
                  error={errors?.financialInstitutionCode}
                  field="financialInstitutionCode"
                  required
                  filter
                />
                <AppFormInput
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  required={true}
                />
                <AppFormInput
                  required={true}
                  label="Account Number"
                  name="accountNumber"
                  type="text"
                  placeholder="Account Number"
                  disabled={false}
                />
                <div className="col-6 pt-0">
                  <Optional showIf={props.isUpdate}>
                    <div>
                      <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                        Status: <span style={{ color: "red" }}> * </span>
                      </p>
                      <div className="flex default p-mb-4">
                        <FormRadioInput
                          id="active"
                          value={true}
                          name="Status"
                          checked={values.active === true}
                          handleChange={(e) => setFieldValue("active", true)}
                          label="Active"
                        />
                        <FormRadioInput
                          id="Inactive"
                          value={false}
                          name="Status"
                          checked={values.active === false}
                          handleChange={(e) => setFieldValue("active", false)}
                          label="Inactive"
                        />
                      </div>
                    </div>
                  </Optional>
                </div>
              </Optional>

              {message && (
                <CustomToast
                  title={toastType === "error" ? "Error" : "Success"}
                  description={message}
                  type={toastType}
                  closeable={false}
                  inApp={true}
                />
              )}
              <FormActions>
                <AppButton
                  type="button"
                  buttonStyle="secondary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                  }`}
                  onclick={handleClose}
                />
                <AppButton
                  type="submit"
                  buttonStyle="primary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"
                  }`}
                  loading={loading}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <>{renderForm()}</>;
}
