import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { HELPER } from "core/helper/helper";
import { useAcquirersList } from "api/hooks/use-acquirers-list";
import { FormInput } from "shared/components/form-component/form-input";
import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { TERMINAL_TYPES } from "shared/constants";

export function TerminalFilter(props) {
  const { acquirers } = useAcquirersList();
  const initialValues = {
    acquirerCode: "",
    terminalId: "",
    terminalType: "",
    from: "",
    to: "",
  };

  const validationSchema = Yup.object().shape({
    acquirerCode: Yup.string(),
    terminalId: Yup.string(),
    terminalType: Yup.string(),
    from: Yup.string(),
    to: Yup.string(),
  });

  const submit = async (values) => {
    const filters = HELPER.TRIM_OBJECT(values);
    props.onGetTerminals(filters);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <p className="modal-title p-text-left ">{props.title}</p>
            <AppFormDropdown
              value={values.acquirerCode}
              onchange={(e) => {
                setFieldValue("acquirerCode", e?.target?.value);
              }}
              options={acquirers}
              optionLabel="financialInstitutionName"
              optionValue="code"
              placeholder="Select Acquirer Name"
              label="Acquirer Name"
              error={errors?.acquirerCode}
              field="acquirerCode"
              filter
            />

            <AppFormInput
              label="Terminal ID"
              name="terminalId"
              type="text"
              placeholder="Enter Terminal ID"
              optionLabel="name"
              optionValue="code"
            />

            <AppFormDropdown
              label="Terminal Type"
              optionLabel="name"
              optionValue="code"
              placeholder="Input terminal type"
              options={TERMINAL_TYPES}
              error={errors?.terminalType}
              value={values.terminalType}
              onchange={(option) => {
                setFieldValue("terminalType", option?.value);
              }}
              field="terminalType"
            />
            <div className="flex w-full ">
              <div className="w-full pr-3">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  From:
                </label>
                <FormInput
                  name="From"
                  fn={(e) => setFieldValue("from", e.target.value)}
                  label="Start date"
                  inputType="date"
                  required={false}
                  value={values.from}
                  field="from"
                  type="INPUT"
                />
              </div>
              <div className="w-full">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  To:
                </label>
                <FormInput
                  name="To"
                  fn={(e) => setFieldValue("to", e.target.value)}
                  label="End date"
                  inputType="date"
                  required={false}
                  value={values.to}
                  field="to"
                  type="INPUT"
                />
              </div>
            </div>
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
