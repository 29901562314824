import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { DEFAULT_PAGE_SIZE } from "core/configs/configs";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";

export const useMerchant = () => {
  const { addNotification } = useNotification();
  const [currentIndex, setCurrenIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [details, setDetails] = useState(null);
  const tableHeaders = [
    { label: "Merchant Name", value: "name" },
    { label: "Card Acceptor Id", value: "cardAcceptorId" },
    { label: "Third Party Name", value: "thirdPartyName" },
    { label: "Created Date", value: "createdAt" },
    { label: "Status", value: "active" },
    { label: "Actions", value: "actions" },
  ];
  const [loading, setLoading] = useState(false);
  const tableAuthorities = [
    { label: "OPEN", value: "all" },
    { label: "UPDATE", value: "switch_update_merchant" },
  ];
  const [merchants, setMerchants] = useState([]);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [filterParams, setFilterParams] = useState(null);

  const getMerchants = useCallback(
    async (filters) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...filters,
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.MERCHANTS.GET_MERCHANTS}?${params}`
        );
        setPagination(response.result);
        const merchants = response?.result?.content;
        setMerchants(merchants);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getMerchants();
    } else {
      getMerchants(filterParams);
    }
  }, [getMerchants, filterParams]);

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getMerchants();
    }
  }

  const goBack = () => {
    setCurrenIndex(0);
  };

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  const handleFilter = ({ name, thirdPartyCode }) => {
    setFilterParams({ name, thirdPartyCode });
    setIsModalVisible(false);
  };

  const handleViewAction = (data) => {
    setDetails(data);
    setCurrenIndex(1);
  };

  const handleUpdateAction = (data) => {
    setDetails(data);
    toggleModal(1);
  };

  const handleTableAction = (data, action) => {
    switch (action) {
      case "UPDATE":
        handleUpdateAction(data);
        return;
      case "OPEN":
        handleViewAction(data);
        return;
      default:
        break;
    }
  };

  const incrementPageNo = () => {
    setPageNo(pageNo + 1);
  };
  const decrementPageNo = () => {
    setPageNo(pageNo - 1);
  };
  const goToPage = (number) => {
    setPageNo(number);
  };

  const api = {
    loading,
    error,
    getMerchants,
  };
  const page = {
    isModalVisible,
    pageNo,
    currentIndex,
    modalIndex,
    tableHeaders,
    tableAuthorities,
  };
  const data = {
    merchants,
    pagination,
    details,
  };
  const pageActions = {
    goToPage,
    decrementPageNo,
    incrementPageNo,
    handleFilter,
    handleTableAction,
    handleViewAction,
    reload,
    goBack,
    closeModal,
    toggleModal,
  };

  return { api, page, data, pageActions };
};
