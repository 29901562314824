import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import { PARTY_TYPES } from "shared/constants";
import { API_SERVICE } from "api/service";
import { useNotification } from "core/hooks/useNotification";
import { HELPER } from "core/helper/helper";

export function PartyTypeFormObserver({
  onSetIdentifierMeta,
  onSetPartyIdentifiersData,
}) {
  const partyTypesWithContentInResponse = useRef([
    "THIRD_PARTY",
    "ISSUER_PROCESSOR",
  ]);
  const { values } = useFormikContext();
  const { addNotification } = useNotification();

  useEffect(() => {
    const getPartyIdentifierData = async () => {
      onSetPartyIdentifiersData([]);
      const selectedPartyType = values.partyType || values.participantType;
      try {
        const partyType = PARTY_TYPES.find(
          (partyType) => partyType.value === selectedPartyType
        );
        const response = await API_SERVICE.MAKE_GET_REQUEST(partyType.apiRoute);
        let data;
        if (
          partyTypesWithContentInResponse.current.includes(selectedPartyType)
        ) {
          data = response?.content
            ? response?.content?.result
            : response?.result?.content;
        } else {
          data = response?.result;
        }
        onSetPartyIdentifiersData(data);
        onSetIdentifierMeta({
          optionLabel: partyType.optionLabel,
          optionValue: partyType.optionValue,
        });
      } catch (error) {
        addNotification({
          type: "error",
          message: HELPER.PROCESS_ERROR(error),
        });
        onSetPartyIdentifiersData([]);
      }
    };
    if (values.partyType || values.participantType) {
      getPartyIdentifierData();
    }
  }, [
    addNotification,
    values.partyType,
    values.participantType,
    onSetIdentifierMeta,
    onSetPartyIdentifiersData,
  ]);
  return null;
}
