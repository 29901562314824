import { BACK_OFFICE_API } from "api/backofffice/index";
import { useNetworksList } from "api/hooks/use-networks-list";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useEffect, useState } from "react";
import { PARTY_TYPES } from "shared/constants";
import * as Yup from "yup";

const usePartySourceForm = (props) => {
  const { addNotification } = useNotification();
  const { networks } = useNetworksList();
  const [toast, setToast] = useState({
    type: null,
    message: null,
  });
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [initialValues, setInitialValues] = useState({
    forwardInstitutionId: "",
    receivingInstitutionId: "",
    sinkNodeId: "",
    sourceNodeId: "",
    network: "",
    status: "",
  });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const validationObj = {
    forwardInstitutionId: Yup.string().max(
      11,
      `Too long, maximum length is 11 characters`
    ),
    receivingInstitutionId: Yup.string().max(
      11,
      `Too long, maximum length is 11 characters`
    ),
    sinkNodeId: Yup.string().max(
      128,
      `Too long, maximum length is 128 characters`
    ),
    sourceNodeId: Yup.string().max(
      128,
      `Too long, maximum length is 128 characters`
    ),
    network: Yup.string(),
  };
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;

  useEffect(() => {
    if (props.isUpdate) {
      const {
        forwardInstitutionId,
        receivingInstitutionId,
        sinkNodeId,
        sourceNodeId,
        network,
        status,
      } = props.partySourceInfo;
      setInitialValues({
        forwardInstitutionId,
        receivingInstitutionId,
        sinkNodeId,
        sourceNodeId,
        network,
        status,
      });
    }
  }, [props.isUpdate, props.partySourceInfo]);

  const processConnectionStepMessage = (partyType, isUpdate) => {
    if (props.isConnectionStep) {
      const party = PARTY_TYPES.find((party) => party.value === partyType);
      return `${party.name} ${isUpdate ? "updated" : "created"} successfully!`;
    }
  };

  const handleResponse = (isUpdate) => {
    const connectionStepMessage = processConnectionStepMessage(
      props.partyType,
      isUpdate
    );
    const regularCreateUpdateMessage = `Connection info successfully ${
      isUpdate ? "updated" : "created"
    }!`;
    const formattedMessage = props.isConnectionStep
      ? connectionStepMessage
      : regularCreateUpdateMessage;
    addNotification({
      message: formattedMessage,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToast({ ...toast, type: "error", message: HELPER.PROCESS_ERROR(error) });
    setLoading(false);
  };

  const updatePartySourceInfo = async (payload) => {
    const url = `${BACK_OFFICE_API.PARTY_SOURCE_INFO.PARTY_SOURCE_INFO}`;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(
        url,
        HELPER.changeNullValuesToEmptyStrings({
          code: props.partySourceInfo.code,
          ...payload,
        })
      );
      const isUpdate = true;
      handleResponse(isUpdate);
    } catch (error) {
      handleError(error);
    }
  };

  const createpartySourceInfo = async (payload) => {
    const url = BACK_OFFICE_API.PARTY_SOURCE_INFO.PARTY_SOURCE_INFO;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(
        url,
        HELPER.changeNullValuesToEmptyStrings(payload)
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const processSubmission = ({ status, ...rest }) => {
    setLoading(true);
    let formattedPayload = HELPER.TRIM_OBJECT({
      ...rest,
      active: status,
      partyType: props.partyType,
      partyTypeId: props.partyTypeId,
      partyName: props.partyName,
    });
    if (props?.partySourceInfo?.code && props?.isUpdate) {
      updatePartySourceInfo(formattedPayload);
    } else {
      createpartySourceInfo(formattedPayload);
    }
  };

  const submit = async (payload) => {
    setToast({ message: "", type: "" });
    if (
      currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX ||
      props.isConnectionStep
    ) {
      processSubmission(payload);
    } else {
      const confirmationPayload = HELPER.TRIM_OBJECT(payload);
      setDetails(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (props.isConnectionStep) {
      props.goBack();
    } else if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const form = {
    toast,
    validationObj,
    initialValues,
    details,
    networks,
    FORM_VIEW_INDEX,
    FORM_DETAILS_CONFIRMATION_INDEX,
    currentFormIndex,
  };
  const api = { loading };
  const actions = {
    handleClose,
    submit,
  };

  return { form, actions, api };
};

export default usePartySourceForm;
