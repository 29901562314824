import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useEffect, useState } from "react";
import { CONFIGURATIONS } from "shared/constants";

const useDisputableCodes = () => {
  const [disputableCodes, setDisputableCodes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const url = `${BACK_OFFICE_API.DISPUTE_CONFIG.GET_DISPUTE_CONFIGURATION}/${CONFIGURATIONS.DISPUTE_RESPONSE_CODES}`;

  useEffect(() => {
    const getDisputableCodes = async () => {
      setLoading(true);
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(url);
        const formattedDisputableCode = response?.configValue
          ?.split(",")
          .map((code) => code.trim());
        setDisputableCodes(formattedDisputableCode);
        setError(null);
      } catch (error) {
        setError(HELPER.PROCESS_ERROR(error));
      } finally {
        setLoading(false);
      }
    };
    getDisputableCodes();
  }, [url]);

  return { disputableCodes, loading, error };
};

export default useDisputableCodes;
