import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FeeForm } from "./fee-form";
import { FeesFilter } from "./fees-filter";
import { API_SERVICE } from "api/service";

export function Fees() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Create Fee",
    subtitle: "All fields are required",
  });
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
    { label: "Flat Fee", value: "flat" },
    { label: "Percentage", value: "percentage" },
    { label: "Created At", value: "createdAt" },
    { label: "Status", value: "active" },
    { label: "Actions", value: "actions" },
  ];
  const [fees, setFees] = useState([]);
  const [selectedFee, setSelectedFee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [feeDetails, setFeeDetails] = useState({});
  const [filterParams, setFilterParams] = useState(null);

  const getFees = useCallback(
    async (name) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...(name && { name }),
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.FEES.FEES}?${params}`
        );
        setPagination(response);
        const fees = response?.content;
        setFees(fees);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getFees();
    } else {
      getFees(filterParams);
    }
  }, [getFees, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getFees();
    }
  }

  const handleFeesFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  const handleTableAction = (data, action) => {
    const { maximumCap, minimumCap, active, code, updatedAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Fee",
          subtitle: "All fields are required",
        });
        setSelectedFee({ ...rest, active, updatedAt, code });
        toggleModal(1);
        break;
      case "VIEW":
        const { type } = rest;
        setFeeDetails({ ...rest, type: type.code, status: active });
        toggleModal(2);
        break;
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <FeeForm
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getFees}
          />
        );
      case 1:
        return (
          <FeeForm
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getFees}
            fee={selectedFee}
          />
        );
      case 2:
        return <FormattedDetails details={feeDetails} />;
      case 3:
        return (
          <FeesFilter
            onGetFeeRules={handleFeesFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_get_fee" },
            { label: "OPEN", value: "switch_get_fee" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No fees found!"}
          search={true}
          reload={reload}
          error={error}
          items={fees}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "VIEW"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  const renderPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  return (
    <div className="manage-fees">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Fees" />
      <CustomBreadcrumb page="Manage Fees" />
      <Optional
        showIf={HELPER.HAS_AUTHORITY("switch_get_fee") && currentIndex === 0}
      >
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => {
              setModalInfo({
                title: "Filter List of Fees",
                subtitle: "",
              });
              toggleModal(3);
            }}
          />
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Fee"
            onclick={() => {
              toggleModal(0);
              setModalInfo({
                title: "Create Fee",
                subtitle: "All fields are required",
              });
            }}
          />
        </PageActions>
      </Optional>
      <>{renderPageView()}</>
    </div>
  );
}
