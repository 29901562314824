import "./custom-form.css";

export function FormRadioInput(props) {
  return (
    <label className="label radio-label">
      <input
        disabled={props.disabled}
        id={props.id}
        value={props.value}
        name={props.name}
        type="radio"
        checked={props.checked}
        onChange={(e) => props.handleChange(e)}
      />
      <span>{props.label}</span>
    </label>
  );
}
