import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { useEffect, useState } from "react";

export function useSingleAppConfigurationValue(configKey) {
  const [configValue, setConfigValue] = useState(null);
  useEffect(() => {
    async function getConfigValue() {
      const url = BACK_OFFICE_API.CONFIGURATION.CONFIGURATIONS;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${url}/${configKey}`
        );
        const value = response?.result?.configValue;
        setConfigValue(value);
      } catch (error) {}
    }
    getConfigValue();
  }, [configKey]);
  return configValue;
}
