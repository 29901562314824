import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { DEFAULT_PAGE_SIZE } from "core/configs/configs";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";

const useNetworks = () => {
  const { addNotification } = useNotification();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [loading, setLoading] = useState(false);
  const [networks, setNetworks] = useState([]);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [networkCode, setNetworkCode] = useState();
  const [networkDetails, setNetworkDetails] = useState(null);
  const [filterParams, setFilterParams] = useState(null);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Status", value: "active" },
    { label: "Creation Date", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const tableAuthorities = [
    { label: "UPDATE", value: "switch_update_network" },
    { label: "OPEN", value: "all" },
  ];

  const getNetworks = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...filter,
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.NETWORKS.GET_PAGED_NETWORKS}?${params}`
        );
        setPagination(response.result);
        const networks = response?.result?.content;
        setNetworks(networks);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getNetworks();
    } else {
      getNetworks(filterParams);
    }
  }, [getNetworks, filterParams]);

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getNetworks();
    }
  }

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }
  const handleNetworkFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };
  const handleTableAction = (data, action) => {
    const { active, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setSelectedNetwork(data);
        toggleModal(1);
        return;
      case "OPEN":
        setNetworkCode(data?.code);
        setNetworkDetails({ ...rest, status: active });
        setCurrentIndex(1);
        break;
      default:
        break;
    }
  };

  const incrementPageNo = () => {
    setPageNo(pageNo + 1);
  };
  const decrementPageNo = () => {
    setPageNo(pageNo - 1);
  };
  const goToPage = (number) => {
    setPageNo(number);
  };
  function goBack() {
    setCurrentIndex(0);
  }

  const actions = {
    goBack,
    goToPage,
    incrementPageNo,
    decrementPageNo,
    handleNetworkFilter,
    handleTableAction,
    reload,
    closeModal,
    toggleModal,
  };
  const api = { getNetworks, loading, error, networks, pagination };
  const page = {
    modalIndex,
    isModalVisible,
    pageNo,
    tableHeaders,
    tableAuthorities,
    currentIndex,
    selectedNetwork,
    networkDetails,
    networkCode,
  };

  return { actions, api, page };
};

export default useNetworks;
