import { BACK_OFFICE_API } from "api/backofffice/index";
import { useEffect, useState } from "react";
import { useList } from "./use-list";

export function useThirdPartyCategoriesList() {
  const [thirdPartyCategories, setThirdPartyCategories] = useState(null);
  const {
    list: thirdPartyCategoriesList,
    loading,
    error,
  } = useList(
    BACK_OFFICE_API.THIRD_PARTY_CATEGORIES.GET_THIRD_PARTY_CATEGORY_LIST
  );
  useEffect(() => {
    if (thirdPartyCategoriesList) {
      setThirdPartyCategories(thirdPartyCategoriesList);
    }
  }, [thirdPartyCategoriesList]);

  return { thirdPartyCategories, loading, error };
}
