import { BACK_OFFICE_API } from "api/backofffice/index";
import { useEffect, useState } from "react";
import { useList } from "./use-list";

export function useThirdPartiesList() {
  const [thirdParties, setThirdParties] = useState(null);
  const { list, loading, error } = useList(
    BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES
  );

  useEffect(() => {
    if (list) {
      setThirdParties(list);
    }
  }, [list]);

  return { thirdParties, loading, error };
}
