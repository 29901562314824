import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";
import { PHONE_NUMBER_REGEX } from "shared/constants";

const useMerchantForm = (props) => {
  const [initialValues, setInitialValues] = useState({
    status: "",
    phone: "",
    disputeEmail: "",
    settlementEmail: "",
    supportEmail: "",
    accountName: "",
    adminEmail: "",
    mainEmail: "",
    name: "",
    thirdPartyCode: "",
    cardAcceptorId: "",
    accountNumber: "",
    location: "",
    agent: false,
    acquirerCode: "",
    merchantCategoryCode: "",
    validateTerminal: false,
  });
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const { thirdParties, filtering, filterThirdPartiesByName } =
    useThirdPartyFilter();
  const [validationSchema, setValidationSchema] = useState({
    name: Yup.string()
      .max(100, "Merchant name cannot exceed 100 characters!")
      .required("Required"),
    merchantCategoryCode: Yup.string()
      .matches(/^[0-9]+$/, "Must contain only numbers")
      .length(5, "Merchant category code must be exactly 5 characters"),
    location: Yup.string()
      .required("Required")
      .max(500, "Location cannot exceed 500 characters!")
      .matches(/^[a-zA-Z0-9]*$/, "Location must be alphanumeric"),
    cardAcceptorId: Yup.string()
      .max(15, "Card Acceptor ID cannot exceed 15 characters!")
      .required("Required")
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
    thirdPartyCode: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
    agent: Yup.boolean().required("Required"),
    validateTerminal: Yup.boolean().required("Required"),
  });
  const pageIndex = {
    PRIMARY_INFO: 0,
    SETTLEMENT_INFO: 1,
    CONTACT_INFO: 2,
    DETAILS_CONFIRMATION: 3,
  };
  const stepWizardModel = [
    { label: "Primary Info" },
    { label: "Settlement Info" },
    { label: "Contact Info" },
    { label: "Confirm Details" },
  ];

  useEffect(() => {
    if (props.isUpdate) {
      const values = HELPER.changeNullValuesToEmptyStrings(props.merchant);
      const { active, ...rest } = values;
      filterThirdPartiesByName(values.thirdPartyName);
      setInitialValues({
        ...rest,
        status: active,
      });
    }
  }, [props.isUpdate, props.merchant, filterThirdPartiesByName]);

  const handleResponse = (isUpdate) => {
    const message = `Merchant successfully ${
      isUpdate ? "updated" : "created"
    }!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const createMerchant = async (payload) => {
    const url = BACK_OFFICE_API.MERCHANTS.CREATE_MERCHANTS;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const updateMerchant = async (payload) => {
    const url = BACK_OFFICE_API.MERCHANTS.UPDATE_MERCHANTS;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props.merchant.merchantId}`,
        payload
      );
      const isUpdate = true;
      handleResponse(isUpdate);
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async (payload) => {
    const { status, ...rest } = payload;
    setToastType("");
    setMessage("");
    if (currentFormIndex === pageIndex.PRIMARY_INFO) {
      setValidationSchema({
        ...validationSchema,
        accountNumber: Yup.string()
          .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
          .required("Required")
          .max(15, "Account number cannot exceed 15 characters!"),
        accountName: Yup.string()
          .required("Required")
          .max(100, "Account name cannot exceed 100 characters!"),
        acquirerCode: Yup.string().required("Acquirer Name is required!"),
      });
      setCurrentFormIndex(pageIndex.SETTLEMENT_INFO);
    } else if (currentFormIndex === pageIndex.SETTLEMENT_INFO) {
      setValidationSchema({
        ...validationSchema,
        phone: Yup.string().matches(
          PHONE_NUMBER_REGEX,
          "Must be a valid phone number"
        ),
        adminEmail: Yup.string().email("Enter valid email").required(),
      });
      setCurrentFormIndex(pageIndex.CONTACT_INFO);
    } else if (currentFormIndex === pageIndex.CONTACT_INFO) {
      const confirmationPayload = props.isUpdate
        ? HELPER.TRIM_OBJECT({
            ...payload,
            status,
          })
        : HELPER.TRIM_OBJECT(payload);
      setDetails(confirmationPayload);
      setCurrentFormIndex(pageIndex.DETAILS_CONFIRMATION);
    } else if (currentFormIndex === pageIndex.DETAILS_CONFIRMATION) {
      setLoading(true);
      if (props.isUpdate) {
        updateMerchant({
          ...rest,
          active: status,
        });
      } else {
        createMerchant({ ...rest, active: status });
      }
    }
  };

  const handleClose = () => {
    if (currentFormIndex === pageIndex.PRIMARY_INFO) {
      props.closeModal();
    } else if (currentFormIndex === pageIndex.SETTLEMENT_INFO) {
      setValidationSchema({
        name: Yup.string()
          .max(100, "Merchant name cannot exceed 100 characters!")
          .required("Required"),
        merchantCategoryCode: Yup.string()
          .matches(/^[0-9]+$/, "Must contain only numbers")
          .length(5, "Merchant category code must be exactly 5 characters"),
        location: Yup.string()
          .required("Required")
          .max(500, "Location cannot exceed 500 characters!")
          .matches(/^[a-zA-Z0-9]*$/, "Location must be alphanumeric"),
        cardAcceptorId: Yup.string()
          .length(15, "Card Acceptor ID must be exactly 15 characters!")
          .required("Required")
          .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
        thirdPartyCode: Yup.string()
          .required("Required")
          .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
        agent: Yup.boolean().required("Required"),
        validateTerminal: Yup.boolean().required("Required"),
      });
      setCurrentFormIndex(pageIndex.PRIMARY_INFO);
    } else if (currentFormIndex === pageIndex.CONTACT_INFO) {
      setValidationSchema({
        accountNumber: Yup.string()
          .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
          .required("Required")
          .max(15, "Account number cannot exceed 15 characters!"),
        accountName: Yup.string()
          .required("Required")
          .max(100, "Account name cannot exceed 100 characters!"),
        acquirerCode: Yup.string().required("Acquirer Name is required!"),
      });
      setCurrentFormIndex(pageIndex.SETTLEMENT_INFO);
    } else if (currentFormIndex === pageIndex.DETAILS_CONFIRMATION) {
      setValidationSchema({
        phone: Yup.string().matches(
          PHONE_NUMBER_REGEX,
          "Must be a valid phone number"
        ),
        adminEmail: Yup.string().email("Enter valid email").required(),
      });
      setCurrentFormIndex(pageIndex.CONTACT_INFO);
    }
  };

  const actions = {
    submit,
    handleClose,
    setInitialValues,
    filterThirdPartiesByName,
  };
  const states = {
    validationSchema,
    initialValues,
    toastType,
    message,
    loading,
    details,
    stepWizardModel,
    thirdParties,
    filtering,
    currentFormIndex,
    ...pageIndex,
  };

  return { actions, states };
};

export { useMerchantForm };
