import { useState } from "react";
import { HELPER } from "core/helper/helper";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { FeeReportGenerationProgress } from "./report-generation-progress";
import { FeeReportGenerationForm } from "./report-generation-form";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";

export function GenerateFeeReport(props) {
  const { addNotification } = useNotification();
  const [showReportGenerationProgress, setShowReportGenerationProgress] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [reportRecordId, setReportRecordId] = useState(null);
  const [reportParams, setReportParams] = useState(null);

  const submit = async (payload) => {
    setLoading(true);
    setReportParams(payload);
    let trimmedPayload = HELPER.TRIM_OBJECT(payload);
    const params = HELPER.TO_URL_STRING(trimmedPayload);
    const url = `${BACK_OFFICE_API.FEE_REPORTS.GENERATE_REPORT}?${params}`;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url);
      setReportRecordId(response?.recordId);
      setShowReportGenerationProgress(true);
    } catch (error) {
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
      setLoading(false);
    }
  };

  return (
    <>
      {showReportGenerationProgress ? (
        <FeeReportGenerationProgress
          recordId={reportRecordId}
          reportParams={reportParams}
          closeModal={props.closeModal}
        />
      ) : (
        <FeeReportGenerationForm
          onSubmit={submit}
          loading={loading}
          closeModal={props.closeModal}
        />
      )}
    </>
  );
}
