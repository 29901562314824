import "./custom-upload.css";
import { Icon } from "../icons/icon";
import { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import { Optional } from "../optional/optional";

export function CustomUpload(props) {
  const MAXIMUM_RECEIPT_SIZE_IN_MB = 5;
  const defaultMessage = "PNG, JPG, JPEG are accepted";
  const [fileName, setFileName] = useState(defaultMessage);
  const [uploadError, setUploadError] = useState(null);
  const fileInput = useRef();

  useEffect(() => {
    if (props.fileType) {
      setFileName(null);
    }
  }, [props.fileType]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader?.result.toString()));
    reader.readAsDataURL(img);
  }

  function onUpload(file) {
    const selectedFile = file.target.files[0];
    if (selectedFile) {
      if (props.fileType === "spreadsheet") {
        handleSpreadsheetUpload(selectedFile);
      } else if (props.fileType === "zip") {
        handleZipUpload(selectedFile);
      } else {
        handleImageUpload(selectedFile);
      }
    }
  }

  function handleZipUpload(file) {
    const fileSize = file.size;
    const fileSizeInMb = Math.round(fileSize / 1024);
    if (fileSizeInMb > props.maxSize * 1024) {
      setFileName(defaultMessage);
      setUploadError(
        `File size too large. Size cannot be greater than ${props.maxSize}MB.`
      );
      return;
    }
    const name = file?.name;
    setFileName(name);
    props.onFileUploadedFile(file);
  }

  function handleSpreadsheetUpload(file) {
    const name = file?.name;
    setFileName(name);
    props.onFileUploadedFile(file);
  }

  function handleImageUpload(file) {
    setUploadError(null);
    const x = file.type + "";
    const fileType = x.substr(x.indexOf("/") + 1) + "";
    const isFile = new RegExp("jpeg|png|jpg").test(fileType);
    const fileSize = file.size;
    const fileSizeInMb = Math.round(fileSize / 1024);
    if (fileSizeInMb > MAXIMUM_RECEIPT_SIZE_IN_MB * 1024) {
      setFileName(defaultMessage);
      setUploadError(
        `File size too large. Size cannot be greater than ${MAXIMUM_RECEIPT_SIZE_IN_MB}MB.`
      );
      return;
    }
    if (isFile) {
      const name = file.name;
      setFileName(name);
      props.onSetFileName(name);

      new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          getBase64(compressedResult, (img) => {
            props.onFileUploadedFile(img);
          });
        },
      });
    } else {
      setFileName(defaultMessage);
      setUploadError(
        "Please select a valid file type. Supported file types are PNG, JPG and JPEG."
      );
      props.clearUploadedFile();
    }
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        onChange={onUpload}
        ref={fileInput}
        accept={props.accept ? props.accept : ""}
      />
      <div
        onClick={() => fileInput.current.click()}
        className="custom-upload-container"
      >
        <div className="grid p-text-left">
          <span className="col-3">
            <Icon icon="upload" />
          </span>
          <span className="col-9">
            <span className="grid upload-grid-position">
              <Optional
                showIf={
                  props.fileType !== "zip" && props.fileType !== "spreadsheet"
                }
              >
                <span className="col-12">
                  <span className="upload-title">{props.title}</span>
                </span>
                <span className="col-12">
                  <span className="upload-text">{fileName}</span>
                </span>
              </Optional>
              <Optional
                showIf={
                  props.fileType === "zip" || props.fileType === "spreadsheet"
                }
              >
                <span className="col-12">
                  <span className="upload-title">
                    {fileName ? fileName : props.title}
                  </span>
                </span>
              </Optional>
            </span>
          </span>
        </div>
      </div>
      <Optional showIf={!props.noMaxSizeTip}>
        <div>
          <p
            className="text-left text-small"
            style={{
              color: "#5D7F8D",
            }}
          >
            Note: File size cannot exceed {MAXIMUM_RECEIPT_SIZE_IN_MB}
            MB
          </p>
        </div>
      </Optional>
      <Optional showIf={uploadError}>
        <p className="text-left text-small error-text">{uploadError}</p>
      </Optional>
    </div>
  );
}
