import React from "react";
import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useThirdPartiesList } from "api/hooks/use-third-parties-list";
import { FormInput } from "shared/components/form-component/form-input";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";

const BulkMerchantTerminalFilter = (props) => {
  const { thirdParties } = useThirdPartiesList();
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const formattedThirdPartiesOptions = filteredThirdParties?.length
    ? filteredThirdParties
    : thirdParties?.content;
  const initialValues = {
    thirdPartyCode: "",
    from: "",
    to: "",
  };

  const validationSchemaObject = Yup.object().shape({
    thirdPartyCode: Yup.string(),
    from: Yup.string(),
    to: Yup.string(),
  });

  const submit = async (values) => {
    props.onGetBulkMerchantTerminals(values);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaObject}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <p className="modal-title p-text-left ">{props.title}</p>
            <ThirdPartiesDropdown
              values={values}
              onFilter={filterThirdPartiesByName}
              filter={true}
              isRequired={false}
              thirdParties={formattedThirdPartiesOptions}
              filtering={filtering}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <div>
              <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color ">
                Upload Date
              </p>
              <div className="flex w-full ">
                <div className="w-full pr-3">
                  <label className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    From:
                  </label>
                  <FormInput
                    name="From"
                    fn={(e) => setFieldValue("from", e.target.value)}
                    inputType="date"
                    required={false}
                    value={values.from}
                    field="from"
                    type="INPUT"
                  />
                </div>
                <div className="w-full">
                  <label className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    To:
                  </label>
                  <FormInput
                    name="To"
                    fn={(e) => setFieldValue("to", e.target.value)}
                    inputType="date"
                    required={false}
                    value={values.to}
                    field="to"
                    type="INPUT"
                  />
                </div>
              </div>
            </div>

            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BulkMerchantTerminalFilter;
