import { BACK_OFFICE_API } from "api/backofffice/index";
import { useEffect, useState } from "react";
import { useNotification } from "../../../../core/hooks/useNotification";
import { HELPER } from "core/helper/helper";
import { API_SERVICE } from "api/service";

export function useDisputeConfigValue(configKey) {
  const [value, setConfigValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();

  useEffect(() => {
    const getDisputeConfig = async () => {
      setLoading(true);
      try {
        const url = BACK_OFFICE_API.DISPUTE_CONFIG.DISPUTE_CONFIG;
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${url}/${configKey}`
        );
        setConfigValue(response?.configValue);
        setLoading(false);
      } catch (error) {
        addNotification({
          type: "error",
          message: HELPER.PROCESS_ERROR(error),
        });
        setLoading(false);
      }
    };
    getDisputeConfig();
  }, [addNotification, configKey]);
  return { loading, value };
}
