import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { GenerateFeeReport } from "./generate-fee-report";
import { ConfirmationDialog } from "shared/components/confirmation-dialog/confirmation-dialog";
import { saveAs } from "file-saver";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";
import { IN_PROGRESS } from "shared/constants";

export function FeeReports() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Generate Fee Report",
    subtitle: "Fields marked * are required",
  });
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const tableHeaders = [
    { label: "Report Generation Date", value: "createdAt" },
    { label: "Record Id", value: "recordId" },
    { label: "Percentage Processed", value: "percentageProcessed" },
    { label: "Report Generation Status", value: "reportGenerationStatus" },
    { label: "Actions", value: "actions" },
  ];
  const [feeReports, setFeeReports] = useState([]);
  const [selectedFeeReport, setSelectedFeeReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const waitTimeBeforeReloadInSeconds = useRef(10);

  const getFeeReports = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
    });
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.FEE_REPORTS.GET_FEE_REPORTS}?${params}`
      );
      setPagination(response);
      const feeReports = response?.content;
      const needsToGetUpdatedStatus = feeReports.some(
        (feeReport) => feeReport.reportGenerationStatus === IN_PROGRESS
      );
      if (needsToGetUpdatedStatus) {
        setTimeout(() => {
          getFeeReports();
        }, waitTimeBeforeReloadInSeconds.current * 1000);
      }
      setFeeReports(feeReports);
      setError(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
    }
  }, [pageNo]);

  useEffect(() => {
    getFeeReports();
  }, [getFeeReports]);

  const downloadFeeReport = async () => {
    setDownloadingReport(true);
    const { recordId } = selectedFeeReport;
    const url = `${BACK_OFFICE_API.FEE_REPORTS.DOWNLOAD_REPORT}/${recordId}`;
    const isBlob = true;

    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(url, isBlob);
      saveAs(response, `Fee-Report-Record-Id=${recordId}.xlsx`);
      closeModal();
      addNotification({
        type: "success",
        message: "File download in progress!",
      });
    } catch (error) {
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
      closeModal();
      setDownloadingReport(false);
    }
  };

  function reload() {
    getFeeReports();
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    setIsModalVisible(false);
    if (shouldReload === true) {
      getFeeReports();
    }
  }

  const handleTableAction = (data, action) => {
    switch (action) {
      case "DOWNLOAD":
        setDownloadingReport(false);
        setModalInfo({
          title: "Download Report!",
          subtitle: "",
        });
        setSelectedFeeReport(data);
        toggleModal(1);
        break;
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return <GenerateFeeReport closeModal={closeModal} />;
      case 1:
        return (
          <ConfirmationDialog
            confirmationText="Please confirm that you want to download this report."
            onClose={closeModal}
            onSubmit={downloadFeeReport}
            loading={downloadingReport}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[{ label: "DOWNLOAD", value: "all" }]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo}
          emptyText={"No fee reports found!"}
          search={true}
          reload={reload}
          error={error}
          items={feeReports}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["DOWNLOAD"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-fee-rules">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Fee Reports" />
      <CustomBreadcrumb page="Manage Fee Reports" />
      <Optional showIf={HELPER.HAS_AUTHORITY("all")}>
        <PageActions>
          <AppButton
            type="button"
            buttonStyle="primary"
            text="Generate Fee Report"
            onclick={() => {
              toggleModal(0);
              setModalInfo({
                title: "Fee Report Generation",
                subtitle: "",
              });
            }}
          />
        </PageActions>
      </Optional>
      <>{renderTable()}</>
    </div>
  );
}
