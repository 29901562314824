import React, { useCallback, useEffect, useState } from "react";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { API_SERVICE } from "../../../api/service";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { ThirdPartyAcquirerForm } from "./third-party-acquirer-form";
import { useNotification } from "core/hooks/useNotification";

export function ThirdPartyAcquirers(props) {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Associate Acquirers",
    subtitle: "All fields are required",
  });
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [tableHeaders] = useState([
    { label: "Acquirer Name", value: "financialInstitutionName" },
    { label: "Acquirer Institution ID", value: "acquirerInstitutionId" },
    { label: "Status", value: "active" },
    { label: "Created At", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ]);
  const [authorities] = useState([
    { label: "UPDATE", value: "switch_update_acquirer" },
  ]);
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirer, setSelectedAcquirer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);

  const getAcquirers = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING(
        HELPER.TRIM_OBJECT({
          page: pageNo,
          size: DEFAULT_PAGE_SIZE,
          thirdPartyCode: props.thirdPartyCode,
          ...filter,
        })
      );
      const url = `${BACK_OFFICE_API.THIRD_PARTY_ACQUIRERS.GET_THIRD_PARTY_ACQUIRER}?${params}`;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(url);
        setPagination(response.result);
        const acquirers = response?.result?.content;
        setAcquirers(acquirers);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification, props.thirdPartyCode]
  );

  useEffect(() => {
    getAcquirers();
  }, [getAcquirers]);

  function reload() {
    getAcquirers();
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getAcquirers();
    }
  }

  const handleAssociateAcquirer = () => {
    toggleModal(0);
    setModalInfo({
      title: "Associate Acquirers",
      subtitle: "Fields with * are required",
    });
  };

  const handleTableAction = (data, action) => {
    const { formattedStatus, active, updatedAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Third Party Acquirer",
          subtitle: "All fields are required",
        });
        setSelectedAcquirer({ ...rest, active, updatedAt });
        toggleModal(1);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <ThirdPartyAcquirerForm
            thirdPartyCode={props.thirdPartyCode}
            thirdPartyName={props.thirdPartyName}
            closeModal={closeModal}
          />
        );
      case 1:
        return (
          <ThirdPartyAcquirerForm
            isUpdate={true}
            acquirer={selectedAcquirer}
            thirdPartyCode={props.thirdPartyCode}
            thirdPartyName={props.thirdPartyName}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className=" text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={authorities}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No third party acquirers found!"}
          search={true}
          reload={reload}
          error={error}
          items={acquirers}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-third-party-acquirers">
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageActions>
        <Optional
          showIf={HELPER.HAS_AUTHORITY("switch_create_third_party_acquirer")}
        >
          <AppButton
            type="button"
            buttonStyle="primary"
            text="Associate Acquirers"
            onclick={handleAssociateAcquirer}
          />
        </Optional>
      </PageActions>
      <>{currentPageView()}</>
    </div>
  );
}
