import "../login/login.css";
import { Icon } from "../../components/icons/icon";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { persistLoginDataAndRedirect } from "../login/helpers";
import { HELPER } from "../../../core/helper/helper";
import { useMainState } from "../../../core/hooks/useMainState";
import { Formik, Form } from "formik";
import { AppFormInput } from "../../components/form-inputs/form-input";
import { AppButton } from "../../components/app-button/app-button";
import * as Yup from "yup";
import { AUTH_SERVICE } from "../../../api/backofffice/auth-service";
import { storage } from "../../../core/utils/storage";

export function ChangePassword() {
  let navigate = useNavigate();
  const { mainDispatch, mainState } = useMainState();
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(mainState?.access_token);

  useEffect(() => {
    if (!mainState?.access_token) {
      let token = storage.getToken();
      setAccessToken(token);
      if (!token) {
        navigate("/login");
      }
    }
  }, [navigate, mainState?.access_token]);

  const handleSuccess = (response) => {
    const { loginData } = mainState;
    if (loginData) {
      const performLogin = true;
      persistLoginDataAndRedirect(
        loginData,
        mainDispatch,
        navigate,
        performLogin
      );
    } else {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };

  const submit = async (payload) => {
    closeAlert();
    setLoading(true);
    try {
      const response = await AUTH_SERVICE.CHANGE_PASSWORD(accessToken, payload);
      handleSuccess(response?.result);
    } catch (error) {
      handleErrors(error);
    }
  };

  function closeAlert() {
    mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "",
        description: "",
        type: "",
        showAlert: false,
      },
    });
  }

  function handleErrors(error) {
    mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "Error",
        description: HELPER.PROCESS_ERROR(error),
        type: "error",
        showAlert: true,
      },
    });
    // setErrorTitle("Error");
    // setErrorMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  }

  return (
    <div>
      <div className="login-alert-container">
        <div className="auth-form">
          <div className="dots top-right">
            <Icon icon="login-dots" />
          </div>
          <div className="dots bottom-left">
            <Icon icon="login-dots-two" />
          </div>
          <div className="login-container">
            <div className="log-icon-top-container">
              <div className="login-icon-position">
                <div className={`logo-size default`}>
                  <Icon icon="logo" />
                </div>
                <h1 className={`app-name default text-center`}>AptPay</h1>
              </div>
            </div>
            <div className="pl-5">
              {/* <h3 className="mb-1" style={{ color: "#566a7f" }}>
                Welcome back!
              </h3> */}
              <p className="mt-0 text-sm" style={{ color: "#697a8d" }}>
                Please change your password to continue
              </p>
            </div>
            <div className="login-card">
              <div className="login-form-container">
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                  }}
                  validationSchema={Yup.object({
                    oldPassword: Yup.string().required("Required"),
                    newPassword: Yup.string().required("Required"),
                  })}
                  onSubmit={(values) => {
                    submit(values);
                  }}
                >
                  <Form>
                    <AppFormInput
                      label="Current Password"
                      name="oldPassword"
                      type="password"
                      placeholder="Current Password"
                    />
                    <AppFormInput
                      label="New Password"
                      name="newPassword"
                      type="password"
                      placeholder="New Password"
                    />
                    <div style={{ margin: "3rem 0 0", width: "100%" }}>
                      <AppButton
                        width="w-full"
                        text="Submit"
                        loading={loading}
                        type="submit"
                        buttonStyle="primary"
                      />
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
