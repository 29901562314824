import { Formik, Form } from "formik";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { Steps } from "primereact/steps";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { useAcquirersList } from "api/hooks/use-acquirers-list";
import { useThirdPartiesList } from "api/hooks/use-third-parties-list";
import { AppButton } from "shared/components/app-button/app-button";
import { useBulkMerchantTerminalForm } from "./hooks/use-bulk-merchant-terminal-form";
import { Icon } from "shared/components/icons/icon";
import "./bulk-upload.css";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";

const BulkMerchantTerminalForm = (props) => {
  const { acquirers } = useAcquirersList();
  const { thirdParties } = useThirdPartiesList();
  const { actions, states } = useBulkMerchantTerminalForm(props);
  const formattedThirdPartiesOptions = states.thirdParties.length
    ? states.thirdParties
    : thirdParties?.content;
  const {
    currentFormIndex,
    PRIMARY_INFO,
    UPLOAD_INFO,
    DETAILS_CONFIRMATION,
    stepWizardModel,
  } = states;

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === PRIMARY_INFO ? "Close" : "Back"}
          onclick={actions.handleClose}
        />
        <AppButton
          loading={states.loading}
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex <= UPLOAD_INFO ? "Next" : "Submit"}
        />
      </FormActions>
    );
  };

  const renderFormAlert = () => {
    return states.message ? (
      <CustomToast
        title={states.toastType === "error" ? "Error" : "Success"}
        description={states.message}
        type={states.toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };

  const renderUpload = () => {
    return (
      <div className="bulk-upload">
        <div>
          <input
            style={{ display: "none" }}
            type="file"
            onChange={actions.handleUpload}
            ref={states.fileInputRef}
          />
          <div
            onClick={() => states.fileInputRef.current.click()}
            className="custom-upload-container"
          >
            <div className="grid p-text-">
              <span className="col-2">
                <Icon icon="upload" />{" "}
              </span>
              <span className="col-9">
                <span className="grid ">
                  {states?.fileDetails?.name ? (
                    <span className="col-12 upload-text py-5">
                      <span className="">{states?.fileDetails?.name}</span>
                    </span>
                  ) : (
                    <span className="col-12 upload-title py-4">
                      Click to upload .xlsx or .csv - Merchant and Terminals{" "}
                      <span style={{ color: "red" }}> * </span>
                    </span>
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-content-between">
          <button
            className="bare-button"
            onClick={actions.downloadExcelTemplate}
            style={{ textDecoration: "underline", fontSize: "0.9rem" }}
          >
            Download Excel Template
          </button>
          <button
            className="bare-button"
            onClick={actions.downloadCsvTemplate}
            style={{ textDecoration: "underline", fontSize: "0.9rem" }}
          >
            Download CSV Template
          </button>
        </div>
      </div>
    );
  };

  const renderStepWizard = (values, setFieldValue, errors) => {
    switch (currentFormIndex) {
      case PRIMARY_INFO:
        return (
          <>
            <ThirdPartiesDropdown
              values={values}
              onFilter={actions.filterThirdPartiesByName}
              filter={true}
              isRequired={true}
              thirdParties={formattedThirdPartiesOptions}
              filtering={states.filtering}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <AppFormDropdown
              className="col-12"
              value={values.acquirerCode}
              onchange={(e) => {
                setFieldValue("acquirerCode", e?.target?.value);
              }}
              options={acquirers}
              optionLabel="financialInstitutionName"
              optionValue="code"
              placeholder="Select Acquirer Name"
              label="Acquirer Name"
              error={errors?.acquirerCode}
              field="acquirerCode"
              required
            />
          </>
        );
      case UPLOAD_INFO:
        return renderUpload();
      case DETAILS_CONFIRMATION:
        return <FormattedDetails details={states.details} />;
      default:
        break;
    }
  };

  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <>
        <p
          className={`modal-title p-text-left ${
            currentFormIndex === DETAILS_CONFIRMATION ? "mb-5" : ""
          } `}
        >
          {currentFormIndex === DETAILS_CONFIRMATION
            ? "Confirm Bulk Merchant Terminal Details"
            : props.title}
        </p>
        <p className="modal-subtitle mt-0 -mt-3 ">
          {currentFormIndex === DETAILS_CONFIRMATION ? "" : props.subtitle}
        </p>
        <Steps activeIndex={currentFormIndex} model={stepWizardModel} />
        <div className="custom-modal-item">
          {renderStepWizard(values, setFieldValue, errors)}
        </div>
      </>
    );
  };

  const renderFormView = () => {
    return (
      <Formik
        initialValues={states.initialValues}
        validationSchema={Yup.object(states.validationSchema)}
        onSubmit={(values) => {
          actions.submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderFormAlert()}
            {renderFormActions()}
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderFormView()}</div>;
};

export default BulkMerchantTerminalForm;
