import { BACK_OFFICE_API } from "api/backofffice/index";
import React from "react";
import Disputes from "./disputes";
import { LOCAL_STORAGE_SERVICE } from "core/services/storage-service";

const PartyDisputes = () => {
  const partyId = LOCAL_STORAGE_SERVICE.GET_PARTY_USER_ID();
  const partyType = LOCAL_STORAGE_SERVICE.GET_PARTY_TYPE();

  const url = BACK_OFFICE_API.DISPUTES.GET_PARTY_DISPUTES;
  return (
    <Disputes
      url={url}
      partyId={partyId}
      partyType={partyType}
      isThirdPartyDisputes={true}
    />
  );
};

export default PartyDisputes;
