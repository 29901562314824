import "./custom-table.css";

export function TableAction({ data, action, onclick, icon }) {
  return (
    <button
      onClick={() => {
        onclick(data, action);
      }}
      className={`table-action dcir-show ${icon}`}
    >
      <span className="add-cursor">
        <i className={`pi pi-${icon}`} />
      </span>
    </button>
  );
}
