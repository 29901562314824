import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";
import { GENERAL_CLEARING_RULE_ENUMS } from "shared/constants";
import { CUSTOM_VALIDATION } from "core/utils/validation";

export function FeeRuleForm(props) {
  const { addNotification } = useNotification();
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    generalClearingRule: "",
    feeSourceCode: "",
    flatAmount: "",
    percentage: "",
    percentMaxCap: "",
    percentMinCap: "",
  });
  const validationSchemaObject = {
    name: Yup.string()
      .max(50, `Too long, maximum length is 50 characters`)
      .required("Required"),
    description: Yup.string()
      .max(200, `Too long, maximum length is 200 characters`)
      .required("Required"),
    generalClearingRule: Yup.string().required("Required"),
    feeSourceCode: Yup.string().required("Required"),
    flatAmount: Yup.number("Enter valid amount!")
      .typeError("Enter valid amount!")
      .test(
        "max-decimals",
        "Flat amount should not exceed two decimal places.",
        CUSTOM_VALIDATION.IS_VALID_TWO_DECIMAL_PLACE_AMOUNT
      ),
    percentage: Yup.number("Must be a valid number").typeError(
      "Must be a valid number"
    ),
    percentMaxCap: Yup.number("Must be a valid number"),
    percentMinCap: Yup.number("Must be a valid number"),
  };
  const [feeSources, setFeeSources] = useState([]);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;

  useEffect(() => {
    if (props.isUpdate) {
      const {
        name,
        description,
        generalClearingRule,
        feeSourceCode,       
        flatAmount,
        percentage,
        percentMaxCap,
        percentMinCap,
      } = HELPER.changeNullValuesToEmptyStrings(props.feeRule);
      setInitialValues({
        name,
        description,
        generalClearingRule,
        feeSourceCode,
        flatAmount: HELPER.TO_MAJOR_AMOUNT(flatAmount),
        percentage,
        percentMaxCap,
        percentMinCap,
      });
    }
  }, [props.feeRule, props.isUpdate]);

  const handleResponse = (isCreate = false) => {
    let notification = {
      type: "success",
      message: `Fee rule successfully ${isCreate ? "created" : "updated"}!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const getFormattedPayload = (payload) => {
    const { flatAmount } = payload;
    const formattedPayload = {
      ...payload,
      flatAmount: String(flatAmount)?.length
        ? HELPER.TO_MINOR_AMOUNT(flatAmount)
        : 0,
    };
    return formattedPayload;
  };

  const createFeeRule = async (payload) => {
    resetToast();
    let formattedPayload = getFormattedPayload(payload);
    const url = BACK_OFFICE_API.FEE_RULES.FEE_RULE;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, formattedPayload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateFeeRule = async (payload) => {
    resetToast();
    let formattedPayload = getFormattedPayload(payload);
    const url = BACK_OFFICE_API.FEE_RULES.FEE_RULE;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.feeRule?.code}`,
        formattedPayload
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const submit = async (payload) => {
    console.log("payload", payload);
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      if (props.isUpdate) {
        updateFeeRule(payload);
      } else {
        createFeeRule(payload);
      }
    } else {
      let { active, flatAmount, ...rest } = payload;
      let confirmationPayload = {
        ...rest,
        flatAmount: HELPER.toTwoDecimalPlacesMinorAmount(flatAmount),
        ...(props.isUpdate && {
          status: payload.active ? "Active" : "Inactive",
        }),
      };
      setFormValues(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  useEffect(() => {
    const getAllFeeSources = async () => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          BACK_OFFICE_API.FEE_SOURCE.ALL_FEE_SOURCE
        );
        setFeeSources(response);
      } catch (error) {
        console.log(error);
      }
    };
    getAllFeeSources();
  }, []);

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchemaObject)}
          onSubmit={(values, { resetForm }) => {
            submit(values, resetForm);
          }}
          enableReinitialize={true}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              {/* {JSON.stringify(errors)} */}
              <Optional
                showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
              >
                <FormattedDetails details={formValues} />
              </Optional>
              <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
                <AppFormInput
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Enter name"
                  required={true}
                />
                <AppFormInput
                  required={true}
                  label="Description"
                  name="description"
                  type="text"
                  placeholder="Enter description"
                  disabled={false}
                />
                <AppFormDropdown
                  value={values.generalClearingRule}
                  onchange={(e) => {
                    setFieldValue("generalClearingRule", e?.target?.value);
                  }}
                  options={GENERAL_CLEARING_RULE_ENUMS.fieldOptions}
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Select general clearing rule"
                  label="General Clearing Rule"
                  error={errors?.generalClearingRules}
                  field="generalClearingRules"
                  required
                />
                <AppFormDropdown
                  value={values.feeSourceCode}
                  onchange={(e) => {
                    setFieldValue("feeSourceCode", e?.target?.value);
                  }}
                  options={feeSources}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select fee source"
                  label="Fee Source"
                  error={errors?.feeSourceCode}
                  field="feeSourceCode"
                  required
                  filter
                />
                <Optional
                  showIf={
                    values.generalClearingRule ===
                    GENERAL_CLEARING_RULE_ENUMS.rules.flatFee
                  }
                >
                  <AppFormInput
                    label="Flat Amount (₦)"
                    name="flatAmount"
                    type="text"
                    placeholder="Flat Amount (₦)"
                    required
                  />
                </Optional>
                <Optional
                  showIf={
                    values.generalClearingRule ===
                    GENERAL_CLEARING_RULE_ENUMS.rules.percantageFee
                  }
                >
                  <AppFormInput
                    label="Percentage (%)"
                    name="percentage"
                    type="text"
                    placeholder="Percentage"
                    required
                  />
                  <AppFormInput
                    label="Maximum Cap (Flat Value)"
                    name="percentMaxCap"
                    type="text"
                    placeholder="Maximum Cap"
                  />
                  <AppFormInput
                    label="Minimum Cap"
                    name="percentMinCap"
                    type="text"
                    placeholder="Minimum Cap"
                  />
                </Optional>
              </Optional>

              {message && (
                <CustomToast
                  title={toastType === "error" ? "Error" : "Success"}
                  description={message}
                  type={toastType}
                  closeable={false}
                  inApp={true}
                />
              )}
              <FormActions>
                <AppButton
                  type="button"
                  buttonStyle="secondary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                  }`}
                  onclick={handleClose}
                />
                <AppButton
                  type="submit"
                  buttonStyle="primary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"
                  }`}
                  loading={loading}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <>{renderForm()}</>;
}
