import { Dropdown } from "primereact/dropdown";
import { useFormikContext } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";
import { Optional } from "../optional/optional";
import "./form-inputs.css";

export function AppFormDropdown(props) {
  const { submitCount } = useFormikContext();

  const shouldShowError = () => {
    return submitCount > 0 && props.error;
  };

  return (
    <div className="mb-3">
      <label
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          marginBottom: "0.5rem",
          display: "block",
          color: "#566a7f",
          textAlign: "left",
        }}
      >
        <p style={{ position: "relative" }}>
          {props.label}:
          {props.required ? <span style={{ color: "red" }}> * </span> : null}
        </p>
      </label>
      <div className="dropdown-wrapper">
        <Dropdown
          disabled={props.disabled || props.loading}
          filter={props.filter}
          value={props.value}
          onChange={(e) => {
            props.onchange(e);
          }}
          loadingicon={
            <ProgressSpinner
              style={{ width: "20px", height: "20px" }}
              strokeWidth="4"
            />
          }
          loading={"true"}
          onFilter={props.onFilter}
          options={props.options}
          placeholder={props.placeholder}
          optionLabel={props.optionLabel}
          optionValue={props.optionValue}
          className={shouldShowError() ? "error" : ""}
        />
        <Optional showIf={props.loading}>
          <div className="dropdown-loading-container">
            <ProgressSpinner
              style={{ width: "20px", height: "20px" }}
              strokeWidth="4"
            />
          </div>
        </Optional>
      </div>
      {shouldShowError() ? (
        <p className="error text-left">{props.error}</p>
      ) : null}
    </div>
  );
}
