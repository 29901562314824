import React from "react";
import "./progress-bar.css";

export function ProgressBar(props) {
  return (
    <span className="progress-bar">
      <span
        className="progress-outer"
        style={{
          width: `${props.width ? props.width : "60%"}`,
          height: `${props.height ? props.height : "1.25rem"}`,
        }}
      >
        <span
          className={`progress-inner ${
            props.value === 100 || props.value > 89 ? "complete" : ""
          }`}
          style={{
            width: `${props.value}%`,
            // background: `${props.background ? props.background : "#50b8ee"}`,
            background: `${
              props.background
                ? props.background
                : props.value === 100
                ? "#43BE09"
                : "#50b8ee"
            }`,
          }}
        >
          {props.value > 19 && <span>{props.value}%</span>}
        </span>
      </span>
    </span>
  );
}
