import React from "react";
import { AppButton } from "shared/components/app-button/app-button";
import { CustomBreadcrumb } from "shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "shared/components/custom-table/custom-table";
import { Optional } from "shared/components/optional/optional";
import { PageActions } from "shared/components/page-actions/page-actions";
import { PageTitle } from "shared/components/page-title/page-title";
import DisputeDetails from "./dispute-details";
import { DisputesFilter } from "./disputes-filter";
import { useDisputes } from "backoffice/pages/disputes/hooks/use-disputes";
import { GenerateDisputeReport } from "./generate-dispute-report";

const Disputes = (props) => {
  const { api, page, data, pageActions } = useDisputes(props);

  function modalContent() {
    switch (page.modalIndex) {
      case 0:
        return (
          <DisputesFilter
            onFilter={pageActions.handleFilter}
            closeModal={pageActions.closeModal}
            isParty={props.partyType}
          />
        );
      case 1:
        return (
          <GenerateDisputeReport
            closeModal={pageActions.closeModal}
            apiRoute={props.url}
          />
        );
      default:
        break;
    }
  }

  const renderTableView = () => {
    if (api.loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={page.tableAuthorities}
          isReload={true}
          numberOfElements={data.pagination?.numberOfElements}
          totalPages={data.pagination?.totalPages}
          totalItems={data.pagination?.totalElements}
          currentPage={page.pageNo + 1}
          emptyText={"No disputes found!"}
          reload={pageActions.reload}
          error={api.error}
          items={data.disputes}
          search={true}
          headers={page.tableHeaders}
          nextPage={pageActions.incrementPageNo}
          prevPage={pageActions.decrementPageNo}
          goToFirstPage={() => pageActions.goToPage(1)}
          goToLastPage={() =>
            pageActions.goToPage(data.pagination?.totalPages - 1)
          }
          goToPage={(pageNo) => pageActions.goToPage(pageNo)}
          actions={["OPEN"]}
          onPerformAction={pageActions.handleTableAction}
        />
      );
    }
  };

  const currentPageView = () => {
    switch (page.currentIndex) {
      case 0:
        return renderTableView();
      case 1:
        return (
          <DisputeDetails
            goBack={pageActions.goBack}
            disputeDetails={data.details}
            statusLogInfo={data.statusLogInfo}
          />
        );
      default:
        break;
    }
  };

  return (
    <div>
      <>
        <CustomModal
          closeModal={pageActions.closeModal}
          onHide={pageActions.closeModal}
          visible={page.isModalVisible}
          modalContent={modalContent}
          title={page.modalInfo.title}
          subtitle={page.modalInfo.subtitle}
          hasOwnModalHeader={true}
        />
      </>
      <PageTitle text="All Disputes" />
      <CustomBreadcrumb page="Manage All Disputes" />
      <PageActions>
        <Optional showIf={page.currentIndex === 0}>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => pageActions.toggleModal(0)}
          />
          <AppButton
            type="button"
            buttonStyle="primary"
            text="Generate Report"
            onclick={() => {
              pageActions.toggleModal(1);
            }}
          />
        </Optional>
      </PageActions>
      <div>{currentPageView()}</div>
    </div>
  );
};

export default Disputes;
