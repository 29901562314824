import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { API_SERVICE } from "../../../api/service";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { HELPER } from "../../../core/helper/helper";
import { Optional } from "shared/components/optional/optional";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";

export function ParticipantsFilter(props) {
  const [parties, setParties] = useState([]);
  const [fees, setFees] = useState([]);
  const [initialValues] = useState({
    partyType: "",
    partyTypeId: "",
    feeName: "",
    active: "",
    to: "",
    from: "",
  });
  const validationSchemaObject = {
    partyType: Yup.string(),
    partyTypeId: Yup.string(),
    feeName: Yup.string(),
    active: Yup.string(),
    to: Yup.string(),
    from: Yup.string(),
  };
  const partyTypes = [
    { name: "Issuer", ISSUER: BACK_OFFICE_API.ISSUERS.GET_ISSUERS_LIST },
    { name: "Acquirer", ACQUIRER: BACK_OFFICE_API.ACQUIRER.ACQUIRER_LIST },
    {
      name: "Third Party",
      THIRD_PARTY: BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES,
    },
    { name: "Network", NETWORK: BACK_OFFICE_API.NETWORKS.GET_NETWORKS_FILLER },
  ];
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const formattedThirdPartiesOptions = filteredThirdParties?.length
    ? filteredThirdParties
    : parties;
  const [partyLoader, setPartyLoader] = useState(false);
  const THIRD_PARTY = "Third Party";

  const submit = async ({ status, ...args }) => {
    const payload = HELPER.TRIM_OBJECT({ active: status, ...args });
    props.onGetParticipants(payload);
  };

  useEffect(() => {
    const getFees = async () => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          BACK_OFFICE_API.FEES.FEES
        );
        setFees(response?.content);
      } catch (error) {
        console.log(error);
      }
    };
    getFees();
  }, []);

  const getParties = async (partyType) => {
    setPartyLoader(true);
    const formattedUrl = await partyTypes.filter(
      (item) => item[partyType.toUpperCase().replace(" ", "_")]
    )[0][partyType.toUpperCase().replace(" ", "_")];

    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(formattedUrl);
      const result = response?.result?.content
        ? response?.result?.content
        : response?.result;
      const formattedResult = result.map((item) => {
        return {
          ...item,
          name: item.financialInstitutionName
            ? item.financialInstitutionName
            : item.name,
        };
      });
      setParties(formattedResult);
      setPartyLoader(false);
    } catch (error) {
      console.log(error);
      setPartyLoader(false);
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <AppFormDropdown
              value={values.partyType}
              onchange={(e) => {
                setFieldValue("partyType", e?.target?.value);
                getParties(e?.target?.value);
              }}
              options={partyTypes}
              optionLabel="name"
              optionValue="name"
              placeholder="Select party type"
              label="Party Type"
              error={errors?.partyType}
              field="partyType"
              filter
            />
            <Optional showIf={values.partyType === THIRD_PARTY}>
              <ThirdPartiesDropdown
                values={values}
                onFilter={filterThirdPartiesByName}
                filter={true}
                isRequired={false}
                thirdParties={formattedThirdPartiesOptions}
                filtering={filtering}
                placeholder={
                  partyLoader || filtering ? "Loading..." : "Select a party"
                }
                setFieldValue={setFieldValue}
                disabled={partyLoader || filtering}
                errors={errors}
                field="partyTypeId"
                label="Party"
              />
            </Optional>
            <Optional showIf={values.partyType !== THIRD_PARTY}>
              <AppFormDropdown
                value={values.partyTypeId}
                onchange={(e) => {
                  setFieldValue("partyTypeId", e?.target?.value);
                }}
                options={parties}
                optionLabel="name"
                optionValue="code"
                disabled={partyLoader || !parties.length}
                placeholder={partyLoader ? "Loading..." : "Select a party"}
                label="Party"
                error={errors?.partyTypeId}
                field="partyTypeId"
                filter
                loading={partyLoader}
              />
            </Optional>

            <AppFormDropdown
              value={values.feeName}
              onchange={(e) => {
                setFieldValue("feeName", e?.target?.value);
              }}
              options={fees}
              optionLabel="name"
              optionValue="name"
              placeholder="Select a fee"
              label="Fee"
              error={errors?.feeName}
              field="feeName"
              filter
            />
            <div className="flex w-full ">
              <div className="w-full pr-3">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  From:
                </label>
                <FormInput
                  name="From"
                  fn={(e) => setFieldValue("from", e.target.value)}
                  label="Start date"
                  inputType="date"
                  required={false}
                  value={values.from}
                  field="from"
                  type="INPUT"
                />
              </div>
              <div className="w-full">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  To:
                </label>
                <FormInput
                  name="To"
                  fn={(e) => setFieldValue("to", e.target.value)}
                  label="End date"
                  inputType="date"
                  required={false}
                  value={values.to}
                  field="to"
                  type="INPUT"
                />
              </div>
            </div>
            <div className="pt-3">
              <div>
                <p
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  Status:
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="Status"
                    checked={values.active === true}
                    handleChange={(e) => setFieldValue("active", true)}
                    label="Active"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="Status"
                    checked={values.active === false}
                    handleChange={(e) => setFieldValue("active", false)}
                    label="Inactive"
                  />
                </div>
              </div>
            </div>

            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
