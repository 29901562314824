import { CUSTOM_VALIDATION } from "core/utils/validation";
import * as Yup from "yup";

export const defaultValidationSchemaObject = {
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  flat: Yup.number("Enter valid amount!")
    .test(
      "max-decimals",
      "The number must have at most 2 decimal places",
      CUSTOM_VALIDATION.IS_VALID_TWO_DECIMAL_PLACE_AMOUNT
    )
    .nullable(true),
  percentage: Yup.number("Enter valid number!").nullable(true),
  type: Yup.string().required("Required"),
  active: Yup.boolean(),
};

const flatValidationSchemaObject = {
  ...defaultValidationSchemaObject,
  flat: Yup.number("Enter valid amount!")
    .typeError("Enter valid amount!")
    .test(
      "max-decimals",
      "Flat amount should not exceed two decimal places.",
      CUSTOM_VALIDATION.IS_VALID_TWO_DECIMAL_PLACE_AMOUNT
    )
    .required("Required"),
};

const percentAllValidationSchemaObject = {
  ...defaultValidationSchemaObject,
  percentage: Yup.number("Enter valid number!").required("Required"),
};

export const feeFormValidationSchemaMapping = {
  FLAT: flatValidationSchemaObject,
  PERCENT_ALL: percentAllValidationSchemaObject,
};
