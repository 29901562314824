import { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { Icon } from "../../../shared/components/icons/icon";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import "./third-parties-details.css";
import { HELPER } from "../../../core/helper/helper";
import { ThirdPartyAcquirers } from "./third-party-acquirers";
import { THIRD_PARTY_ENUMS } from "shared/constants";
import { IssuerProcessors } from "../issuer-processors/issuer-processors";
import { PartySourceInfo } from "../party-source-info/party-source-info";
import LinkedThirdParties from "./linked-third-parties";

export const ThirdPartyDetails = (props) => {
  const [currentIndex] = useState(0);
  const thirdPartyTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={"Third Party Summary"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
              </div>
              <div className="centralize">
                <div className="p-2">
                  <FormattedDetails details={props.details} />
                </div>
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY("switch_fetch_party_source_sink_info") ? (
            <TabPanel header={"Connection Info"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <PartySourceInfo
                      partyName={props.details.name}
                      partyTypeId={props.thirdPartyCode}
                      partyType={THIRD_PARTY_ENUMS.THIRD_PARTY}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
          {HELPER.HAS_AUTHORITY("switch_get_issuer_processor") ? (
            <TabPanel header={"Issuer Processor"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <IssuerProcessors thirdPartyCode={props.thirdPartyCode} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
          {HELPER.HAS_AUTHORITY("switch_get_third_party_acquirer") ? (
            <TabPanel header={"Acquirers"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <ThirdPartyAcquirers
                      thirdPartyCode={props.thirdPartyCode}
                      thirdPartyName={props.details.name}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
          {HELPER.HAS_AUTHORITY("switch_get_third_party") ? (
            <TabPanel header={"Linked Third Parties"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <LinkedThirdParties
                      parentThirdPartyCode={props.thirdPartyCode}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };

  const thirdPartyDetailsView = () => {
    switch (currentIndex) {
      case 0:
        return thirdPartyTabView();
      case 1:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="Loading details ..." />
          </div>
        );
      default:
        break;
    }
  };
  return <div>{thirdPartyDetailsView()}</div>;
};
