import "../details-break-down/details-break-down.css";
import { HELPER } from "../../../core/helper/helper";
import { SpecialLabelCases } from "../../../core/utils/utilities";
import { CustomLoader } from "../custom-loader/custom-loader";

export function FormattedDetails({ details, loading }) {
  const transformView = (value, property) => {
    let result = value;
    SpecialLabelCases.forEach((e) => {
      if (e.case === property) {
        if (value || value === false) {
          result = e.action(value, property);
        }
      }
    });
    return result ? result : "---";
  };

  const formatPtsp = (property) => {
    // TODO: Rename CAMEL_TO_TITLE_CASE to camelToTitleCase
    let arr = HELPER.CAMEL_TO_TITLE_CASE(property).split(" ");
    arr[0] = arr[0].toUpperCase();
    return arr.join(" ");
  };

  const formatFieldWithButton = (property) => {
    return (
      <span>
        <span className="details-value mb-2">
          {transformView(property.value, property.name)}
        </span>{" "}
        <br />
        <button onClick={property.fn} className="view-details p-0 underline">
          {property.buttonText}
        </button>
      </span>
    );
  };

  const formatFieldAsHyperlink = (property) => {
    const { value, name, fn, userFriendlyText } = property;
    return (
      <span
        onClick={value ? fn : () => {}}
        className={`details-value cursor-pointer ${value ? "underline" : ""}`}
        style={{ color: "#0371A0" }}
      >
        {transformView(userFriendlyText, name)}
      </span>
    );
  };

  const formatField = (value, property) => {
    if (value?.type === "button") {
      return formatFieldWithButton(value);
    } else if (value?.type === "hyperlink") {
      return formatFieldAsHyperlink(value);
    }
    return transformView(value, property);
  };

  const renderFormattedDetails = () => {
    const properties = Object.keys(details);
    return properties.map((property) => {
      let ptspValue;
      if (property.startsWith("ptsp")) {
        ptspValue = formatPtsp(property);
      }
      return property === "id" || property === "lastModifiedOn" ? null : (
        <div key={String(property)} className="grid dcir-row pl-0">
          <div className="col-5 ml-0 pl-0">
            <p className="details-label">
              {property === "ptsp" || property === "ptsa"
                ? property.toUpperCase()
                : property.startsWith("ptsp")
                ? ptspValue
                : HELPER.CAMEL_TO_TITLE_CASE(property)}
            </p>
          </div>
          <div className="col-7">
            <div className="fle defaul">
              <p className="details-value">
                {typeof details[property] === "object"
                  ? formatField(details[property], property)
                  : transformView(details[property], property)}
              </p>
            </div>
          </div>
        </div>
      );
    });
  };

  if (loading) {
    return <CustomLoader loadingText="Loading..." />;
  }

  return <div className="mt-3 mb-5">{renderFormattedDetails()}</div>;
}
