import "./custom-breadcrumb.css";
// import {Icon}       from "../../icons/icon";
// import {Link}       from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";

export function CustomBreadcrumb(props) {
  const items = [{ label: props.page }];

  const home = { icon: "pi pi-home", url: "" };

  return (
    <BreadCrumb model={items} home={home} />
    // // <div className="text-left custom-breadcrumb-container">
    //     {/*<div className="grid">*/}
    //     {/*<div className="col-3"><Link to={`/dashboard`}><div className="custom-breadcrumb-text">Dashboard</div></Link></div>*/}
    //     {/* <div className="col-1">*/}
    //     {/* <div className="breadcrumb-position">*/}
    //     {/* <Icon icon="breadcrumb-icon"/>*/}
    //     {/* </div>*/}
    //     {/* </div>*/}
    //     {/*    <div className="col-8"><div className="custom-breadcrumb-text-active">{props.page}</div></div>*/}
    //     {/*</div>*/}
    // // </div>
  );
}
