import "./bulk-merchant-terminals-details.css";
import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Icon } from "../../../shared/components/icons/icon";
import { BulkMerchantTerminalEntries } from "./bulk-merchant-terminal-entries";
import { HELPER } from "core/helper/helper";

const BulkMerchantTerminalDetails = (props) => {
  const renderBulkMerchantTerminalDetails = () => {
    return (
      <div
        className="p-shadow-1 p-pb-5 pt-3 mt-5 bg-white"
      >
        <div className="p-text-left ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          {HELPER.HAS_AUTHORITY(
            "switch_get_bulk_merchant_terminal_upload_entry"
          ) ? (
            <TabPanel header={"File Upload Entries"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <BulkMerchantTerminalEntries details={props.details} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };
  return <div>{renderBulkMerchantTerminalDetails()}</div>;
};

export default BulkMerchantTerminalDetails;
