import { AppButton } from "../app-button/app-button";
import { FormActions } from "../utilities/form-actions";

export function ConfirmationDialog({
  onClose,
  onSubmit,
  loading,
  headerText,
  confirmationText,
}) {
  return (
    <div>
      <div className="pb-1">
        <p className="confirm-text mt-0">{headerText}</p>
      </div>
      <p className="text-left mt-0">{confirmationText}</p>

      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text="Cancel"
          onclick={onClose}
        />
        <AppButton
          type="submit"
          buttonStyle="primary"
          text="Confirm"
          loading={loading}
          onclick={onSubmit}
        />
      </FormActions>
    </div>
  );
}
