import "./dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useCallback, useEffect, useRef, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { DASHBOARD_HELPERS } from "./helpers";
import { FormDropdown } from "../form-component/form-dropdown";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export function AnalyticsChart(props) {
  const [loading, setLoading] = useState(true);
  const [superagents, setSuperAgents] = useState([]);
  const selectedSuperagent = useRef({
    desc: "All superagents",
    code: "all",
  });
  // const [chartValueType, setChartValueType] = useState("count");
  const chartValueType = useRef("count");
  const barChartOptions = useRef(null);
  const [chartData, setChartData] = useState(null);

  function handleChartTypeChange(e) {
    chartValueType.current = e?.target?.value;
    if (props?.dateRange === "today") {
      if (props?.metricType === "transactions") {
        formatCurrentDayTransactionsData(props?.currentDayMetrics);
      } else {
        formatCurrentDayDisputesData(props?.currentDayMetrics);
      }
    } else {
      if (props?.metricType === "transactions") {
        if (selectedSuperagent.current.code === "all") {
          formatHistoricTransactionsData(props?.historicMetrics);
        } else {
          formatHistoricTransactionsDataBySuperAgent();
        }
      } else {
        if (selectedSuperagent.current.code === "all") {
          formatHistoricDisputesData(props?.historicMetrics);
        } else {
          formatHistoricDisputesDataBySuperAgent();
        }
      }
    }
  }

  const formatCurrentDayTransactionsData = useCallback(
    (data) => {
      setLoading(true);
      const dataFormatFunction =
        props.userType === "front-office"
          ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_CURRENT_DAY_TRANSACTIONS_DATA
          : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_CURRENT_DAY_TRANSACTIONS_DATA;
      const { chartOptions, chartData } = dataFormatFunction(
        data,
        chartValueType.current
      );
      barChartOptions.current = chartOptions;
      setChartData(chartData);
      setLoading(false);
    },
    [props.userType]
  );

  const formatCurrentDayDisputesData = useCallback(
    (data) => {
      setLoading(true);
      const dataFormatFunction =
        props.userType === "front-office"
          ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_CURRENT_DAY_DISPUTE_DATA
          : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_CURRENT_DAY_DISPUTE_DATA;
      const { chartOptions, chartData } = dataFormatFunction(
        data,
        chartValueType.current
      );
      barChartOptions.current = chartOptions;
      setChartData(chartData);
      setLoading(false);
    },
    [props.userType]
  );

  const formatHistoricTransactionsData = useCallback(
    (data) => {
      setLoading(true);
      const dataFormatFunction =
        props.userType === "front-office"
          ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_HISTORIC_TRANSACTIONS_DATA
          : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_HISTORIC_TRANSACTIONS_DATA;
      const { chartOptions, chartData, formattedSuperAgentArr } =
        dataFormatFunction(data, chartValueType.current);
      barChartOptions.current = chartOptions;
      setSuperAgents(formattedSuperAgentArr);
      setChartData(chartData);
      setLoading(false);
    },
    [props.userType]
  );

  const formatHistoricDisputesData = useCallback(
    (data) => {
      setLoading(true);
      const dataFormatFunction =
        props.userType === "front-office"
          ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_HISTORIC_DISPUTES_DATA
          : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_HISTORIC_DISPUTES_DATA;
      const { chartOptions, chartData, formattedSuperAgentArr } =
        dataFormatFunction(data, chartValueType.current);
      barChartOptions.current = chartOptions;
      setSuperAgents(formattedSuperAgentArr);
      setChartData(chartData);
      setLoading(false);
    },
    [props.userType]
  );

  useEffect(() => {
    if (props?.dateRange === "today") {
      if (props?.metricType === "transactions") {
        formatCurrentDayTransactionsData(props?.currentDayMetrics);
      } else {
        formatCurrentDayDisputesData(props?.currentDayMetrics);
      }
    } else {
      if (props?.metricType === "transactions") {
        formatHistoricTransactionsData(props?.historicMetrics);
      } else {
        formatHistoricDisputesData(props?.historicMetrics);
      }
    }
  }, [
    formatCurrentDayTransactionsData,
    formatHistoricTransactionsData,
    formatCurrentDayDisputesData,
    formatHistoricDisputesData,
    props.dateRange,
    props?.currentDayMetrics,
    props?.historicMetrics,
    props?.metricType,
  ]);

  function handleSuperagentSelection(e) {
    setLoading(true);
    const superagent = e?.value?.code;
    selectedSuperagent.current = e?.value;
    if (props?.metricType === "transactions") {
      if (superagent === "all") {
        formatHistoricTransactionsData(props?.historicMetrics);
      } else {
        formatHistoricTransactionsDataBySuperAgent();
      }
    } else {
      if (superagent === "all") {
        formatHistoricDisputesData(props?.historicMetrics);
      } else {
        formatHistoricDisputesDataBySuperAgent();
      }
    }

    setLoading(false);
  }

  function formatHistoricTransactionsDataBySuperAgent() {
    const { chartOptions, chartData } =
      DASHBOARD_HELPERS.FORMAT_SELECTED_SUPERAGENT_TRANSACTIONS_DATA(
        props?.historicMetrics,
        selectedSuperagent?.current?.code,
        chartValueType.current
      );
    barChartOptions.current = chartOptions;
    setChartData(chartData);
  }

  function formatHistoricDisputesDataBySuperAgent() {
    const { chartOptions, chartData } =
      DASHBOARD_HELPERS.FORMAT_SELECTED_SUPERAGENT_DISPUTES_DATA(
        props?.historicMetrics,
        selectedSuperagent?.current?.code,
        chartValueType.current
      );
    barChartOptions.current = chartOptions;
    setChartData(chartData);
  }

  const historicChart = () => {
    return (
      <div className="grid mt-5">
        <div className="col-12">
          <div className="card">
            <div
              className="text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="mt-0" style={{ fontWeight: "900" }}>
                {props?.metricType === "transactions"
                  ? "Transaction"
                  : "Dispute"}{" "}
                Metrics
              </h3>
              {/* <small>{moment().format("HH:mm")}</small> */}
            </div>
            <div className="grid">
              <div className="col-6">
                <div
                  style={{
                    marginBottom: "2rem",
                  }}
                >
                  <label className="label">
                    <input
                      id="count"
                      value="count"
                      name="chartValueType"
                      type="radio"
                      checked={chartValueType.current === "count"}
                      onChange={handleChartTypeChange}
                    />
                    <span>
                      {props?.metricType === "transactions"
                        ? "Transactions"
                        : "Disputes"}{" "}
                      by Count
                    </span>
                  </label>
                  <label className="label">
                    <input
                      id="value"
                      value="value"
                      name="chartValueType"
                      type="radio"
                      checked={chartValueType.current === "value"}
                      onChange={handleChartTypeChange}
                    />
                    <span>
                      {props?.metricType === "transactions"
                        ? "Transactions"
                        : "Disputes"}{" "}
                      by Value
                    </span>
                  </label>
                </div>
              </div>
              {props.userType !== "front-office" && (
                <div className="col-6">
                  <FormDropdown
                    required={true}
                    label="desc"
                    value={selectedSuperagent.current}
                    fn={handleSuperagentSelection}
                    options={superagents}
                    placeholder="Select superagent"
                  />
                </div>
              )}
            </div>
            {!loading && (
              <div>
                <Bar options={barChartOptions.current} data={chartData} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const currentDayChart = () => {
    return (
      <div className="grid mt-5">
        <div className="col-12">
          <div className="card">
            <div
              className="text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="mt-0" style={{ fontWeight: "900" }}>
                {props?.metricType === "transactions"
                  ? "Transaction"
                  : "Dispute"}{" "}
                Metrics
              </h3>
              {/* <small>{moment().format("HH:mm")}</small> */}
            </div>
            <div
              style={{
                marginBottom: "2rem",
              }}
            >
              <label className="label">
                <input
                  id="count"
                  value="count"
                  name="chartValueType"
                  type="radio"
                  checked={chartValueType.current === "count"}
                  onChange={handleChartTypeChange}
                />
                <span>
                  {props?.metricType === "transactions"
                    ? "Transactions"
                    : "Disputes"}{" "}
                  by Count
                </span>
              </label>
              <label className="label">
                <input
                  id="value"
                  value="value"
                  name="chartValueType"
                  type="radio"
                  checked={chartValueType.current === "value"}
                  onChange={handleChartTypeChange}
                />
                <span>
                  {props?.metricType === "transactions"
                    ? "Transactions"
                    : "Disputes"}{" "}
                  by Value
                </span>
              </label>
            </div>
            {!loading && (
              <div>
                {props.userType === "front-office" ? (
                  <Pie
                    data={chartData}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                  />
                ) : (
                  <Bar options={barChartOptions.current} data={chartData} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const chartView = () => {
    switch (props.dateRange) {
      case "today":
        return currentDayChart();
      default:
        return historicChart();
    }
  };

  return (
    <div>
      <div>{chartView()}</div>
    </div>
  );
}
