import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { FeeSourceForm } from "./fee-source-form";
import { FeeSourcesFilter } from "./fee-sources-filter";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";

export function FeeSources() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Create Fee Source",
    subtitle: "All fields are required",
  });
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
    { label: "Source Node ID", value: "sourceNodeId" },
    { label: "Status", value: "active" },
    { label: "Created At", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [feeSources, setFeeSources] = useState([]);
  const [selectedFeeSource, setSelectedFeeSource] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [filterParams, setFilterParams] = useState(null);

  const getFeeSources = useCallback(
    async (filters) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...filters,
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.FEE_SOURCE.FEE_SOURCE}?${params}`
        );
        setPagination(response);
        const feeSources = response?.content;
        setFeeSources(feeSources);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getFeeSources();
    } else {
      getFeeSources(filterParams);
    }
  }, [getFeeSources, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getFeeSources();
    }
  }

  const handleFeeSourcesFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  const handleTableAction = (data, action) => {
    const { active, updatedAt, code, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Fee Rule",
          subtitle: "All fields are required",
        });
        setSelectedFeeSource({ ...rest, active, code, updatedAt });
        toggleModal(1);
        break;
      case "VIEW":
        setModalInfo({
          title: "Fee Source",
          subtitle: "View details of fee source below",
        });
        setSelectedFeeSource({ ...rest, status: active });
        toggleModal(2);
        break;
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <FeeSourceForm
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getFeeSources}
          />
        );
      case 1:
        return (
          <FeeSourceForm
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getFeeSources}
            feeSource={selectedFeeSource}
          />
        );
      case 2:
        return <FormattedDetails details={selectedFeeSource} />;
      case 3:
        return (
          <FeeSourcesFilter
            onGetFeeSources={handleFeeSourcesFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_update_fee_rule" },
            { label: "OPEN", value: "all" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No fee source found!"}
          search={true}
          reload={reload}
          error={error}
          items={feeSources}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "VIEW"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  const renderPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  return (
    <div className="manage-fee-sources">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Fee Sources" />
      <CustomBreadcrumb page="Manage Fee Sources" />
      <Optional
        showIf={
          HELPER.HAS_AUTHORITY("switch_create_fee_rule") && currentIndex === 0
        }
      >
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => {
              setModalInfo({
                title: "Filter Fee Source",
                subtitle: "",
              });
              toggleModal(3);
            }}
          />
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Fee Source"
            onclick={() => {
              toggleModal(0);
              setModalInfo({
                title: "Create Fee Source",
                subtitle: "All fields are required",
              });
            }}
          />
        </PageActions>
      </Optional>
      <>{renderPageView()}</>
    </div>
  );
}
