import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { DEFAULT_PAGE_SIZE } from "core/configs/configs";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";

export const useBulkMerchantTerminalEntries = (props) => {
  const { addNotification } = useNotification();
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const tableHeaders = [
    { label: "Merchant Id", value: "merchantId" },
    { label: "Merchant Name", value: "merchantName" },
    { label: "Terminal Id", value: "terminalId" },
    { label: "Status", value: "status" },
    { label: "Actions", value: "actions" },
  ];
  const tableAuthorities = [{ label: "VIEW", value: "all" }];
  const [bulkMerchantTerminalsEntries, setBulkMerchantTerminalsEntries] =
    useState([]);

  const getBulkMerchantTerminalEntries = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
      bulkMerchantTerminalUploadCode: props.details.code,
    });
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.BULK_MERCHANT_TERMINAL.GET_BULK_MERCHANT_TERMINAL_UPLOAD_ENTRIES}?${params}`
      );
      setPagination(response.result);
      const bulkMerchantTerminals = response?.result?.content;
      setBulkMerchantTerminalsEntries(bulkMerchantTerminals);
      setError(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    }
  }, [pageNo, addNotification, props.details.code]);

  useEffect(() => {
    getBulkMerchantTerminalEntries();
  }, [getBulkMerchantTerminalEntries]);

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getBulkMerchantTerminalEntries();
    }
  }

  function reload() {
    getBulkMerchantTerminalEntries();
    setPageNo(0);
  }

  const handleViewAction = (data) => {
    setModalIndex(0);
    setDetails(data);
    setIsModalVisible(!isModalVisible);
  };

  const handleTableAction = (data, action) => {
    switch (action) {
      case "VIEW":
        const {
          code,
          bulkMerchantTerminalUploadCode,
          thirdPartyCode,
          createdBy,
          ...rest
        } = data;
        handleViewAction(rest);
        return;
      default:
        break;
    }
  };

  const incrementPageNo = () => {
    setPageNo(pageNo + 1);
  };
  const decrementPageNo = () => {
    setPageNo(pageNo - 1);
  };
  const goToPage = (number) => {
    setPageNo(number);
  };

  const api = {
    loading,
    error,
    getBulkMerchantTerminalEntries,
  };
  const page = {
    isModalVisible,
    pageNo,
    modalIndex,
    tableHeaders,
    tableAuthorities,
  };
  const data = {
    bulkMerchantTerminalsEntries,
    pagination,
    details,
  };
  const pageActions = {
    goToPage,
    decrementPageNo,
    incrementPageNo,
    handleTableAction,
    reload,
    closeModal,
    toggleModal,
  };

  return { api, page, data, pageActions };
};
