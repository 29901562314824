import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { Steps } from "primereact/steps";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { useAcquirersList } from "api/hooks/use-acquirers-list";
import { useThirdPartiesList } from "api/hooks/use-third-parties-list";
import { useMerchantForm } from "backoffice/pages/merchants/use-merchant-form";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";

export function MerchantForm(props) {
  const { acquirers } = useAcquirersList();
  const { thirdParties } = useThirdPartiesList();
  const { actions, states } = useMerchantForm(props);
  const filteredThirdParties = states.thirdParties.length
    ? states.thirdParties
    : thirdParties?.content;
  const {
    currentFormIndex,
    PRIMARY_INFO,
    SETTLEMENT_INFO,
    CONTACT_INFO,
    DETAILS_CONFIRMATION,
    stepWizardModel,
  } = states;

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === PRIMARY_INFO ? "Close" : "Back"}
          onclick={actions.handleClose}
        />
        <AppButton
          loading={states.loading}
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex <= CONTACT_INFO ? "Next" : "Submit"}
        />
      </FormActions>
    );
  };

  const renderFormAlert = () => {
    return states.message ? (
      <CustomToast
        title={states.toastType === "error" ? "Error" : "Success"}
        description={states.message}
        type={states.toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };

  const renderStepWizard = (values, setFieldValue, errors) => {
    switch (currentFormIndex) {
      case PRIMARY_INFO:
        return (
          <>
            <AppFormInput
              label="Merchant Name"
              name="name"
              type="text"
              placeholder="Input merchant name"
              required
            />
            <ThirdPartiesDropdown
              values={values}
              onFilter={actions.filterThirdPartiesByName}
              filter={true}
              isRequired={true}
              thirdParties={filteredThirdParties}
              filtering={states.filtering}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <AppFormInput
              label="Card Acceptor Id (MID)"
              name="cardAcceptorId"
              type="text"
              placeholder="Input card acceptor Id"
              required
            />
            <AppFormInput
              label="Location"
              name="location"
              type="text"
              placeholder="Location"
              required
            />
            <AppFormInput
              label="Merchant Category Code"
              name="merchantCategoryCode"
              type="text"
              placeholder="Merchant Category Code"
            />
            <div className="col-6 pt-0">
              <div>
                <p
                  className="m-0 text-sm font-bold mb-2 block text-left"
                  style={{ color: "#566a7f" }}
                >
                  Is the merchant an agent? :{" "}
                  <span style={{ color: "red" }}> * </span>
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="Agent"
                    checked={values.agent === true}
                    handleChange={(e) => setFieldValue("agent", true)}
                    label="Yes"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="Agent"
                    checked={values.agent === false}
                    handleChange={(e) => setFieldValue("agent", false)}
                    label="No"
                  />
                </div>
              </div>
            </div>
            <div className="col-6 pt-0">
              <div>
                <p
                  className="m-0 text-sm font-bold mb-2 block text-left"
                  style={{ color: "#566a7f" }}
                >
                  Validate terminal? : <span style={{ color: "red" }}> * </span>
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="validateTerminal"
                    checked={values.validateTerminal === true}
                    handleChange={(e) =>
                      setFieldValue("validateTerminal", true)
                    }
                    label="Yes"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="validateTerminal"
                    checked={values.validateTerminal === false}
                    handleChange={(e) =>
                      setFieldValue("validateTerminal", false)
                    }
                    label="No"
                  />
                </div>
              </div>
            </div>
          </>
        );
      case SETTLEMENT_INFO:
        return (
          <>
            <AppFormDropdown
              className="col-12"
              value={values.acquirerCode}
              onchange={(e) => {
                setFieldValue("acquirerCode", e?.target?.value);
              }}
              options={acquirers}
              optionLabel="financialInstitutionName"
              optionValue="code"
              placeholder="Select Acquirer Name"
              label="Acquirer Name"
              error={errors?.acquirerCode}
              field="acquirerCode"
              required
            />
            <AppFormInput
              label="Account Number"
              name="accountNumber"
              type="text"
              placeholder="Input account number"
              required
            />
            <AppFormInput
              label="Account Name"
              name="accountName"
              type="text"
              placeholder="Input account name"
              required
            />
            <AppFormInput
              label="Settlement Email"
              name="settlementEmail"
              type="text"
              placeholder="Input settlement email"
              // required
            />
          </>
        );
      case CONTACT_INFO:
        return (
          <>
            <AppFormInput
              label="Main Email"
              name="mainEmail"
              type="text"
              placeholder="Input main email"
              // required
            />
            <AppFormInput
              label="Admin Email"
              name="adminEmail"
              type="text"
              placeholder="Input admin email"
              required
            />
            <AppFormInput
              label="Support Email"
              name="supportEmail"
              type="text"
              // required
              placeholder="Input support email"
            />
            <AppFormInput
              label="Dispute Email"
              name="disputeEmail"
              type="text"
              placeholder="Input dispute email"
              // required
            />
            <AppFormInput
              label="Phone"
              name="phone"
              type="text"
              placeholder="Input phone number"
              required
            />

            <div className="col-6 pt-0">
              <Optional showIf={props.isUpdate}>
                <div>
                  <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    Status: <span style={{ color: "red" }}> * </span>
                  </p>
                  <div className="flex default p-mb-4">
                    <FormRadioInput
                      id="active"
                      value={true}
                      name="Status"
                      checked={values.status === true}
                      handleChange={(e) => setFieldValue("status", true)}
                      label="Active"
                    />
                    <FormRadioInput
                      id="Inactive"
                      value={false}
                      name="Status"
                      checked={values.status === false}
                      handleChange={(e) => setFieldValue("status", false)}
                      label="Inactive"
                    />
                  </div>
                </div>
              </Optional>
            </div>
          </>
        );
      case DETAILS_CONFIRMATION:
        return <FormattedDetails details={states.details} />;
      default:
        break;
    }
  };

  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <>
        <p className="modal-title p-text-left ">
          {currentFormIndex === DETAILS_CONFIRMATION
            ? "Confirm merchant details"
            : props.title}
        </p>
        <p className="modal-subtitle mt-0 -mt-3">
          {currentFormIndex === DETAILS_CONFIRMATION ? "" : props.subtitle}
        </p>
        <Steps activeIndex={currentFormIndex} model={stepWizardModel} />
        <div className="custom-modal-item">
          {renderStepWizard(values, setFieldValue, errors)}
        </div>
      </>
    );
  };

  const renderFormView = () => {
    return (
      <Formik
        initialValues={states.initialValues}
        validationSchema={Yup.object(states.validationSchema)}
        onSubmit={(values) => {
          actions.submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderFormAlert()}
            {renderFormActions()}
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderFormView()}</div>;
}
