import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { DEFAULT_PAGE_SIZE } from "core/configs/configs";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";

const useLinkedThirdParties = (props) => {
  const { addNotification } = useNotification();
  const [linkedThirdParties, setLinkedThirdParties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageNo: 0,
  });
  const [error, setError] = useState(null);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Status", value: "active" },
    { label: "Creation Date", value: "createdAt" },
  ];

  const getLinkedThirdParties = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pagination.pageNo,
      size: DEFAULT_PAGE_SIZE,
      parentThirdPartyCode: props.parentThirdPartyCode,
    });

    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES}?${params}`
      );
      setPagination({ ...response.result, pageNo: pagination.pageNo });
      const thirdParties = response?.result?.content;
      setLinkedThirdParties(thirdParties);
      setError(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    }
  }, [pagination.pageNo, addNotification, props.parentThirdPartyCode]);

  useEffect(() => {
    getLinkedThirdParties();
  }, [getLinkedThirdParties]);

  function reload() {
    getLinkedThirdParties();
    setPagination({ ...pagination, pageNo: 0 });
  }

  const incrementPageNo = () => {
    setPagination({ ...pagination, pageNo: pagination.pageNo + 1 });
  };
  const decrementPageNo = () => {
    setPagination({ ...pagination, pageNo: pagination.pageNo - 1 });
  };
  const goToPage = (number) => {
    setPagination({ ...pagination, pageNo: number });
  };

  const actions = {
    reload,
    incrementPageNo,
    decrementPageNo,
    goToPage,
  };
  const page = {
    linkedThirdParties,
    tableHeaders,
    loading,
    error,
    pagination,
  };
  return { actions, page };
};

export default useLinkedThirdParties;
