import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { Steps } from "primereact/steps";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { THIRD_PARTY_ENUMS } from "shared/constants";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import useAcquirerForm from "./use-acquirers-form";
import { PartySourceInfoForm } from "../party-source-info/party-source-info-form";
import { Optional } from "shared/components/optional/optional";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";

export function AcquirersForm(props) {
  const { actions, api, formInfo } = useAcquirerForm(
    props,
    THIRD_PARTY_ENUMS.ACQUIRER
  );
  const { submit, handleClose, goBack } = actions;
  const {
    loading,
    toast,
    acquirerInfo,
    partySourceInfo,
    financialInstitutions,
  } = api;
  const {
    closeBackButtonText,
    submitNextButtonText,
    PRIMARY_INFO_INDEX,
    CONTACT_INFO_INDEX,
    CONNECTION_INFO_INDEX,
    initialValues,
    validationSchema,
    currentFormIndex,
    stepWizardModel,
  } = formInfo;
  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={closeBackButtonText}
          onclick={handleClose}
        />
        <AppButton
          type="submit"
          buttonStyle="primary"
          text={submitNextButtonText}
          loading={loading}
        />
      </FormActions>
    );
  };
  const renderFormAlert = () => {
    return toast.message ? (
      <CustomToast
        title={toast.type === "error" ? "Error" : "Success"}
        description={toast.message}
        type={toast.type}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };
  const renderStepWizard = (values, setFieldValue, errors) => {
    switch (currentFormIndex) {
      case PRIMARY_INFO_INDEX:
        return (
          <div className="custom-modal-item">
            <AppFormDropdown
              value={values.financialInstitutionCode}
              onchange={(e) => {
                setFieldValue("financialInstitutionCode", e?.target?.value);
              }}
              options={financialInstitutions}
              optionLabel="name"
              optionValue="code"
              placeholder="Select financial institution"
              label="Financial Institution"
              error={errors?.financialInstitutionCode}
              field="financialInstitutionCode"
              required
              filter
            />
          </div>
        );
      case CONTACT_INFO_INDEX:
        return (
          <div className="custom-modal-item">
            <div className="grid">
              <div className="col-6 pt-0">
                <AppFormInput
                  label="Admin Email"
                  name="adminEmail"
                  type="text"
                  placeholder="Input admin email"
                />
              </div>
              <div className="col-6 pt-0">
                <AppFormInput
                  label="Settlement Email"
                  name="settlementEmail"
                  type="text"
                  required
                  placeholder="Input settlement email"
                />
              </div>
              <div className="col-6 pt-0">
                <AppFormInput
                  label="Dispute Email"
                  name="disputeEmail"
                  type="text"
                  placeholder="Input dispute email"
                />
              </div>
              <div className="col-6 pt-0">
                <AppFormInput
                  label="Support Email"
                  name="supportEmail"
                  type="text"
                  placeholder="Input support email"
                />
              </div>
              <div className="col-6 pt-0">
                <AppFormInput
                  label="Phone"
                  name="phone"
                  type="text"
                  placeholder="Input phone number"
                />
              </div>
              <div className="col-6 pt-3">
                <Optional showIf={props.isUpdate}>
                  <div>
                    <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                      Status:
                    </p>
                    <div className="flex default p-mb-4">
                      <FormRadioInput
                        id="active"
                        value={true}
                        name="Status"
                        checked={values.status === true}
                        handleChange={(e) => setFieldValue("status", true)}
                        label="Active"
                      />
                      <FormRadioInput
                        id="Inactive"
                        value={false}
                        name="Status"
                        checked={values.status === false}
                        handleChange={(e) => setFieldValue("status", false)}
                        label="Inactive"
                      />
                    </div>
                  </div>
                </Optional>
              </div>
            </div>
          </div>
        );
      case CONNECTION_INFO_INDEX:
        return (
          <PartySourceInfoForm
            partySourceInfo={partySourceInfo}
            isUpdate={props.isUpdate}
            partyName={acquirerInfo.financialInstitutionName}
            partyTypeId={acquirerInfo.code}
            partyType={THIRD_PARTY_ENUMS.ACQUIRER}
            isConnectionStep={true}
            goBack={() => goBack()}
            closeModal={props.closeModal}
          />
        );
      default:
        break;
    }
  };

  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <>
        <p className="modal-title p-text-left ">{props.title}</p>
        <p className="modal-subtitle -mt-3">{props.subtitle}</p>
        <Steps activeIndex={currentFormIndex} model={stepWizardModel} />
        {loading ? (
          <div className="loading-container text-center">
            <CustomLoader loadingText="Loading..." />
          </div>
        ) : (
          renderStepWizard(values, setFieldValue, errors)
        )}
      </>
    );
  };
  const renderFormView = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchema)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderFormAlert()}
            {CONNECTION_INFO_INDEX !== currentFormIndex && !loading
              ? renderFormActions()
              : null}
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderFormView()}</div>;
}
