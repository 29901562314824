import { BACK_OFFICE_API } from "api/backofffice/index";
import { AppButton } from "shared/components/app-button/app-button";
import { saveAs } from "file-saver";
import { HELPER } from "core/helper/helper";
import { CustomToast } from "shared/components/alert/custom-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { ProgressBar } from "shared/components/progress-bar/progress-bar";
import { COMPLETED, FAILED, PENDING } from "shared/constants";
import { API_SERVICE } from "api/service";
import { useNotification } from "core/hooks/useNotification";

export function TransactionReportGenerationProgress({
  reportParams,
  recordId,
  closeModal,
}) {
  const [message, setMessage] = useState(
    "Your request is being processed. You can view the Transaction Reports list to monitor progress and download your report."
  );
  const [isReportAvailable, setIsReportAvailable] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [percentageProcessed, setPercentageProcessed] = useState(0);
  const reportStatusTimeout = useRef(null);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const { addNotification } = useNotification();
  const handleReportStatusResponse = useRef(null);
  const getReportStatus = useRef(null);
  const waitTimeBeforeReloadInSeconds = 5;

  const downloadReport = async () => {
    setErrorMessage(null);
    setDownloadingReport(true);
    const url = `${BACK_OFFICE_API.TRANSACTION_REPORT.DOWNLOAD_TRANSACTION_REPORT}/${recordId}`;
    const isBlob = true;

    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(url, isBlob);
      addNotification({
        type: "success",
        message: "File download in progress!",
      });
      saveAs(
        response,
        `Transaction-Report-From${reportParams["startDate"]}To${reportParams["endDate"]}.xlsx`
      );
      setDownloadingReport(false);
      closeModal();
    } catch (error) {
      const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
      setErrorMessage(errMessage);
      setDownloadingReport(false);
    }
  };

  handleReportStatusResponse.current = useCallback((response) => {
    const { reportGenerationStatus, percentageProcessed, errorMessage } =
      response;
    if (reportGenerationStatus === FAILED) {
      setErrorMessage(errorMessage);
      return;
    }
    if (reportGenerationStatus === COMPLETED) {
      setMessage("Your report is now available to be downloaded!");
      clearTimeout(reportStatusTimeout.current);
      setIsReportAvailable(true);
      return;
    }
    if (reportGenerationStatus === PENDING) {
      setPercentageProcessed(percentageProcessed);
      reportStatusTimeout.current = setTimeout(() => {
        getReportStatus.current();
      }, waitTimeBeforeReloadInSeconds * 1000);
    }
  }, []);

  getReportStatus.current = useCallback(async () => {
    setErrorMessage(null);
    const params = HELPER.TO_URL_STRING({
      code: recordId,
    });
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.TRANSACTION_REPORT.GET_TRANSACTION_REPORTS}?${params}`
      );
      handleReportStatusResponse.current(response.result.content[0]);
    } catch (error) {
      clearTimeout(reportStatusTimeout.current);
      setErrorMessage(HELPER.PROCESS_ERROR(error));
    }
  }, [recordId]);

  useEffect(() => {
    getReportStatus.current();
  }, [getReportStatus]);

  return (
    <div style={{ fontSize: "0.9rem" }}>
      <p>{message}</p>

      {!isReportAvailable && (
        <div className="px-1">
          <p className="mt-5">Report generation progress</p>
          <ProgressBar value={percentageProcessed} width="100%" height="1rem" />
        </div>
      )}

      {errorMessage && (
        <CustomToast title="Error" type="error" description={errorMessage} />
      )}
      <div className="mt-5 mb-2 grid">
        <div className={`${isReportAvailable ? "col-6" : "col-12"}`}>
          <button
            onClick={() => {
              closeModal();
              clearTimeout(reportStatusTimeout.current);
            }}
            className="secondary-button"
          >
            Dismiss
          </button>
        </div>
        {isReportAvailable && (
          <div className="col-6">
            <AppButton
              type="button"
              buttonStyle="primary"
              text="Download Report"
              onclick={downloadReport}
              loading={downloadingReport}
              width="w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
}
