import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { CHARGE_SCHEME_FORM_MAPPING } from "./charge-scheme-form-mapping";
import { CHARGE_TYPE_SCHEMES } from "shared/constants";
import {
  defaultValidationSchemaObject,
  feeFormValidationSchemaMapping,
} from "./fee-validation-schemas";
import { API_SERVICE } from "api/service";

export function FeeForm(props) {
  const { addNotification } = useNotification();
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    flat: "",
    percentage: "",
    type: "",
  });
  const [validationSchemaObject, setValidationSchemaObject] = useState(
    defaultValidationSchemaObject
  );
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const [visibleOptionalFormFields, setVisibleOptionalFormFields] = useState(
    []
  );

  useEffect(() => {
    if (props.isUpdate) {
      const { name, description, flat, percentage, active, type } = props.fee;
      setVisibleOptionalFormFields(CHARGE_SCHEME_FORM_MAPPING[type.code]);
      setInitialValues({
        name,
        description,
        flat: HELPER.TO_MAJOR_AMOUNT(flat),
        percentage,
        active,
        type: type.code,
      });
    }
  }, [props.isUpdate, props.fee]);

  const handleResponse = (isCreate = false) => {
    let notification = {
      type: "success",
      message: `Fee successfully ${isCreate ? "created" : "updated"}!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const getFormattedPayload = (payload) => {
    const { flat, percentage, maximumCap, minimumCap } = payload;
    const formattedPayload = {
      ...payload,
      flat: String(flat)?.length
        ? HELPER.toTwoDecimalPlacesMinorAmount(flat)
        : 0,
      percentage: String(percentage)?.length ? Number(percentage) : 0,
      minimumCap: String(minimumCap)?.length
        ? HELPER.TO_MINOR_AMOUNT(minimumCap)
        : 0,
      maximumCap: String(maximumCap)?.length
        ? HELPER.TO_MINOR_AMOUNT(maximumCap)
        : 0,
    };
    return formattedPayload;
  };

  const createFee = async (payload) => {
    resetToast();
    let formattedPayload = getFormattedPayload(payload);
    const url = BACK_OFFICE_API.FEES.FEES;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, formattedPayload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateFee = async (payload) => {
    resetToast();
    let formattedPayload = await getFormattedPayload(payload);
    const url = BACK_OFFICE_API.FEES.FEES;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.fee?.code}`,
        formattedPayload
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const submit = async (payload) => {
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      if (props.isUpdate) {
        updateFee(payload);
      } else {
        createFee(payload);
      }
    } else {
      let { active, ...rest } = payload;
      let confirmationPayload = {
        ...rest,
        flat: payload?.flat ? HELPER.TO_MINOR_AMOUNT(payload?.flat) : 0,
        percentage: payload?.percentage ? Number(payload?.percentage) : 0,
        ...(props.isUpdate && {
          status: payload.active ? "Active" : "Inactive",
        }),
      };
      setFormValues(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
              <AppFormInput
                label="Name"
                name="name"
                type="text"
                placeholder="Name"
                required={true}
              />
              <AppFormInput
                label="Description"
                name="description"
                type="text"
                placeholder="Description"
                required={true}
              />
              <AppFormDropdown
                value={values.type}
                onchange={(e) => {
                  const type = e?.target?.value;
                  setFieldValue("type", type);
                  setFieldValue("flat", "");
                  setFieldValue("percentage", "");
                  setValidationSchemaObject(
                    feeFormValidationSchemaMapping[type]
                  );
                  setVisibleOptionalFormFields(
                    CHARGE_SCHEME_FORM_MAPPING[type]
                  );
                }}
                options={CHARGE_TYPE_SCHEMES}
                placeholder="Select Type"
                label="Fee Type"
                error={errors?.type}
                required={true}
              />
              <Optional
                showIf={visibleOptionalFormFields.includes("percentage")}
              >
                <AppFormInput
                  label="Percentage"
                  name="percentage"
                  type="text"
                  placeholder="Percentage"
                  required={true}
                />
              </Optional>
              <Optional showIf={visibleOptionalFormFields.includes("flat")}>
                <AppFormInput
                  label="Flat Fee (₦)"
                  name="flat"
                  type="text"
                  placeholder="Flat Fee (₦)"
                  required={true}
                />
              </Optional>
              <Optional showIf={props.isUpdate}>
                <div>
                  <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    Status: <span style={{ color: "red" }}> * </span>
                  </p>
                  <div className="flex default p-mb-4">
                    <FormRadioInput
                      id="active"
                      value={true}
                      name="Status"
                      checked={values.active === true}
                      handleChange={(e) => setFieldValue("active", true)}
                      label="Active"
                    />
                    <FormRadioInput
                      id="Inactive"
                      value={false}
                      name="Status"
                      checked={values.active === false}
                      handleChange={(e) => setFieldValue("active", false)}
                      label="Inactive"
                    />
                  </div>
                </div>
              </Optional>
            </Optional>
            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <FormattedDetails details={formValues} />
            </Optional>
            {message && (
              <CustomToast
                title={toastType === "error" ? "Error" : "Success"}
                description={message}
                type={toastType}
                closeable={false}
                inApp={true}
              />
            )}
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text={`${
                  currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                }`}
                onclick={handleClose}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                // text="Submit"
                text={`${
                  currentFormIndex === FORM_VIEW_INDEX ? "Confirm" : "Submit"
                }`}
                loading={loading}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
