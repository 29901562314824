import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { useNotification } from "core/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";
import { AppButton } from "shared/components/app-button/app-button";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { PageActions } from "shared/components/page-actions/page-actions";
import { HELPER } from "core/helper/helper";
import { Optional } from "shared/components/optional/optional";
import { PartySourceInfoForm } from "./party-source-info-form";
import { PartySourceInfoDetails } from "./party-source-info-details";

export const PartySourceInfo = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [partySourceInfo, setPartySourceInfo] = useState();
  const { addNotification } = useNotification();
  const [modalIndex, setModalIndex] = useState(0);
  const CREATE_MODAL_INDEX = 0;
  const UPDATE_MODAL_INDEX = 1;
  const [emptyText, setEmptyText] = useState("");

  const handleResponse = (partySources) => {
    const formattedData = partySources?.map((partySource) => {
      return {
        ...partySource,
        status: partySource.active,
      };
    });
    const {
      partyTypeId,
      partyType,
      createdBy,
      createdAt,
      updatedAt,
      active,
      ...result
    } = formattedData[0];
    setPartySourceInfo(HELPER.changeNullValuesToEmptyStrings({ ...result }));
  };

  const getPartySources = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({ partyTypeId: props.partyTypeId });
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.PARTY_SOURCE_INFO.PARTY_SOURCE_INFO}?${params}`
      );
      const partySources = response?.result?.content;
      if (partySources.length) {
        handleResponse(partySources);
      } else {
        setEmptyText("No Connnection Info found!");
      }
      setLoading(false);
    } catch (error) {
      setError(HELPER.PROCESS_ERROR(error));
      const message = HELPER.PROCESS_ERROR(error);
      addNotification({ message, type: "error" });
      setLoading(false);
    }
  }, [addNotification, props.partyTypeId]);

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function reload() {
    getPartySources();
  }
  useEffect(() => {
    getPartySources();
  }, [getPartySources]);

  function closeModal(shouldReload) {
    setIsModalVisible(false);
    if (shouldReload === true) {
      getPartySources();
    }
  }

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <PartySourceInfoForm
            title={"Create Party Source Info"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            {...props}
          />
        );
      case 1:
        return (
          <PartySourceInfoForm
            title={"Update Party Source Info"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isUpdate={true}
            partySourceInfo={partySourceInfo}
            {...props}
          />
        );
      default:
        break;
    }
  }
  const renderPageView = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <>
          <div className="flex align-items-center justify-content-end mx-3">
            <PageActions>
              <Optional
                showIf={
                  partySourceInfo &&
                  HELPER.HAS_AUTHORITY("switch_update_party_source_sink_info")
                }
              >
                <AppButton
                  onclick={() => toggleModal(UPDATE_MODAL_INDEX)}
                  icon="pencil"
                  text="Edit"
                  type="button"
                  buttonStyle="bare"
                  margin={"mr-2"}
                />
              </Optional>
              <Optional
                showIf={
                  !partySourceInfo &&
                  HELPER.HAS_AUTHORITY("switch_create_party_source_sink_info")
                }
              >
                <AppButton
                  onclick={() => toggleModal(CREATE_MODAL_INDEX)}
                  type="button"
                  buttonStyle="primary"
                  text="Create Connection Info"
                />
              </Optional>
            </PageActions>
          </div>
          <PartySourceInfoDetails
            error={error}
            emptyText={emptyText}
            partySourceInfo={partySourceInfo}
            reload={reload}
          />
        </>
      );
    }
  };

  return (
    <div>
      <CustomModal
        hasOwnModalHeader={true}
        closeModal={closeModal}
        onHide={closeModal}
        visible={isModalVisible}
        modalContent={modalContent}
      />
      {renderPageView()}
    </div>
  );
};
