import React from "react";
import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { useThirdPartiesList } from "api/hooks/use-third-parties-list";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";

export function MerchantFilter(props) {
  const { thirdParties } = useThirdPartiesList();
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const formattedThirdPartiesOptions = filteredThirdParties?.length
    ? filteredThirdParties
    : thirdParties?.content;
  const initialValues = {
    name: "",
    thirdPartyCode: "",
  };

  const validationSchemaObject = Yup.object().shape({
    name: Yup.string(),
    thirdPartyCode: Yup.string(),
  });

  const submit = async (values) => {
    props.onGetMerchants(values);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaObject}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue, handleBlur }) => (
          <Form>
            <p className="modal-title p-text-left ">{props.title}</p>
            <AppFormInput
              label="Name"
              name="name"
              type="text"
              placeholder="Enter merchant name"
              required={false}
            />
            <ThirdPartiesDropdown
              values={values}
              onFilter={filterThirdPartiesByName}
              filter={true}
              isRequired={false}
              thirdParties={formattedThirdPartiesOptions}
              filtering={filtering}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
