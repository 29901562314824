import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useCallback, useState } from "react";

export function useThirdPartyFilter() {
  const [filtering, setFiltering] = useState(false);
  const [thirdParties, setThirdParties] = useState([]);

  const filterThirdPartiesByName = useCallback(
    async (userInput, filterByCode = false) => {
      setFiltering(true);
      let params;
      if (filterByCode) {
        const route = `${BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES}/${userInput}`;
        const { result } = await API_SERVICE.MAKE_GET_REQUEST(route);
        params = HELPER.TO_URL_STRING({
          name: result?.name?.trim(),
        });
      } else {
        params = HELPER.TO_URL_STRING({
          name: userInput.trim(),
        });
      }
      const route = `${BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES}?${params}`;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(route);
        const data = response?.result?.content;

        if (data.length) {
          setThirdParties(data);
          return data;
        }
      } catch (error) {
      } finally {
        setFiltering(false);
      }
    },
    []
  );

  return { thirdParties, filtering, filterThirdPartiesByName };
}
