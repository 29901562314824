export const NavItems = [
  {
    text: "Manage Transactions",
    icon: "transactions",
    authorities: ["switch_get_teamapt_backoffice_transaction"],
    roles: [],
    id: "manage-teamapt-backoffice-transaction",
    children: [
      {
        text: "Transactions",
        authority: "switch_get_teamapt_backoffice_transaction",
        route: "/transactions",
        roles: [],
        id: "manage-teamapt-backoffice-transaction",
      },
      {
        text: "Transaction Reports",
        authority: "switch_get_teamapt_backoffice_transaction",
        route: "/transaction-reports",
        roles: [],
        id: "manage-transaction-reports",
      },
    ],
  },
  {
    text: "Manage Transactions",
    icon: "transactions",
    authorities: ["switch_get_third_party_transaction"],
    roles: [],
    id: "manage-third-party-transaction",
    children: [
      {
        text: "Transactions",
        authority: "switch_get_third_party_transaction",
        route: "/third-party-transactions",
        roles: [],
        id: "manage-third-party-transaction",
      },
      {
        text: "Transaction Reports",
        authority: "switch_get_third_party_transaction",
        route: "/transaction-reports",
        roles: [],
        id: "manage-transaction-reports",
      },
    ],
  },
  {
    text: "Financial Institutions",
    icon: "financial-institutions",
    // icon: "merchant",
    authorities: ["switch_get_financial_institution", "switch_get_issuer"],
    roles: [],
    id: "financial-institution",
    children: [
      {
        text: "Financial Institutions",
        route: "/financial-institutions",
        icon: "merchant",
        authority: "switch_get_financial_institution",
        roles: [],
        id: "inancial-institution",
        children: [],
      },
      {
        text: "Acquirers",
        route: "/financial-institutions/acquirers",
        icon: "merchant",
        authority: "switch_get_acquirer",
        roles: [],
        id: "acquirers",
        children: [],
      },
      {
        text: "Issuers",
        route: "/issuers",
        icon: "merchant",
        authority: "switch_get_issuer",
        roles: [],
        id: "issuers",
        children: [],
      },
    ],
  },
  {
    text: "Party Users",
    icon: "merchant-user",
    route: "/party-users",
    authority: "switch_get_all_party_office_users",
    roles: [],
    id: "manage-party-users",
    children: [],
  },
  {
    text: "NSS Settlement",
    icon: "nss-settlement",
    authorities: ["switch_get_nss_bank_config"],
    roles: [],
    id: "nss-settlement",
    children: [
      {
        text: "Configurations",
        route: "/nss-configurations",
        authority: "switch_get_nss_bank_config",
        id: "nss-configurations",
      },
      {
        text: "Settlement Sessions",
        route: "/nss-settlement-sessions",
        authority: "switch_get_paginated_nss_settlement_sessions",
        id: "nss-settlement-sessions",
      },
    ],
  },
  {
    text: "Manage Disputes",
    icon: "ticket",
    authorities: [
      "switch_get_party_disputes",
      "switch_get_all_disputes",
      "switch_get_dispute_config",
    ],
    id: "disputes",
    children: [
      {
        text: "All Disputes",
        route: "/disputes",
        authority: "switch_get_all_disputes",
        id: "teamapt-disputes",
      },
      {
        text: "All Disputes",
        route: "/party-disputes",
        authority: "switch_get_party_disputes",
        id: "party-disputes",
      },
      {
        text: "Dispute Configurations",
        route: "/dispute-configuration",
        authority: "switch_get_dispute_config",
        id: "dispute-configuration",
      },
    ],
  },
  {
    text: "Third Parties",
    icon: "third-parties",
    authorities: [
      "switch_get_third_party_category",
      "switch_get_third_parties",
      "switch_get_merchant",
    ],
    id: "third-party",
    children: [
      {
        text: "Merchants and Terminals",
        route: "/third-parties/merchant",
        authority: "switch_get_merchant",
        id: "merchant",
      },
      {
        text: "Bulk Merchant Terminal",
        route: "/third-parties/bulk-merchant-terminal",
        authority: "switch_get_bulk_merchant_terminal_upload",
        id: "merchant",
      },
      {
        text: "Third Parties",
        route: "/third-parties/third-parties",
        authority: "switch_get_third_parties",
        id: "third-parties",
      },
      {
        text: "Third Party Categories",
        route: "/third-parties/third-party-categories",
        authority: "switch_get_third_party_category",
        id: "third-party-categories",
      },
    ],
  },
  {
    text: "Fee Configuration",
    icon: "fee-configuration",
    authorities: ["switch_get_fee", "switch_get_fee_rule_identifier"],
    id: "fee-configuration",
    children: [
      {
        text: "Fees",
        route: "/fee-configuration/fees",
        authority: "switch_get_fee",
        id: "fee",
      },
      {
        text: "Fee Rules",
        route: "/fee-configuration/fee-rule",
        authority: "switch_get_fee_rule",
        id: "fee-rule",
      },
      {
        text: "Fee Source",
        route: "/fee-configuration/fee-source",
        authority: "switch_get_fee_rule",
        id: "fee-source",
      },
    ],
  },
  {
    text: "Fee Reports",
    icon: "fee-reports",
    route: "/fee-reports",
    authority: "switch_manage_user",
    roles: [],
    id: "fee-reports",
    children: [],
  },
  {
    text: "Settlement Configuration",
    icon: "settlement-configuration",
    authorities: ["switch_create_network"],
    id: "settlement-configuration",
    children: [
      {
        text: "Networks",
        route: "/settlement-configuration/networks",
        authority: "switch_create_network",
        // type: "bo",
        id: "networks",
      },
      {
        text: "Settlement Rules",
        route: "/settlement-configuration/settlement-rules",
        authority: "all",
        // type: "bo",
        id: "settlement-rules",
      },
    ],
  },
  {
    text: "Workflow Authorizations",
    icon: "workflow",
    authorities: [""],
    id: "workflow-authorizations",
    children: [
      {
        text: "My Requests",
        route: "/workflow-requests/self",
        authority: "all",
        // type: "bo",
        id: "my-requests",
      },
      {
        text: "All Requests",
        route: "/workflow-requests/all",
        authority: "all",
        // type: "bo",
        id: "all-requests",
      },
    ],
  },
  {
    text: "Settings",
    icon: "settings",
    authority: "all",
    roles: [],
    id: "settings",
    children: [
      {
        text: "Change Password",
        route: "/account/change-password",
        authority: "all",
        type: "all",
        id: "change-password",
        roles: [],
      },
      {
        text: "Public Holidays",
        route: "/public-holidays",
        authority: "all",
        id: "public-holidays",
        roles: [],
      },
      {
        text: "Global Configurations",
        route: "/configurations",
        authority: "switch_get_all_global_configs",
        id: "configurations",
        roles: [],
      },
      {
        text: "Integration Details",
        route: "/integration-details",
        authority: "switch_get_third_party_api_cred",
        id: "integration-details",
        roles: [],
      },
    ],
  },
];
