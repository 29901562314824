import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";
import { HELPER } from "core/helper/helper";

export function NssSettlementSessionsFilter(props) {
  const [initialValues] = useState({
    from: "",
    to: "",
  });
  const validationSchemaObject = {
    from: Yup.string(),
    to: Yup.string(),
  };

  const submit = (payload) => {
    const trimmedPayload = HELPER.TRIM_OBJECT(payload);
    props.onFilter(trimmedPayload);
    props.closeModal();
  };

  return (
    <div>
      <p className="modal-title p-text-left ">Filter NSS Settlement Sessions</p>
      <p className="mb-1">Filters by Settlement Date</p>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <div className="grid">
              <div className="col-6">
                <AppFormInput
                  name="from"
                  label="From"
                  placeholder="From"
                  type="date"
                />
              </div>
              <div className="col-6">
                <AppFormInput
                  name="to"
                  label="To"
                  placeholder="To"
                  type="date"
                />
              </div>
            </div>
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
