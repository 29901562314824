import "./custom-form.css";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
// import { Icon } from "../../icons/icon";
import { Icon } from "../icons/icon";

export function FormInput(props) {
  const [inputType, setInputType] = useState("text");
  const [hasFloatingLabel, setFloatingLabel] = useState(true);

  useEffect(() => {
    if (props.inputType) {
      setInputType(props.inputType);
    }
    if (props.noFloatingLabel) {
      setFloatingLabel(false);
    }
    if (props.inputType === "password") {
      setFloatingLabel(false);
    }
  }, [props.inputType, props.noFloatingLabel]);

  function toggleInputType() {
    if (inputType === "text") {
      setInputType("password");
    } else {
      setInputType("text");
    }
  }

  function changed(e) {
    props.fn(e, props.field, props.type, props.placeholder, props.required);
  }

  const showError = () => {
    if (props.error) {
      return <p className="p-error p-text-left">{props.error}</p>;
    } else return <small />;
  };

  const showVerified = () => {
    if (!props.error && props.verified && !props?.verifying) {
      return <p className="p-success p-text-left">{props.verified}</p>;
    } else return <small />;
  };

  const verifying = () => {
    if (!props.error && props?.verifying) {
      return (
        <div className="text-left mt-1">
          <span className="p-success">
            {props.gettingCustomerInfo ? "Getting" : "Verifying"}{" "}
            {props.verifyingField || props.field} ...{" "}
            <ProgressSpinner
              style={{
                width: "15px",
                height: "15px",
                marginLeft: ".1em",
                position: "relative",
                top: ".35em",
              }}
              strokeWidth="5"
              animationDuration=".5s"
            />
          </span>
        </div>
      );
    } else return <small />;
  };

  const today = moment().format("YYYY-MM-DD");

  const inputView = () => {
    if (props.inputType && props.inputType === "date") {
      return (
        <div className="mt-1 form-input">
          <input
            className={props.error ? "validation-error" : ""}
            disabled={props.loading}
            onChange={(e) =>
              props.fn(
                e,
                props.field,
                props.type,
                props.placeholder,
                props.required
              )
            }
            type="date"
            id={props.field}
            placeholder={props.placeholder}
            name={props.field}
            value={props.value}
            max={props.noMax ? null : props.max ? props.max : today}
            autoComplete="off"
          />
          {showError()}
          {showVerified()}
        </div>
      );
    } else {
      if (props.controlled) {
        return (
          <div className="mt-1 form-input">
            <div className="input-wrapper">
              <label>
                <input
                  id={props.id || props.field}
                  name={props.field}
                  value={props.value}
                  className={`${props.error ? "validation-error" : ""}
                  ${hasFloatingLabel ? "floating-label-input" : ""}`}
                  disabled={props.loading || props.disabled}
                  onChange={(e) => changed(e)}
                  placeholder={props.placeholder}
                  type={inputType}
                  autoComplete="off"
                />
                {props.inputType === "password" && props.value.length ? (
                  <button
                    type="button"
                    className="button bare"
                    onClick={toggleInputType}
                  >
                    {inputType === "text" && <Icon icon="slashed-eye" />}
                    {inputType === "password" && <Icon icon="eye" />}
                  </button>
                ) : (
                  ""
                )}
                {hasFloatingLabel && (
                  <span className="label">
                    {props.placeholder} {props.required && <span>*</span>}
                  </span>
                )}
              </label>
            </div>

            {showError()}
            {showVerified()}
            {verifying()}
          </div>
        );
      } else {
        return (
          <div className="mt-1 form-input">
            <div className="input-wrapper">
              <label>
                <input
                  id={props.id || props.field}
                  name={props.field}
                  defaultValue={props.value}
                  className={`${props.error ? "validation-error" : ""}
                  ${hasFloatingLabel ? "floating-label-input" : ""}`}
                  disabled={props.loading || props.disabled}
                  onChange={(e) =>
                    props.fn(
                      e,
                      props.field,
                      props.type,
                      props.placeholder,
                      props.required
                    )
                  }
                  placeholder={props.placeholder}
                  type={inputType}
                  checked={props.checked}
                  autoComplete="off"
                />
                {props.inputType === "password" && props.value.length ? (
                  <button
                    type="button"
                    className="button bare"
                    onClick={toggleInputType}
                  >
                    {inputType === "text" && <Icon icon="slashed-eye" />}
                    {inputType === "password" && <Icon icon="eye" />}
                  </button>
                ) : (
                  ""
                )}
                {hasFloatingLabel && (
                  <span className="label">
                    {props.placeholder} {props.required && <span>*</span>}
                  </span>
                )}
              </label>
            </div>

            {showError()}
            {showVerified()}
            {verifying()}
          </div>
        );
      }
    }
  };

  return <div>{inputView()}</div>;
}
