import "./login.css";
import { Icon } from "../../components/icons/icon";
import { useState, useEffect } from "react";
import { CustomToast } from "../../components/alert/custom-toast";
import { useNavigate } from "react-router-dom";
import { persistLoginDataAndRedirect } from "./helpers";
import { HELPER } from "../../../core/helper/helper";
import {
  LOCAL_STORAGE,
  LOCAL_STORAGE_SERVICE,
} from "../../../core/services/storage-service";
import { useLocation } from "react-router";
import { useMainState } from "../../../core/hooks/useMainState";
import { Formik, Form } from "formik";
import { AppFormInput } from "../../components/form-inputs/form-input";
import { AppButton } from "../../components/app-button/app-button";
import * as Yup from "yup";
import { AUTH_SERVICE } from "../../../api/backofffice/auth-service";

export function LoginPage() {
  let navigate = useNavigate();
  const { mainDispatch } = useMainState();
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const hasToken = LOCAL_STORAGE_SERVICE.GET_TOKEN();
    const isParty =
      LOCAL_STORAGE_SERVICE.GET_PARTY_USER_ID() === "null"
        ? null
        : LOCAL_STORAGE_SERVICE.GET_PARTY_USER_ID();
    if (hasToken && !isParty) {
      navigate("/transactions");
    }
    if (isParty && hasToken) {
      navigate("/third-party-transactions");
    }
  }, [navigate, mainDispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const isSessionExpired = queryParams.get("session_expired");
    if (isSessionExpired) {
      mainDispatch({
        type: "SET_ALERT",
        alert: {
          title: "Session Expired",
          description: "Your previous session has expired. Please login again.",
          type: "error",
          showAlert: true,
        },
      });
      navigate("/login");
    }
  }, [search, navigate, mainDispatch]);

  const handleSuccess = (response) => {
    if (response["2faEnabled"]) {
      handleTwoFactorAuthentication(response?.secondFactorAuthKey);
    } else if (response?.resetPassword) {
      handlePasswordReset(response?.cosmosResponse?.accessToken, response);
    } else {
      persistLoginDataAndRedirect(response, mainDispatch, navigate);
    }
  };

  const submit = async (payload) => {
    closeAlert();
    setLoading(true);
    try {
      const response = await AUTH_SERVICE.LOGIN(payload);
      handleSuccess(response?.result);
    } catch (error) {
      handleErrors(error);
    }
  };

  function handlePasswordReset(accessToken, loginData) {
    mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "Change Default Password",
        description:
          "You just tried to login with a system generated default password. You need to change this password to access your account.",
        type: "info",
        showAlert: true,
      },
    });
    mainDispatch({
      type: "TEMPORARILY_SET_LOGIN_DETAILS",
      loginData,
      access_token: accessToken,
    });
    navigate("/change-password");
  }

  function handleTwoFactorAuthentication(secondFactorAuthKey) {
    sessionStorage.setItem(LOCAL_STORAGE.TWO_FA_KEY, secondFactorAuthKey);
    navigate("/login/second-factor");
  }

  function closeAlert() {
    mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "",
        description: "",
        type: "",
        showAlert: false,
      },
    });
  }

  function handleErrors(error) {
    setErrorTitle("Error");
    setErrorMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  }

  return (
    <div>
      <div className="login-alert-container">
        {errorTitle ? (
          <CustomToast
            title={errorTitle}
            description={errorMessage}
            type="error"
          />
        ) : (
          <div />
        )}

        <div className="auth-form">
          <div className="dots top-right">
            <Icon icon="login-dots" />
          </div>
          <div className="dots bottom-left">
            <Icon icon="login-dots-two" />
          </div>
          <div className="login-container">
            <div className="log-icon-top-container">
              <div className="login-icon-position">
                <div className={`logo-size default`}>
                  <Icon icon="logo" />
                </div>
                <h1 className={`app-name default text-center`}>AptPay</h1>
              </div>
            </div>
            <div className="pl-5">
              <h3 className="mb-1" style={{ color: "#566a7f" }}>
                Welcome back!
              </h3>
              <p className="mt-0 text-sm" style={{ color: "#697a8d" }}>
                Please sign-in to your account to continue
              </p>
            </div>
            <div className="login-card">
              <div className="login-form-container">
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  validationSchema={Yup.object({
                    username: Yup.string().required("Required"),
                    password: Yup.string().required("Required"),
                  })}
                  onSubmit={(values) => {
                    submit(values);
                  }}
                >
                  <Form>
                    <AppFormInput
                      label="Username"
                      name="username"
                      type="text"
                      placeholder="Username"
                    />
                    <AppFormInput
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                    <div style={{ margin: "3rem 0 0", width: "100%" }}>
                      <AppButton
                        width="w-full"
                        text="Sign In"
                        loading={loading}
                        type="submit"
                        buttonStyle="primary"
                      />
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
