import React, { useReducer } from "react";
import { reducer } from "../redux/reducer/reducer";
import { initialState } from "../redux/state/state";

export const MainContext = React.createContext({
  mainState: null,
  mainDispatch: () => {},
});

export function MainProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = {
    mainState: state,
    mainDispatch: dispatch,
  };

  return (
    <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
  );
}
