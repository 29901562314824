import React from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { ThirdPartyForm } from "./third-party-form";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { Optional } from "../../../shared/components/optional/optional";
import { ThirdPartyDetails } from "./third-parties-details";
import { ThirdpartyFilter } from "./third-parties-filter";
import useThirdParties from "./hooks/use-third-parties";

export function ThirdParties() {
  const { actions, page } = useThirdParties();
  const {
    goBack,
    handleTableAction,
    handleThirdpartyFilter,
    closeModal,
    reload,
    toggleModal,
    incrementPageNo,
    decrementPageNo,
    updateModalInfo,
    goToPage,
    getThirdParties,
  } = actions;

  const {
    error,
    loading,
    currentIndex: pageIndex,
    thirdParty,
    selectedThirdParty,
    thirdPartyCode,
    details,
    pagination,
    tableHeaders,
    modalFeatures,
  } = page;

  const currentPageView = () => {
    switch (pageIndex) {
      case 0:
        return renderTable();
      case 1:
        return (
          <div className="mt-5">
            <ThirdPartyDetails
              goBack={goBack}
              thirdPartyCode={thirdPartyCode}
              details={details}
            />
          </div>
        );
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalFeatures.index) {
      case 0:
        return (
          <ThirdPartyForm
            title={"Create Third Party"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getThirdParties}
          />
        );
      case 1:
        return (
          <ThirdPartyForm
            title={"Update Third Party"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getThirdParties}
            thirdParty={selectedThirdParty}
          />
        );
      case 2:
        return (
          <ThirdpartyFilter
            closeModal={closeModal}
            onGetThirdparties={handleThirdpartyFilter}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_update_third_party" },
            { label: "OPEN", value: "switch_get_third_party" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pagination.pageNo + 1}
          emptyText={"No Third Party found!"}
          search={true}
          reload={reload}
          error={error}
          items={thirdParty}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => incrementPageNo}
          prevPage={() => decrementPageNo}
          goToFirstPage={() => goToPage(0)}
          goToLastPage={() => goToPage(pagination?.totalPages - 1)}
          goToPage={(pageNo) => goToPage(pageNo)}
          actions={["UPDATE", "OPEN"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <ErrorBoundary>
      <div className="manage-third-parties">
        <>
          <CustomModal
            hasOwnModalHeader={false}
            closeModal={closeModal}
            onHide={closeModal}
            visible={modalFeatures.isVisible}
            modalContent={modalContent}
            title={modalFeatures.info.title}
            subtitle={modalFeatures.info.subtitle}
          />
        </>
        <PageTitle text="Third Parties" />
        <CustomBreadcrumb page="Manage Third Parties" />
        <Optional
          showIf={
            HELPER.HAS_AUTHORITY("switch_create_third_party") && pageIndex === 0
          }
        >
          <PageActions>
            <AppButton
              icon="filter"
              text="Filter"
              type="button"
              buttonStyle="bare"
              margin={"mr-2"}
              onclick={() => {
                updateModalInfo({
                  title: "Filter Third Parties",
                  subtitle: "",
                });
                toggleModal(2);
              }}
            />
            <AppButton
              type="button"
              buttonStyle="primary"
              text="New Third Party"
              onclick={() => {
                toggleModal(0);
              }}
            />
          </PageActions>
        </Optional>
        <>{currentPageView()}</>
      </div>
    </ErrorBoundary>
  );
}
