import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import * as Yup from "yup";
import { HELPER } from "../../../core/helper/helper";
import { useEffect, useState } from "react";
import { CustomToast } from "shared/components/alert/custom-toast";
import { Optional } from "shared/components/optional/optional";
import { TransactionReportGenerationProgress } from "../transaction-reports/report-generation-progress";
import { useNotification } from "core/hooks/useNotification";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { ProgressSpinner } from "primereact/progressspinner";
import { useSingleAppConfigurationValue } from "api/hooks/use-single-config-value";
import { GLOBAL_CONFIG_KEYS } from "shared/constants";

export function TransactionsFilter(props) {
  const { addNotification } = useNotification();
  const [initialValues] = useState({
    cardAcceptorId: "",
    from: "",
    to: "",
    maskedCardPan: "",
    responseCode: "",
    rrn: "",
    terminalId: "",
    processingCode: "",
    stan: "",
  });
  const formValidationSchema = Yup.object({
    cardAcceptorId: Yup.string(),
    from: Yup.string(),
    to: Yup.string(),
    maskedCardPan: Yup.string(),
    responseCode: Yup.string(),
    rrn: Yup.string(),
    stan: Yup.string(),
    terminalId: Yup.string(),
    processingCode: Yup.string(),
  });
  const [reportRecordId, setReportRecordId] = useState(null);
  const [reportParams, setReportParams] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generateReportError, setGenerateReportError] = useState({
    hasError: false,
    title: "",
    description: "",
  });
  const FILTER_INDEX = 0;
  const REPORT_GENERATION_INDEX = 1;
  const [currentIndex, setCurrentIndex] = useState(FILTER_INDEX);
  const downloadRange = useSingleAppConfigurationValue(
    GLOBAL_CONFIG_KEYS.TRANSACTIONS_DOWNLOAD_DATE_RANGE
  );
  const [maxDownloadDateRange, setMaxDownloadDateRange] = useState(null);

  useEffect(() => {
    if (downloadRange) {
      setMaxDownloadDateRange(Number(downloadRange));
    } else {
      setMaxDownloadDateRange(7);
    }
  }, [downloadRange]);

  const submit = (payload) => {
    const trimmedPayload = HELPER.TRIM_OBJECT(payload);
    props.onFilter(trimmedPayload);
    props.closeModal();
  };

  const generateReport = async (payload) => {
    setLoading(true);
    setReportParams(payload);
    let trimmedPayload = HELPER.TRIM_OBJECT(payload);
    const params = HELPER.TO_URL_STRING({
      partyId: props.partyId,
      partyType: props.partyType,
    });
    const url = props.partyTransactionReportGenerationUrl
      ? `${props.partyTransactionReportGenerationUrl}?${params}`
      : BACK_OFFICE_API.TRANSACTION_REPORT.GENERATE_TRANSACTION_REPORTS;

    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url, trimmedPayload);
      setReportRecordId(response?.result.code);
      setCurrentIndex(REPORT_GENERATION_INDEX);
    } catch (error) {
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
      props.closeModal();
    } finally {
      setLoading(false);
    }
  };

  const calculateDateRange = (startDate, endDate) => {
    const formattedStartDate = new Date(startDate);
    const formattedEndDate = new Date(endDate);
    const dateRange =
      Math.abs(formattedStartDate.getTime() - formattedEndDate.getTime()) /
      1000 /
      60 /
      60 /
      24;
    return dateRange;
  };

  const validateReportGeneration = ({ from, to, ...values }) => {
    setGenerateReportError({
      hasError: false,
      title: "",
      description: "",
    });

    const dateRange = calculateDateRange(from, to);
    if (from && to) {
      if (dateRange > maxDownloadDateRange - 1 || dateRange < 0) {
        setGenerateReportError({
          hasError: true,
          title: "Error",
          description: `Date range for report generation cannot exceed ${maxDownloadDateRange} day(s). Please select a date range not longer than ${maxDownloadDateRange} day.`,
        });
      } else {
        generateReport({ ...values, startDate: from, endDate: to });
      }
    } else {
      setGenerateReportError({
        hasError: true,
        title: "Error",
        description: "Start and End Dates are required to generate report.",
      });
    }
  };

  const handleCloseModal = () => {
    setGenerateReportError({
      hasError: false,
      title: "",
      description: "",
    });
    props.closeModal();
  };

  const closeAlert = () => {
    setGenerateReportError({
      hasError: false,
      title: "",
      description: "",
    });
  };

  const renderView = () => {
    if (currentIndex === FILTER_INDEX) {
      return (
        <Formik
          initialValues={initialValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => {
            submit(values);
          }}
          enableReinitialize={true}
        >
          {({ values }) => (
            <Form>
              <AppFormInput
                label="RRN"
                name="rrn"
                type="text"
                placeholder="RRN"
              />
              <AppFormInput
                label="STAN"
                name="stan"
                type="text"
                placeholder="STAN"
              />
              <AppFormInput
                label="Card Acceptor Id"
                name="cardAcceptorId"
                type="text"
                placeholder="Card Acceptor Id"
              />

              <AppFormInput
                label="Processing Code"
                name="processingCode"
                type="text"
                placeholder="Processing code"
              />
              <AppFormInput
                label="Terminal Id"
                name="terminalId"
                type="text"
                placeholder="Terminal Id"
              />
              <AppFormInput
                label="Masked Card PAN"
                name="maskedCardPan"
                type="text"
                placeholder="Masked Card PAN"
              />
              <AppFormInput
                label="Response Code"
                name="responseCode"
                type="text"
                placeholder="Response Code"
              />
              <div className="grid p-pb-0">
                <div className="col-6">
                  <AppFormInput
                    label="Start Date"
                    name="from"
                    type="date"
                    placeholder="Start Date"
                  />
                </div>
                <div className="col-6">
                  <AppFormInput
                    label="End Date"
                    name="to"
                    type="date"
                    placeholder="End Date"
                  />
                </div>
              </div>
              <Optional showIf={generateReportError.hasError}>
                <CustomToast
                  type="error"
                  closeModal={closeAlert}
                  title={generateReportError.title}
                  description={generateReportError.description}
                />
              </Optional>
              <Optional showIf={loading}>
                <div style={{ textAlign: "center" }}>
                  <div>
                    <ProgressSpinner
                      style={{ width: "25px", height: "25px" }}
                      strokeWidth="4"
                    />
                  </div>
                  <p style={{ fontSize: "0.9rem", fontWeight: "400" }}>
                    Submitting...
                  </p>
                </div>
              </Optional>
              <Optional showIf={!loading}>
                <div className="grid justify-content-evenly">
                  <div className="col-3 ">
                    <AppButton
                      type="button"
                      buttonStyle="secondary"
                      text="Cancel"
                      onclick={handleCloseModal}
                    />
                  </div>
                  <div className="col-5 ">
                    <AppButton
                      type="button"
                      buttonStyle="tetiary"
                      text="Generate Report "
                      onclick={() => validateReportGeneration(values)}
                    />
                  </div>
                  <div className="col-3 ">
                    <AppButton
                      type="submit"
                      buttonStyle="primary"
                      text="Submit"
                    />
                  </div>
                </div>
              </Optional>
            </Form>
          )}
        </Formik>
      );
    } else {
      return (
        <TransactionReportGenerationProgress
          recordId={reportRecordId}
          reportParams={reportParams}
          closeModal={handleCloseModal}
        />
      );
    }
  };

  return <div>{renderView()}</div>;
}
