import { CONFIG } from "core/configs/configs";

const GET_TRANSACTION_RECORDS = `/management/api/${CONFIG.API_VERSION}/transaction-record/transactions`;
const GET_PARTY_TRANSACTION_RECORDS = `/management/api/${CONFIG.API_VERSION}/transaction-record/third-party/transactions`;
const GET_TRANSACTION_RECORD = `/management/api/${CONFIG.API_VERSION}/transaction-record`;

export const TRANSACTION_ROUTES = {
  GET_TRANSACTION_RECORD,
  GET_TRANSACTION_RECORDS,
  GET_PARTY_TRANSACTION_RECORDS,
};
