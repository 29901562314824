import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "../../../api/service";
import { ThirdPartyCategoryForm } from "./categories-form";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { ThirdPartyCategoryFilter } from "./third-party-category-filter";

export function ThirdPartyCategory() {
  const { addNotification } = useNotification();
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [filterParams, setFilterParams] = useState(null);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
    { label: "Status", value: "active" },
    { label: "Creation Date", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [categories, setCategories] = useState([]);
  const [selectedCatrgory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);

  const getCategories = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...filter,
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.THIRD_PARTY_CATEGORIES.GET_THIRD_PARTY_CATEGORY}?${params}`
        );
        setPagination(response.result);
        const categories = response?.result?.content;
        setCategories(categories);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getCategories();
    } else {
      getCategories(filterParams);
    }
  }, [getCategories, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getCategories();
    }
  }
  const handleCategoriesFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };
  const handleTableAction = (data, action) => {
    const { active, updatedAt, createdBy, createdAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setSelectedCategory({
          ...rest,
          active,
          updatedAt,
          createdAt,
          status: active,
        });
        toggleModal(1);
        break;
      case "VIEW":
        setSelectedCategory({
          ...rest,
          createdAt,
          status: active,
        });
        toggleModal(2);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <ThirdPartyCategoryForm
            title={"Create Third Party Category"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getCategories}
          />
        );
      case 1:
        return (
          <ThirdPartyCategoryForm
            title={"Update Third Party Category"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getCategories}
            category={selectedCatrgory}
          />
        );
      case 2:
        return (
          <>
            <p className="modal-title p-text-left mb-1">
              Third Party Category Details
            </p>
            <div className="custom-modal-item">
              <FormattedDetails details={selectedCatrgory} />
            </div>
          </>
        );
      case 3:
        return (
          <ThirdPartyCategoryFilter
            onGetThirdPartyCategory={handleCategoriesFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            {
              label: "UPDATE",
              value: "switch_update_third_party_category",
            },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No Third Party Categories found!"}
          search={true}
          reload={reload}
          error={error}
          items={categories}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "VIEW"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };
  return (
    <div className="manage-third-party-category">
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
        />
      </>
      <PageTitle text="Third Party Categories" />
      <CustomBreadcrumb page="Manage Third Party Categories" />
      <PageActions>
        <AppButton
          icon="filter"
          text="Filter"
          type="button"
          buttonStyle="bare"
          margin={"mr-2"}
          onclick={() => toggleModal(3)}
        />
        <Optional
          showIf={HELPER.HAS_AUTHORITY("switch_create_third_party_category")}
        >
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Category"
            onclick={() => {
              toggleModal(0);
            }}
          />
        </Optional>
      </PageActions>
      <>{currentPageView()}</>
    </div>
  );
}
