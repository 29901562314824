import { useState } from "react";
import * as Yup from "yup";

const useDisputeFilter = (props) => {
  const [partyIdentifiers, setPartyIdentifiers] = useState([]);
  const [identiferMeta, setIdentifierMeta] = useState({
    optionLabel: "name",
    optionValue: "code",
  });
  const [selectedDateType, setSelectedDateType] = useState("disputeLogDate");
  const [initialValues, setInitialValues] = useState({
    ...(!props.isParty && { partyId: "", partyType: "" }),
    logCode: "",
    resolutionStatus: "",
    disputeStatus: "",
    rrn: "",
    maskedPan: "",
    terminalId: "",
    createdDateFrom: "",
    createdDateTo: "",
  });
  const validationSchema = Yup.object({
    partyId: Yup.string(),
    partyType: Yup.string(),
    logCode: Yup.string(),
    resolutionStatus: Yup.string(),
    disputeStatus: Yup.string(),
    rrn: Yup.string(),
    maskedPan: Yup.string(),
    terminalId: Yup.string(),
    transactionDateTo: Yup.string().nullable(true),
    transactionDateFrom: Yup.string().nullable(true),
    createdDateFrom: Yup.string().nullable(true),
    createdDateTo: Yup.string().nullable(true),
    tatExpiryDateFrom: Yup.string().nullable(true),
    tatExpiryDateTo: Yup.string().nullable(true),
  });

  const updateDateType = (dateType) => {
    setSelectedDateType(dateType);
    const dateKeysToBeAdded = {
      [`${dateType}From`]: "",
      [`${dateType}To`]: "",
    };
    const formattedInitialValues = initialValues;
    for (const key in formattedInitialValues) {
      if (key.includes("Date")) {
        delete formattedInitialValues[key];
      }
    }
    setInitialValues({ ...formattedInitialValues, ...dateKeysToBeAdded });
  };

  return {
    selectedDateType,
    initialValues,
    validationSchema,
    updateDateType,
    partyIdentifiers,
    setPartyIdentifiers,
    identiferMeta,
    setIdentifierMeta,
  };
};

export { useDisputeFilter };
