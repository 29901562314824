import React from "react";
import { Dialog } from "primereact/dialog";
import "./modal.css";

export function CustomModal(props) {
  function onHide() {
    props.onHide();
  }

  const header = (
    <div>
      <div>
        <div
          style={{ position: "relative", top: "-.4em", left: "-.4em" }}
          className="text-left"
        >
          <i
            onClick={() => props.closeModal()}
            className="pi pi-times-circle add-cursor"
            style={{
              fontSize: "1em",
              color: "#ffffff",
              backgroundColor: "#0371AC",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Dialog
        draggable={false}
        header={header}
        blockScroll={true}
        dismissableMask={true}
        closable={false}
        position="top"
        showHeader={true}
        visible={props.visible}
        onHide={() => onHide()}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      >
        {props.hasOwnModalHeader ? (
          <div className="custom-modal-content">{props.modalContent()} </div>
        ) : (
          <div className="custom-modal-content">
            <p className="modal-title p-text-left mb-1">{props.title}</p>
            <p className="modal-subtitle mt-0 mb-1">{props.subtitle}</p>
            {/* <div className="hr" /> */}
            <div className="custom-modal-item">{props.modalContent()}</div>
          </div>
        )}
      </Dialog>
    </div>
  );
}
