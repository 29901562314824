export function Optional(props) {
  const view = () => {
    if (props.showIf) {
      return props.children;
    } else {
      return <></>;
    }
  };
  return view();
}
