import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import { HELPER } from "../../../core/helper/helper";
import { Optional } from "shared/components/optional/optional";
import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";
import DisputeDateSelectors from "./dispute-date-selectors";
import { useDisputeFilter } from "backoffice/pages/disputes/hooks/use-dispute-filter";
import {
  DISPUTE_RESOLUTION_STATUSES,
  DISPUTE_STATUSES,
  PARTY_TYPES,
} from "shared/constants";
import { PartyTypePartyIdentifierFormInputs } from "../party-users/party-type-party-identifier-form-inputs";

export function DisputesFilter(props) {
  const { selectedDateType, updateDateType, initialValues, validationSchema } =
    useDisputeFilter(props);

  const submit = (payload) => {
    const trimmedPayload = HELPER.TRIM_OBJECT(payload);
    props.onFilter(trimmedPayload);
    props.closeModal();
  };

  const renderDateSelectors = () => {
    switch (selectedDateType) {
      case "disputeLogDate":
        return <DisputeDateSelectors dateType={"createdDate"} />;
      case "transactionDate":
        return <DisputeDateSelectors dateType={"transactionDate"} />;
      case "tatExpiryDate":
        return <DisputeDateSelectors dateType={"tatExpiryDate"} />;
      default:
        break;
    }
  };

  return (
    <div>
      <p className="modal-title p-text-left mt-0">Filter</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={!props.isParty}>
              <PartyTypePartyIdentifierFormInputs
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                partyTypes={PARTY_TYPES}
                identifierField="partyIdentifier"
                partyTypeField="partyType"
                partyTypePlaceholder="Select party type"
                identiferPlaceholder="Select identifier"
                partyTypeLabel="Party Type"
                identifierLabel="Party Identifier"
                isRequiredField={false}
              />
            </Optional>
            <AppFormInput
              label="Log Code"
              name="logCode"
              type="text"
              placeholder="Log code"
            />

            <AppFormInput
              label="Masked Pan"
              name="maskedPan"
              type="text"
              placeholder="Masked pan"
            />
            <AppFormInput
              label="RRN"
              name="rrn"
              type="text"
              placeholder="RRN"
            />
            <AppFormDropdown
              value={values.disputeStatus}
              onchange={(e) => {
                setFieldValue("disputeStatus", e?.target?.value);
              }}
              options={DISPUTE_STATUSES}
              placeholder="Dispute Status"
              label="Dispute Status"
              error={errors?.disputeStatus}
              field="disputeStatus"
            />
            <AppFormDropdown
              value={values.resolutionStatus}
              onchange={(e) => {
                setFieldValue("resolutionStatus", e?.target?.value);
              }}
              options={DISPUTE_RESOLUTION_STATUSES}
              label="Resolution Status"
              error={errors?.resolutionStatus}
              field="resolutionStatus"
              placeholder="Resolution status"
            />
            <div className="grid my-4">
              <div className="col-4 p-0">
                <FormRadioInput
                  id="disputeLogDate"
                  name="disputeLogDate"
                  checked={selectedDateType === "disputeLogDate"}
                  handleChange={(e) => updateDateType("disputeLogDate")}
                  label="Dispute Log Date"
                />
              </div>
              <div className="col-4 p-0">
                <FormRadioInput
                  id="transactionDate"
                  name="transactionDate"
                  checked={selectedDateType === "transactionDate"}
                  handleChange={(e) => updateDateType("transactionDate")}
                  label="Transaction Date"
                />
              </div>
              <div className="col-4 p-0">
                <FormRadioInput
                  id="tatExpiryDate"
                  name="tatExpiryDate"
                  checked={selectedDateType === "tatExpiryDate"}
                  handleChange={(e) => updateDateType("tatExpiryDate")}
                  label="TAT Expiry Date"
                />
              </div>
            </div>
            <p
              className="m-0 text-sm font-bold mb-2 block text-left"
              style={{ color: "#566a7f" }}
            >
              {" "}
              {HELPER.CAMEL_TO_TITLE_CASE(selectedDateType)}
            </p>
            {renderDateSelectors(selectedDateType)}
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Cancel"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
