import React from "react";
import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { HELPER } from "../../../core/helper/helper";
import { AppFormInput } from "shared/components/form-inputs/form-input";

export function ThirdPartyCategoryFilter(props) {
  const initialValues = {
    name: "",
    active: "",
    to: "",
    from: ""
   };
  const validationSchemaObject = Yup.object().shape({
    name: Yup.string(),
    active: Yup.string(),
    to: Yup.string(),
    from: Yup.string(),
  });
  const submit = async ({ active: status, ...args }) => {
    const payload = HELPER.TRIM_OBJECT({ active: status, ...args });
    props.onGetThirdPartyCategory(payload);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaObject}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <AppFormInput
              label="Name"
              name="name"
              type="text"
              placeholder="Enter Third Party Category name"
              required={false}
            />
            <div className="flex w-full ">
              <div className="w-full pr-3">
                <label className="date-status-style">
                  From:
                </label>
                <FormInput
                  name="From"
                  fn={(e) => setFieldValue("from", e.target.value)}
                  label="Start date"
                  inputType="date"
                  required={false}
                  value={values.from}
                  field="from"
                  type="INPUT"
                />
              </div>
              <div className="w-full">
                <label
                 className="date-status-style">
                  To:
                </label>
                <FormInput
                  name="To"
                  fn={(e) => setFieldValue("to", e.target.value)}
                  label="End date"
                  inputType="date"
                  required={false}
                  value={values.to}
                  field="to"
                  type="INPUT"
                />
              </div>
            </div>
            <div className="pt-3">
              <div>
                <p
                  className="date-status-style" >
                  Status:
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="Status"
                    checked={values.active === true}
                    handleChange={(e) => setFieldValue("active", true)}
                    label="Active"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="Status"
                    checked={values.active === false}
                    handleChange={(e) => setFieldValue("active", false)}
                    label="Inactive"
                  />
                </div>
              </div>
            </div>

            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
