import { CONFIG } from "core/configs/configs";

const GET_BULK_MERCHANT_TERMINAL_UPLOADS = `/management/api/${CONFIG.API_VERSION}/bulk-merchant-terminal-uploads`;
const GET_BULK_MERCHANT_TERMINAL_UPLOAD_ENTRIES = `/management/api/${CONFIG.API_VERSION}/bulk-merchant-terminal-upload-entry`;
const UPLOAD_BULK_MERCHANT_TERMINAL = `/management/api/${CONFIG.API_VERSION}/merchant-terminal`;

export const BULK_MERCHANT_TERMINAL_ROUTES = {
  GET_BULK_MERCHANT_TERMINAL_UPLOADS,
  GET_BULK_MERCHANT_TERMINAL_UPLOAD_ENTRIES,
  UPLOAD_BULK_MERCHANT_TERMINAL,
};
