import { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { Icon } from "../../../shared/components/icons/icon";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { HELPER } from "core/helper/helper";
import { THIRD_PARTY_ENUMS } from "shared/constants";
import "./acquirer-details.css";
import { AcquirerThirdParties } from "./acquirer-third-parties";
import { PartySourceInfo } from "../party-source-info/party-source-info";

export const AcquirerDetails = (props) => {
  const [currentIndex] = useState(0);
  const acquirerTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={"Acquirer Summary"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
              </div>
              <div className="centralize">
                <div className="p-2">
                  <FormattedDetails details={props.details} />
                </div>
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY("switch_fetch_party_source_sink_info") ? (
            <TabPanel header={"Connection Info"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <PartySourceInfo
                      partyName={props.details.financialInstitutionName}
                      partyTypeId={props.acquirerCode}
                      partyType={THIRD_PARTY_ENUMS.ACQUIRER}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
          {/* TODO: convert functions like HAS_AUTHORITY() to hasAuthority() */}
          {HELPER.HAS_AUTHORITY("switch_get_third_party_acquirer") ? (
            <TabPanel header={"Third Parties"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <AcquirerThirdParties acquirerCode={props.acquirerCode} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };

  const acquirerDetailsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return acquirerTabView();
      case 1:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="Loading details ..." />
          </div>
        );
    }
  };
  return <div>{acquirerDetailsView()}</div>;
};
