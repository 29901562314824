import "./new-table.css";
import "./custom-table.css";
import { useEffect } from "react";
import { CustomPagination } from "../custom-pagination/custom-pagination";
import { Icon } from "../icons/icon";
import { HELPER } from "../../../core/helper/helper";
import { Optional } from "../optional/optional";
import { TableAction } from "./table-action";
import { SpecialLabelCases } from "core/utils/utilities";

export function DesktopTable(props) {
  useEffect(() => {
    function reDivideColumns() {
      const columns = props.showCheckbox ? props.columns - 1 : props.columns;
      const maxWidth = props.showCheckbox ? 95 : 100;
      let i;
      const newColumnSize = maxWidth / columns;
      let elements = document.getElementsByClassName("dcir-column");
      // let actions = document.getElementsByClassName("col-table-action");

      for (i = 0; i < elements.length; i++) {
        elements[i].style.width = `${newColumnSize}%`;
      }
      if (props.showCheckbox) {
        let checkboxColumns = document.getElementsByClassName("checkbox-col");
        for (i = 0; i < checkboxColumns.length; i++) {
          checkboxColumns[i].style.width = `5%`;
        }
      }
    }
    if (props?.columns !== 5) {
      reDivideColumns();
    }
  }, [props.columns, props.showCheckbox]);

  const tableActions = (item) => {
    return (
      <span className="dcir-tb-action-position" style={{ cursor: "pointer" }}>
        <Optional
          showIf={
            props.actions.includes("UPDATE") &&
            HELPER.CAN_PERFORM_ACTION(props?.authorities, "UPDATE")
          }
        >
          <TableAction
            onclick={props.onPerformAction}
            data={item}
            action="UPDATE"
            icon="pencil"
          />
        </Optional>
        <Optional showIf={props.actions.includes("VIEW")}>
          <TableAction
            onclick={(a, b) => props.onPerformAction(a, b)}
            data={item}
            action="VIEW"
            icon="eye"
          />
        </Optional>
        <Optional
          showIf={
            props.actions.includes("DELETE") &&
            HELPER.CAN_PERFORM_ACTION(props?.authorities || "all", "DELETE")
          }
        >
          <TableAction
            onclick={props.onPerformAction}
            data={item}
            action="DELETE"
            icon="trash"
          />
        </Optional>
        <Optional showIf={props.actions.includes("DOWNLOAD")}>
          <TableAction
            onclick={props.onPerformAction}
            data={item}
            action="DOWNLOAD"
            icon="download"
          />
        </Optional>
        <Optional
          showIf={
            props.actions.includes("OPEN") &&
            HELPER.CAN_PERFORM_ACTION(props?.authorities, "OPEN")
          }
        >
          <button
            onClick={() => {
              props.onPerformAction(item, "OPEN");
            }}
            className={`table-action dcir-show`}
          >
            <span className="add-cursor">
              <Icon icon="back" />
            </span>
          </button>
        </Optional>
      </span>
    );
  };

  const headers = () => {
    return props.headers.map((item, index) => {
      return (
        <div key={index.toString()}>
          <>
            <div
              className={`dcir-column ${
                item.label === "checkbox" ? "checkbox-col" : ""
              }`}
            >
              <Optional showIf={item.label !== "checkbox"}>
                <p>{item.label}</p>
              </Optional>
              <Optional showIf={item.label === "checkbox"}>
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={props.selectAllItems}
                />
              </Optional>
            </div>
          </>
        </div>
      );
    });
  };

  const transformView = (itemCase, value, type) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          if (type === "dateOnly") {
            result = HELPER.FORMAT_DATE_ONLY(value);
          } else {
            result = e.action(value, itemCase);
          }
        }
      });
    }
    return result ? result : result === 0 ? result : "___";
  };

  const tableContent = (item, label, type = null) => {
    const formattedClickableItem = props.clickableItems?.find(
      (e) => e.name === label
    );
    if (label === "actions") {
      return (
        <div
          key={`${item.id} ${label}`}
          className="dcir-column col-table-action"
        >
          {tableActions(item)}
        </div>
      );
    } else if (formattedClickableItem?.name === label) {
      return (
        <div
          className={`dcir-column ${
            label === "checkbox" ? "checkbox-col" : ""
          }`}
        >
          <p
            onClick={() => formattedClickableItem.fn(item[label])}
            style={{ color: "#0371A0" }}
            className="details-value cursor-pointer underline"
          >
            {transformView(label, item[label], type)}
          </p>
        </div>
      );
    } else {
      return (
        <div
          className={`dcir-column ${
            label === "checkbox" ? "checkbox-col" : ""
          }`}
        >
          <p>{transformView(label, item[label], type)}</p>
        </div>
      );
    }
  };

  const tableValues = () => {
    return props.items.map((item, index) => {
      return (
        <div key={index.toString()} className="dcir-row table-body-text">
          <>
            {props.headers.map((headerContent, index) => {
              return (
                <div key={index.toString()}>
                  {tableContent(item, headerContent.value, headerContent.type)}
                </div>
              );
            })}
          </>
        </div>
      );
    });
  };

  return (
    <div style={{ position: "relative" }} className="table">
      <div
        style={{ display: props?.isReload ? "block" : "none" }}
        onClick={() => props?.reload()}
        className="custom-table-refresh"
      >
        <span>
          <i className="pi pi-refresh px-1" />
          <span className="px-1">Reset</span>
        </span>
      </div>
      <div className="table-container">
        <div
          style={{ marginTop: props.isReload ? "1.4em" : "0" }}
          className="table-card"
        >
          <div className="dcir-row table-header">{headers()}</div>
          {tableValues()}
        </div>
      </div>
      <div className="custom-page-card text-center">
        <CustomPagination
          totalPages={props.totalPages}
          totalItems={props.totalItems}
          currentPage={props.currentPage}
          range={props.range}
          nextPage={() => {
            props.nextPage();
          }}
          prevPage={props.prevPage}
          goToFirstPage={props.goToFirstPage}
          goToLastPage={props.goToLastPage}
          goToPage={props.goToPage}
          numberOfElements={props.numberOfElements}
        />
      </div>
    </div>
  );
}
