import React from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { useBulkMerchantTerminalEntries } from "./hooks/use-bulk-merchant-terminal-entries";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { AppButton } from "shared/components/app-button/app-button";

export function BulkMerchantTerminalEntries(props) {
  const { api, page, data, pageActions } =
    useBulkMerchantTerminalEntries(props);

  function modalContent() {
    switch (page.modalIndex) {
      case 0:
        return (
          <div className="flex flex-column">
            <FormattedDetails details={data.details} />
            <AppButton
              text="Dismiss"
              type="button"
              buttonStyle="bare"
              margin={"mr-2"}
              onclick={pageActions.closeModal}
            />
          </div>
        );
      default:
        break;
    }
  }

  const renderTableView = () => {
    if (api.loading) {
      return (
        <div className="loading-container">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={page.tableAuthorities}
          isReload={true}
          numberOfElements={data.pagination?.numberOfElements}
          totalPages={data.pagination?.totalPages}
          totalItems={data.pagination?.totalElements}
          currentPage={page.pageNo + 1}
          emptyText={"No bulk merchant terminal entries found!"}
          search={true}
          reload={pageActions.reload}
          error={api.error}
          items={data.bulkMerchantTerminalsEntries}
          headers={page.tableHeaders}
          nextPage={pageActions.incrementPageNo}
          prevPage={pageActions.decrementPageNo}
          goToFirstPage={() => pageActions.goToPage(0)}
          goToLastPage={() =>
            pageActions.goToPage(data.pagination?.totalPages - 1)
          }
          goToPage={(pageNo) => pageActions.goToPage(pageNo)}
          actions={["VIEW"]}
          onPerformAction={pageActions.handleTableAction}
        />
      );
    }
  };

  return (
    <div>
      <>
        <CustomModal
          hasOwnModalHeader={false}
          title={"Merchant Upload Details"}
          closeModal={pageActions.closeModal}
          onHide={pageActions.closeModal}
          visible={page.isModalVisible}
          modalContent={modalContent}
        />
      </>
      <>{renderTableView()}</>
    </div>
  );
}
