import { HELPER } from "core/helper/helper";
import { SpecialLabelCases } from "core/utils/utilities";
import { Icon } from "shared/components/icons/icon";

export const formatTransactionDetailsToBeCopied = (details) => {
  const priorityDetails = {
    cardAcceptorId: details.cardAcceptorId,
    maskedPan: details.maskedPan,
    responseCode: details.responseCode,
    rrn: details.rrn,
    stan: details.stan,
    transactionAmount: details.transactionAmount,
    terminalId: details.terminalId,
    responseTime: details.responseTime,
    requestTime: details.requestTime,
    transactionTime: details.transactionTime,
    sourceNodeId: details.sourceNodeId,
    sinkNodeId: details.sinkNodeId,
    requestAmount: details.requestAmount,
    responseAmount: details.responseAmount,
    processingCode: details.processingCode,
    cardAcceptorLocation: details.cardAcceptorId,
    transactionCurrencyCode: details.transactionCurrencyCode,
    transactionDate: details.transactionDate,
    mti: details.mti,
    authId: details.authId,
    authorizationIdResponse: details.authorizationIdResponse,
    reversed: details.reversed,
    fromAccount: details.fromAccount,
    toAccount: details.toAccount,
    acquiringInstitutionId: details.acquiringInstitutionId,
    forwardingInstitutionId: details.forwardingInstitutionId,
    receivingInstitutionId: details.receivingInstitutionId,
    merchantType: details.merchantType,
    accountIdentification1: details.accountIdentification1,
    accountIdentification2: details.accountIdentification2,
    responseCodeReceived: details.responseCodeReceived,
    issuerCode: details.issuerCode,
    cardCurrencyCode: details.cardCurrencyCode,  
    serviceRestrictionCode: details.serviceRestrictionCode,  
    acquiringInstitutionCountryCode: details.acquiringInstitutionCountryCode,  
    transmissionDateTime: details.transmissionDateTime,  
    reversalTransactionId: details.reversalTransactionId,  
    emvDataRequest: details.emvDataRequest,  
    emvDataResponse: details.emvDataResponse,  
    processorReferenceCode: details.processorReferenceCode,  
    sinkTerminalId: details.sinkTerminalId,  
    sinkCardAcceptorId: details.sinkCardAcceptorId,  
    encryptedPan: details.encryptedPan,
  };
  const transactionDetailsText = convertObjectToText(priorityDetails);
  return transactionDetailsText;
};

export const convertObjectToText = (details) => {
  const keys = Object.keys(details);
  let text = "";
  keys.forEach(
    (key) =>
      (text +=
        `\n${HELPER.CAMEL_TO_TITLE_CASE(key)}: ` +
        convertValueToUserFriendly(key, details[key]))
  );
  return text;
};

const convertValueToUserFriendly = (key, value) => {
  let formattedValue = value;
  SpecialLabelCases.map((e) => {
    if (
      key !== "responseCode" &&
      key !== "status" &&
      key !== "resolutionStatus"
    ) {
      if (e.case === key) {
        if (value) {
          formattedValue = e.action(value);
        }
      }
    }
    return e;
  });
  return formattedValue ? formattedValue : "---";
};

export const getPrimaryInformationFields = (details) => {
  const {
    cardAcceptorId,
    maskedPan,
    responseCode,
    rrn,
    stan,
    transactionAmount,
    terminalId,
    responseTime,
    requestTime,
    transactionTime,
  } = details;

  return {
    maskedPan,
    terminalId,
    transactionAmount,
    rrn,
    stan,
    cardAcceptorId,
    responseCode,
    transactionTime,
    requestTime,
    responseTime,
  };
};
export const getOtherInformationFields = (details) => {
  const {
    accountIdentification1,
    accountIdentification2,
    sourceNodeId,
    sinkNodeId,
    requestAmount,
    responseAmount,
    processingCode,
    cardAcceptorLocation,
    transactionCurrencyCode,
    transactionDate,
    mti,
    authId,
    authorizationIdResponse: authIdResponse,
    reversed,
    fromAccount,
    toAccount,
    acquiringInstitutionId,
    forwardingInstitutionId,
    receivingInstitutionId,
    merchantType,
    responseCodeReceived,
    issuerCode,
    cardCurrencyCode,
    serviceRestrictionCode,
    acquiringInstitutionCountryCode,
    transmissionDateTime,
    reversalTransactionId,
    emvDataRequest,
    emvDataResponse,
    processorReferenceCode,
    sinkTerminalId,
    sinkCardAcceptorId,
    encryptedPan,
  } = details;
  return {
    sourceNodeId,
    sinkNodeId,
    requestAmount,
    responseAmount,
    processingCode,
    cardAcceptorLocation,
    transactionCurrencyCode,
    transactionDate,
    mti,
    authId,
    authIdResponse,
    reversed,
    fromAccount,
    toAccount,
    acquiringInstitutionId,
    forwardingInstitutionId,
    receivingInstitutionId,
    merchantType,
    accountIdentification1,
    accountIdentification2,
    responseCodeReceived,
    issuerCode,
    cardCurrencyCode,
    serviceRestrictionCode,
    acquiringInstitutionCountryCode,
    transmissionDateTime,
    reversalTransactionId,
    emvDataRequest,
    emvDataResponse,
    processorReferenceCode,
    sinkTerminalId,
    sinkCardAcceptorId,
    encryptedPan,
  };
};

export const errorView = (errorMessage, reload) => {
  return (
    <div className="text-center">
      <div className="empty-container">
        <Icon icon="error-message-icon" />
        <div className="text-center">
          <p className="empty-text notification-tex">{errorMessage}</p>
        </div>
        <div className="mt-3">
          <button onClick={() => reload()} className="primary-button">
            Reload
          </button>
        </div>
      </div>
    </div>
  );
};
