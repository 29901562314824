import { useCallback, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { Icon } from "../../../shared/components/icons/icon";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import "./fee-rule-details.css";
import { FeeRuleIdentifiers } from "../fee-rule-identifiers/fee-rule-identifiers";
import { HELPER } from "../../../core/helper/helper";
import { ManageParticipants } from "../participants/participants";

import { AppButton } from "shared/components/app-button/app-button";
import { API_SERVICE } from "api/service";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomConfirmDialog } from "shared/components/custom-confirm-dialog/custom-confirm-dialog";

export const FeeRuleDetails = (props) => {
  const [currentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const UPDATE_FEE_RULE_STATUS_MODAL_INDEX = 0;
  const [modalIndex, setModalIndex] = useState(null);
  const [confirmModalSuccess, setConfirmModalSuccess] = useState(null);
  const buttonStyle = !props.details.status ? "primary" : "secondary";
  const buttonText = !props.details.status ? "Activate" : "Deactivate";
  const action = buttonText.toUpperCase();
  const formattedStatusValue = props.details.status ? "Active" : "Inactive";
  const loadingText =
    action === "DEACTIVATE"
      ? "Deactivating fee rule..."
      : "Activating fee rule...";

  const updateFeeRuleStatus = useCallback(
    async (status) => {
      const params = HELPER.TO_URL_STRING({
        code: props.feeRuleCode,
        active: status,
      });
      setLoading(true);
      try {
        await API_SERVICE.MAKE_PUT_REQUEST(
          `${BACK_OFFICE_API.FEE_RULES.UPDATE_FEE_RULE_STATUS}?${params}`
        );
        setLoading(false);
        setConfirmModalSuccess(
          `Successfully ${status ? "activated" : "deactivated"} fee rule!`
        );
      } catch (error) {
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
        setLoading(false);
        setIsModalVisible(false);
      }
    },
    [addNotification, props]
  );

  const closeModal = () => {
    setIsModalVisible(false);
    setModalIndex(null);
    if (confirmModalSuccess) {
      const shouldReload = true;
      props?.goBack(shouldReload);
    }
  };
  const toggelModal = (modalIndex) => {
    setConfirmModalSuccess(null);
    setModalIndex(modalIndex);
    setIsModalVisible(!isModalVisible);
  };
  const handleClick = () => {
    toggelModal(UPDATE_FEE_RULE_STATUS_MODAL_INDEX);
  };

  const modalContent = () => {
    if (UPDATE_FEE_RULE_STATUS_MODAL_INDEX === modalIndex) {
      return (
        <CustomConfirmDialog
          action={action}
          success={confirmModalSuccess}
          code={props.feeRuleCode}
          confirmText={props.details.name}
          loading={loading}
          loadingText={loadingText}
          fn={updateFeeRuleStatus}
          closeModal={closeModal}
        />
      );
    }
  };
  const feeRuleTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left ml-3 p-mt-2 p-pb-3 d-flex justify-content-between">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
          <div className="d-flex flex-column px-2">
            <div className="d-flex align-items-start h-fit pr-2">
              <p className="text-color">Fee Rule Status:</p>
              <p className="pl-2">
                {HELPER.RESPONSE_STATUS(formattedStatusValue, "status")}
              </p>
            </div>
            <div className="align-self-end">
              <AppButton
                disabled={loading}
                type="button"
                buttonStyle={buttonStyle}
                loading={loading}
                text={buttonText}
                onclick={handleClick}
              />
            </div>
          </div>
        </div>
        <TabView>
          <TabPanel header={"Fee Rule Summary"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
              </div>
              <div className="centralize">
                <div className="p-2">
                  <FormattedDetails details={props.details} />
                </div>
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY("switch_get_fee_rule_identifier") ? (
            <TabPanel header={"Fee Rule Identifiers"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <FeeRuleIdentifiers feeRuleCode={props.feeRuleCode} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
          {HELPER.HAS_AUTHORITY("switch_get_participant") ? (
            <TabPanel header={"Participants"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <ManageParticipants feeRuleCode={props.feeRuleCode} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };
  const feeRuleBreakDownView = () => {
    switch (currentIndex) {
      case 0:
        return feeRuleTabView();
      case 1:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="Loading details ..." />
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div>
      <CustomModal
        closeModal={closeModal}
        onHide={closeModal}
        visible={isModalVisible}
        modalContent={modalContent}
      />
      {feeRuleBreakDownView()}
    </div>
  );
};
