import { Outlet } from "react-router-dom";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useMainState } from "../core/hooks/useMainState";
import { LOCAL_STORAGE_SERVICE } from "../core/services/storage-service";
import { Header } from "./header/header";
import Sider from "./sider/sider";
import { useEffect, useState } from "react";
import ErrorBoundary from "shared/components/error-boundary/error-boundary";
import { API_SERVICE } from "api/service";
import { CONFIG } from "core/configs/configs";

export function AppLayout() {
  const location = useLocation();
  const { mainState, mainDispatch } = useMainState();
  const [idleTimeInMinutes] = useState(5);
  let navigate = useNavigate();

  useEffect(() => {
    function resetIdleTimeout() {
      const idleTimeInMilliseconds =
        CONFIG.APP_IDLE_TIMEOUT_IN_MINUTES * 60 * 1000;
      localStorage.setItem(
        "_idleTimeExpiry",
        Date.now() + idleTimeInMilliseconds
      );
    }
    window.addEventListener("click", resetIdleTimeout);
    window.addEventListener("mousemove", resetIdleTimeout);
    window.addEventListener("scroll", resetIdleTimeout);
    window.addEventListener("keydown", resetIdleTimeout);

    const clearStorageAndRedirect = () => {
      LOCAL_STORAGE_SERVICE.CLEAR_STORAGE();
      navigate("/login");
    };

    const logout = async () => {
      const url = `/management/api/${CONFIG.API_VERSION}/common/authentication/logout`;
      try {
        await API_SERVICE.MAKE_PUT_REQUEST(url);
        clearStorageAndRedirect();
      } catch (error) {
        clearStorageAndRedirect();
      }
    };

    resetIdleTimeout();

    const interval = setInterval(() => {
      const expiredTime = localStorage.getItem("_idleTimeExpiry");
      if (expiredTime < Date.now()) {
        mainDispatch({
          type: "SET_ALERT",
          alert: {
            title: "Info",
            description: "Your session has timed out, please login again.",
            type: "error",
            showAlert: true,
          },
        });
        logout();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      window.removeEventListener("click", resetIdleTimeout);
      window.removeEventListener("mousemove", resetIdleTimeout);
      window.removeEventListener("scroll", resetIdleTimeout);
      window.removeEventListener("keydown", resetIdleTimeout);
    };
  }, [navigate, idleTimeInMinutes, mainDispatch]);

  function isUserAuthenticated() {
    let isAuthenticated = mainState.isAuthenticated;
    if (!isAuthenticated) {
      isAuthenticated = LOCAL_STORAGE_SERVICE.IS_LOGIN();
    }
    return isAuthenticated;
  }
  return (
    <>
      <div>
        <Sider />
      </div>
      <div id="main">
        <div>
          <Header />
        </div>
        <div className="main-body inner-content">
          <ErrorBoundary>
            {isUserAuthenticated() ? (
              <Outlet />
            ) : (
              <Navigate to="/login" state={{ from: location }} replace />
            )}
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
}
