import { CONFIG } from "core/configs/configs";

const LOG_DISPUTE = `/dispute/api/${CONFIG.API_VERSION}/dispute`;
const RESOLVE_DISPUTE = `/dispute/api/${CONFIG.API_VERSION}/dispute/resolve`;
const REOPEN_DISPUTE = `/dispute/api/${CONFIG.API_VERSION}/dispute/reopen`;
const GET_DISPUTE = `/dispute/api/${CONFIG.API_VERSION}/dispute`;
const GET_PARTY_DISPUTES = `/dispute/api/${CONFIG.API_VERSION}/dispute/party`;
const GET_DISPUTES = `/dispute/api/${CONFIG.API_VERSION}/dispute`;
const GENERATE_DISPUTE_REPORT = `/dispute/api/${CONFIG.API_VERSION}/dispute-report`;
const GENERATE_TEAMAPT_DISPUTE_REPORT = `/dispute/api/${CONFIG.API_VERSION}/dispute-report/teamapt`;

export const DISPUTE_ROUTES = {
  LOG_DISPUTE,
  RESOLVE_DISPUTE,
  REOPEN_DISPUTE,
  GET_PARTY_DISPUTES,
  GET_DISPUTES,
  GET_DISPUTE,
  GENERATE_DISPUTE_REPORT,
  GENERATE_TEAMAPT_DISPUTE_REPORT,
};
