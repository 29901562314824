import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useCallback, useEffect, useState } from "react";

const useDisputes = (props) => {
  const { addNotification } = useNotification();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [details, setDetails] = useState({});
  const [modalInfo] = useState({
    title: `Filter Disputes`,
    subtitle: "",
  });
  const [loading, setLoading] = useState(true);
  const [disputes, setDisputes] = useState([]);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [statusLogInfo, setStatusLogInfo] = useState([]);
  const tableHeaders = [
    { label: "Log Code", value: "logCode" },
    { label: "Amount", value: "transactionAmount" },
    { label: "Transaction Time", value: "transactionTime" },
    { label: "Log Date", value: "createdAt" },
    { label: "TAT Expiry Date", value: "tatExpiryDate" },
    { label: "Dispute Status", value: "status" },
    { label: "Resolution Status", value: "resolutionStatus" },
    { label: "Actions", value: "actions" },
  ];
  const tableAuthorities = [{ label: "OPEN", value: "all" }];
  const [filterParams, setFilterParams] = useState(null);

  const formatPayload = useCallback(
    (filter) => {
      return props.partyType
        ? {
            partyType: props.partyType,
            partyId: props.partyId,
            ...filter,
          }
        : { ...filter };
    },
    [props.partyId, props.partyType]
  );

  const getDisputes = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({ page: pageNo });
      const payload = formatPayload(filter);
      try {
        const response = await API_SERVICE.MAKE_PUT_REQUEST(
          `${props.url}?${params}`,
          payload
        );
        setPagination(response);
        setDisputes(response?.content);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [addNotification, props.url, formatPayload, pageNo]
  );

  useEffect(() => {
    if (!filterParams) {
      getDisputes();
    } else {
      getDisputes(filterParams);
    }
  }, [getDisputes, filterParams]);

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal() {
    setIsModalVisible(false);
  }

  function reload() {
    setPageNo(0);
    setFilterParams(null);
  }

  const goBack = () => {
    setCurrentIndex(0);
    reload();
  };

  const handleFilter = (filter) => {
    setFilterParams(filter);
  };

  const handleViewAction = ({ statusLogInfo, ...rest }) => {
    try {
      setStatusLogInfo(JSON.parse(statusLogInfo));
    } catch (error) {
      setStatusLogInfo([]);
    }
    setDetails(rest);
    setCurrentIndex(1);
  };

  const handleTableAction = (data, action) => {
    switch (action) {
      case "OPEN":
        handleViewAction(data);
        return;
      default:
        break;
    }
  };

  const incrementPageNo = () => {
    setPageNo(pageNo + 1);
  };
  const decrementPageNo = () => {
    setPageNo(pageNo - 1);
  };
  const goToPage = (number) => {
    setPageNo(number);
  };

  const api = {
    loading,
    error,
    getDisputes,
  };
  const page = {
    isModalVisible,
    pageNo,
    currentIndex,
    modalIndex,
    tableHeaders,
    tableAuthorities,
    modalInfo,
  };
  const data = {
    disputes,
    statusLogInfo,
    pagination,
    details,
  };
  const pageActions = {
    goToPage,
    decrementPageNo,
    incrementPageNo,
    handleFilter,
    handleTableAction,
    handleViewAction,
    reload,
    goBack,
    closeModal,
    toggleModal,
  };

  return { api, page, data, pageActions };
};

export { useDisputes };
