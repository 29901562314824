import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";
import { AppFormTextArea } from "shared/components/form-inputs/form-text-area";
import { CustomUpload } from "shared/components/custom-upload/custom-upload";
import { FormCheckBoxInput } from "shared/components/form-component/form-checkbox-input";

export function ResolveDispute(props) {
  const { addNotification } = useNotification();
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    comment: "",
    approved: "",
  });
  const [validationSchemaObject, setValidationSchemaObject] = useState({
    comment: Yup.string().required("Required"),
    approved: Yup.boolean().required("Required"),
  });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const [receiptFileName, setReceiptFileName] = useState(null);
  const [confirmedResolution, setConfirmedResolution] = useState(true);
  const [approvalOptions] = useState([
    {
      label: "Approved",
      value: true,
    },
    {
      label: "Rejected",
      value: false,
    },
  ]);

  const handleResponse = () => {
    let notification = {
      type: "success",
      message: `Dispute successfully resolved!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const resolveDispute = async (payload) => {
    resetToast();
    payload = {
      ...payload,
      logCode: props.dispute.logCode,
    };
    const url = BACK_OFFICE_API.DISPUTES.RESOLVE_DISPUTE;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const submit = async (payload) => {
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      resolveDispute(payload);
    } else {
      const { receiptDataBase64, approved, ...rest } = payload;
      let confirmationPayload = {
        status: payload.approved ? "Approved" : "Rejected",
        ...rest,
        receipt: receiptFileName,
      };
      setFormValues(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleApprovalChange = (isApproved, setFieldValue) => {
    setFieldValue("approved", isApproved);
    if (!isApproved) {
      setInitialValues({
        ...initialValues,
        receiptDataBase64: "",
      });
      setValidationSchemaObject({
        ...validationSchemaObject,
        receiptDataBase64: Yup.string().required("Required"),
      });
    }
  };

  useEffect(() => {
    if (currentFormIndex === FORM_VIEW_INDEX) setConfirmedResolution(true);
  }, [currentFormIndex]);

  return (
    <div>
      <p className="modal-title p-text-left mb-1">Resolve Dispute</p>
      <p className="modal-subtitle mt-0 mb-1">
        Complete the form below to resolve this dispute.
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
              <AppFormDropdown
                value={values.approved}
                onchange={(e) => {
                  const value = e?.target?.value;
                  handleApprovalChange(value, setFieldValue);
                }}
                options={approvalOptions}
                optionLabel="label"
                optionValue="value"
                placeholder="Action"
                label="Action"
                error={errors?.approved}
              />
              <AppFormTextArea
                name="comment"
                label="Comment"
                placeholder="Comment"
              />
              <Optional showIf={values.approved === false}>
                <CustomUpload
                  onSetFileName={setReceiptFileName}
                  onFileUploadedFile={(receipt) =>
                    setFieldValue("receiptDataBase64", receipt)
                  }
                  title="Receipt"
                  clearUploadedFile={() =>
                    setFieldValue("receiptDataBase64", null)
                  }
                />
              </Optional>
              <Optional showIf={errors.receiptDataBase64}>
                <p className="text-red-500 text-small">Required</p>
              </Optional>
              <Optional showIf={values.approved === false || values.approved}>
                <FormCheckBoxInput
                  id="checkbox"
                  handleChange={() =>
                    setConfirmedResolution(!confirmedResolution)
                  }
                  label="I have confirmed the transactions details and the nature of
                    this dispute claim."
                />
              </Optional>
            </Optional>

            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <FormattedDetails details={formValues} />
            </Optional>

            {message && (
              <CustomToast
                title={toastType === "error" ? "Error" : "Success"}
                description={message}
                type={toastType}
                closeable={false}
                inApp={true}
              />
            )}
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text={`${
                  currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                }`}
                onclick={handleClose}
              />
              <AppButton
                type="submit"
                disabled={confirmedResolution}
                buttonStyle={confirmedResolution ? "disabled" : "primary"}
                text={`${
                  currentFormIndex === FORM_VIEW_INDEX ? "Confirm" : "Submit"
                }`}
                loading={loading}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
