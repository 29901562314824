import "./dashboard.css";
import { useRef, useState } from "react";
import moment from "moment";
import { AnalyticsChart } from "./analytics-chart";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CustomBreadcrumb } from "../custom-breadcrumb/custom-breadcrumb";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { Icon } from "../icons/icon";
import { Optional } from "../optional/optional";

export function Dashboard(props) {
  const toast = useRef(null);
  const [selectedMetricType] = useState("transactions");
  const [dateRangeValue] = useState("today");
  const [currentDayMetrics] = useState(null);
  const [historicMetrics] = useState(null);
  const [loading] = useState(true);
  const [noData] = useState(false);
  const [errorMessage] = useState("");
  const reloadFunctionDetails = useRef({
    reloadFunction: null,
    params: null,
  });

  const selectedReport = useRef({
    desc: "Transactions",
    code: "transactions",
  });
  const selectedDateRange = useRef({
    desc: "Today",
    code: "today",
  });
  const dataOptions = [
    { desc: "Transactions", code: "transactions" },
    { desc: "Disputes", code: "disputes" },
  ];
  const dateRanges = [
    { desc: "Today", code: "today" },
    { desc: "Yesterday", code: "1" },
    { desc: "Last 7 days", code: "7" },
    { desc: "Last 14 days", code: "14" },
    { desc: "Custom", code: "custom" },
  ];
  const [showCustomDateField] = useState(false);
  const [dateFilterError] = useState(false);
  const dateFilter = useRef({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [customDate, setCustomDate] = useState({
    startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  });

  function validateDateSelection(e, field) {
    const dateValue = e?.target?.value;
    setCustomDate({
      ...customDate,
      [field]: dateValue,
    });
    dateFilter.current = {
      ...dateFilter.current,
      [field]: dateValue,
    };
  }

  function reload() {
    const params = reloadFunctionDetails?.current?.params;
    reloadFunctionDetails?.current?.reloadFunction(params);
  }

  const currentChartView = () => {
    if (noData) {
      return (
        <div className="card mt-5 empty" style={{ textAlign: "center" }}>
          <Icon icon="not-found" />
          <p style={{ fontSize: "0.8rem" }}>{errorMessage}</p>
          <div>
            <button className="primary-button" onClick={reload}>
              Reload
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <AnalyticsChart
          userType={props.userType}
          dateRange={dateRangeValue}
          metricType={selectedMetricType}
          currentDayMetrics={currentDayMetrics}
          historicMetrics={historicMetrics}
        />
      );
    }
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="page-title p-text-left">Dashboard</div>
      <div className="mt-2">
        <CustomBreadcrumb page="Dashboard" />
      </div>
      <div className="grid mt-3">
        <div className="col-4 dropdown-wrapper">
          <FormDropdown
            required={true}
            label="desc"
            value={selectedReport.current}
            // fn={handleReportTypeSelection}
            options={dataOptions}
            placeholder="Select analytics type"
          />
        </div>
        <div className="col-4 dropdown-wrapper">
          <FormDropdown
            required={true}
            label="desc"
            value={selectedDateRange.current}
            // fn={handleDateRangeSelection}
            options={dateRanges}
            placeholder="Select time"
          />
        </div>
        <div className="col-4 date-select-row">
          <Optional show={showCustomDateField}>
            <div>
              <div className="grid">
                <div className="col-6 pt-0">
                  <FormInput
                    inputType="date"
                    value={customDate["startDate"]}
                    required={false}
                    field="startDate"
                    type="INPUT"
                    error={dateFilterError}
                    fn={validateDateSelection}
                    loading={false}
                    max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                    placeholder="Start date"
                  />
                </div>
                <div className="col-6 pt-0">
                  <FormInput
                    inputType="date"
                    value={customDate["endDate"]}
                    required={false}
                    field="endDate"
                    type="INPUT"
                    error={dateFilterError}
                    fn={validateDateSelection}
                    max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                    loading={false}
                    placeholder="End date"
                  />
                </div>
              </div>
            </div>
          </Optional>
          <Optional show={dateFilterError}>
            <p
              className="text-error mt-0"
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "0.75rem",
                transform: "translateY(-15px)",
              }}
            >
              Please select a valid date range, 14 days or less!
            </p>
          </Optional>
          <Optional show={showCustomDateField && !dateFilter}>
            <p
              className="text-error mt-0"
              style={{ textAlign: "left", fontSize: "0.75rem" }}
            >
              Note: You can only retrieve metrics within 14 day intervals.
            </p>
          </Optional>
          <Optional show={showCustomDateField}>
            <div style={{ textAlign: "right" }}>
              <button className="primary-button" style={{ width: "10rem" }}>
                Search
              </button>
            </div>
          </Optional>
        </div>
      </div>

      <Optional show={dateRangeValue !== "today"}>
        <div>
          <p
            className="text-left"
            style={{ fontSize: "0.8rem", fontStyle: "italic" }}
          >
            Showing results from{" "}
            <span>
              {" "}
              {moment(dateFilter?.current?.startDate).format(
                "MMMM Do YYYY"
              )}{" "}
            </span>
            -{" "}
            <span>
              {moment(dateFilter?.current?.endDate).format("MMMM Do YYYY")}{" "}
            </span>
          </p>
        </div>
      </Optional>
      <Optional show={loading}>
        <div className="card mt-4">
          <Skeleton />
          <Skeleton className="mt-3" />
          <Skeleton className="mt-3" />
          <Skeleton className="mt-3" />
          <Skeleton className="mt-3" />
          <Skeleton className="mt-3" />
          <Skeleton className="mt-3" />
        </div>
      </Optional>
      <Optional show={!loading}>{currentChartView()}</Optional>
    </div>
  );
}
