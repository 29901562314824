import { CONFIG } from "core/configs/configs";

const GET_MERCHANTS = `/management/api/${CONFIG.API_VERSION}/merchant`;
const CREATE_MERCHANTS = `/management/api/${CONFIG.API_VERSION}/merchant`;
const UPDATE_MERCHANTS = `/management/api/${CONFIG.API_VERSION}/merchant`;

export const MERCHANT_ROUTES = {
  GET_MERCHANTS,
  UPDATE_MERCHANTS,
  CREATE_MERCHANTS,
};
