import React, { useCallback, useEffect, useState } from "react";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { API_SERVICE } from "../../../api/service";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { AcquirersForm } from "./acquirers-form";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { AcquirerDetails } from "./acquirer-details";
import { AcquirersFilter } from "./acquirers-filter";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { useNotification } from "core/hooks/useNotification";

export function ManageAcquirers() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Associate Acquirers",
    subtitle: "All fields are required",
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [tableHeaders] = useState([
    { label: "Name", value: "financialInstitutionName" },
    { label: "Status", value: "active" },
    { label: "Create At", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ]);
  const [actions] = useState(["UPDATE", "OPEN"]);
  const [authorities] = useState([
    { label: "UPDATE", value: "switch_update_acquirer" },
    { label: "OPEN", value: "all" },
  ]);
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirer, setSelectedAcquirer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [details, setDetails] = useState([]);
  const [acquirerCode, setAcquirerCode] = useState("");
  const [filterParams, setFilterParams] = useState(null);

  const getAcquirers = useCallback(
    async (filter) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING(
        HELPER.TRIM_OBJECT({
          page: pageNo,
          size: DEFAULT_PAGE_SIZE,
          ...filter,
        })
      );
      const url = `${BACK_OFFICE_API.ACQUIRER.ACQUIRER}?${params}`;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(url);
        setPagination(response.result);
        const acquirers = response?.result?.content;
        setAcquirers(acquirers);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },

    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getAcquirers();
    } else {
      getAcquirers(filterParams);
    }
  }, [getAcquirers, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getAcquirers();
    }
  }

  const handleAcquirerFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  function goBack() {
    setCurrentIndex(0);
  }

  const handleCreateAcquirer = () => {
    toggleModal(0);
    setModalInfo({
      title: "Create Acquirer",
      subtitle: "Fields with * are required",
    });
  };

  const handleTableAction = (data, action) => {
    const { active, updatedAt, code, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Acquirer",
          subtitle: "All fields are required",
        });
        setSelectedAcquirer({ ...rest, status: active, updatedAt, code });
        toggleModal(1);
        break;
      case "OPEN":
        setAcquirerCode(data.code);
        setDetails({ ...rest, status: active });
        setCurrentIndex(1);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      case 1:
        return (
          <div className="mt-5">
            <AcquirerDetails
              goBack={goBack}
              acquirerCode={acquirerCode}
              details={details}
            />
          </div>
        );
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <AcquirersForm
            title={"Create Acquirer"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getAcquirers}
          />
        );
      case 1:
        return (
          <AcquirersForm
            title={"Update Acquirer"}
            subtitle={"Fields with * are required"}
            acquirer={selectedAcquirer}
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getAcquirers}
          />
        );
      case 3:
        return (
          <AcquirersFilter
            onGetAcquirers={handleAcquirerFilter}
            closeModal={closeModal}
          />
        );

      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className=" text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={authorities}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No acquirers found!"}
          search={true}
          reload={reload}
          error={error}
          items={acquirers}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={actions}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-acquirers">
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Acquirers" />
      <CustomBreadcrumb page="Manage Acquirers" />
      <PageActions>
        <Optional showIf={currentIndex === 0}>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => {
              setModalInfo({
                title: "Filter Acquirers",
                subtitle: "",
              });
              toggleModal(3);
            }}
          />
        </Optional>
        <Optional
          showIf={
            HELPER.HAS_AUTHORITY("switch_create_acquirer") && currentIndex === 0
          }
        >
          <AppButton
            type="button"
            buttonStyle="primary"
            text={"Create Acquirer"}
            onclick={handleCreateAcquirer}
          />
        </Optional>
      </PageActions>
      <>{currentPageView()}</>
    </div>
  );
}
