import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";

export function NssNetSettlementSummaries(props) {
  const { addNotification } = useNotification();
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(true);
  const [netSummaries, setNetSummaries] = useState([]);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const tableHeaders = [
    { label: "Settlement Amount", value: "settlementAmount" },
    {
      label: "Financial Institution Name",
      value: "financialInstitutionName",
    },
    {
      label: "Financial Institution Code",
      value: "financialInstitutionCode",
    },
  ];

  const getNetSummaries = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
    });
    const url = `${BACK_OFFICE_API.NSS_SETTLEMENT_SESSIONS.NSS_SETTLEMENT_SESSION_NET_SUMMARIES}/${props.details.code}`;
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`);
      setPagination(response);
      setNetSummaries(response?.content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    }
  }, [pageNo, addNotification, props.details.code]);

  useEffect(() => {
    getNetSummaries();
  }, [getNetSummaries]);

  function reload() {
    setPageNo(0);
    getNetSummaries();
  }

  const renderTableView = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    }

    return (
      <CustomTable
        search={true}
        isReload={true}
        numberOfElements={pagination?.numberOfElements}
        totalPages={pagination?.totalPages}
        totalItems={pagination?.totalElements}
        currentPage={pageNo + 1}
        emptyText={"No NSS settlement summaries found!"}
        reload={reload}
        error={error}
        items={netSummaries}
        headers={tableHeaders}
        nextPage={() => {
          setPageNo(pageNo + 1);
        }}
        prevPage={() => setPageNo(pageNo - 1)}
        goToFirstPage={() => setPageNo(1)}
        goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
        goToPage={(pageNo) => setPageNo(pageNo)}
      />
    );
  };

  return <div>{renderTableView()}</div>;
}
