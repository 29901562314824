import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";

export function FeeSourceForm(props) {
  const { addNotification } = useNotification();
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    sourceNodeId: "",
  });
  const validationSchemaObject = {
    name: Yup.string()
      .max(255, `Too long, maximum length is 255 characters`)
      .required("Required"),
    description: Yup.string()
      .max(255, `Too long, maximum length is 255 characters`)
      .required("Required"),
    sourceNodeId: Yup.string()
      .required("Required")
      .max(128, `Too long, maximum length is 128 characters`),
    active: Yup.boolean(),
  };
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (props.isUpdate) {
      const { name, description, sourceNodeId, active } = props.feeSource;
      setInitialValues({
        name,
        description,
        sourceNodeId,
        active,
      });
    }
  }, [props.isUpdate, props.feeSource]);

  const handleResponse = (isCreate = false) => {
    let notification = {
      type: "success",
      message: `Fee source successfully ${isCreate ? "created" : "updated"}!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const createFeeSource = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.FEE_SOURCE.FEE_SOURCE;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateFeeSource = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.FEE_SOURCE.FEE_SOURCE;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.feeSource?.code}`,
        payload
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const submit = async (payload, resetFormFuntion) => {
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      if (props.isUpdate) {
        updateFeeSource(payload, resetFormFuntion);
      } else {
        createFeeSource(payload, resetFormFuntion);
      }
    } else {
      let { active, ...rest } = payload;
      let confirmationPayload = {
        ...rest,
        ...(props.isUpdate && {
          status: payload.active ? "Active" : "Inactive",
        }),
      };
      setFormValues(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchemaObject)}
          onSubmit={(values) => {
            submit(values);
          }}
          enableReinitialize={true}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <Optional
                showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
              >
                <FormattedDetails details={formValues} />
              </Optional>
              <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
                <AppFormInput
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Enter name"
                  required={true}
                />
                <AppFormInput
                  required={true}
                  label="Description"
                  name="description"
                  type="text"
                  placeholder="Enter description"
                  disabled={false}
                />
                <AppFormInput
                  required={true}
                  label="Source Node Id"
                  name="sourceNodeId"
                  type="text"
                  placeholder="Enter source node Id"
                  disabled={false}
                />

                <Optional showIf={props.isUpdate}>
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        marginBottom: "0.5rem",
                        display: "block",
                        color: "#566a7f",
                        textAlign: "left",
                      }}
                    >
                      Status: <span style={{ color: "red" }}> * </span>
                    </p>
                    <div className="flex default p-mb-4">
                      <FormRadioInput
                        id="active"
                        value={true}
                        name="Status"
                        checked={values.active === true}
                        handleChange={(e) => setFieldValue("active", true)}
                        label="Active"
                      />
                      <FormRadioInput
                        id="Inactive"
                        value={false}
                        name="Status"
                        checked={values.active === false}
                        handleChange={(e) => setFieldValue("active", false)}
                        label="Inactive"
                      />
                    </div>
                  </div>
                </Optional>
              </Optional>

              {message && (
                <CustomToast
                  title={toastType === "error" ? "Error" : "Success"}
                  description={message}
                  type={toastType}
                  closeable={false}
                  inApp={true}
                />
              )}
              <FormActions>
                <AppButton
                  type="button"
                  buttonStyle="secondary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                  }`}
                  onclick={handleClose}
                />
                <AppButton
                  type="submit"
                  buttonStyle="primary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"
                  }`}
                  loading={loading}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <>{renderForm()}</>;
}
