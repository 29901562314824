import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { FeeRuleForm } from "./fee-rule-form";
import { FeeRulesFilter } from "./fee-rules-filter";
import { FeeRuleDetails } from "./fee-rule-details";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";

export function FeeRules() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Create Fee Rule",
    subtitle: "Fields marked * are required",
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
    { label: "General Clearing Rule", value: "generalClearingRule" },
    { label: "Fee Source", value: "feeSourceName" },
    { label: "Status", value: "active" },
    { label: "Created At", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [feeRules, setFeeRules] = useState([]);
  const [selectedFeeRule, setSelectedFeeRule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [feeRuleCode, setFeeRuleCode] = useState("");
  const [feeRuleDetails, setFeeRuleDetails] = useState({});
  const [filterParams, setFilterParams] = useState(null);

  const getFeeRules = useCallback(
    async (name) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...(name && { name }),
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.FEE_RULES.FEE_RULE}?${params}`
        );
        setPagination(response);
        const feeRules = response?.content;
        setFeeRules(feeRules);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },

    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getFeeRules();
    } else {
      getFeeRules(filterParams);
    }
  }, [getFeeRules, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getFeeRules();
    }
  }

  const handleFeeRulesFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  function goBack(shouldReload) {
    setCurrentIndex(0);
    if (shouldReload) {
      getFeeRules();
    }
  }
  const handleTableAction = (data, action) => {
    const { active, code, updatedAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        if (data?.hasClearingEntries) {
          addNotification({
            message: "Fee rule has clearing entries and cannot be updated!",
            type: "error",
          });
          return;
        }
        setModalInfo({
          title: "Update Fee Rule",
          subtitle: "Fields marked * are required",
        });
        setSelectedFeeRule({ ...rest, active, updatedAt, code });
        toggleModal(1);
        break;
      case "OPEN":
        setFeeRuleCode(data.code);
        setFeeRuleDetails({
          ...rest,
          status: active,
        });
        setCurrentIndex(1);
        break;
      default:
        break;
    }
  };

  const handleFilterClick = () => {
    setModalInfo({
      title: "Filter Fee Rules",
      subtitle: "All fields are required",
    });
    toggleModal(3);
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <FeeRuleForm
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getFeeRules}
          />
        );
      case 1:
        return (
          <FeeRuleForm
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getFeeRules}
            feeRule={selectedFeeRule}
          />
        );
      case 2:
        return (
          <FormattedDetails
            details={selectedFeeRule}
            feeRuleCode={feeRuleCode}
          />
        );
      case 3:
        return (
          <FeeRulesFilter
            onGetFeeRules={handleFeeRulesFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_update_fee_rule" },
            { label: "OPEN", value: "all" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No fee rule found!"}
          search={true}
          reload={reload}
          error={error}
          items={feeRules}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "OPEN"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  const renderPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      case 1:
        return (
          <div className="mt-5">
            <FeeRuleDetails
              goBack={goBack}
              feeRuleCode={feeRuleCode}
              details={feeRuleDetails}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="manage-fee-rules">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Fee Rules" />
      <CustomBreadcrumb page="Manage Fee Rules" />
      <Optional
        showIf={
          HELPER.HAS_AUTHORITY("switch_create_fee_rule") && currentIndex === 0
        }
      >
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={handleFilterClick}
          />
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Fee Rule"
            onclick={() => {
              toggleModal(0);
              setModalInfo({
                title: "Create Fee Rule",
                subtitle: "Fields marked * are required",
              });
            }}
          />
        </PageActions>
      </Optional>
      <>{renderPageView()}</>
    </div>
  );
}
