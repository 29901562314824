import { useMainState } from "../../../core/hooks/useMainState";
import { CustomToast } from "../alert/custom-toast";

export function AppAlert() {
  const { mainState } = useMainState();

  return mainState?.alert?.showAlert ? (
    <CustomToast
      title={mainState?.alert?.title}
      description={mainState?.alert?.description}
      type={mainState?.alert?.type}
    />
  ) : null;
}
