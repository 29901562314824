import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "../../../api/service";
import { IssuerForm } from "./issuers-form";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { IssuerDetails } from "./issuer-details";
import { useNotification } from "core/hooks/useNotification";

export function ManageIssuers() {
  const { addNotification } = useNotification();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
    { label: "Status", value: "active" },
    { label: "Creation Date", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [issuers, setIssuers] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [issuersPagination, setIssuersPagination] = useState({});
  const [error, setError] = useState(null);
  const [issuerCode, setIssuerCode] = useState();
  const [issuerDetails, setIssuerDetails] = useState(null);

  const getIssuers = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
    });
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.ISSUERS.GET_PAGED_ISSUERS}?${params}`
      );
      setIssuersPagination(response.result);
      const issuers = response?.result?.content;
      setIssuers(issuers);
      setError(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    }
  }, [pageNo, addNotification]);

  useEffect(() => {
    getIssuers();
  }, [getIssuers]);

  function reload() {
    getIssuers();
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getIssuers();
    }
  }

  const handleTableAction = (data, action) => {
    const { active, updatedAt, createdBy, createdAt, code, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setSelectedIssuer({
          ...rest,
          active,
          updatedAt,
          createdAt,
          code,
          status: active,
        });
        toggleModal(1);
        break;
      case "OPEN":
        setIssuerCode(data?.code);
        setIssuerDetails({ ...rest, status: active });
        setCurrentIndex(1);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      case 1:
        return (
          <IssuerDetails
            issuerCode={issuerCode}
            issuerDetails={issuerDetails}
            goBack={() => setCurrentIndex(0)}
          />
        );
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <IssuerForm
            title={"Create Issuer"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getIssuers}
          />
        );
      case 1:
        return (
          <IssuerForm
            title={"Update Issuer"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getIssuers}
            issuer={selectedIssuer}
          />
        );
      case 2:
        return (
          <>
            <p className="modal-title p-text-left mb-1">Issuer Details</p>
            <div className="custom-modal-item">
              <FormattedDetails details={selectedIssuer} />
            </div>
          </>
        );

      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_update_issuer" },
            { label: "OPEN", value: "switch_get_issuer" },
          ]}
          isReload={true}
          numberOfElements={issuersPagination?.numberOfElements}
          totalPages={issuersPagination?.totalPages}
          totalItems={issuersPagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No Issuers found!"}
          search={true}
          reload={reload}
          error={error}
          items={issuers}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(issuersPagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "OPEN"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-issuers">
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
        />
      </>
      <PageTitle text="Issuers" />
      <CustomBreadcrumb page="Manage Issuers" />
      <PageActions>
        {/* <AppButton
          icon="filter"
          text="Filter"
          type="button"
          buttonStyle="primary"
          margin={"mr-2"}
          onclick={() => toggleModal(3)}
        /> */}
        <Optional
          showIf={
            HELPER.HAS_AUTHORITY("switch_create_issuer") && currentIndex === 0
          }
        >
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Issuer"
            onclick={() => {
              toggleModal(0);
            }}
          />
        </Optional>
      </PageActions>
      <div className="mt-5">{currentPageView()}</div>
    </div>
  );
}
