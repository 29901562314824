import "./header.css";
// import { Icon } from "../../shared/icons/icon";
import { Icon } from "../../shared/components/icons/icon";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_SERVICE } from "../../core/services/storage-service";
import { Optional } from "../../shared/components/optional/optional";
import { Toast } from "primereact/toast";
import { useMainState } from "../../core/hooks/useMainState";
import { API_SERVICE } from "api/service";
import { useNotification } from "core/hooks/useNotification";
import { HELPER } from "core/helper/helper";
import { CONFIG } from "core/configs/configs";

export function Header() {
  const { addNotification } = useNotification();

  const mainContext = useMainState();
  const toast = useRef(null);

  let navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const logout = async () => {
    setIsLoggingOut(true);
    const url = `/management/api/${CONFIG.API_VERSION}/common/authentication/logout`;

    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url);
      clearStorageAndRedirect();
      setIsLoggingOut(false);
    } catch (error) {
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
      setIsLoggingOut(false);
    }
  };

  function clearStorageAndRedirect() {
    mainContext.mainDispatch({ type: "LOG_OUT" });
    LOCAL_STORAGE_SERVICE.CLEAR_STORAGE();
    navigate("/login");
  }

  function openNav(e) {
    e.preventDefault();
    let sideNav = document.getElementById("mySidenav");
    let mainContent = document.getElementById("main");
    const width = sideNav.style.width;
    if (window.innerWidth < 900) {
      if (width && width === "70vw") {
        sideNav.style.width = "0";
        mainContent.style.marginLeft = "0";
      } else {
        sideNav.style.width = "70vw";
        mainContent.style.marginLeft = "0";
      }
    } else {
      if (width && width === "250px") {
        sideNav.style.width = "0";
        mainContent.style.marginLeft = "0";
      } else {
        sideNav.style.width = "250px";
        mainContent.style.marginLeft = "250px";
      }
    }
  }

  return (
    <div className="header">
      <Toast ref={toast} />
      <div className="grid header-container">
        <div className="col-4">
          <div className="header-icon add-cursor">
            <button onClick={openNav} className="bare-button">
              <Icon icon="menu-icon" />
            </button>
          </div>
        </div>
        <div className="col-8 header-actions">
          {/* <Optional show={HELPER.HAS_ROLE(["DCIR_BACKOFFICE_ADMIN"])}>
            <div className="add-cursor">
              <a
                className="nav-profile-title mt-0"
                href="https://teamapt.atlassian.net/wiki/spaces/DUTM/overview?homepageId=703725830"
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <Icon icon="bulk-disputes" color="#0371ac" />
                </div>
                User Manual
              </a>
            </div>
          </Optional> */}
          <div onClick={logout} className="col-6 log-out add-cursor">
            <Optional showIf={!isLoggingOut}>
              <Icon icon="log-out" />
            </Optional>
            <Optional showIf={isLoggingOut}>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "1.5em" }}
              ></i>
            </Optional>
          </div>
        </div>
      </div>
    </div>
  );
}
