import moment from "moment";
const options = {
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

function getValueInMajor(val) {
  return Number(val) / 100;
}

function getChartOptions(chartTitle) {
  const options = {
    plugins: {
      title: {
        display: true,
        text: `${chartTitle}`,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return options;
}

function formatBackofficeCurrentDayTransactionsData(data, valueType = "count") {
  const chartOptions = getChartOptions(
    `Transactions by ${valueType} as at ${moment().format("HH:mm")}`
  );
  const labels = Object.keys(data?.merchantMetrics);
  let metricsObject = {
    successfulTransactionsByValue: [],
    successfulTransactionsByCount: [],
    failedTransactionsByValue: [],
    failedTransactionsByCount: [],
  };
  labels.forEach((label) => {
    const metrics = data?.merchantMetrics[label];
    metricsObject.successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulAmount)
    );
    metricsObject.successfulTransactionsByCount.push(metrics?.successfulCount);
    metricsObject.failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedAmount)
    );
    metricsObject.failedTransactionsByCount.push(metrics?.failedCount);
  });
  const {
    successfulTransactionsByValue,
    successfulTransactionsByCount,
    failedTransactionsByValue,
    failedTransactionsByCount,
  } = metricsObject;
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Successsful Transactions",
        data:
          valueType === "count"
            ? successfulTransactionsByCount
            : successfulTransactionsByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Failed Transactions",
        data:
          valueType === "count"
            ? failedTransactionsByCount
            : failedTransactionsByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return { chartOptions, chartData };
}

function formatFrontofficeCurrentDayTransactionsData(
  data,
  valueType = "count"
) {
  let dataArr = [];
  if (valueType === "count") {
    dataArr.push(data?.successfulCount);
    dataArr.push(data?.failedCount);
  } else {
    dataArr.push(getValueInMajor(data?.successfulAmount));
    dataArr.push(getValueInMajor(data?.failedAmount));
  }
  const chartData = {
    labels: ["Successful Transactions", "Failed Transactions"],
    datasets: [
      {
        label: "Transactions",
        data: dataArr,
        backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  };
  return { chartOptions: null, chartData };
}

function formatBackofficeHistoricTransactionsData(data, valueType = "count") {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Transactions by ${valueType}`,
      },
    },
  };
  const labels = Object.keys(data?.analyticsSummaryDistributedByDays);
  const superagents = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay
  );
  let successfulTransactionsByValue = [];
  let successfulTransactionsByCount = [];
  let failedTransactionsByValue = [];
  let failedTransactionsByCount = [];
  labels.forEach((label) => {
    const metrics = data?.analyticsSummaryDistributedByDays[label];
    successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulTransactionValue)
    );
    successfulTransactionsByCount.push(metrics?.successfulTransactionCount);
    failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedTransactionValue)
    );
    failedTransactionsByCount.push(metrics?.failedTransactionCount);
  });
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Successsful Transactions",
        data:
          valueType === "count"
            ? successfulTransactionsByCount
            : successfulTransactionsByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Failed Transactions",
        data:
          valueType === "count"
            ? failedTransactionsByCount
            : failedTransactionsByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  let formattedSuperAgentArr = [
    {
      desc: "All superagents",
      code: "all",
    },
  ];
  superagents.forEach((agent) => {
    let agentObj = {
      desc: agent,
      code: agent,
    };
    formattedSuperAgentArr.push(agentObj);
  });
  return { chartOptions, chartData, formattedSuperAgentArr };
}

function formatFrontofficeHistoricTransactionsData(data, valueType = "count") {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Transactions by ${valueType}`,
      },
    },
  };
  const superagents = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay
  );
  const superagent = superagents[0];
  const labels = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay[superagent]
  );
  let successfulTransactionsByValue = [];
  let successfulTransactionsByCount = [];
  let failedTransactionsByValue = [];
  let failedTransactionsByCount = [];

  labels.forEach((label) => {
    const metrics =
      data?.analyticsSummaryDistributedBySuperAgentByDay[superagent][label];
    successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulTransactionValue)
    );
    successfulTransactionsByCount.push(metrics?.successfulTransactionCount);
    failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedTransactionValue)
    );
    failedTransactionsByCount.push(metrics?.failedTransactionCount);
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Successsful Transactions",
        data:
          valueType === "count"
            ? successfulTransactionsByCount
            : successfulTransactionsByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Failed Transactions",
        data:
          valueType === "count"
            ? failedTransactionsByCount
            : failedTransactionsByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return { chartOptions, chartData };
}

function formatSelectedSuperAgentTransactionsData(
  data,
  superagent,
  valueType = "count"
) {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Transactions by ${valueType}`,
      },
    },
  };
  const labels = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay[superagent]
  );
  let successfulTransactionsByValue = [];
  let successfulTransactionsByCount = [];
  let failedTransactionsByValue = [];
  let failedTransactionsByCount = [];
  labels.forEach((label) => {
    const metrics =
      data?.analyticsSummaryDistributedBySuperAgentByDay[superagent][label];
    successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulTransactionValue)
    );
    successfulTransactionsByCount.push(metrics?.successfulTransactionCount);
    failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedTransactionValue)
    );
    failedTransactionsByCount.push(metrics?.failedTransactionCount);
  });
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Successsful Transactions",
        data:
          valueType === "count"
            ? successfulTransactionsByCount
            : successfulTransactionsByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Failed Transactions",
        data:
          valueType === "count"
            ? failedTransactionsByCount
            : failedTransactionsByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return { chartOptions, chartData };
}

function formatBackofficeCurrentDayDisputesData(data, valueType = "count") {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Disputes by ${valueType}`,
      },
    },
  };
  const labels = Object.keys(data?.merchantMetrics);
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    const metrics = data?.merchantMetrics[label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Accepted Disputes",
        data:
          valueType === "count"
            ? acceptedDisputesByCount
            : acceptedDisputesByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Rejected Disputes",
        data:
          valueType === "count"
            ? rejectedDisputesByCount
            : rejectedDisputesByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return { chartOptions, chartData };
}

function formatFrontofficeCurrentDayDisputesData(data, valueType = "count") {
  let dataArr = [];
  if (valueType === "count") {
    dataArr.push(data?.backofficeAcceptedCount + data?.merchantAcceptedCount);
    dataArr.push(data?.backofficeRejectedCount + data?.merchantRejectedCount);
  } else {
    dataArr.push(
      getValueInMajor(
        data?.backofficeAcceptedValue + data?.merchantAcceptedValue
      )
    );
    dataArr.push(
      getValueInMajor(
        data?.backofficeRejectedValue + data?.merchantRejectedValue
      )
    );
  }
  const chartData = {
    labels: ["Accepted Disputes", "Rejected Disputes"],
    datasets: [
      {
        label: "Disputes",
        data: dataArr,
        backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  };
  return { chartOptions: null, chartData };
}

function formatBackofficeHistoricDisputesData(data, valueType = "count") {
  let chartOptions = getChartOptions(`Disputes by ${valueType}`);
  const labels = Object.keys(data?.disputeAnalysisDistributedByDays);
  const superagents = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays
  );
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    const metrics = data?.disputeAnalysisDistributedByDays[label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Accepted Disputes",
        data:
          valueType === "count"
            ? acceptedDisputesByCount
            : acceptedDisputesByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Rejected Disputes",
        data:
          valueType === "count"
            ? rejectedDisputesByCount
            : rejectedDisputesByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  let formattedSuperAgentArr = [
    {
      desc: "All superagents",
      code: "all",
    },
  ];
  superagents.forEach((agent) => {
    let agentObj = {
      desc: agent,
      code: agent,
    };
    formattedSuperAgentArr.push(agentObj);
  });
  return { chartOptions, chartData, formattedSuperAgentArr };
}

function formatSelectedSuperAgentDisputeData(
  data,
  superagent,
  valueType = "count"
) {
  let chartOptions = getChartOptions(`Disputes by ${valueType}`);
  const labels = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent]
  );
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    // const metrics = data?.disputeAnalysisDistributedByDays[label];
    const metrics =
      data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent][label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Accepted Disputes",
        data:
          valueType === "count"
            ? acceptedDisputesByCount
            : acceptedDisputesByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Rejected Disputes",
        data:
          valueType === "count"
            ? rejectedDisputesByCount
            : rejectedDisputesByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return { chartOptions, chartData };
}

function formatFrontofficeHistoricDisputesData(data, valueType = "count") {
  let chartOptions = getChartOptions(`Disputes by ${valueType}`);
  // const superagent = data?.disputeAnalysisDistributedBySuperAgentsByDays;
  const superagents = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays
  );
  const superagent = superagents[0];
  const labels = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent]
  );
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    const metrics =
      data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent][label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Accepted Disputes",
        data:
          valueType === "count"
            ? acceptedDisputesByCount
            : acceptedDisputesByValue,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Rejected Disputes",
        data:
          valueType === "count"
            ? rejectedDisputesByCount
            : rejectedDisputesByValue,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return { chartOptions, chartData };
}

export const DASHBOARD_HELPERS = {
  FORMAT_BACKOFFICE_CURRENT_DAY_TRANSACTIONS_DATA:
    formatBackofficeCurrentDayTransactionsData,
  FORMAT_FRONTOFFICE_CURRENT_DAY_TRANSACTIONS_DATA:
    formatFrontofficeCurrentDayTransactionsData,
  FORMAT_BACKOFFICE_HISTORIC_TRANSACTIONS_DATA:
    formatBackofficeHistoricTransactionsData,
  FORMAT_FRONTOFFICE_HISTORIC_TRANSACTIONS_DATA:
    formatFrontofficeHistoricTransactionsData,
  FORMAT_SELECTED_SUPERAGENT_TRANSACTIONS_DATA:
    formatSelectedSuperAgentTransactionsData,
  FORMAT_BACKOFFICE_CURRENT_DAY_DISPUTE_DATA:
    formatBackofficeCurrentDayDisputesData,
  FORMAT_FRONTOFFICE_CURRENT_DAY_DISPUTE_DATA:
    formatFrontofficeCurrentDayDisputesData,
  FORMAT_BACKOFFICE_HISTORIC_DISPUTES_DATA:
    formatBackofficeHistoricDisputesData,
  FORMAT_FRONTOFFICE_HISTORIC_DISPUTES_DATA:
    formatFrontofficeHistoricDisputesData,
  FORMAT_SELECTED_SUPERAGENT_DISPUTES_DATA: formatSelectedSuperAgentDisputeData,
};
