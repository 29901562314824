export const responseCodeConfig = {
  5: {
    code: 5,
    color: "#50B8EE",
  },
  9: {
    code: 9,
    color: "#f44435",
  },
  "00": {
    color: "#37AC00",
    code: "00",
  },
};
