import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";

export function NssSettlementSessionTransactions(props) {
  const { addNotification } = useNotification();
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const tableHeaders = [
    { label: "Masked PAN", value: "maskedPan" },
    { label: "Merchant ID", value: "merchantId" },
    { label: "Terminal Id", value: "terminalId" },
    { label: "Transaction Amount", value: "transactionAmount" },
    { label: "Transaction Net Amount", value: "netSettlementAmount" },
    { label: "Transaction Date", value: "transactionTime" },
    { label: "RRN", value: "rrn" },
    { label: "Stan", value: "stan" },
  ];

  const getTransactions = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
    });
    const url = `${BACK_OFFICE_API.NSS_SETTLEMENT_SESSIONS.NSS_SETTLEMENT_SESSION_TRANSACTIONS}/${props.details.code}/settlement-transactions`;
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`);
      setPagination(response);
      setTransactions(response?.content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    }
  }, [pageNo, addNotification, props.details.code]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  function reload() {
    setPageNo(0);
    getTransactions();
  }

  const renderTableView = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    }

    return (
      <CustomTable
        search={true}
        isReload={true}
        numberOfElements={pagination?.numberOfElements}
        totalPages={pagination?.totalPages}
        totalItems={pagination?.totalElements}
        currentPage={pageNo + 1}
        emptyText={"No transactions found!"}
        reload={reload}
        error={error}
        items={transactions}
        headers={tableHeaders}
        nextPage={() => {
          setPageNo(pageNo + 1);
        }}
        prevPage={() => setPageNo(pageNo - 1)}
        goToFirstPage={() => setPageNo(1)}
        goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
        goToPage={(pageNo) => setPageNo(pageNo)}
      />
    );
  };

  return <div>{renderTableView()}</div>;
}
