import React, { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { Icon } from "shared/components/icons/icon";
import { HELPER } from "core/helper/helper";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { THIRD_PARTY_ENUMS } from "shared/constants";
import { PartySourceInfo } from "../party-source-info/party-source-info";

export const NetworkDetails = (props) => {
  const [activeTabIndex] = useState(0);
  const NetworkTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={"Network Summary"}>
            <div className="p-text-left p-2">
              <div className="p-grid p-mt-2">
                <div className="p-col-8">
                  <FormattedDetails details={props.networkDetails} />
                </div>
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY("switch_fetch_party_source_sink_info") ? (
            <TabPanel header={"Connection Info"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <PartySourceInfo
                      partyName={props.networkDetails.name}
                      partyTypeId={props.networkCode}
                      partyType={THIRD_PARTY_ENUMS.NETWORK}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };
  const networkDetailsView = () => {
    switch (activeTabIndex) {
      case 0:
        return NetworkTabView();
      case 1:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="Loading details ..." />
          </div>
        );
      default:
        break;
    }
  };
  return <div>{networkDetailsView()}</div>;
};
