import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../api/service";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { Optional } from "../../../shared/components/optional/optional";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";

export function DisputeConfigForm(props) {
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [initialValues, setInitialValues] = useState({
    configName: "",
    configKey: "",
    configValue: "",
    configDesc: "",
    valueType: "",
  });

  const validationSchemaObject = {
    configName: Yup.string(),
    configKey: Yup.string(),
    configValue: Yup.string(),
    configDesc: Yup.string(),
    valueType: Yup.string(),
  };
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;

  useEffect(() => {
    if (props.isUpdate) {
      const { configName, configKey, configValue, configDesc, valueType } =
        props.disputeconfig;
      setInitialValues({
        configName,
        configKey,
        configValue,
        configDesc,
        valueType,
      });
    }
  }, [props.isUpdate, props.disputeconfig]);

  const handleResponse = () => {
    const message = "Dispute configuration successfully updated! ";
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const updateDisputeConfig = async (payload) => {
    const url = `${BACK_OFFICE_API.DISPUTE_CONFIG.UPDATE_DISPUTE_CONFIGURATION}/${props.disputeconfig.configKey}`;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const processSubmission = ({ status, ...rest }) => {
    setLoading(true);
    let formattedPayload = {
      ...rest,
      active: status,
    };
    updateDisputeConfig(formattedPayload);
 };

  const submit = async (payload) => {
    setToastType("");
    setMessage("");
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      processSubmission(payload);
    } else {
      const confirmationPayload = HELPER.TRIM_OBJECT(payload);
      setDetails(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
          onclick={handleClose}
        />
        <AppButton
          loading={loading}
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
        />
      </FormActions>
    );
  };
  const renderFormAlert = () => {
    return message ? (
      <CustomToast
        title={toastType === "error" ? "Error" : "Success"}
        description={message}
        type={toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };
  const renderViewDisputeConfig = () => {
    return (
      <Optional showIf={currentFormIndex === 1}>
        <p className="modal-title p-text-left mb-1">
          Dispute Configuration Details
        </p>
        <div className="custom-modal-item">
          <FormattedDetails details={details} />
        </div>
      </Optional>
    );
  };

  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
        <p className="modal-title p-text-left mb-2">{props.title}</p>

        <div className="custom-modal-item">
          <AppFormInput
            label="Config Name"
            name="configName"
            type="text"
            placeholder="Input configuration Name"
            disabled
          />
          <AppFormInput
            label="Config Description"
            name="configDesc"
            type="text"
            placeholder="Input a description institution"
            disabled
          />
          <AppFormInput
            label="Config Key"
            name="configKey"
            placeholder="Select config Key"
            type="text"
            disabled
          />
          <AppFormInput
            label="Config Value"
            name="configValue"
            type="text"
            placeholder="Input Config Value"
          />
        </div>
      </Optional>
    );
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderViewDisputeConfig()}
            {renderFormAlert()}
            {renderFormActions()}
          </Form>
        )}
      </Formik>
    </div>
  );
}
