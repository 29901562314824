import { BACK_OFFICE_API } from "api/backofffice/index";

export const CONFIGURATIONS = {
  DISPUTE_RESPONSE_CODES: "switch.dispute.response-codes",
};

export const ACCOUNT_SCHEMES = ["WALLET", "BANK_ACCOUNT", "GENERAL_LEDGER"];

export const CHARGE_TYPE_SCHEMES = ["FLAT", "PERCENT_ALL"];

export const THIRD_PARTY_ENUMS = {
  THIRD_PARTY: "THIRD_PARTY",
  NETWORK: "NETWORK",
  ACQUIRER: "ACQUIRER",
  ACQUIRER_PROCESSOR: "ACQUIRER_PROCESSOR",
  ISSUER: "ISSUER",
  ISSUER_PROCESSOR: "ISSUER_PROCESSOR",
};

export const TERMINAL_TYPES = [
  { name: "ATM", code: "ATM" },
  { name: "WEB", code: "WEB" },
  { name: "POS", code: "POS" },
];

export const MODAL_TYPES = {
  CREATE_MODAL:0,
  UPDATE_MODAL: 1,
  VIEW_MODAL: 2,  
  FILTER_MODAL: 3,
  OTHER_MODAL:4,
};

export const GENERAL_CLEARING_RULE_ENUMS = {
  fieldOptions: [
    { name: "Percentage Fee", value: "PERCENTAGE_FEE" },
    { name: "Surcharge Fee", value: "SURCHARGE_FEE" },
    { name: "Flat Fee", value: "FLAT_FEE" },
  ],
  rules: {
    percantageFee: "PERCENTAGE_FEE",
    surchargeFee: "SURCHARGE_FEE",
    flatFee: "FLAT_FEE",
  },
};

export const { EMAIL_REGEX, PHONE_NUMBER_REGEX } = {
  EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/,
  PHONE_NUMBER_REGEX:
    /^0((90[1-9])|(70[1-9])|(80[1-9])|((8|9)1[0-9]))\d{7}/,
};

export const FEE_RULE_IDENTIFIER_LOGICAL_OPERATORS = [
  { code: "EQUALS", name: "Equals" },
  { code: "NOT_EQUALS", name: "Not Equals" },
  { code: "ENDS_WITH", name: "Ends With" },
  { code: "STARTS_WITH", name: "Starts With" },
  { code: "NOT_STARTS_WITH", name: "Not Starts With" },
  { code: "CONTAINS", name: "Contains" },
  { code: "LESS_THAN", name: "Less Than" },
  { code: "GREATER_THAN", name: "Greater Than" },
  { code: "LESS_THAN_OR_EQUAL_TO", name: "Less Than Or Equal To" },
  { code: "GREATER_THAN_OR_EQUAL_TO", name: "Greater Than Or Equal To" },
];

export const CODE_TIP = `A Code is used to uniquely identify the entity. Enter a shortened format of the name with no spaces or special characters except hyphens (-) and underscores (_).`;

export const UserTypes = {
  FrontOffice: "FrontOffice",
  BackOffice: "BackOffice",
};

export const FAILED = "FAILED";
export const COMPLETED = "COMPLETED";
export const PENDING = "PENDING";
export const DECLINED = "DECLINED";
export const RESOLVED = "RESOLVED";
export const IN_PROGRESS = "IN_PROGRESS";

export const PARTY_TYPES = [
  {
    name: "Third Party",
    value: "THIRD_PARTY",
    apiRoute: BACK_OFFICE_API.THIRD_PARTY.GET_THIRD_PARTIES,
    optionLabel: "name",
    optionValue: "code",
  },
  {
    name: "Financial Institution",
    value: "FINANCIAL_INSTITUTION",
    apiRoute: BACK_OFFICE_API.FINANCIAL_INSITUTION.FINANCIAL_INSTITUTION_FILLER,
    optionLabel: "name",
    optionValue: "code",
  },
  {
    name: "Network",
    value: "NETWORK",
    apiRoute: BACK_OFFICE_API.NETWORKS.GET_NETWORKS_FILLER,
    optionLabel: "name",
    optionValue: "code",
  },
  {
    name: "Acquirer",
    value: "ACQUIRER",
    apiRoute: BACK_OFFICE_API.ACQUIRER.ACQUIRER_LIST,
    optionLabel: "financialInstitutionName",
    optionValue: "code",
  },
  {
    name: "Issuer",
    value: "ISSUER",
    apiRoute: BACK_OFFICE_API.ISSUERS.GET_ISSUERS_LIST,
    optionLabel: "name",
    optionValue: "code",
  },
  {
    name: "Issuer Processor",
    value: "ISSUER_PROCESSOR",
    apiRoute: BACK_OFFICE_API.ISSUER_PROCESSORS.ISSUER_PROCESSOR,
    optionLabel: "issuerName",
    optionValue: "code",
  },
];

export const DISPUTE_RESOLUTION_STATUSES = ["PENDING", "ACCEPTED", "DECLINED"];
export const DISPUTE_STATUSES = ["PENDING", "RESOLVED", "SYSTEM_ACCEPTED"];

export const DISPUTE_CONFIG_KEYS = {
  GET_DISPUTE_REASONS: "switch.dispute.reason.list",
  GET_MERCHANT_DISPUTEABLE_REASONS: "switch.dispute.merchant.response-codes",
};

export const GLOBAL_CONFIG_KEYS = {
  TRANSACTIONS_DOWNLOAD_DATE_RANGE: "switch.calendar.date-range-in-days",
};
