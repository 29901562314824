import { BACK_OFFICE_API } from "api/backofffice/index";
import React from "react";
import Disputes from "./disputes";

const TeamaptDisputes = () => {
  const url = BACK_OFFICE_API.DISPUTES.GET_DISPUTES;
  return <Disputes url={url} />;
};

export default TeamaptDisputes;
