import "./custom-confirm-dialog.css";
import { CustomLoader } from "../custom-loader/custom-loader";
import { CustomToast } from "../alert/custom-toast";
import { CustomMessage } from "../alert/custom-message";
import { useState } from "react";
import { HELPER } from "../../../core/helper/helper";
import { AppFormInput } from "../form-inputs/form-input";
import { Formik, Form } from "formik";

export function CustomConfirmDialog(props) {
  const [initialValues] = useState({
    value: "",
  });

  const handleSubmit = (data) => {
    if (props.confirmText === data.value) {
      const status = props.action === "DEACTIVATE" ? false : true;
      props.fn(status);
    }
  };

  const cancelButton = () => {
    if (props.loading) {
      return <div />;
    } else {
      return (
        <button
          type="button"
          onClick={props.closeModal}
          className="secondary-button"
        >
          Cancel
        </button>
      );
    }
  };

  const submitButton = () => {
    if (props.loading) {
      return (
        <div className="my-2">
          <CustomLoader loadingText={props.loadingText} />
        </div>
      );
    } else {
      return (
        <button type="submit" className="primary-button">
          {HELPER.TITLE_CASE(getActionText())}
        </button>
      );
    }
  };

  function getActionText() {
    switch (props.action) {
      case "DEACTIVATE":
        return "deactivate";
      case "ACTIVATE":
        return "activate";
      default:
        return "delete";
    }
  }

  function getConfirmActionText() {
    switch (props.action) {
      case "DEACTIVATE":
        return "deactivation";
      case "ACTIVATE":
        return "activation";
      default:
        return "deletion";
    }
  }

  const currentView = () => {
    if (props.loading) {
      return (
        <div className="my-2">
          <CustomLoader loadingText={props.loadingText} />
        </div>
      );
    } else if (props?.success) {
      return (
        <div className="mt-2 pb-1 d-flex flex-column align-items-center">
          <CustomMessage
            messageType="success"
            message={props.success}
            close={true}
            closeModal={props.closeModal}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="pb-1">
            <p className="confirm-text mt-0">
              Confirm that you want to {getActionText()} {props.confirmText}!
            </p>
          </div>
          <div style={{ marginTop: "-1.5em" }}>
            <p className="text-left">
              To confirm {getConfirmActionText()}, type{" "}
              <b> {props.confirmText}</b> in the text input field
            </p>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              enableReinitialize={true}
            >
              {() => (
                <Form>
                  <AppFormInput label="Enter value" name="value" type="text" />
                  <div
                    style={{ display: props?.toastError ? "block" : "none" }}
                    className="mt-1"
                  >
                    <CustomToast
                      title="Error"
                      description={props?.toastError}
                      type="error"
                    />
                  </div>
                  <div className="mt-4 pb-2">
                    <div className="grid">
                      <div className={props.loading ? "col-12" : "col-6"}>
                        {cancelButton()}
                      </div>
                      <div className={props.loading ? "col-12" : "col-6"}>
                        {submitButton()}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    }
  };

  return <div>{currentView()}</div>;
}
