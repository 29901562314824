import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";
import { HELPER } from "../../../core/helper/helper";
import { AppFormInput } from "shared/components/form-inputs/form-input";

export function AcquirerThirdPartyFilter(props) {
  const [initialValues] = useState({
    thirdPartyName: "",
  });
  const validationSchemaObject = {
    thirdPartyName: Yup.string(),
  };

  const submit = async ({ status, ...args }) => {
    const payload = HELPER.TRIM_OBJECT({ active: status, ...args });
    props.onGetAcquirerThirdParties(payload);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <AppFormInput
              label="Third Party Name"
              name="thirdPartyName"
              type="text"
              placeholder="Input third party name"
            />
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
