import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../api/service";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { Optional } from "../../../shared/components/optional/optional";
import { useAcquirersList } from "api/hooks/use-acquirers-list";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { TERMINAL_TYPES } from "shared/constants";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";

export function TerminalForm(props) {
  const { addNotification } = useNotification();
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const { acquirers } = useAcquirersList();
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [initialValues, setInitialValues] = useState({
    terminalId: "",
    terminalType: "",
    acquirerCode: "",
    accountNumber: "",
    accountName: "",
    from: "",
    to: "",
    status: "",
  });
  const validationSchemaObject = {
    terminalId: Yup.string()
      .max(10, `Too long, maximum length is 10 characters`)
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
      .required("Required"),
    terminalType: Yup.string().required("Required"),
    acquirerCode: Yup.string().required("Required"),
    accountNumber: Yup.string().max(
      50,
      "Account Number must be 50 characters or less"
    ),
    accountName: Yup.string().max(
      200,
      "Account Name must be 200 characters or less"
    ),

    from: Yup.string(),
    to: Yup.string(),
  };

  useEffect(() => {
    if (props.isUpdate) {
      const {
        terminalId,
        terminalType,
        acquirerCode,
        accountNumber,
        accountName,
        status,
      } = props.terminal;
      setInitialValues({
        terminalId,
        terminalType,
        acquirerCode,
        accountNumber,
        accountName,
        status,
      });
    }
  }, [props.isUpdate, props.terminal]);

  const handleResponse = (isUpdate) => {
    let notification = {
      type: "success",
      message: `Terminal has been successfully ${
        isUpdate ? "updated" : "created"
      }!`,
    };
    addNotification(notification);
    props.onSuccess();
    props.closeModal();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const createTerminal = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.TERMINAL.CREATE_TERMINALS;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateTerminal = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.TERMINAL.UPDATE_TERMINALS;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props.terminal.code}`,
        payload
      );
      const isUpdate = true;
      handleResponse(isUpdate);
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async (payload) => {
    setToastType("");
    setMessage("");
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      const { status, ...rest } = payload;
      setLoading(true);
      const formattedPayload = {
        ...rest,
        active: status,
        merchantId: props.merchantId,
      };
      if (props.isUpdate) {
        updateTerminal(formattedPayload);
      } else {
        createTerminal(formattedPayload);
      }
    } else {
      const { from, to, status, ...confirmationPayload } = payload;
      if (props.isUpdate) {
        setDetails({ ...confirmationPayload, status });
      } else {
        setDetails(confirmationPayload);
      }
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
          onclick={handleClose}
        />
        <AppButton
          loading={loading}
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
        />
      </FormActions>
    );
  };

  const renderFormAlert = () => {
    return message ? (
      <CustomToast
        title={toastType === "error" ? "Error" : "Success"}
        description={message}
        type={toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };

  const renderFormContent = (values, errors, setFieldValue, handleBlur) => {
    return (
      <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
        <p className="modal-title p-text-left ">{props.title}</p>
        <p className="modal-subtitle -mt-3">{props.subtitle}</p>
        <div className="custom-modal-item">
          <AppFormInput
            label="Terminal ID"
            name="terminalId"
            type="text"
            placeholder="Input Terminal Id"
            required
          />
          <AppFormDropdown
            label="Terminal Type"
            optionLabel="name"
            optionValue="code"
            placeholder="Input terminal type"
            options={TERMINAL_TYPES}
            error={errors?.terminalType}
            value={values.terminalType}
            onchange={(option) => {
              setFieldValue("terminalType", option?.value);
            }}
            field="terminalType"
            required
          />
          <AppFormDropdown
            value={values.acquirerCode}
            onchange={(e) => {
              setFieldValue("acquirerCode", e?.target?.value);
            }}
            options={acquirers}
            optionLabel="financialInstitutionName"
            optionValue="code"
            placeholder="Select Acquirer"
            label="Acquirer"
            error={errors?.acquirerCode}
            field="acquirerCode"
            filter
            required
          />
          <div className="grid">
            <div className="col-6 pt-2">
              <AppFormInput
                label="Account Number"
                name="accountNumber"
                type="text"
                placeholder="Input account number"
                required={false}
              />
            </div>
            <div className="col-6 pt-2">
              <AppFormInput
                label="Account Name"
                name="accountName"
                type="text"
                placeholder="Input account name"
                required={false}
              />
            </div>
          </div>
          <div className="col-6 pt-3">
            <Optional showIf={props.isUpdate}>
              <div>
                <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                  Status:
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="Status"
                    checked={values.status === true}
                    handleChange={(e) => setFieldValue("status", true)}
                    label="Active"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="Status"
                    checked={values.status === false}
                    handleChange={(e) => setFieldValue("status", false)}
                    label="Inactive"
                  />
                </div>
              </div>
            </Optional>
          </div>
        </div>
      </Optional>
    );
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <>
                <p className="modal-title p-text-left mb-1">
                  Confirm Terminal Details
                </p>
                <div className="custom-modal-item">
                  <FormattedDetails details={details} />
                </div>
              </>
            </Optional>
            {renderFormContent(values, errors, setFieldValue)}
            {renderFormAlert()}
            {renderFormActions()}
          </Form>
        )}
      </Formik>
    </div>
  );
}
