import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import {  useState } from "react";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { AppFormInput } from "shared/components/form-inputs/form-input";
import { HELPER } from "core/helper/helper";


export function FinancialInstitutionFilter(props) {
  const [initialValues] = useState({
    name: "",
    to: "",
    from: "",
  });
  const validationSchemaObject = Yup.object().shape({
    name: Yup.string(),
    to: Yup.string(),
    from: Yup.string(),
  });

  const submit = async (payload) => {
    props.onGetFinancialInstitution(HELPER.TRIM_OBJECT(payload));
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaObject}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
               <AppFormInput
              label="Name"
              name="name"
              type="text"
              placeholder="Enter financial Institution name"
              required={false}
            />
            <div className="flex w-full ">
              <div className="w-full pr-3">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  From:
                </label>
                <FormInput
                  name="From"
                  fn={(e) => setFieldValue("from", e.target.value)}
                  label="Start date"
                  inputType="date"
                  required={false}
                  value={values.from}
                  field="from"
                  type="INPUT"
                />
              </div>
              <div className="w-full">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  To:
                </label>
                <FormInput
                  name="To"
                  fn={(e) => setFieldValue("to", e.target.value)}
                  label="End date"
                  inputType="date"
                  required={false}
                  value={values.to}
                  field="to"
                  type="INPUT"
                />
              </div>
            </div>
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
