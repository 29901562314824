import "./custom-form.css";

export function FormCheckBoxInput(props) {
  return (
    <label htmlFor={props.id} className="label checkbox-label">
      <input
        disabled={props.disabled}
        id={props.id}
        value={props.value}
        name={props.name}
        type="checkbox"
        checked={props.checked}
        className="checkbox"
        onChange={(e) => props.handleChange(e)}
      />
      <span> {props.label}</span>
    </label>
  );
}
