import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import usePartySourceForm from "./use-party-source-form";
import { THIRD_PARTY_ENUMS } from "shared/constants";

export function PartySourceInfoForm(props) {
  const { form, actions, api } = usePartySourceForm(props);
  const {
    validationObj,
    initialValues,
    details,
    networks,
    FORM_VIEW_INDEX,
    currentFormIndex,
    toast,
  } = form;
  const { loading } = api;
  const { handleClose, submit } = actions;
  const { message, type: toastType } = toast;

  const renderFormActions = (values) => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={
            !props.isConnectionStep && currentFormIndex === FORM_VIEW_INDEX
              ? "Close"
              : "Back"
          }
          onclick={handleClose}
        />
        <AppButton
          loading={loading}
          type={props.isConnectionStep ? "button" : "submit"}
          onclick={props.isConnectionStep ? () => submit(values) : null}
          buttonStyle="primary"
          text={
            !props.isConnectionStep && currentFormIndex === FORM_VIEW_INDEX
              ? "Next"
              : "Submit"
          }
        />
      </FormActions>
    );
  };
  const renderFormAlert = () => {
    return message ? (
      <CustomToast
        title={toastType === "error" ? "Error" : "Success"}
        description={message}
        type={toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };
  const renderViewPartySourceInfo = () => {
    return (
      <Optional showIf={currentFormIndex === 1}>
        <p className="modal-title p-text-left mb-1">Connection Info Details</p>
        <div className="custom-modal-item">
          <FormattedDetails details={details} />
        </div>
      </Optional>
    );
  };

  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
        <p className="modal-title p-text-left ">{props.title}</p>
        <p className="modal-subtitle -mt-3">{props.subtitle}</p>
        <div className="custom-modal-item">
          <AppFormInput
            label="Sink Node ID"
            name="sinkNodeId"
            type="text"
            placeholder="Input sink node Id"
          />
          <AppFormInput
            label="Source Node ID"
            name="sourceNodeId"
            type="text"
            placeholder="Input source node Id"
          />
          <AppFormInput
            label="Fowarding Institution"
            name="forwardInstitutionId"
            type="text"
            placeholder="Input fowarding institution"
          />
          <AppFormInput
            label="Recieving Institution"
            name="receivingInstitutionId"
            type="text"
            placeholder="Input recieving institution"
          />
          <Optional showIf={props.partyType !== THIRD_PARTY_ENUMS.NETWORK}>
            <AppFormDropdown
              value={values.network}
              onchange={(e) => {
                setFieldValue("network", e?.target?.value);
              }}
              options={networks}
              optionLabel="name"
              optionValue="code"
              placeholder="Select network"
              label="Network"
              error={errors?.network}
              field="network"
            />
          </Optional>
          <Optional showIf={props?.partySourceInfo?.code && props?.isUpdate}>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                  display: "block",
                  color: "#566a7f",
                  textAlign: "left",
                }}
              >
                Status:
              </p>
              <div className="flex default p-mb-4">
                <FormRadioInput
                  id="active"
                  value={true}
                  name="Status"
                  checked={values.status === true}
                  handleChange={(e) => setFieldValue("status", true)}
                  label="Active"
                />
                <FormRadioInput
                  id="Inactive"
                  value={false}
                  name="Status"
                  checked={values.status === false}
                  handleChange={(e) => setFieldValue("status", false)}
                  label="Inactive"
                />
              </div>
            </div>
          </Optional>
        </div>
      </Optional>
    );
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationObj)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderViewPartySourceInfo()}
            {renderFormAlert()}
            {renderFormActions(values)}
          </Form>
        )}
      </Formik>
    </div>
  );
}
