import { CONFIG } from "core/configs/configs";

const GET_TRANSACTION_REPORTS = `/management/api/${CONFIG.API_VERSION}/filtered-record-report`;
const DOWNLOAD_TRANSACTION_REPORT = `/management/api/${CONFIG.API_VERSION}/filtered-record-report/download`;
const GENERATE_TRANSACTION_REPORTS = `/management/api/${CONFIG.API_VERSION}/filtered-record-report/generate`;
const GENERATE_THIRD_PARTY_TRANSACTION_REPORTS = `/management/api/${CONFIG.API_VERSION}/filtered-record-report/third-party/generate`;

export const TRANSACTION_REPORT_ROUTES = {
  GET_TRANSACTION_REPORTS,
  GENERATE_TRANSACTION_REPORTS,
  GENERATE_THIRD_PARTY_TRANSACTION_REPORTS,
  DOWNLOAD_TRANSACTION_REPORT,
};
