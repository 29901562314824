import { Formik, Form } from "formik";
import { AppButton } from "shared/components/app-button/app-button";
import { AppFormInput } from "shared/components/form-inputs/form-input";
import { FormActions } from "shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";
import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";
import { useAcquirersList } from "api/hooks/use-acquirers-list";
import { useIssuersList } from "api/hooks/use-issuers-list";
import { useThirdPartiesList } from "api/hooks/use-third-parties-list";
import { useNetworksList } from "api/hooks/use-networks-list";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";

export function SettlementRulesFilter(props) {
  const [initialValues] = useState({
    acquirerCode: "",
    issuerCode: "",
    networkCode: "",
    settlementAgent: "",
    thirdPartyCode: "",
    createdAt: "",
  });
  const validationSchemaObject = {
    acquirerCode: Yup.string(),
    issuerCode: Yup.string(),
    networkCode: Yup.string(),
    settlementAgent: Yup.string(),
    thirdPartyCode: Yup.string(),
    createdAt: Yup.string(),
  };

  const { acquirers } = useAcquirersList();
  const { issuers } = useIssuersList();
  const { thirdParties } = useThirdPartiesList();
  const { networks } = useNetworksList();
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const formattedThirdPartiesOptions = filteredThirdParties?.length
    ? filteredThirdParties
    : thirdParties?.content;

  const getValidFields = (payload) => {
    let filteredObj = {};
    for (const property in payload) {
      if (payload[property]) {
        filteredObj[property] = payload[property];
      }
    }
    return filteredObj;
  };

  const submit = async (payload) => {
    const filters = getValidFields(payload);
    props.onGetFeeSources(filters);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <AppFormDropdown
              value={values.acquirerCode}
              onchange={(e) => {
                setFieldValue("acquirerCode", e?.target?.value);
              }}
              options={acquirers}
              optionLabel="financialInstitutionName"
              optionValue="code"
              placeholder="Select Acquirer"
              label="Acquirer"
              error={errors?.acquirerCode}
              field="acquirerCode"
            />
            <AppFormDropdown
              value={values.issuerCode}
              onchange={(e) => {
                setFieldValue("issuerCode", e?.target?.value);
              }}
              options={issuers}
              optionLabel="name"
              optionValue="code"
              placeholder="Select Issuer"
              label="Issuer"
              error={errors?.issuerCode}
              field="issuerCode"
            />
            <ThirdPartiesDropdown
              values={values}
              onFilter={filterThirdPartiesByName}
              filter={true}
              isRequired={false}
              thirdParties={formattedThirdPartiesOptions}
              filtering={filtering}
              setFieldValue={setFieldValue}
              errors={errors}
            />

            <AppFormDropdown
              value={values.networkCode}
              onchange={(e) => {
                setFieldValue("networkCode", e?.target?.value);
              }}
              options={networks}
              optionLabel="name"
              optionValue="code"
              placeholder="Select Network"
              label="Network"
              error={errors?.networkCode}
              field="networkCode"
            />
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                  display: "block",
                  color: "#566a7f",
                  textAlign: "left",
                }}
              >
                Settlement Agent:
              </p>
              <div className="flex default p-mb-4">
                <FormRadioInput
                  id="NSS"
                  value="NSS"
                  name="settlementAgent"
                  checked={values.settlementAgent === "NSS"}
                  handleChange={(e) => setFieldValue("settlementAgent", "NSS")}
                  label="NSS"
                />
              </div>
            </div>

            <AppFormInput
              label="Created At"
              name="createdAt"
              type="date"
              placeholder="Created At"
              required={false}
            />

            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
