import React from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { NetworkForm } from "./network-form";
import { Optional } from "../../../shared/components/optional/optional";
import { NetworkDetails } from "./network-details";
import useNetworks from "./use-networks";
import { NetworkFilter } from "./network-filter";

export function ManageNetworks() {
  const { actions, page, api } = useNetworks();
  const {
    goToPage,
    incrementPageNo,
    decrementPageNo,
    handleTableAction,
    handleNetworkFilter,
    reload,
    closeModal,
    toggleModal,
    goBack,
  } = actions;
  const { getNetworks, loading, error, networks, pagination } = api;
  const {
    pageNo,
    tableHeaders,
    tableAuthorities,
    currentIndex,
    selectedNetwork,
    networkDetails,
    networkCode,
    modalIndex,
    isModalVisible,
  } = page;

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTableView();
      case 1:
        return (
          <NetworkDetails
            networkCode={networkCode}
            networkDetails={networkDetails}
            goBack={goBack}
          />
        );
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <NetworkForm
            title={"Create Network"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            onSuccess={getNetworks}
            isUpdate={false}
          />
        );
      case 1:
        return (
          <NetworkForm
            title={"Update Network"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            onSuccess={getNetworks}
            isUpdate={true}
            network={selectedNetwork}
          />
        );
        case 2:
        return (
          <NetworkFilter
            title={"Filter Network"}
            closeModal={closeModal}
            onGetNetworks={handleNetworkFilter}
          />
        );
      default:
        break;
    }
  }

  const renderTableView = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={tableAuthorities}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No networks found!"}
          search={true}
          reload={reload}
          error={error}
          items={networks}
          headers={tableHeaders}
          nextPage={incrementPageNo}
          prevPage={decrementPageNo}
          goToFirstPage={() => goToPage(0)}
          goToLastPage={() => goToPage(pagination?.totalPages - 1)}
          goToPage={goToPage}
          actions={["UPDATE", "OPEN"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div>
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
        />
      </>
      <PageTitle text="Networks" />
      <CustomBreadcrumb page="Manage Networks" />
      <PageActions>
        <Optional
          showIf={
            HELPER.HAS_AUTHORITY("switch_create_network") && currentIndex === 0
          }
        >
           <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => {
              toggleModal(2);
            }}
          />
          <AppButton
            text="New Network"
            type="button"
            buttonStyle="primary"
            onclick={() => {
              toggleModal(0);
            }}
          />
        </Optional>
      </PageActions>
      <>{currentPageView()}</>
    </div>
  );
}
