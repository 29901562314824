import { Formik, Form } from "formik";
import { AppButton } from "shared/components/app-button/app-button";
import { AppFormInput } from "shared/components/form-inputs/form-input";
import { FormActions } from "shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "shared/components/alert/custom-toast";
import { HELPER } from "core/helper/helper";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";
import { Optional } from "shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { useAcquirersList } from "api/hooks/use-acquirers-list";
import { useIssuersList } from "api/hooks/use-issuers-list";
import { useThirdPartiesList } from "api/hooks/use-third-parties-list";
import { useNetworksList } from "api/hooks/use-networks-list";
import { API_SERVICE } from "api/service";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";

export function SettlementRuleForm(props) {
  const { addNotification } = useNotification();
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    acquirerCode: "",
    issuerCode: "",
    networkCode: "",
    priority: "",
    settlementAgent: "",
    thirdPartyCode: "",
  });
  const validationSchemaObject = {
    acquirerCode: Yup.string().required("Required"),
    issuerCode: Yup.string().required("Required"),
    networkCode: Yup.string(),
    priority: Yup.number().required("Required"),
    settlementAgent: Yup.string().required("Required"),
    thirdPartyCode: Yup.string(),
    active: Yup.boolean(),
  };
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const { acquirers } = useAcquirersList();
  const { issuers } = useIssuersList();
  const { thirdParties } = useThirdPartiesList();
  const { networks } = useNetworksList();
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const formattedThirdPartiesOptions = filteredThirdParties?.length
    ? filteredThirdParties
    : thirdParties?.content;

  useEffect(() => {
    if (props.isUpdate) {
      const {
        acquirerCode,
        issuerCode,
        networkCode,
        priority,
        settlementAgent,
        thirdPartyCode,
        active,
      } = props.settlementRule;
      filterThirdPartiesByName(props.settlementRule.thirdPartyName);
      setInitialValues({
        acquirerCode,
        issuerCode,
        networkCode,
        priority,
        settlementAgent,
        thirdPartyCode,
        active,
      });
    }
  }, [props.isUpdate, props.settlementRule, filterThirdPartiesByName]);

  const handleResponse = (isCreate = false) => {
    let notification = {
      type: "success",
      message: `Settlement rule successfully ${
        isCreate ? "created" : "updated"
      }!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const createSettlementRule = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.SETTLEMENT_RULE.SETTLEMENT_RULE;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateSettlementRule = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.SETTLEMENT_RULE.SETTLEMENT_RULE;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.settlementRule?.code}`,
        payload
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const getNameFromCode = (code, list) => {
    const foundItem = list.find((item) => item.code === code);
    return foundItem?.name || foundItem?.financialInstitutionName;
  };

  const submit = async (payload, resetFormFuntion) => {
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      if (props.isUpdate) {
        updateSettlementRule(payload);
      } else {
        createSettlementRule(payload);
      }
    } else {
      let {
        active,
        acquirerCode,
        issuerCode,
        networkCode,
        thirdPartyCode,
        ...rest
      } = payload;
      let confirmationPayload = {
        acquirer: getNameFromCode(acquirerCode, acquirers),
        issuer: getNameFromCode(issuerCode, issuers),
        network: getNameFromCode(networkCode, networks),
        thirdParty: getNameFromCode(thirdPartyCode, thirdParties?.content),
        ...rest,
        ...(props.isUpdate && {
          status: payload.active ? "Active" : "Inactive",
        }),
      };
      setFormValues(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const renderForm = () => {
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchemaObject)}
          onSubmit={(values) => {
            submit(values);
          }}
          enableReinitialize={true}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <Optional
                showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
              >
                <FormattedDetails details={formValues} />
              </Optional>
              <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
                <AppFormDropdown
                  value={values.acquirerCode}
                  onchange={(e) => {
                    setFieldValue("acquirerCode", e?.target?.value);
                  }}
                  options={acquirers}
                  optionLabel="financialInstitutionName"
                  optionValue="code"
                  placeholder="Select Acquirer"
                  label="Acquirer"
                  error={errors?.acquirerCode}
                  field="acquirerCode"
                  required
                  filter
                />
                <AppFormDropdown
                  value={values.issuerCode}
                  onchange={(e) => {
                    setFieldValue("issuerCode", e?.target?.value);
                  }}
                  options={issuers}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select Issuer"
                  label="Issuer"
                  error={errors?.issuerCode}
                  field="issuerCode"
                  required
                  filter
                />
                <ThirdPartiesDropdown
                  values={values}
                  onFilter={filterThirdPartiesByName}
                  filter={true}
                  isRequired={false}
                  thirdParties={formattedThirdPartiesOptions}
                  filtering={filtering}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
                <AppFormDropdown
                  value={values.networkCode}
                  onchange={(e) => {
                    setFieldValue("networkCode", e?.target?.value);
                  }}
                  options={networks}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select Network"
                  label="Network"
                  error={errors?.networkCode}
                  field="networkCode"
                  filter
                />
                <div>
                  <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    Settlement Agent: <span style={{ color: "red" }}> * </span>
                  </p>
                  <div className="flex default p-mb-4">
                    <FormRadioInput
                      id="NSS"
                      value="NSS"
                      name="settlementAgent"
                      checked={values.settlementAgent === "NSS"}
                      handleChange={(e) =>
                        setFieldValue("settlementAgent", "NSS")
                      }
                      label="NSS"
                    />
                  </div>
                </div>
                <AppFormInput
                  label="Priority"
                  name="priority"
                  type="number"
                  placeholder="Priority"
                  required={true}
                />
                <Optional showIf={props.isUpdate}>
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        marginBottom: "0.5rem",
                        display: "block",
                        color: "#566a7f",
                        textAlign: "left",
                      }}
                    >
                      Status: <span style={{ color: "red" }}> * </span>
                    </p>
                    <div className="flex default p-mb-4">
                      <FormRadioInput
                        id="active"
                        value={true}
                        name="Status"
                        checked={values.active === true}
                        handleChange={(e) => setFieldValue("active", true)}
                        label="Active"
                      />
                      <FormRadioInput
                        id="Inactive"
                        value={false}
                        name="Status"
                        checked={values.active === false}
                        handleChange={(e) => setFieldValue("active", false)}
                        label="Inactive"
                      />
                    </div>
                  </div>
                </Optional>
              </Optional>

              {message && (
                <CustomToast
                  title={toastType === "error" ? "Error" : "Success"}
                  description={message}
                  type={toastType}
                  closeable={false}
                  inApp={true}
                />
              )}
              <FormActions>
                <AppButton
                  type="button"
                  buttonStyle="secondary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                  }`}
                  onclick={handleClose}
                />
                <AppButton
                  type="submit"
                  buttonStyle="primary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"
                  }`}
                  loading={loading}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <>{renderForm()}</>;
}
