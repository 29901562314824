import "./custom-loader.css";
import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

export function CustomLoader(props) {
  return (
    <div style={{ padding: "10rem 0", textAlign: "center" }}>
      <div>
        <ProgressSpinner
          style={{ width: "25px", height: "25px" }}
          strokeWidth="4"
        />
      </div>
      <p style={{ fontSize: "0.9rem", fontWeight: "400" }}>
        {props.loadingText}
      </p>
    </div>
  );
}
