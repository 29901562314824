import React, { useState } from "react";
import { CustomTable } from "shared/components/custom-table/custom-table";
import { Icon } from "shared/components/icons/icon";

const TransactionDisputeHistoryLog = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [totalPages] = useState(1);
  const tableHeaders = [
    { label: "Order", value: "order" },
    { label: "Status", value: "status" },
    { label: "Action Type", value: "actionType" },
    { label: "Log Time", value: "logTime" },
    { label: "Updated By", value: "updatedBy" },
  ];

  const reload = () => {};

  return (
    <div className="px-4">
      <p onClick={() => props.goBack()} className="close-modal add-cursor ml-">
        <Icon icon="go-back-icon" />
      </p>

      <div className="p-grid p-mt-2">
        <div className="p-col-8" />
      </div>
      <CustomTable
        totalPages={totalPages}
        totalItems={props.logs.length}
        currentPage={pageNo + 1}
        emptyText={"No results found!"}
        reload={reload}
        error={null}
        items={props.logs}
        headers={tableHeaders}
        nextPage={() => {
          setPageNo(pageNo + 1);
        }}
        prevPage={() => setPageNo(pageNo - 1)}
        goToFirstPage={() => setPageNo(1)}
        goToLastPage={() => setPageNo(totalPages - 1)}
        goToPage={(pageNo) => setPageNo(pageNo)}
      />
    </div>
  );
};

export default TransactionDisputeHistoryLog;
