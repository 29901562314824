import { useEffect, useRef } from "react";
import { useNotification } from "../../../core/hooks/useNotification";
import { Toast } from "primereact/toast";

export function AppNotification() {
  const { message, type, clearNotificationState } = useNotification();
  const toast = useRef(null);
  useEffect(() => {
    let timeout;
    if (message) {
      timeout = setTimeout(() => {
        clearNotificationState();
      }, 10000);
      toast.current.show({
        severity: type,
        summary: type === "error" ? "Error" : "Success",
        detail: message,
        life: 10000,
      });
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [message, type, clearNotificationState]);

  return <Toast ref={toast} />;
}
