import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";
import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { HELPER } from "core/helper/helper";
import { useFinancialInstitutionsList } from "api/hooks/use-financial-institutions-list";

export function NssConfigurationsFilter(props) {
  const { financialInstitutions } = useFinancialInstitutionsList();

  const [initialValues] = useState({
    name: "",
    finInstCode: "",
    from: "",
    to: "",
  });
  const validationSchemaObject = {
    name: Yup.string(),
    finInstCode: Yup.string(),
    from: Yup.string(),
    to: Yup.string(),
  };

  const submit = (payload) => {
    const trimmedPayload = HELPER.TRIM_OBJECT(payload);
    props.onFilter(trimmedPayload);
    props.closeModal();
  };

  return (
    <div>
      <p className="modal-title p-text-left mb-1">Filter NSS Configuration</p>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <AppFormInput
              label="Name"
              name="name"
              type="text"
              placeholder="Name"
            />

            <AppFormDropdown
              value={values.finInstCode}
              onchange={(e) => {
                setFieldValue("finInstCode", e?.target?.value);
              }}
              options={financialInstitutions}
              optionLabel="name"
              optionValue="code"
              placeholder="Select Financial Institution"
              label="Financial Institution"
              error={errors?.finInstCode}
              field="finInstCode"
              filter
            />
            <div className="grid">
              <div className="col-6">
                <AppFormInput
                  name="From"
                  label="From"
                  placeholder="From"
                  type="date"
                />
              </div>
              <div className="col-6">
                <AppFormInput
                  name="To"
                  label="To"
                  placeholder="To"
                  type="date"
                />
              </div>
            </div>

            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
