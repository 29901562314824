import { BACK_OFFICE_API } from "api/backofffice/index";
import { useEffect, useState } from "react";
import { useList } from "./use-list";

export function useAcquirersList() {
  const [acquirers, setAcquirers] = useState(null);
  const {
    list: acquirersList,
    loading,
    error,
  } = useList(BACK_OFFICE_API.ACQUIRER.ACQUIRER_LIST);

  useEffect(() => {
    if (acquirersList) {
      setAcquirers(acquirersList);
    }
  }, [acquirersList]);

  return { acquirers, loading, error };
}
