import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { HELPER } from "../../../core/helper/helper";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { Optional } from "../../../shared/components/optional/optional";
import { API_SERVICE } from "api/service";
import { TerminalForm } from "./terminal-form";
import { TerminalFilter } from "./terminal-filter";

export function Terminals(props) {
  const { addNotification } = useNotification();
  const [modalIndex, setModalIndex] = useState(0);
  const [currentIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [terminals, setTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [actions] = useState(["UPDATE"]);
  const [authorities] = useState([
    { label: "UPDATE", value: "switch_update_a_terminal" },
  ]);
  const [modalInfo, setModalInfo] = useState({
    title: "",
    subtitle: "",
  });
  const [filterParams, setFilterParams] = useState(null);
  const tableHeaders = [
    { label: "Terminal Id", value: "terminalId" },
    { label: "Terminal Type", value: "terminalType" },
    { label: "Status", value: "active" },
    { label: "Created Date", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];

  const handleResponse = useCallback((response) => {
    setPagination(response?.result);
    setTerminals(response?.result?.content);
    setError(null);
    setLoading(false);
  }, []);

  const handleError = useCallback(
    (error) => {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    },
    [addNotification]
  );

  const getTerminals = useCallback(
    async (filters) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        merchantId: props.merchantId,
        ...filters,
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.TERMINAL.GET_TERMINALS}?${params}`
        );
        handleResponse(response);
      } catch (error) {
        handleError(error);
      }
    },
    [pageNo, handleResponse, handleError, props.merchantId]
  );

  useEffect(() => {
    if (!filterParams) {
      getTerminals();
    } else {
      getTerminals(filterParams);
    }
  }, [getTerminals, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getTerminals();
    }
  }
  const handleTerminalsFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  const handleTableAction = (data, action) => {
    const { active, updatedAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Terminal",
          subtitle: "Fields with * are required",
        });
        setSelectedTerminal({ ...rest, status: active, updatedAt });
        toggleModal(1);
        break;
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <TerminalForm
            title={"Create Terminal"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isCreate={true}
            merchantId={props.merchantId}
            onSuccess={getTerminals}
          />
        );
      case 1:
        return (
          <TerminalForm
            title={"Update Terminal"}
            subtitle={"Fields with * are required"}
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getTerminals}
            merchantId={props.merchantId}
            terminal={selectedTerminal}
          />
        );
      case 2:
        return (
          <TerminalFilter
            title={"Filter Terminals"}
            subtitle={""}
            onGetTerminals={handleTerminalsFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={authorities}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No Terminals found!"}
          search={true}
          reload={reload}
          error={error}
          items={terminals}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={actions}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  return (
    <div className="manage-terminal">
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <Optional showIf={HELPER.HAS_AUTHORITY("switch_save_a_terminal")}>
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => {
              toggleModal(2);
            }}
          />
          <AppButton
            text="New Terminal"
            type="button"
            buttonStyle="primary"
            onclick={() => {
              toggleModal(0);
              setModalInfo({
                title: "Create a new Terminal",
                subtitle: "All fields are required",
              });
            }}
          />
        </PageActions>
      </Optional>
      <>{currentPageView()}</>
    </div>
  );
}
