import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";
import { EditConfiguration } from "./edit-configuration";
import { MODAL_TYPES } from "shared/constants";

export function GlobalConfigurations() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [currentIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Name", value: "configName" },
    { label: "Description", value: "configDesc" },
    { label: "Value", value: "configValue" },
    { label: "Actions", value: "actions" },
  ];
  const [globalConfigurations, setGlobalConfigurations] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [actions] = useState(["UPDATE", "VIEW"]);
  const [authorities] = useState([
    { label: "UPDATE", value: "switch_update_global_config" },
    { label: "VIEW", value: "all" },
  ]);

  const handleResponse = useCallback((response) => {
    setPagination(response?.result);
    setGlobalConfigurations(response?.result?.content);
    setError(null);
    setLoading(false);
  }, []);

  const handleError = useCallback(
    (error) => {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    },
    [addNotification]
  );

  const getGlobalConfigurations = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
    });
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.CONFIGURATION.CONFIGURATIONS}?${params}`
      );
      handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  }, [pageNo, handleResponse, handleError]);

  useEffect(() => {
    getGlobalConfigurations();
  }, [getGlobalConfigurations]);

  function reload() {
    setPageNo(0);
    getGlobalConfigurations();
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getGlobalConfigurations();
    }
  }

  const handleTableAction = (data, action) => {
    const { active, updatedAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Confiuration",
          subtitle: "All fields are required",
        });
        setSelectedConfig({ ...rest, active, updatedAt });
        toggleModal(MODAL_TYPES.UPDATE_MODAL);
        break;
      case "VIEW":
        setModalInfo({
          title: "View Configuration",
          subtitle: "",
        });
        const { code, valueType, ...others } = rest;
        setSelectedConfig({
          ...others,
          status: active ? "Active" : "Inactive",
        });
        toggleModal(MODAL_TYPES.VIEW_MODAL);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };
  function modalContent() {
    switch (modalIndex) {
      case MODAL_TYPES.UPDATE_MODAL:
        return (
          <EditConfiguration
            title={"Update Configuration"}
            subtitle={""}
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getGlobalConfigurations}
            config={selectedConfig}
          />
        );
      case MODAL_TYPES.VIEW_MODAL:
        return (
          <div>
            <p className="modal-title p-text-left">View Configuration</p>
            <FormattedDetails details={selectedConfig} />
          </div>
        );
      default:
        break;
    }
  }
  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={authorities}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No configurations found!"}
          search={true}
          reload={reload}
          error={error}
          items={globalConfigurations}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={actions}
          onPerformAction={handleTableAction}
        />
      );
    }
  };
  return (
    <div className="manage-configurations">
      <>
        <CustomModal
          hasOwnModalHeader={true}
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Configurations" />
      <CustomBreadcrumb page="Manage Configurations" />
      <>{currentPageView()}</>
    </div>
  );
}
