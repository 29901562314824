import React from "react";
import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormInput } from "shared/components/form-component/form-input";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";
import { HELPER } from "core/helper/helper";

export function DisputeConfigurationFilter(props) {
  const initialValues = {
   configKey: "",
    active: "",
    to: "",
    from: "",
  };

  const validationSchemaObject = Yup.object().shape({
    configKey: Yup.string(),
    active: Yup.string(),
    to: Yup.string(),
    from: Yup.string(),
  });
  const submit = async ({ status, ...args }) => {
    const values = HELPER.TRIM_OBJECT({ active: status, ...args });
    props.onGetDisputeConfiguration (values);
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaObject}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ values,  setFieldValue }) => (
          <Form>
            <p className="modal-title p-text-left ">{props.title}</p>
            <AppFormInput
              label="Confiuration Key"
              name="configKey"
              type="text"
              placeholder="Enter dispute Configuration Key"
              required={false}
            />
            <div className="flex w-full ">
              <div className="w-full pr-3">
                <label className="date-status-style">
                  From:
                </label>
                <FormInput
                  name="From"
                  fn={(e) => setFieldValue("from", e.target.value)}
                  label="Start date"
                  inputType="date"
                  required={false}
                  value={values.from}
                  field="from"
                  type="INPUT"
                />
              </div>
              <div className="w-full">
                <label className="date-status-style" >
                  To:
                </label>
                <FormInput
                  name="To"
                  fn={(e) => setFieldValue("to", e.target.value)}
                  label="End date"
                  inputType="date"
                  required={false}
                  value={values.to}
                  field="to"
                  type="INPUT"
                />
              </div>
            </div>
            <div className="pt-3">
              <div>
                <p className="date-status-style">
                  Status:
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="Status"
                    checked={values.active === true}
                    handleChange={(e) => setFieldValue("active", true)}
                    label="Active"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="Status"
                    checked={values.active === false}
                    handleChange={(e) => setFieldValue("active", false)}
                    label="Inactive"
                  />
                </div>
              </div>
            </div>


            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
