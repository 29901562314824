import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../api/service";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { useNotification } from "../../../core/hooks/useNotification";
import { useAcquirersList } from "api/hooks/use-acquirers-list";

export function ThirdPartyAcquirerForm(props) {
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();
  const [initialValues, setInitialValues] = useState({
    acquirerCode: "",
    acquirerInstitutionId: "",
  });
  const [validationSchemaObject] = useState({
    acquirerInstitutionId: Yup.string()
      .required("Acquirer institution is required")
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
      .max(11, "Acquirer institution cannot exceed 11 characters!"),
    acquirerCode: Yup.string().required("Acquirer is required"),
  });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [formValues, setFormValues] = useState(null);
  const { acquirers } = useAcquirersList();

  useEffect(() => {
    if (props.isUpdate) {
      const { acquirerCode, acquirerInstitutionId } =
        HELPER.changeNullValuesToEmptyStrings(props.acquirer);
      setInitialValues({
        acquirerCode,
        acquirerInstitutionId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isUpdate]);

  const handleResponse = (isCreate) => {
    const message = `Acquirer successfully ${
      isCreate ? "associated" : "updated"
    }!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const createAcquirer = async (payload) => {
    const url =
      BACK_OFFICE_API.THIRD_PARTY_ACQUIRERS.CREATE_THIRD_PARTY_ACQUIRER;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateAcquirer = async (payload) => {
    const url =
      BACK_OFFICE_API.THIRD_PARTY_ACQUIRERS.UPDATE_THIRD_PARTY_ACQUIRER;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.acquirer?.code}`,
        payload
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async ({ status, ...payload }) => {
    setToastType("");
    setMessage("");
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      let formattedPayload = {
        thirdPartyCode: props.thirdPartyCode,
        thirdPartyName: props.thirdPartyName,
        ...payload,
      };
      setLoading(true);
      if (props.isUpdate) {
        updateAcquirer(formattedPayload);
      } else {
        createAcquirer(formattedPayload);
      }
    } else {
      let confirmationPayload = {
        ...payload,
      };
      setFormValues(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
          onclick={handleClose}
        />
        <AppButton
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
          loading={loading}
        />
      </FormActions>
    );
  };
  const renderFormAlert = () => {
    if (toastType === "error") {
      return (
        <CustomToast
          title={toastType === "error" ? "Error" : "Success"}
          description={message}
          type={toastType}
          closeable={false}
          inApp={true}
        />
      );
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
              <p className="modal-title p-text-left ">
                Associate Acquirer To Third Party
              </p>
              <p className="modal-subtitle -mt-3">All fields are required</p>
              <AppFormDropdown
                value={values.acquirerCode}
                onchange={(e) => {
                  setFieldValue("acquirerCode", e?.target?.value);
                }}
                options={acquirers}
                optionLabel="financialInstitutionName"
                optionValue="code"
                placeholder="Select Acquirer"
                label="Acquirer"
                error={errors?.acquirerCode}
                field="acquirerCode"
                required
              />
              <AppFormInput
                required={true}
                label="Acquirer Institution ID"
                name="acquirerInstitutionId"
                type="text"
                placeholder="Acquirer Institution Id"
                disabled={false}
              />
            </Optional>
            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <>
                <p className="modal-title p-text-left mb-1">Confirm Details</p>
                <div className="custom-modal-item">
                  <FormattedDetails details={formValues} />
                </div>
              </>
            </Optional>
            {renderFormAlert()}
            {renderFormActions(errors)}
            {/* <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
                onclick={handleClose}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
                loading={loading}
              />
            </FormActions> */}
          </Form>
        )}
      </Formik>
    </div>
  );
}
