import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { ConfirmationDialog } from "shared/components/confirmation-dialog/confirmation-dialog";
import { saveAs } from "file-saver";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";
import { PENDING } from "shared/constants";

export function TransactionReports() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Generate Fee Report",
    subtitle: "Fields marked * are required",
  });
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Report Generation Date", value: "createdAt" },
    { label: "Completion Date", value: "completedAt" },
    { label: "Percentage Processed", value: "percentageProcessed" },
    { label: "Status", value: "reportGenerationStatus" },
    { label: "Actions", value: "actions" },
  ];
  const [transactionReports, setTransactionReports] = useState([]);
  const [selectedTransactionReports, setSelectedTransactionReports] =
    useState(null);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [error, setError] = useState(null);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const waitTimeBeforeReloadInSeconds = useRef(5);

  const getTransactionReports = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: DEFAULT_PAGE_SIZE,
    });
    let timeout;
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.TRANSACTION_REPORT.GET_TRANSACTION_REPORTS}?${params}`
      );
      setPagination(response?.result);
      const transactionReports = response?.result?.content;

      const needsToGetUpdatedStatus = transactionReports.some(
        (transactionReport) =>
          transactionReport.reportGenerationStatus === PENDING
      );
      // this could be better -- I need ideas
      if (needsToGetUpdatedStatus) {
        timeout = setTimeout(() => {
          getTransactionReports();
        }, waitTimeBeforeReloadInSeconds.current * 1000);
      } else {
        if (timeout) {
          clearTimeout(timeout);
        }
      }
      setTransactionReports(transactionReports);
      setError(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
    }
  }, [pageNo]);

  useEffect(() => {
    getTransactionReports();
  }, [getTransactionReports]);

  const downloadTransactionReport = async () => {
    setDownloadingReport(true);
    const { code } = selectedTransactionReports;
    const url = `${BACK_OFFICE_API.TRANSACTION_REPORT.DOWNLOAD_TRANSACTION_REPORT}/${code}`;
    const isBlob = true;

    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(url, isBlob);
      saveAs(response, `Transaction-Report-Record-Id=${code}.xlsx`);
      closeModal();
      addNotification({
        type: "success",
        message: "File download in progress!",
      });
    } catch (error) {
      const reader = new FileReader();
      reader.onload = function () {
        const paresedError = JSON.parse(reader.result);
        const formattedError = { data: paresedError };
        addNotification({
          message: HELPER.PROCESS_ERROR(formattedError),
          type: "error",
        });
        closeModal();
        setDownloadingReport(false);
      };
      reader.readAsText(error.data);
    }
  };

  function reload() {
    getTransactionReports();
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    setIsModalVisible(false);
    if (shouldReload === true) {
      getTransactionReports();
    }
  }

  const handleTableAction = (data, action) => {
    switch (action) {
      case "DOWNLOAD":
        setDownloadingReport(false);
        setModalInfo({
          title: "Download Report!",
          subtitle: "",
        });
        setSelectedTransactionReports(data);
        toggleModal(1);
        break;
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        break;
      case 1:
        return (
          <ConfirmationDialog
            confirmationText="Please confirm that you want to download this report."
            onClose={closeModal}
            onSubmit={downloadTransactionReport}
            loading={downloadingReport}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[{ label: "DOWNLOAD", value: "all" }]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No transactions reports found!"}
          search={true}
          reload={reload}
          error={error}
          items={transactionReports}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["DOWNLOAD"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-transaction-reports">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Transaction Reports" />
      <CustomBreadcrumb page="Manage Transaction Reports" />
      <>{renderTable()}</>
    </div>
  );
}
