import React from "react";
import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";


export function NetworkFilter(props) {
  const initialValues = {
    name: "",
  };

  const validationSchemaObject = Yup.object().shape({
    name: Yup.string(),
  });

  const submit = (values) => {
    props.onGetNetworks(values);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaObject}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <p className="modal-title p-text-left ">{props.title}</p>
            <AppFormInput
              label="Name"
              name="name"
              type="text"
              placeholder="Enter Network name"
              required={false}
            />
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
