import { createContext, useCallback, useState } from "react";

export const NotificationContext = createContext({
  type: "",
  message: "",
  addNotification: () => {},
  clearNotificationState: () => {},
});

export function NotificationProvider({ children }) {
  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const contextValue = {
    message,
    clearNotificationState: () => {
      setMessage("");
      setType("");
    },
    addNotification: useCallback((payload) => {
      setMessage(payload.message);
      setType(payload.type || "success");
    }, []),
    type,
  };
  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}
