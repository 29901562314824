import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { Icon } from "shared/components/icons/icon";

export const PartySourceInfoDetails = (props) => {
  if (props.partySourceInfo) {
    const { status, code, ...rest } = props.partySourceInfo;
    return <FormattedDetails details={{ ...rest }} />;
  } else if (props.error) {
    return (
      <div className="text-center">
        <div className="empty-container">
          <Icon icon="error-message-icon" />
          <div className="text-center">
            <p className="empty-text notification-tex">{props.error}</p>
          </div>
          <div className="mt-3">
            <button onClick={props.reload} className="primary-button">
              Reload
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="empty-container">
          <div className="adjust-empty-icon">
            <Icon icon="no-item" />
          </div>
          <div>
            <p className="empty-text-two">{props.emptyText}</p>
          </div>
        </div>
      </div>
    );
  }
};
