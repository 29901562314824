import { useCallback, useEffect, useState } from "react";
import { AppButton } from "shared/components/app-button/app-button";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { FormActions } from "shared/components/utilities/form-actions";
import { PartyUserUpdateForm } from "./party-user-update-form";
import { useNotification } from "core/hooks/useNotification";
import { HELPER } from "core/helper/helper";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { BACK_OFFICE_API } from "api/backofffice/index";
import "./party-users.css";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";

export function PartyUserDetails(props) {
  const [shouldShowUpdateForm, setShouldShowUpdateForm] = useState(false);
  const [updateAction, setUpdateAction] = useState("");
  const [partyUser, setPartyUser] = useState({});
  const [loading, setLoading] = useState(null);
  const { addNotification } = useNotification();

  const getPartyUserDetails = useCallback(async () => {
    setLoading(true);
    setShouldShowUpdateForm(false);
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.PARTY_USERS.GET_SINGLE_PARTY_USER}/${props.partyUser.code}`
      );
      let { partyInfo, ...remainingInfo } = response?.result;
      setPartyUser({
        ...remainingInfo,
        ...partyInfo,
      });
      setLoading(false);
    } catch (error) {
      addNotification({
        type: "error",
        message: HELPER.PROCESS_ERROR(error),
      });
      props.closeModal();
      setLoading(false);
    }
  }, [addNotification, props]);

  useEffect(() => {
    getPartyUserDetails();
  }, [getPartyUserDetails]);

  const handleUpdateStatus = () => {
    setUpdateAction(UPDATE_USER_STATUS);
    setShouldShowUpdateForm(true);
  };

  const handleResetPassword = () => {
    setUpdateAction(RESET_PASSWORD);
    setShouldShowUpdateForm(true);
  };

  if (shouldShowUpdateForm) {
    return (
      <PartyUserUpdateForm
        goBack={() => setShouldShowUpdateForm(false)}
        partyUser={partyUser}
        onSuccess={getPartyUserDetails}
        updateAction={updateAction}
      />
    );
  }

  if (loading) {
    return <CustomLoader loadingText="Getting party user details..." />;
  }

  return (
    <div className="party-user-details">
      <p className="modal-title p-text-left mb-1">View Party User Details</p>
      <p className="modal-subtitle mt-0 mb-1">Find party user details below</p>
      <FormattedDetails details={partyUser} />
      <FormActions>
        <AppButton
          buttonStyle="bare"
          text={partyUser?.active ? "Disable" : "Enable"}
          onclick={handleUpdateStatus}
        />
        <AppButton
          buttonStyle="bare"
          text="Reset Password"
          onclick={handleResetPassword}
        />
      </FormActions>
    </div>
  );
}
