import { BACK_OFFICE_API } from "api/backofffice/index";
import { useEffect, useState } from "react";
import { useList } from "./use-list";

export function useIssuersList() {
  const [issuers, setIssuers] = useState(null);
  const {
    list: issuersList,
    loading,
    error,
  } = useList(BACK_OFFICE_API.ISSUERS.GET_ISSUERS_LIST);

  useEffect(() => {
    if (issuersList) {
      setIssuers(issuersList);
    }
  }, [issuersList]);

  return { issuers, loading, error };
}
