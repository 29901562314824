import React from "react";
import { Icon } from "shared/components/icons/icon";
import { TabPanel, TabView } from "primereact/tabview";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { HELPER } from "core/helper/helper";
import { NssSettlementSessionTransactions } from "./nss-settlement-session-transactions";
import { NssNetSettlementSummaries } from "./net-summaries";

const NssSettlementSessionsDetails = (props) => {
  const renderNssSettlementSessionDetails = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left ml-3 p-mt-2 p-pb-3">
          <span onClick={() => props.goBack(0)} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={"Settlement Session Info"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
              </div>
              <div className="centralize">
                <div className="p-2">
                  <FormattedDetails details={props.details} />
                </div>
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY(
            "switch_get_paginated_nss_settlement_summaries"
          ) ? (
            <TabPanel header={"Net Settlement Summaries"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <NssNetSettlementSummaries {...props} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
          {HELPER.HAS_AUTHORITY(
            "switch_get_paginated_nss_settlement_transactions"
          ) ? (
            <TabPanel header={"Transactions"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <NssSettlementSessionTransactions {...props} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };
  return <div>{renderNssSettlementSessionDetails()}</div>;
};

export default NssSettlementSessionsDetails;
