import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { HELPER } from "../../../core/helper/helper";
import { useState } from "react";
import { AppFormDropdown } from "shared/components/form-inputs/form-dropdown";
import { PartyTypeFormObserver } from "../party-users/party-type-form-observer";
import { DISPUTE_RESOLUTION_STATUSES, PARTY_TYPES, THIRD_PARTY_ENUMS } from "shared/constants";
import { Optional } from "shared/components/optional/optional";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";
import { LOCAL_STORAGE_SERVICE } from "core/services/storage-service";
import { API_SERVICE } from "api/service";
import { useNotification } from "core/hooks/useNotification";
import { saveAs } from "file-saver";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { ThirdPartiesDropdown } from "../third-parties/third-parties-dropdown";
import { useThirdPartyFilter } from "../third-parties/third-party-filter-helper";

export function GenerateDisputeReport(props) {
  const { addNotification } = useNotification();
  const [isThirdPartyUser] = useState(
    LOCAL_STORAGE_SERVICE.IS_THIRD_PARTY_USER()
  );
  const [partyInfo] = useState({
    partyType: LOCAL_STORAGE_SERVICE.GET_PARTY_TYPE(),
    partyId: LOCAL_STORAGE_SERVICE.GET_PARTY_USER_ID(),
  });
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [initialValues] = useState({
    createdDateFrom: "",
    createdDateTo: "",
    logCode: "",
    maskedPan: "",
    resolutionStatus: "",
    disputeStatus: "",
    rrn: "",
    terminalId: "",
    tatExpiryDateFrom: "",
    tatExpiryDateTo: "",
    transactionDateFrom: "",
    transactionDateTo: "",
    partyType: "",
    partyId: "",
  });
  const formValidationSchema = Yup.object({
    createdDateFrom: Yup.string(),
    createdDateTo: Yup.string(),
    logCode: Yup.string(),
    maskedPan: Yup.string(),
    resolutionStatus: Yup.string(),
    disputeStatus: Yup.string(),
    rrn: Yup.string(),
    terminalId: Yup.string(),
    tatExpiryDateFrom: Yup.string(),
    tatExpiryDateTo: Yup.string(),
    transactionDateFrom: Yup.string(),
    transactionDateTo: Yup.string(),
    partyType: Yup.string(),
    partyId: Yup.string(),
  });
  const [partyIdentifiers, setPartyIdentifiers] = useState([]);
  const [identiferMeta, setIdentifierMeta] = useState({
    optionLabel: "name",
    optionValue: "code",
  });
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const formattedThirdPartiesOptions = filteredThirdParties?.length
    ? filteredThirdParties
    : partyIdentifiers;
  const [selectedDateType, setSelectedDateType] = useState("createdDate");

  const generateReport = async (payload) => {
    setIsGeneratingReport(true);
    const isBlobResponse = true;
    const reportParams = HELPER.TRIM_OBJECT({
      ...payload,
      ...(isThirdPartyUser && partyInfo),
    });
    const { GENERATE_DISPUTE_REPORT, GENERATE_TEAMAPT_DISPUTE_REPORT } =
      BACK_OFFICE_API.DISPUTES;
    const apiRoute = isThirdPartyUser
      ? GENERATE_DISPUTE_REPORT
      : GENERATE_TEAMAPT_DISPUTE_REPORT;

    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(
        apiRoute,
        reportParams,
        isBlobResponse
      );
      addNotification({
        type: "success",
        message: "File download in progress!",
      });
      saveAs(
        response,
        `${HELPER.TO_URL_STRING(reportParams)}-dispute-report.xlsx`
      );
      setIsGeneratingReport(false);
      props.closeModal();
    } catch (error) {
      let notification = {
        type: "error",
        message: HELPER.PARSE_BLOB_ERROR(error),
      };
      addNotification(notification);
      setIsGeneratingReport(false);
    }
  };

  return (
    <div>
      <p className="modal-title p-text-left mt-0">Generate Dispute Report</p>
      <p className="modal-subtitle mt-0 mb-1">All fields are optional</p>
      <Formik
        initialValues={initialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values) => {
          generateReport(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <AppFormInput
              label="RRN"
              name="rrn"
              type="text"
              placeholder="RRN"
            />
            <AppFormInput
              label="Log Code"
              name="logCode"
              type="text"
              placeholder="Log Code"
            />
            <AppFormInput
              label="Terminal Id"
              name="terminalId"
              type="text"
              placeholder="Terminal Id"
            />
            <AppFormInput
              label="Masked PAN"
              name="maskedPan"
              type="text"
              placeholder="Masked PAN"
            />

            <AppFormDropdown
              value={values.disputeStatus}
              onchange={(e) => {
                setFieldValue("disputeStatus", e?.target?.value);
              }}
              options={DISPUTE_RESOLUTION_STATUSES}
              placeholder="Dispute Status"
              label="Dispute Status"
              error={errors?.disputeStatus}
              field="disputeStatus"
            />
            <AppFormDropdown
              value={values.resolutionStatus}
              onchange={(e) => {
                setFieldValue("resolutionStatus", e?.target?.value);
              }}
              options={DISPUTE_RESOLUTION_STATUSES}
              label="Resolution Status"
              error={errors?.resolutionStatus}
              field="resolutionStatus"
              placeholder="Resolution status"
            />

            <Optional showIf={!isThirdPartyUser}>
              <AppFormDropdown
                value={values.partyType}
                onchange={(e) => {
                  const value = e?.target?.value;
                  setFieldValue("partyType", value);
                }}
                options={PARTY_TYPES}
                optionLabel="name"
                optionValue="value"
                placeholder="Select party type"
                label="Party Type"
                error={errors?.partyType}
                field="partyType"
              />
              <PartyTypeFormObserver
                onSetPartyIdentifiersData={setPartyIdentifiers}
                onSetIdentifierMeta={setIdentifierMeta}
              />
              <Optional
                showIf={values.partyType === THIRD_PARTY_ENUMS.THIRD_PARTY}
              >
                <ThirdPartiesDropdown
                  values={values}
                  onFilter={filterThirdPartiesByName}
                  filter={true}
                  label="Party Identifier"
                  placeholder="Select Identifier"
                  isRequired={false}
                  field="partyIdentifier"
                  thirdParties={formattedThirdPartiesOptions}
                  filtering={filtering}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              </Optional>
              <Optional
                showIf={values.partyType !== THIRD_PARTY_ENUMS.THIRD_PARTY}
              >
                <AppFormDropdown
                  value={values.partyIdentifier}
                  onchange={(e) => {
                    setFieldValue("partyIdentifier", e?.target?.value);
                  }}
                  options={partyIdentifiers}
                  optionLabel={identiferMeta.optionLabel}
                  optionValue={identiferMeta.optionValue}
                  placeholder="Select identifier"
                  label="Party Identifier"
                  error={errors?.partyIdentifier}
                  field="partyIdentifier"
                  filter
                  disabled={!values.partyType}
                />
              </Optional>
              {/* <AppFormDropdown
                value={values.partyIdentifier}
                onchange={(e) => {
                  setFieldValue("partyIdentifier", e?.target?.value);
                }}
                options={partyIdentifiers}
                optionLabel={identiferMeta.optionLabel}
                optionValue={identiferMeta.optionValue}
                placeholder="Select identifier"
                label="Party Identifier"
                error={errors?.partyIdentifier}
                field="partyIdentifier"
                filter
                disabled={!values.partyType}
              /> */}
            </Optional>

            <div className="grid my-4">
              <div className="col-4 p-0">
                <FormRadioInput
                  id="active"
                  value="createdDate"
                  name="dateType"
                  checked={selectedDateType === "createdDate"}
                  handleChange={(e) => setSelectedDateType(e?.target?.value)}
                  label="Created Date"
                />
              </div>
              <div className="col-4 p-0">
                <FormRadioInput
                  id="tatExpiryDate"
                  value="tatExpiryDate"
                  name="dateType"
                  checked={selectedDateType === "tatExpiryDate"}
                  handleChange={(e) => setSelectedDateType(e?.target?.value)}
                  label="TAT Expiry Date"
                />
              </div>
              <div className="col-4 p-0">
                <FormRadioInput
                  id="transactionDate"
                  value="transactionDate"
                  name="dateType"
                  checked={selectedDateType === "transactionDate"}
                  handleChange={(e) => setSelectedDateType(e?.target?.value)}
                  label="Transaction Date"
                />
              </div>
            </div>
            <p
              className="m-0 text-sm font-bold mb-2 block text-left"
              style={{ color: "#566a7f" }}
            >
              {HELPER.CAMEL_TO_TITLE_CASE(selectedDateType)}
            </p>
            <div className="grid p-pb-0">
              <div className="col-6">
                <AppFormInput
                  label="From"
                  name={`${selectedDateType}From`}
                  type="date"
                  placeholder="From"
                />
              </div>
              <div className="col-6">
                <AppFormInput
                  label="To"
                  name={`${selectedDateType}To`}
                  type="date"
                  placeholder="To"
                />
              </div>
            </div>
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Cancel"
                onclick={props.closeModal}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                text="Submit"
                loading={isGeneratingReport}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
