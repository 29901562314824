import { LOCAL_STORAGE_SERVICE } from "../../../core/services/storage-service";
import { HELPER } from "../../../core/helper/helper";

export function persistLoginDataAndRedirect(
  data,
  mainDispatch,
  navigate,
  isChangePasswordAutoLogin = false
) {
  let loginRoute = HELPER.GET_USER_LOGIN_ROUTE(
    data?.cosmosResponse?.authorities
  );

  let selectedSideNav = loginRoute.split("/")[2].split("-").join(" ");
  let loginData = {
    username: data?.username,
    email: data?.email,
    access_token: data?.cosmosResponse?.accessToken,
    authorities: data?.cosmosResponse?.authorities,
    expires_in: data?.cosmosResponse?.expiresIn,
    firstName: data?.firstName,
    lastName: data?.lastName,
    roles: data?.cosmosResponse?.roles,
    isAuthenticated: true,
    name: data?.firstname + " " + data?.lastname,
    selectedSideNav,
    selectedSideNavIndex: 0,
    merchantId: data?.merchantId,
    merchantName: data?.branch,
    partyUserId: data?.partyTypeIdentifier,
    partyType: data?.partyType,
  };

  LOCAL_STORAGE_SERVICE.SAVE_USER_CREDENTIAL(loginData);
  mainDispatch({
    type: "PERSIST_LOGIN_DATA",
    loginData: loginData,
  });
  // Changing deafult password requires temporarily storing the users login data which might still have
  // the "resetPassword" property as true.
  if (data.resetPassword && !isChangePasswordAutoLogin) {
    navigate("/change-password");
  } else {
    data?.partyTypeIdentifier
      ? navigate("/third-party-transactions")
      : navigate("/transactions");
  }
}
