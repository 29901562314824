import React from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomTable } from "shared/components/custom-table/custom-table";
import useLinkedThirdParties from "./hooks/use-linked-third-parties";

const LinkedThirdParties = (props) => {
  const { actions, page } = useLinkedThirdParties(props);
  const { reload, incrementPageNo, decrementPageNo, goToPage } = actions;
  const { linkedThirdParties, tableHeaders, loading, error, pagination } = page;

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pagination.pageNo + 1}
          emptyText={"No linked third parties found!"}
          search={true}
          reload={reload}
          error={error}
          items={linkedThirdParties}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => incrementPageNo()}
          prevPage={() => decrementPageNo()}
          goToFirstPage={() => goToPage(0)}
          goToLastPage={() => goToPage(pagination?.totalPages - 1)}
          goToPage={(pageNo) => goToPage(pageNo)}
          actions={[]}
        />
      );
    }
  };

  return <>{renderTable()}</>;
};

export default LinkedThirdParties;
