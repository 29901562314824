import { useField } from "formik";
import { ToolTip } from "../tooltip/tooltip";
import { ProgressSpinner } from "primereact/progressspinner";

import "./form-inputs.css";
import { Optional } from "../optional/optional";
import { useEffect, useState } from "react";

export function AppFormInput({ label, ...props }) {
  const [field, meta] = useField(props);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const today = new Date();
    const maxDate = formatDate(today);
    setMaxDate(maxDate);
  }, []);

  return (
    <div style={{ marginBottom: "1rem" }}>
      <label
        style={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          marginBottom: "0.5rem",
          display: "block",
          color: "#566a7f",
          textAlign: "left",
          // display: "flex",
        }}
        htmlFor={props.id || props.name}
      >
        <p style={{ position: "relative" }}>
          {label}
          {props.showtooltip === "true" ? (
            <ToolTip tip={props.tooltiptext} />
          ) : null}
          : {props.required ? <span style={{ color: "red" }}> * </span> : null}
        </p>
      </label>
      <input
        className={`text-input${meta.touched && meta.error ? " error" : ""}`}
        {...field}
        {...props}
        max={props.type === "date" ? maxDate : null}
      />
      <Optional showIf={meta.touched && meta.error}>
        <p className="error text-left">{meta.error}</p>
      </Optional>
      <Optional showIf={props.loading}>
        <ProgressSpinner
          style={{
            width: "15px",
            height: "15px",
            marginLeft: ".1em",
            position: "relative",
            top: ".35em",
          }}
          strokeWidth="5"
          animationDuration=".5s"
        />
      </Optional>
    </div>
  );
}
