import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";
import { NssConfigurationForm } from "./nss-configuration-form";
import { NssConfigurationsFilter } from "./nss-configurations-filter";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";

export function NssConfigurations() {
  const { addNotification } = useNotification();
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Name", value: "name" },
    {
      label: "Financial Institution Code",
      value: "financialInstitutionCode",
    },
    { label: "Account Number", value: "accountNumber" },
    { label: "Status", value: "active" },
    { label: "Created At", value: "createdAt" },
    { label: "Updated At", value: "updatedAt" },
    { label: "Created By", value: "createdBy" },
    { label: "Actions", value: "actions" },
  ];
  const [nssConfigs, setNssConfigs] = useState([]);
  const [selectedNssConfig, setSelectedNssConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [configFilters, setConfigFilters] = useState(null);

  const handleResponse = useCallback((response) => {
    setPagination(response);
    setNssConfigs(response?.content);
    setError(null);
    setLoading(false);
  }, []);

  const handleError = useCallback(
    (error) => {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    },
    [addNotification]
  );

  const getNssConfigurations = useCallback(
    async (filters) => {
      if (filters) {
        setConfigFilters({ ...filters });
      }
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...(filters ? filters : configFilters),
      });

      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.NSS_CONFIGURATIONS.NSS_CONFIGURATIONS_BASE_URL}?${params}`
        );
        handleResponse(response);
      } catch (error) {
        handleError(error);
      }
    },
    [pageNo, handleResponse, handleError, configFilters]
  );

  useEffect(() => {
    getNssConfigurations();
  }, [getNssConfigurations]);

  function reload() {
    setPageNo(0);
    setConfigFilters(null);
    getNssConfigurations();
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getNssConfigurations();
    }
  }

  const handleTableAction = (data, action) => {
    switch (action) {
      case "VIEW":
        setSelectedNssConfig(data);
        toggleModal(3);
        break;
      case "UPDATE":
        setSelectedNssConfig(data);
        toggleModal(1);
        break;
      default:
        break;
    }
  };

  const handleFilterClick = () => {
    toggleModal(2);
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <NssConfigurationForm
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getNssConfigurations}
          />
        );
      case 1:
        return (
          <NssConfigurationForm
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getNssConfigurations}
            nssConfig={selectedNssConfig}
          />
        );
      case 2:
        return (
          <NssConfigurationsFilter
            closeModal={closeModal}
            onFilter={getNssConfigurations}
          />
        );
      case 3:
        return (
          <div className="configuration-details">
            <p className="modal-title p-text-left mb-1">
              Configuration Details
            </p>
            <p className="modal-subtitle mt-0 mb-1">
              Find configuration details below
            </p>
            <FormattedDetails details={selectedNssConfig} />
          </div>
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "VIEW", value: "switch_get_all_party_office_users" },
            { label: "UPDATE", value: "switch_get_all_party_office_users" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No NSS configurations found!"}
          search={true}
          reload={reload}
          error={error}
          items={nssConfigs}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["VIEW", "UPDATE"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-nss-configs">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          hasOwnModalHeader={true}
        />
      </>
      <PageTitle text="NSS Configurations" />
      <CustomBreadcrumb page="Manage NSS Configurations" />
      <Optional showIf={HELPER.HAS_AUTHORITY("all")}>
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={handleFilterClick}
          />
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Config"
            onclick={() => {
              toggleModal(0);
            }}
          />
        </PageActions>
      </Optional>
      <>{renderTable()}</>
    </div>
  );
}
