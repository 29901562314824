import { CONFIG } from "core/configs/configs";
const NSS_SETTLEMENT_SESSIONS_BASE_URL = `/nss/api/${CONFIG.API_VERSION}/settlement-sessions`;
const NSS_SETTLEMENT_SESSION_TRANSACTIONS = `/nss/api/${CONFIG.API_VERSION}/session`;
const NSS_SETTLEMENT_SESSION_NET_SUMMARIES = `/nss/api/${CONFIG.API_VERSION}/settlement-summaries`;
const NSS_SETTLEMENT_SESSION_DOWNLOAD = `/nss/api/${CONFIG.API_VERSION}/nss-file-summaries/download`;

export const NSS_SETTLEMENT_SESSION_ROUTES = {
  NSS_SETTLEMENT_SESSIONS_BASE_URL,
  NSS_SETTLEMENT_SESSION_TRANSACTIONS,
  NSS_SETTLEMENT_SESSION_NET_SUMMARIES,
  NSS_SETTLEMENT_SESSION_DOWNLOAD,
};
