import { BACK_OFFICE_API } from "api/backofffice/index";
import { useThirdPartiesList } from "api/hooks/use-third-parties-list";
import { useThirdPartyCategoriesList } from "api/hooks/use-third-party-catgories-list";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useThirdPartyFilter } from "../third-party-filter-helper";
import { PHONE_NUMBER_REGEX } from "shared/constants";

const useThirdPartyForm = (props, partyType) => {
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const { thirdPartyCategories } = useThirdPartyCategoriesList();
  const { thirdParties: thirdPartiesList } = useThirdPartiesList();
  const [toast, setToast] = useState({
    message: "",
    type: "",
  });
  const {
    thirdParties: filteredThirdParties,
    filtering,
    filterThirdPartiesByName,
  } = useThirdPartyFilter();
  const thirdParties = filteredThirdParties?.length
    ? filteredThirdParties
    : thirdPartiesList?.content;
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    thirdPartyCategoryCode: "",
    settlementEmail: "",
    disputeEmail: "",
    adminEmail: "",
    phone: "",
    status: "",
    supportEmail: "",
    thirdPartyGlobalId: "",
    parentThirdPartyCode: "",
  });
  const [thirdPartyInfo, setThirdPartyInfo] = useState({});

  const [validtionObj, setValidationObj] = useState({
    name: Yup.string()
      .max(30, `Too long, maximum length is 30 characters`)
      .required("Required"),
    thirdPartyGlobalId: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
      .max(100, `Too long, maximum length is 100 characters`),
    thirdPartyCategoryCode: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
  });
  const [partySourceInfo, setPartySourceInfo] = useState({});
  const [formattedThirdParties, setFormattedThirdParties] = useState([]);
  const stepWizard = {
    PRIMARY_INFO_INDEX: 0,
    CONTACT_INFO_INDEX: 1,
    CONNECTION_INFO_INDEX: 2,
  };
  const stepWizardModel = [
    { label: "Primary Info" },
    { label: "Contact Info" },
    { label: "Connection Info" },
  ];

  const getPartySourceInfo = useCallback(
    async (partyCode) => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.PARTY_SOURCE_INFO.PARTY_SOURCE_INFO}/${partyType}/${partyCode}`
        );
        const partySource = response?.result;
        handlePartySourceResponse(partySource);
      } catch (error) {}
    },
    [partyType]
  );

  useEffect(() => {
    if (thirdParties?.length) {
      setFormattedThirdParties([{ name: "None", code: "" }, ...thirdParties]);
    }
  }, [thirdParties]);

  useEffect(() => {
    if (props.isUpdate) {
      const {
        name,
        thirdPartyCategoryCode,
        settlementEmail,
        disputeEmail,
        adminEmail,
        phone,
        status,
        supportEmail,
        cardAcceptorId,
        thirdPartyGlobalId,
        parentThirdPartyCode,
      } = props.thirdParty;
      if (parentThirdPartyCode) {
        const isByCode = true;
        filterThirdPartiesByName(parentThirdPartyCode, isByCode);
      }
      setInitialValues({
        name,
        thirdPartyCategoryCode,
        settlementEmail,
        disputeEmail,
        adminEmail,
        phone,
        status,
        supportEmail,
        cardAcceptorId,
        thirdPartyGlobalId,
        parentThirdPartyCode,
      });
    }
  }, [
    props.isUpdate,
    props.thirdParty,
    getPartySourceInfo,
    filterThirdPartiesByName,
  ]);

  const handlePartySourceResponse = (partySource) => {
    const {
      partyTypeId,
      partyType,
      createdBy,
      createdAt,
      updatedAt,
      active,
      ...result
    } = partySource;
    setPartySourceInfo(
      HELPER.changeNullValuesToEmptyStrings({ ...result, status: active })
    );
  };

  const handleResponse = (response) => {
    setToast({
      message: "",
      type: "",
    });
    setThirdPartyInfo(response.result);
    if (props.isUpdate) {
      getPartySourceInfo(props.thirdParty.code);
    }
    setCurrentFormIndex(stepWizard.CONNECTION_INFO_INDEX);
    setLoading(false);
  };

  const handleError = (error) => {
    setToast({
      message: HELPER.PROCESS_ERROR(error),
      type: "error",
    });
    setLoading(false);
    setCurrentFormIndex(stepWizard.CONTACT_INFO_INDEX);
  };

  const updateThirdParty = async (payload) => {
    const url = `${BACK_OFFICE_API.THIRD_PARTY.UPDATE_THIRD_PARTY}/${props.thirdParty.code}`;
    try {
      const response = await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      const isUpdate = true;
      handleResponse(response, isUpdate);
    } catch (error) {
      handleError(error);
    }
  };

  const createThirdParty = async (payload) => {
    const url = BACK_OFFICE_API.THIRD_PARTY.CREATE_THIRD_PARTY;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async (payload) => {
    const { status, ...rest } = payload;
    if (currentFormIndex === stepWizard.PRIMARY_INFO_INDEX) {
      setCurrentFormIndex(stepWizard.CONTACT_INFO_INDEX);
      setValidationObj({
        ...validtionObj,
        settlementEmail: Yup.string()
          .required("Required")
          .email("Enter valid email")
          .max(50, "Settlement Email cannot exceed 50 characters!"),
        disputeEmail: Yup.string()
          .required("Required")
          .email("Enter valid email")
          .max(50, "Dispute Email cannot exceed 50 characters!"),
        adminEmail: Yup.string()
          .required("Required")
          .email("Enter valid email")
          .max(50, "Admin Email cannot exceed 50 characters!"),
        supportEmail: Yup.string()
          .required("Required")
          .email("Enter valid email")
          .max(50, "Support Email cannot exceed 50 characters!"),
        phone: Yup.string("Enter valid number")
          .matches(PHONE_NUMBER_REGEX, "Must be a phone number")
          .required("Required")
          .max(20, "Phone number cannot exceed 20 characters!"),
      });
    } else if (currentFormIndex === stepWizard.CONTACT_INFO_INDEX) {
      setLoading(true);
      let formattedPayload = HELPER.TRIM_OBJECT({
        ...rest,
        active: status,
      });
      if (props.isUpdate) {
        updateThirdParty(formattedPayload);
      } else {
        createThirdParty(formattedPayload);
      }
    }
  };

  const handleClick = () => {
    setValidationObj({
      name: Yup.string()
        .max(30, `Too long, maximum length is 30 characters`)
        .required("Required"),
      thirdPartyGlobalId: Yup.string()
        .required("Required")
        .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric")
        .max(100, `Too long, maximum length is 100 characters`),
      thirdPartyCategoryCode: Yup.string()
        .required("Required")
        .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
    });
    if (currentFormIndex === stepWizard.PRIMARY_INFO_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(stepWizard.PRIMARY_INFO_INDEX);
    }
  };

  const goBack = () => {
    setCurrentFormIndex(stepWizard.CONTACT_INFO_INDEX);
  };

  const closeBackButtonText =
    currentFormIndex !== stepWizard.PRIMARY_INFO_INDEX ? "Back" : "Close";

  const submitNextButtonText =
    currentFormIndex === stepWizard.CONTACT_INFO_INDEX && props.isUpdate
      ? "Submit"
      : "Next";

  const actions = {
    submit,
    handleClick,
    goBack,
    filterThirdPartiesByName,
  };

  const formInfo = {
    closeBackButtonText,
    submitNextButtonText,
    ...stepWizard,
    initialValues,
    validtionObj,
    stepWizardModel,
    currentFormIndex,
  };

  const api = {
    loading,
    toast,
    thirdPartyInfo,
    thirdParties: formattedThirdParties,
    thirdPartyCategories,
    partySourceInfo,
    filtering,
  };
  return { actions, api, formInfo };
};

export default useThirdPartyForm;
