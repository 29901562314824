import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { API_SERVICE } from "api/service";
import { SettlementRuleForm } from "./settlement-rule-form";
import { SettlementRulesFilter } from "./settlement-rules-filter";
import { useNotification } from "core/hooks/useNotification";

export function SettlementRules() {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Create Settlement Rule",
    subtitle: "All fields are required",
  });
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [filterParams, setFilterParams] = useState(null);
  const tableHeaders = [
    { label: "Acquirer", value: "acquirerName" },
    { label: "Issuer", value: "issuerName" },
    { label: "Third Party", value: "thirdPartyName" },
    { label: "Network", value: "networkName" },
    { label: "Settlement Agent", value: "settlementAgent" },
    { label: "Priority", value: "priority" },
    { label: "Status", value: "active" },
    { label: "Created At", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [settlementRules, setSettlementRules] = useState([]);
  const [selectedSettlementRule, setSelectedSettlementRule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);

  const getSettlementRules = useCallback(
    async (filters) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...(filters && { ...filters }),
      });
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.SETTLEMENT_RULE.SETTLEMENT_RULE}?${params}`
        );
        setPagination(response?.result);
        const settlementRules = response?.content;
        setSettlementRules(settlementRules);
        setError(null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(HELPER.PROCESS_ERROR(error));
        addNotification({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [pageNo, addNotification]
  );

  useEffect(() => {
    if (!filterParams) {
      getSettlementRules();
    } else {
      getSettlementRules(filterParams);
    }
  }, [getSettlementRules, filterParams]);

  function reload() {
    setPageNo(0);
    setFilterParams(null);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getSettlementRules();
    }
  }

  const handleSettlementRulesFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  const handleTableAction = (data, action) => {
    const { active, updatedAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Settlement Rule",
          subtitle: "All fields are required",
        });
        setSelectedSettlementRule({ ...rest, active, updatedAt });
        toggleModal(1);
        break;
      case "VIEW":
        setModalInfo({
          title: "Settlement Rule",
          subtitle: "View details of settlement rule below",
        });
        const {
          acquirerCode,
          thirdPartyCode,
          issuerCode,
          networkCode,
          code,
          ...others
        } = data;
        setSelectedSettlementRule(others);
        toggleModal(2);
        break;
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <SettlementRuleForm
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getSettlementRules}
          />
        );
      case 1:
        return (
          <SettlementRuleForm
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getSettlementRules}
            settlementRule={selectedSettlementRule}
          />
        );
      case 2:
        return <FormattedDetails details={selectedSettlementRule} />;
      case 3:
        return (
          <SettlementRulesFilter
            onGetFeeSources={handleSettlementRulesFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_update_fee_rule" },
            { label: "OPEN", value: "all" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No settlement rule found!"}
          search={true}
          reload={reload}
          error={error}
          items={settlementRules}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE", "VIEW"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  const renderPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  return (
    <div className="manage-settlement-rules">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageTitle text="Settlement Rules" />
      <CustomBreadcrumb page="Manage Settlement Rules" />
      <Optional
        showIf={
          HELPER.HAS_AUTHORITY("switch_create_fee_rule") && currentIndex === 0
        }
      >
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={() => {
              setModalInfo({
                title: "Filter Settlement Rules",
                subtitle: "",
              });
              toggleModal(3);
            }}
          />
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Settlement Rule"
            onclick={() => {
              toggleModal(0);
              setModalInfo({
                title: "Create Settlement Rule",
                subtitle: "All fields are required",
              });
            }}
          />
        </PageActions>
      </Optional>
      <>{renderPageView()}</>
    </div>
  );
}
