import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { PARTY_TYPES } from "shared/constants";
import { PartyTypePartyIdentifierFormInputs } from "../party-users/party-type-party-identifier-form-inputs";

export function FeeReportGenerationForm({ onSubmit, loading, closeModal }) {
  const [initialValues] = useState({
    participantType: "",
    participantId: "",
    from: "",
    to: "",
  });
  const validationSchemaObject = {
    participantType: Yup.string().required("Required"),
    participantId: Yup.string().required("Required"),
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
  };
  const validPartyTypes = useRef([
    "ISSUER",
    "ACQUIRER",
    "THIRD_PARTY",
    "NETWORK",
  ]);
  const partyTypes = useRef(
    PARTY_TYPES.filter((partyType) =>
      validPartyTypes.current.includes(partyType.value)
    )
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <PartyTypePartyIdentifierFormInputs
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              partyTypes={partyTypes.current}
              identifierField="participantId"
              partyTypeField="participantType"
              partyTypePlaceholder="Select participant type"
              identiferPlaceholder="Select participant"
              partyTypeLabel="Participant Type"
              identifierLabel="Participant"
              isRequiredField={true}
            />
            <div className="flex w-full ">
              <div className="w-full pr-3">
                <AppFormInput
                  label="From"
                  name="from"
                  type="date"
                  placeholder="Start Date"
                  required={true}
                />
              </div>
              <div className="w-full">
                <AppFormInput
                  label="To"
                  name="to"
                  type="date"
                  placeholder="End Date"
                  required={true}
                />
              </div>
            </div>
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={closeModal}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                text="Submit"
                loading={loading}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
