import { TabPanel, TabView } from "primereact/tabview";
import { Icon } from "../../../shared/components/icons/icon";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import "./merchant-details.css";
import { HELPER } from "../../../core/helper/helper";
import { Terminals } from "../terminal/terminal";

export const MerchantDetails = (props) => {
  const renderMerchantDetailsTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5 mt-5"
      >
        <div className="p-text-left ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={"Merchant Summary"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
              </div>
              <div className="centralize">
                <div className="p-2">
                  <FormattedDetails details={props.details} />
                </div>
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY("switch_retrieve_all_terminals") ? (
            <TabPanel header={"Terminals"}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                </div>
                <div className="centralize">
                  <div className="p-2">
                    <Terminals merchantId={props.details.merchantId} />
                  </div>
                </div>
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };
  return <div>{renderMerchantDetailsTabView()}</div>;
};
