import { BACK_OFFICE_API } from "api/backofffice/index";
import { useEffect, useState } from "react";
import { useList } from "./use-list";

export function useFinancialInstitutionsList() {
  const [financialInstitutions, setFinancialInstitutions] = useState(null);
  const {
    list: financialInstitutionsList,
    loading,
    error,
  } = useList(
    BACK_OFFICE_API.FINANCIAL_INSITUTION.FINANCIAL_INSTITUTION_FILLER
  );

  useEffect(() => {
    if (financialInstitutionsList) {
      setFinancialInstitutions(financialInstitutionsList);
    }
  }, [financialInstitutionsList]);

  return { financialInstitutions, loading, error };
}
