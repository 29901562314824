import { BACK_OFFICE_API } from "api/backofffice/index";
import { useEffect, useState } from "react";
import { useList } from "./use-list";

export function useNetworksList() {
  const [networks, setNetworks] = useState(null);
  const { list, loading, error } = useList(
    BACK_OFFICE_API.NETWORKS.GET_NETWORKS_FILLER
  );
  useEffect(() => {
    if (list) {
      setNetworks(list);
    }
  }, [list]);

  return { networks, loading, error };
}
