import { CONFIG } from "core/configs/configs";

const DISPUTE_CONFIG = `/dispute/api/${CONFIG.API_VERSION}/dispute-config`;
const GET_DISPUTE_CONFIGURATION = `/dispute/api/${CONFIG.API_VERSION}/dispute-config`;
const CREATE_DISPUTE_CONFIGURATION = `/dispute/api/${CONFIG.API_VERSION}/dispute-config`;
const UPDATE_DISPUTE_CONFIGURATION = `/dispute/api/${CONFIG.API_VERSION}/dispute-config`;
export const DISPUTE_CONFIG_ROUTES = {
  DISPUTE_CONFIG,
  GET_DISPUTE_CONFIGURATION,
  CREATE_DISPUTE_CONFIGURATION,
  UPDATE_DISPUTE_CONFIGURATION,
};
