import "./sider.css";
import React, { useEffect, useState } from "react";
import { Icon } from "../../shared/components/icons/icon";
import { Accordion, AccordionTab } from "primereact/accordion";
import { NavItems } from "../../shared/nav-items";
import { Link, useNavigate } from "react-router-dom";
import { HELPER } from "../../core/helper/helper";
import { LOCAL_STORAGE_SERVICE } from "../../core/services/storage-service";
import { Optional } from "../../shared/components/optional/optional";
import { useMainState } from "../../core/hooks/useMainState";

function Sider() {
  const { mainState, mainDispatch } = useMainState();
  let navigate = useNavigate();
  const [colorIndex, setColorIndex] = useState(0);
  const defaultColor = "#6D8C98";
  const defaultActiveColor = "#0371AC";
  const [menuClicked, setMenuClicked] = useState(mainState?.selectedSideNav);
  const [windowWidth, setWindowWidth] = useState(900);
  const role = HELPER.GET_ROLE();
  const userCredentials = {
    username: LOCAL_STORAGE_SERVICE.GET_USERNAME(),
    name: LOCAL_STORAGE_SERVICE.GET_FULL_NAME(),
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const sideNav = LOCAL_STORAGE_SERVICE.GET_CURRENT_NAV();
      setMenuClicked(sideNav.selectedSideNav);
      setColorIndex(sideNav.selectedSideNavIndex);
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function setNavColorOnMouseEnter(index, menuName) {
    if (menuName !== menuClicked) {
      setColorIndex(index);
      // setColor("#0371AC");
    }
  }

  function setNavColorOnMouseLeave(index, menuName) {
    if (menuName !== menuClicked) {
      setColorIndex(mainState?.selectedSideNavIndex);
      // setColor("#6D8C98");
    }
  }

  function setNavColorOnClicked(navId, clickedMenu, route) {
    setMenuClicked(clickedMenu);
    mainDispatch({
      type: "CLICKED_SIDE_NAV",
      selectedSideNav: clickedMenu,
      selectedSideNavIndex: navId,
    });
    LOCAL_STORAGE_SERVICE.STORE_CURRENT_PAGE(clickedMenu, navId);
    navigate(route);
  }

  function closeSideNav() {
    const sidenav = document.getElementById("mySidenav");
    sidenav.style.width = "0";
  }
  const sideMenuItems = () => {
    return NavItems.map((navItem) => {
      if (navItem.children.length === 0) {
        const hasAuthority = HELPER.HAS_AUTHORITY(navItem.authority);
        return (
          <Optional showIf={hasAuthority} key={`${navItem.id}-${navItem.type}`}>
            <div id={navItem.id}>
              <div
                onClick={() =>
                  setNavColorOnClicked(navItem.id, navItem.text, navItem.route)
                }
                onMouseLeave={() =>
                  setNavColorOnMouseLeave(navItem.id, navItem.text)
                }
                onMouseEnter={() =>
                  setNavColorOnMouseEnter(navItem.id, navItem.text)
                }
                className={
                  menuClicked?.toLowerCase() === navItem?.text?.toLowerCase()
                    ? "singleNavContainer singleNavContainer-active"
                    : "singleNavContainer"
                }
              >
                <div className="grid text-left">
                  <div className="col-2">
                    <div className="singleNavContainer-icon">
                      <Icon
                        color={
                          menuClicked?.toLowerCase() ===
                          navItem?.text?.toLowerCase()
                            ? defaultActiveColor
                            : colorIndex === navItem.id
                            ? defaultActiveColor
                            : defaultColor
                        }
                        icon={navItem.icon}
                      />
                    </div>
                  </div>
                  <div className="col-10">
                    <span
                      style={{
                        color:
                          menuClicked?.toLowerCase() ===
                          navItem?.text?.toLowerCase()
                            ? defaultActiveColor
                            : colorIndex === navItem.id
                            ? defaultActiveColor
                            : defaultColor,
                      }}
                      className="singleNavContainer-text adjust-single-nav"
                    >
                      {navItem.text}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Optional>
        );
      } else {
        // const hasAuthority = HELPER.HAS_AUTHORITY(navItem.authority);
        let hasAuthority;
        // const hasAuthority = navItem.au
        if (navItem?.authorities) {
          navItem?.authorities.forEach((authority) => {
            if (HELPER.HAS_AUTHORITY(authority)) {
              hasAuthority = true;
            }
          });
        } else {
          hasAuthority = HELPER.HAS_AUTHORITY(navItem.authority);
        }

        return (
          hasAuthority && (
            <Accordion
              key={`${navItem.id}`}
              activeIndex={
                mainState?.selectedSideNav === "business-schemes" &&
                navItem.id === "business-schemes"
                  ? 0
                  : null
              }
            >
              <AccordionTab
                header={
                  <React.Fragment>
                    {/* custom-tab-text d-flex align-cente custom-font-family */}
                    <div
                      className={`sidebar-accordion-header ${
                        navItem.icon === "settings" ? "settings" : ""
                      }`}
                      style={{
                        color: defaultColor,
                      }}
                    >
                      <Icon color={defaultColor} icon={navItem.icon} />
                      <p
                        style={{
                          paddingLeft: "1.2rem",
                        }}
                        className="my-0"
                      >
                        {navItem.text}
                      </p>
                    </div>
                  </React.Fragment>
                }
              >
                {navItem.children.map((subNav) => {
                  let hasAuthority = false;
                  if (subNav.authorities) {
                    subNav.authorities.forEach((authority) => {
                      if (HELPER.HAS_AUTHORITY(authority)) {
                        hasAuthority = true;
                      }
                    });
                  } else {
                    hasAuthority = HELPER.HAS_AUTHORITY(subNav.authority);
                  }
                  if (hasAuthority) {
                    return (
                      <Link
                        key={`${subNav.id}-${subNav.type}`}
                        to={`${subNav.route}`}
                      >
                        <div
                          onClick={() =>
                            setNavColorOnClicked(
                              subNav.id,
                              subNav.text,
                              subNav.route
                            )
                          }
                          className={
                            menuClicked === subNav?.text
                              ? "custom-tab-content mt-1 custom-tab-active"
                              : "custom-tab-content"
                          }
                          id={subNav.id}
                        >
                          <span>
                            <span className="custom-font-family custom-tab-title">
                              {subNav?.text}
                            </span>
                          </span>
                        </div>
                      </Link>
                    );
                  } else {
                    return "";
                  }
                })}
              </AccordionTab>
            </Accordion>
          )
        );
      }
    });
  };

  return (
    <div id="mySidenav" className="sidenav shadow-1 text-center">
      {windowWidth < 900 && (
        <div style={{ float: "right" }} className="mt-2 mr-2">
          <button className="bare-button" onClick={closeSideNav}>
            <Icon icon="go-back-icon" />
          </button>
        </div>
      )}

      <div className="grid">
        <div className="col-12">
          <div className="logo">
            <Icon icon="logo" />
          </div>
          <h1 className="app-name top">Aptpay Switch</h1>
        </div>
        <div className="col-12 mt-3">
          <div className="nav-profile-container">
            <div className="grid">
              <div className="col-12">
                <div className="nav-profile-text-container">
                  <div>
                    <h4 className="nav-profile-title">
                      {userCredentials?.name ? userCredentials?.name : ""}
                    </h4>
                  </div>
                  <div>
                    <span className="nav-profile-username">
                      Username:{" "}
                      {userCredentials?.username
                        ? userCredentials?.username
                        : ""}
                    </span>
                  </div>
                  <div>
                    <span className="nav-profile-subtitle">{role}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 pt-0">
          <a
            className="nav-profile-title version underline mt-0"
            href="https://teamapt.atlassian.net/wiki/spaces/DUTM/pages/707198977/DCIR+Change+Log"
            target="_blank"
            rel="noreferrer"
          >
            Version {version}
          </a>
        </div> */}
        <div className="col-12">
          <div>{sideMenuItems()}</div>
        </div>
      </div>
    </div>
  );
}
export default Sider;
