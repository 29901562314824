import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useNotification } from "core/hooks/useNotification";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import { useThirdPartyFilter } from "backoffice/pages/third-parties/third-party-filter-helper";

const useBulkMerchantTerminalForm = (props) => {
  const fileInputRef = useRef(null);
  const [initialValues, setInitialValues] = useState({
    thirdPartyCode: "",
    acquirerCode: "",
  });
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [fileDetails, setFileDetails] = useState({
    name: "",
    isValidUpload: "",
    data: "",
  });
  const { thirdParties, filtering, filterThirdPartiesByName } =
    useThirdPartyFilter();
  const pageIndex = {
    PRIMARY_INFO: 0,
    UPLOAD_INFO: 1,
    DETAILS_CONFIRMATION: 2,
  };
  const stepWizardModel = [
    { label: "Primary Info" },
    { label: "Upload  Info" },
    { label: "Confirm Details" },
  ];
  const [validationSchema, setValidationSchema] = useState({
    thirdPartyCode: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
    acquirerCode: Yup.string().required("Acquirer Name is required!"),
  });

  useEffect(() => {
    if (props.isUpdate) {
      const values = HELPER.changeNullValuesToEmptyStrings(props.merchant);
      const { active, ...rest } = values;
      filterThirdPartiesByName(values.thirdPartyName);
      setInitialValues({
        ...rest,
        status: active,
      });
    }
  },[props.isUpdate, props.merchant, filterThirdPartiesByName]);

  const handleResponse = (response) => {
    const message = response?.result?.response;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const createBulkMerchantTerminal = async (payload) => {
    const { fileName } = payload;
    const formData = new FormData();
    formData.append("file", payload.file, fileName);
    formData.append("thirdPartyCode", payload.thirdPartyCode);
    formData.append("acquirerCode", payload.acquirerCode);
    const url =
      BACK_OFFICE_API.BULK_MERCHANT_TERMINAL.UPLOAD_BULK_MERCHANT_TERMINAL;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url, formData);
      handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  };

  function downloadExcelTemplate() {
    saveAs(
      `${process.env.PUBLIC_URL}/testmecrhantterminal.xlsx`,
      "testmecrhantterminal.xlsx"
    );
  }

  function downloadCsvTemplate() {
    saveAs(
      `${process.env.PUBLIC_URL}/testmecrhantterminal.csv`,
      "testmecrhantterminal.csv"
    );
  }

  function handleUpload(e) {
    setMessage("null");
    setFileDetails({ name: "Click to upload file", isValidUpload: false });
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const name = selectedFile?.name;
      setFileDetails({ name, isValidUpload: true, data: selectedFile });
    }
  }

  const submit = async (payload) => {
    setToastType("");
    setMessage("");
    if (currentFormIndex === pageIndex.PRIMARY_INFO) {
      setCurrentFormIndex(pageIndex.UPLOAD_INFO);
    } else if (currentFormIndex === pageIndex.UPLOAD_INFO) {
      if (fileDetails.name) {
        setDetails({ ...payload, fileName: fileDetails.name });
        setCurrentFormIndex(pageIndex.DETAILS_CONFIRMATION);
      }
    } else if (currentFormIndex === pageIndex.DETAILS_CONFIRMATION) {
      setLoading(true);
      createBulkMerchantTerminal({
        ...payload,
        file: fileDetails.data,
        fileName: fileDetails.name,
      });
    }
  };

  const handleClose = () => {
    if (currentFormIndex === pageIndex.PRIMARY_INFO) {
      props.closeModal();
    } else if (currentFormIndex === pageIndex.UPLOAD_INFO) {
      setValidationSchema({
        thirdPartyCode: Yup.string()
          .required("Required")
          .matches(/^[a-zA-Z0-9]*$/, "Must be alphanumeric"),
        acquirerCode: Yup.string().required("Acquirer Name is required!"),
      });
      setCurrentFormIndex(pageIndex.PRIMARY_INFO);
    } else if (currentFormIndex === pageIndex.DETAILS_CONFIRMATION) {
      setCurrentFormIndex(pageIndex.UPLOAD_INFO);
    }
  };

  const actions = {
    downloadExcelTemplate,
    downloadCsvTemplate,
    submit,
    handleUpload,
    handleClose,
    setInitialValues,
    filterThirdPartiesByName,
  };
  const states = {
    filtering,
    thirdParties,
    fileDetails,
    fileInputRef,
    validationSchema,
    initialValues,
    toastType,
    message,
    loading,
    details,
    stepWizardModel,
    currentFormIndex,
    ...pageIndex,
  };

  return { actions, states };
};

export { useBulkMerchantTerminalForm };
