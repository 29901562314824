import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { API_SERVICE } from "../../../api/service";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { Optional } from "../../../shared/components/optional/optional";
import { FormattedDetails } from "../../../shared/components/formatted-details/formatted-details";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";

export function ThirdPartyCategoryForm(props) {
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [initialValues, setInitialValues] = useState({
    name: "",
    status: "",
    description: "",
  });
  const validationSchemaObject = {
    name: Yup.string()
      .max(100, `Too long, maximum length is 100 characters`)
      .required("Required"),
    description: Yup.string()
      .max(255, `Too long, maximum length is 255 characters`)
      .required("Required"),
  };
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  useEffect(() => {
    if (props.isUpdate) {
      const { name, status, description } = props.category;
      setInitialValues({
        name,
        description,
        status,
      });
    }
  }, [props.isUpdate, props.category]);

  const handleResponse = (isUpdate) => {
    const message = `Category successfully ${
      isUpdate ? "updated" : "created"
    }!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const updateCategory = async (payload) => {
    const url = `${BACK_OFFICE_API.THIRD_PARTY_CATEGORIES.UPDATE_THIRD_PARTY_CATEGORY}/${props.category.code}`;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      const isUpdate = true;
      handleResponse(isUpdate);
    } catch (error) {
      handleError(error);
    }
  };

  const createCategory = async (payload) => {
    const url =
      BACK_OFFICE_API.THIRD_PARTY_CATEGORIES.CREATE_THIRD_PARTY_CATEGORY;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async (payload) => {
    setToastType("");
    setMessage("");
    const { status, active, ...rest } = payload;
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      let formattedPayload = {
        ...rest,
        active: status,
      };
      if (props.isUpdate) {
        updateCategory(formattedPayload);
      } else {
        createCategory(formattedPayload);
      }
    } else {
      const confirmationPayload = props.isUpdate
        ? HELPER.TRIM_OBJECT({
            ...payload,
            status: status,
          })
        : HELPER.TRIM_OBJECT(payload);
      setDetails(confirmationPayload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const renderFormActions = () => {
    return (
      <FormActions>
        <AppButton
          type="button"
          buttonStyle="secondary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
          onclick={handleClose}
        />
        <AppButton
          loading={loading}
          type="submit"
          buttonStyle="primary"
          text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
        />
      </FormActions>
    );
  };

  const renderFormAlert = () => {
    return message ? (
      <CustomToast
        title={toastType === "error" ? "Error" : "Success"}
        description={message}
        type={toastType}
        closeable={false}
        inApp={true}
      />
    ) : null;
  };
  const renderViewCategory = () => {
    return (
      <Optional showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}>
        <p className="modal-title p-text-left mb-1">Confirm Details</p>
        <div className="custom-modal-item">
          <FormattedDetails details={details} />
        </div>
      </Optional>
    );
  };
  const renderFormContent = (values, errors, setFieldValue) => {
    return (
      <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
        <p className="modal-title p-text-left ">{props.title}</p>
        <p className="modal-subtitle -mt-3">{props.subtitle}</p>
        <div className="custom-modal-item">
          <AppFormInput
            label="Name"
            name="name"
            type="text"
            placeholder="Input category name"
            required
          />
          <AppFormInput
            label="Description"
            name="description"
            type="text"
            placeholder="Input description"
            required
          />
          <Optional showIf={props.isUpdate}>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                  display: "block",
                  color: "#566a7f",
                  textAlign: "left",
                }}
              >
                Status: <span style={{ color: "red" }}> * </span>
              </p>
              <div className="flex default p-mb-4">
                <FormRadioInput
                  id="active"
                  value={true}
                  name="Status"
                  checked={values.status === true}
                  handleChange={(e) => setFieldValue("status", true)}
                  label="Active"
                />
                <FormRadioInput
                  id="Inactive"
                  value={false}
                  name="Status"
                  checked={values.status === false}
                  handleChange={(e) => setFieldValue("status", false)}
                  label="Inactive"
                />
              </div>
            </div>
          </Optional>
        </div>
      </Optional>
    );
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            {renderFormContent(values, errors, setFieldValue)}
            {renderViewCategory()}
            {renderFormAlert()}
            {renderFormActions(errors)}
          </Form>
        )}
      </Formik>
    </div>
  );
}
