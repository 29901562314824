import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";
import { FormRadioInput } from "shared/components/form-component/form-radio-input";
import { RESET_PASSWORD, UPDATE_USER_STATUS } from "./party-user-details";

export function PartyUserUpdateForm(props) {
  const { addNotification } = useNotification();
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
  });
  const validationSchemaObject = {
    email: Yup.string().email("Enter a valid email").required("Required!"),
    active: Yup.boolean(),
  };
  const [isUpdateUserStatus] = useState(
    props.updateAction === UPDATE_USER_STATUS
  );
  const [isResetPassword] = useState(props.updateAction === RESET_PASSWORD);

  useEffect(() => {
    const { active, email } = props.partyUser;
    let initValues = {
      email,
    };
    if (isUpdateUserStatus) {
      initValues = {
        ...initValues,
        active: !active,
      };
    }
    setInitialValues(initValues);
  }, [props.partyUser, isUpdateUserStatus]);

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const handleResponse = () => {
    let notification = {
      type: "success",
      message: `${
        isUpdateUserStatus
          ? "User status updated!"
          : "User password has been reset!"
      }`,
    };
    addNotification(notification);
    props.onSuccess();
    setLoading(false);
  };

  const updatePartyUser = async (payload) => {
    resetToast();
    const { partyUser } = props;
    const { RESET_PASSWORD, UPDATE_USER_STATUS } = BACK_OFFICE_API.PARTY_USERS;
    const url = isResetPassword
      ? RESET_PASSWORD
      : `${UPDATE_USER_STATUS}/${partyUser?.code}`;
    payload = {
      ...payload,
      username: partyUser.username,
    };
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async (payload) => {
    setLoading(true);
    updatePartyUser(payload);
  };

  const getSubtitle = () => {
    if (props.updateAction === UPDATE_USER_STATUS) {
      return `Confirm that you want to ${
        props.partyUser.active ? "disable" : "enable"
      } this user`;
    } else {
      return `Confirm that you want to reset this users password!`;
    }
  };

  const renderForm = () => {
    return (
      <div>
        <p className="modal-title p-text-left mb-1">
          {isUpdateUserStatus
            ? `${props.partyUser.active ? "Disable" : "Enable"} user`
            : "Reset users password"}
        </p>
        <p className="modal-subtitle mt-0 mb-1">{getSubtitle()}</p>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchemaObject)}
          onSubmit={(values, { resetForm }) => {
            submit(values, resetForm);
          }}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <AppFormInput
                required={true}
                label="Email"
                name="email"
                type="text"
                placeholder="Email"
                disabled={false}
              />
              <Optional showIf={isUpdateUserStatus}>
                <div>
                  <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    Status: <span style={{ color: "red" }}> * </span>
                  </p>
                  <div className="flex default p-mb-4">
                    <FormRadioInput
                      id="active"
                      value={true}
                      name="status"
                      checked={values.active === true}
                      handleChange={() => setFieldValue("active", true)}
                      label="Active"
                    />
                    <FormRadioInput
                      id="inactive"
                      value={false}
                      name="status"
                      checked={values.active === false}
                      handleChange={() => setFieldValue("active", false)}
                      label="Inactive"
                    />
                  </div>
                </div>
              </Optional>

              {message && (
                <CustomToast
                  title={toastType === "error" ? "Error" : "Success"}
                  description={message}
                  type={toastType}
                  closeable={false}
                  inApp={true}
                />
              )}
              <FormActions>
                <AppButton
                  type="button"
                  buttonStyle="secondary"
                  text="Back"
                  onclick={props.goBack}
                />
                <AppButton
                  type="submit"
                  buttonStyle="primary"
                  text="Submit"
                  loading={loading}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <>{renderForm()}</>;
}
