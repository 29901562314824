import { Optional } from "../optional/optional";
import "./app-button.css";

export function AppButton({
  disabled,
  type,
  loading,
  text,
  onclick,
  buttonStyle,
  width,
  margin,
  icon,
}) {
  return (
    <button
      disabled={disabled}
      className={`${buttonStyle} ${width} ${margin}`}
      onClick={onclick}
      type={type ? type : "button"}
      style={{ cursor: "pointer" }}
    >
      <Optional showIf={loading}>
        <i
          className="pi pi-spin pi-spinner"
          style={{ fontSize: "1.5em", color: "#ffffff" }}
        />
      </Optional>
      <Optional showIf={!loading}>
        {icon ? <i className={`pi pi-${icon}`} /> : null}
        <span className={icon ? "ml-2" : ""} style={{ fontSize: "1rem" }}>
          {" "}
          {text}
        </span>
      </Optional>
    </button>
  );
}
