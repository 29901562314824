import { CONFIG } from "core/configs/configs";

const GET_FINANCIAL_INSTITUTION = `/management/api/${CONFIG.API_VERSION}/financial-institution`;
const CREATE_FINANCIAL_INSTITUTION = `/management/api/${CONFIG.API_VERSION}/financial-institution`;
const UPDATE_FINANCIAL_INSTITUTION = `/management/api/${CONFIG.API_VERSION}/financial-institution`;
const FINANCIAL_INSTITUTION_FILLER = `/management/api/${CONFIG.API_VERSION}/financial-institution/filler`;

export const FINANCIAL_INSTITUTION_ROUTES = {
  GET_FINANCIAL_INSTITUTION,
  CREATE_FINANCIAL_INSTITUTION,
  UPDATE_FINANCIAL_INSTITUTION,
  FINANCIAL_INSTITUTION_FILLER,
};
