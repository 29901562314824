import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { API_SERVICE } from "../../../api/service";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { HELPER } from "../../../core/helper/helper";

export function AcquirersFilter(props) {
  const [financialInsitutions, setFinancialInsitutions] = useState([]);
  const [initialValues] = useState({
    financialInstitutionCode: "",
    acquirerInstitutionId: "",
    active: "",
    to: "",
    from: "",
  });
  const validationSchemaObject = {
    financialInstitutionCode: Yup.string(),
    acquirerInstitutionId: Yup.string().matches(
      /^[a-zA-Z0-9]*$/,
      "Must be alphanumeric"
    ),
    active: Yup.string(),
    to: Yup.string(),
    from: Yup.string(),
  };

  const submit = async ({ status, ...args }) => {
    const payload = HELPER.TRIM_OBJECT({ active: status, ...args });
    props.onGetAcquirers(payload);
  };

  useEffect(() => {
    const getFinancialInstitutions = async () => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          BACK_OFFICE_API.FINANCIAL_INSITUTION.FINANCIAL_INSTITUTION_FILLER
        );
        if (response.success) {
          setFinancialInsitutions(response?.result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFinancialInstitutions();
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <AppFormDropdown
              value={values.financialInstitutionCode}
              onchange={(e) => {
                setFieldValue("financialInstitutionCode", e?.target?.value);
              }}
              options={financialInsitutions}
              optionLabel="name"
              optionValue="code"
              placeholder="Select financial institution"
              label="Financial Institution"
              error={errors?.financialInstitutionCode}
              field="financialInstitutionCode"
            />
            <div className="flex w-full ">
              <div className="w-full pr-3">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  From:
                </label>
                <FormInput
                  name="From"
                  fn={(e) => setFieldValue("from", e.target.value)}
                  label="Start date"
                  inputType="date"
                  required={false}
                  value={values.from}
                  field="from"
                  type="INPUT"
                />
              </div>
              <div className="w-full">
                <label
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  To:
                </label>
                <FormInput
                  name="To"
                  fn={(e) => setFieldValue("to", e.target.value)}
                  label="End date"
                  inputType="date"
                  required={false}
                  value={values.to}
                  field="to"
                  type="INPUT"
                />
              </div>
            </div>
            <div className="pt-3">
              <div>
                <p
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  Status:
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="Status"
                    checked={values.active === true}
                    handleChange={(e) => setFieldValue("active", true)}
                    label="Active"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="Status"
                    checked={values.active === false}
                    handleChange={(e) => setFieldValue("active", false)}
                    label="Inactive"
                  />
                </div>
              </div>
            </div>

            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
