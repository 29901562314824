import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { FeeRuleIdentifiersForm } from "./fee-rule-identifiers-form";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";

export function FeeRuleIdentifiers(props) {
  const { addNotification } = useNotification();
  const [modalInfo, setModalInfo] = useState({
    title: "Create Identifier",
    subtitle: "All fields are required",
  });
  const [currentIndex] = useState(0);
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "Key", value: "key" },
    { label: "Value", value: "value" },
    { label: "Logical Operator", value: "logicalOperator" },
    { label: "Status", value: "active" },
    { label: "Create At", value: "createdAt" },
    { label: "Create By", value: "createdBy" },
    { label: "Actions", value: "actions" },
  ];
  const [identifiers, setIdentifiers] = useState();
  const [identifier, setIdentifier] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);

  const getIdentifiers = useCallback(async () => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING(
      HELPER.TRIM_OBJECT({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        feeRuleCode: props.feeRuleCode,
      })
    );
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.FEE_RULE_IDENTIFIER.FEE_IDENTIFIER}?${params}`
      );
      setPagination(response);
      const identifiers = response?.content;
      setIdentifiers(identifiers);
      setError(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  useEffect(() => {
    getIdentifiers();
  }, [getIdentifiers]);

  function reload() {
    getIdentifiers();
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getIdentifiers();
    }
  }

  const handleTableAction = (data, action) => {
    const { active, updatedAt, ...rest } = data;
    switch (action) {
      case "UPDATE":
        setModalInfo({
          title: "Update Identifier",
          subtitle: "All fields are required",
        });
        setIdentifier({ ...rest, active, updatedAt });
        toggleModal(1);
        break;
      default:
        break;
    }
  };

  const currentPageView = () => {
    switch (currentIndex) {
      case 0:
        return renderTable();
      default:
        break;
    }
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <FeeRuleIdentifiersForm
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getIdentifiers}
            feeRuleCode={props.feeRuleCode}
          />
        );
      case 1:
        return (
          <FeeRuleIdentifiersForm
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getIdentifiers}
            identifier={identifier}
            feeRuleCode={props.feeRuleCode}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "UPDATE", value: "switch_update_fee_rule_identifier" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No fee rule identifiers found!"}
          search={true}
          reload={reload}
          error={error}
          items={identifiers}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["UPDATE"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-fee-rules-idenifiers">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          title={modalInfo.title}
          subtitle={modalInfo.subtitle}
        />
      </>
      <PageActions>
        {/* <AppButton
          icon="filter"
          text="Filter"
          type="button"
          buttonStyle="primary"
          margin={"mr-2"}
          onclick={() => toggleModal(3)}
        /> */}
        <Optional
          showIf={HELPER.HAS_AUTHORITY("switch_create_fee_rule_identifier")}
        >
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New Identifier"
            onclick={() => {
              toggleModal(0);
              setModalInfo({
                title: "Create Identifier",
                subtitle: "All fields are required",
              });
            }}
          />
        </Optional>
      </PageActions>
      <>{currentPageView()}</>
    </div>
  );
}
