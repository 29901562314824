import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../api/service";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { useNotification } from "core/hooks/useNotification";

export function BinsForm(props) {
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    bin: "",
  });
  const [validationSchemaObject, setValidationSchemaObject] = useState({
    bin: Yup.string()
      .matches(/^[0-9]+$/, "Must contain only numbers")
      .length(6, `Must be 6 characters`)
      .matches(/^[1-9]\d*$/, "Cannot start with 0")
      .required("Required"),
  });
  const { addNotification } = useNotification();
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (props.isUpdate) {
      setValidationSchemaObject({
        bin: Yup.string()
          .max(6, `Too long, maximum length is 6 characters`)
          .matches(/^[0-9]+$/, "Must contain only numbers")
          .required("Required"),
        status: Yup.boolean().required("Required"),
      });
      const { bin, active } = props.bin;
      setInitialValues({
        bin,
        status: active,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isUpdate]);

  const handleResponse = (response, resetForm, isCreate) => {
    const message = `BIN successfully ${isCreate ? "created" : "updated"}!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
    if (!props.isUpdate) {
      resetForm({
        values: {
          bin: "",
        },
      });
    }
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const createBin = async (payload, resetFormFuntion) => {
    const url = BACK_OFFICE_API.BINS.BIN;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(response, resetFormFuntion, isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updateBin = async (payload, resetFormFuntion) => {
    const url = BACK_OFFICE_API.BINS.BIN;
    try {
      const response = await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.bin?.code}`,
        payload
      );
      handleResponse(response, resetFormFuntion);
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async ({ status, ...payload }, resetFormFuntion) => {
    setToastType("");
    setMessage("");
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      let formattedPayload = {
        issuerCode: props.issuerCode,
        ...payload,
      };
      if (props.isUpdate) {
        updateBin({
          ...formattedPayload,
          active: status,
        });
      } else {
        createBin(formattedPayload, resetFormFuntion);
      }
    } else {
      let confirmationPayload = payload;
      if (props.isUpdate) {
        setFormValues({
          ...confirmationPayload,
          status: status ? "active" : "inactive",
        });
      } else {
        setFormValues({
          ...confirmationPayload,
        });
      }
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
              <AppFormInput
                required
                label="BIN"
                name="bin"
                type="text"
                placeholder="Enter BIN"
                disabled={props.isUpdate}
              />
              <Optional showIf={props.isUpdate}>
                <div>
                  <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    Status:
                  </p>
                  <div className="flex default p-mb-4">
                    <FormRadioInput
                      id="active"
                      value={true}
                      name="Status"
                      checked={values.status === true}
                      handleChange={(e) => setFieldValue("status", true)}
                      label="Active"
                    />
                    <FormRadioInput
                      id="Inactive"
                      value={false}
                      name="Status"
                      checked={values.status === false}
                      handleChange={(e) => setFieldValue("status", false)}
                      label="Inactive"
                    />
                  </div>
                </div>
              </Optional>
            </Optional>
            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <div className="custom-modal-item">
                <FormattedDetails details={formValues} />
              </div>
            </Optional>
            {message && (
              <CustomToast
                title={toastType === "error" ? "Error" : "Success"}
                description={message}
                type={toastType}
                closeable={false}
                inApp={true}
              />
            )}
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text={currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"}
                onclick={handleClose}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                text={currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"}
                loading={loading}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
