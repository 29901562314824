import "./custom-toast.css";
import { Icon } from "../icons/icon";
import React, { useState } from "react";

export function CustomToast(props) {
  const [toastType, setToastType] = useState(props.type);

  function closeAlert() {
    setToastType(null);
  }
  switch (toastType) {
    case "info":
      return (
        <div className="error-toast-container">
          <div className="info-toast">
            <div className="grid">
              <div className="col-2 pl-5 ">
                <div
                  className="icon-container cursor-pointer"
                  onClick={closeAlert}
                >
                  <Icon icon="info" color="#0171AC" />
                </div>
              </div>
              <div className="col-7 ml-2">
                <div className="error-toast-text-container">
                  <p className="info-toast-text-title">{props.title}</p>
                  <p className="notification-text"> {props.description}</p>
                </div>
              </div>
              <div className="col-2">
                {props?.closable ? (
                  <div className="icon-container-cancel">
                    <Icon icon="cancel" />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case "error":
      return (
        <div className="error-toast-container">
          <div className="error-toast">
            <div className="grid">
              <div className="col-2 pl-5 ">
                <div
                  className="icon-container cursor-pointer"
                  onClick={closeAlert}
                >
                  <Icon icon="circular-cancel" />
                </div>
              </div>
              <div className="col-7 ml-2">
                <div className="error-toast-text-container">
                  <p className="error-toast-text-title">{props.title}</p>
                  <p className="notification-text"> {props.description}</p>
                </div>
              </div>
              <div className="col-2">
                {props?.closable ? (
                  <div className="icon-container-cancel">
                    <Icon icon="cancel" />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case "success":
      return (
        <div className="error-toast-container">
          <div className="success-toast">
            <div className="grid">
              <div className="col-2 pl-5">
                <div
                  className="icon-container-success cursor-pointer"
                  onClick={closeAlert}
                >
                  <Icon icon="circular-success" />
                </div>
              </div>
              <div className="col-8">
                <div className="error-toast-text-container">
                  <p className="success-toast-text-title">{props.title}</p>
                  <p className="notification-text">{props.description}</p>
                </div>
              </div>
              {!props.inApp && (
                <div className="col-2">
                  <div className="icon-container-cancel">
                    <Icon icon="cancel-success" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
}
