import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

const useNetworkForm = (props, partyType) => {
  const [initialValues, setInitialValues] = useState({
    status: "",
    name: "",
  });
  const [validationObject, setValidationObject] = useState({
    name: Yup.string()
      .max(100, "Network name cannot exceed 100 characters!")
      .required("Required"),
  });
  const [toast, setToast] = useState({ message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [networkInfo, setNetworkInfo] = useState({});
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [partySourceInfo, setPartySourceInfo] = useState({});
  const stepWizard = {
    PRIMARY_INFO_INDEX: 0,
    CONNECTION_INFO_INDEX: 1,
  };
  const stepWizardModel = [
    { label: "Primary Info" },
    { label: "Connection Info" },
  ];

  const getPartySourceInfo = useCallback(
    async (partyCode) => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.PARTY_SOURCE_INFO.PARTY_SOURCE_INFO}/${partyType}/${partyCode}`
        );
        const partySource = response?.result;
        handlePartySourceResponse(partySource);
      } catch (error) {
        setToast({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [partyType]
  );

  const handlePartySourceResponse = (partySource) => {
    const {
      partyTypeId,
      partyType,
      createdBy,
      createdAt,
      updatedAt,
      active,
      ...result
    } = partySource;
    setPartySourceInfo(
      HELPER.changeNullValuesToEmptyStrings({ ...result, status: active })
    );
  };

  const handleResponse = (response) => {
    setToast({
      message: "",
      type: "",
    });
    setNetworkInfo(response?.result);
    if (props.isUpdate) {
      getPartySourceInfo(props.network.code);
    }
    setCurrentFormIndex(stepWizard.CONNECTION_INFO_INDEX);
    setLoading(false);
  };

  const handleError = (error) => {
    setToast({ type: "error", message: HELPER.PROCESS_ERROR(error) });
    setLoading(false);
  };

  const updateNetwork = async (payload) => {
    const url = BACK_OFFICE_API.NETWORKS.UPDATE_NETWORK;
    try {
      const response = await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props.network.code}`,
        payload
      );
      handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  };

  const createNetwork = async (payload) => {
    const url = BACK_OFFICE_API.NETWORKS.CREATE_NETWORK;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  };

  const submit = async (payload) => {
    setToast({ message: "", type: "" });
    const { status, createdAt, updatedAt, code, ...rest } = payload;
    if (currentFormIndex === stepWizard.PRIMARY_INFO_INDEX) {
      setLoading(true);
      if (props.isUpdate) {
        updateNetwork({ ...rest, active: status });
      } else {
        createNetwork({ ...rest });
      }
    }
  };

  const goBack = () => {
    setCurrentFormIndex(stepWizard.PRIMARY_INFO_INDEX);
  };

  const closeBackButtonText =
    currentFormIndex === stepWizard.PRIMARY_INFO_INDEX ? "Close" : "Back";
  const submitNextButtonText =
    currentFormIndex === stepWizard.CONNECTION_INFO_INDEX ? "Submit" : "Next";

  useEffect(() => {
    if (props.isUpdate) {
      setValidationObject({
        name: Yup.string()
          .max(100, "Network name cannot exceed 100 characters!")
          .required("Required"),
        status: Yup.boolean().required("Required"),
      });
      const values = HELPER.changeNullValuesToEmptyStrings(props.network);
      const { formattedStatus, active, ...rest } = values;
      setInitialValues({
        ...rest,
        status: active,
      });
    }
  }, [props.isUpdate, props.network]);

  const handleClose = () => {
    if (currentFormIndex === stepWizard.PRIMARY_INFO_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(stepWizard.PRIMARY_INFO_INDEX);
    }
  };

  const actions = {
    submit,
    handleClose,
    goBack,
  };

  const formInfo = {
    ...stepWizard,
    initialValues,
    validationObject,
    stepWizardModel,
    currentFormIndex,
    closeBackButtonText,
    submitNextButtonText,
  };
  const api = {
    loading,
    toast,
    partySourceInfo,
    networkInfo,
  };
  return { actions, api, formInfo };
};

export default useNetworkForm;
