import { CONFIG } from "core/configs/configs";

const GENERATE_REPORT = `/clearing/api/${CONFIG.API_VERSION}/switch-fee/transaction/filter/generatereport`;
const GET_FEE_REPORTS = `/clearing/api/${CONFIG.API_VERSION}/switch-fee/transaction/filter/reportinfo`;
const GET_REPORT_STATUS = `/clearing/api/${CONFIG.API_VERSION}/switch-fee/transaction/filter/reportinfo`;
const DOWNLOAD_REPORT = `/clearing/api/${CONFIG.API_VERSION}/switch-fee/transaction/filter/download`;

export const FEE_REPORTS_ROUTE = {
  GENERATE_REPORT,
  GET_FEE_REPORTS,
  GET_REPORT_STATUS,
  DOWNLOAD_REPORT,
};
