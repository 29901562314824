import { CONFIG } from "core/configs/configs";

const GET_TERMINALS = `/management/api/${CONFIG.API_VERSION}/terminals`;
const CREATE_TERMINALS = `/management/api/${CONFIG.API_VERSION}/terminals`;
const UPDATE_TERMINALS = `/management/api/${CONFIG.API_VERSION}/terminals`;

export const TERMINAL_ROUTES = {
  GET_TERMINALS,
  UPDATE_TERMINALS,
  CREATE_TERMINALS,
};