import { ISSUERS_ROUTES } from "./issuers";
import { FINANCIAL_INSTITUTION_ROUTES } from "./financial-institutions";
import { THIRD_PARTY_ROUTES } from "./third-party";
import { THIRD_PARTY_CATEGORY_ROUTES } from "./third-party-category";
import { MERCHANT_ROUTES } from "./merchants";
import { NETWORK_ROUTES } from "./networks";
import { FEE_RULES_ROUTE } from "./fee-rules";
import { FEE_RULE_IDENTIFIER_ROUTES } from "./fee-rule-identifier";
import { ACQUIRER_ROUTES } from "./acquirers";
import { IN_OUT_RULE_ROUTES } from "./in-out-rule";
import { ISSUER_PROCESSOR_ROUTES } from "./issuer-processors";
import { FEES_ROUTE } from "./fees";
import { PARTICIPANT_ROUTES } from "./participants";
import { FEE_SOURCE_ROUTES } from "./fee-source";
import { SETTLEMENT_RULE_ROUTE } from "./settlement-rules";
import { BIN_ROUTES } from "./bins";
import { THIRD_PARTY_ACQUIRER_ROUTES } from "./third-parties-acquirer";
import { TRANSACTION_ROUTES } from "./transactions";
import { FEE_REPORTS_ROUTE } from "./fee-reports";
import { PARTY_USER_ROUTES } from "./party-users";
import { THIRD_PARTY_CRED_ROUTES } from "./third-party-cred";
import { PARTY_SOURCE_INFO_ROUTES } from "./party-source-info";
import { DISPUTE_ROUTES } from "./disputes";
import { DISPUTE_CONFIG_ROUTES } from "./dispute-config";
import { TERMINAL_ROUTES } from "./terminal";
import { NSS_CONFIGURATION_ROUTES } from "./nss-configurations";
import { BULK_MERCHANT_TERMINAL_ROUTES } from "./bulk-merchant-terminal";
import { NSS_SETTLEMENT_SESSION_ROUTES } from "./nss-settlement-session";
import { TRANSACTION_REPORT_ROUTES } from "./transaction-reports";
import { CONFIGURATION_ROUTES } from "./configurations";

export const BACK_OFFICE_API = {
  FINANCIAL_INSITUTION: { ...FINANCIAL_INSTITUTION_ROUTES },
  ISSUERS: { ...ISSUERS_ROUTES },
  THIRD_PARTY: { ...THIRD_PARTY_ROUTES },
  THIRD_PARTY_CATEGORIES: {
    ...THIRD_PARTY_CATEGORY_ROUTES,
  },
  MERCHANTS: { ...MERCHANT_ROUTES },
  NETWORKS: { ...NETWORK_ROUTES },
  FEE_RULES: { ...FEE_RULES_ROUTE },
  FEE_RULE_IDENTIFIER: { ...FEE_RULE_IDENTIFIER_ROUTES },
  ACQUIRER: { ...ACQUIRER_ROUTES },
  IN_OUT_RULE: { ...IN_OUT_RULE_ROUTES },
  PARTY_SOURCE_INFO: { ...PARTY_SOURCE_INFO_ROUTES },
  ISSUER_PROCESSORS: { ...ISSUER_PROCESSOR_ROUTES },
  FEES: { ...FEES_ROUTE },
  PARTICIPANT: { ...PARTICIPANT_ROUTES },
  FEE_SOURCE: { ...FEE_SOURCE_ROUTES },
  SETTLEMENT_RULE: { ...SETTLEMENT_RULE_ROUTE },
  BINS: { ...BIN_ROUTES },
  THIRD_PARTY_ACQUIRERS: { ...THIRD_PARTY_ACQUIRER_ROUTES },
  TRANSACTIONS: { ...TRANSACTION_ROUTES },
  TRANSACTION_REPORT: { ...TRANSACTION_REPORT_ROUTES },
  FEE_REPORTS: { ...FEE_REPORTS_ROUTE },
  PARTY_USERS: { ...PARTY_USER_ROUTES },
  THIRD_PARTY_CRED: { ...THIRD_PARTY_CRED_ROUTES },
  DISPUTES: {
    ...DISPUTE_ROUTES,
  },
  DISPUTE_CONFIG: {
    ...DISPUTE_CONFIG_ROUTES,
  },
  TERMINAL: { ...TERMINAL_ROUTES },
  NSS_CONFIGURATIONS: {
    ...NSS_CONFIGURATION_ROUTES,
  },
  NSS_SETTLEMENT_SESSIONS: { ...NSS_SETTLEMENT_SESSION_ROUTES },
  BULK_MERCHANT_TERMINAL: {
    ...BULK_MERCHANT_TERMINAL_ROUTES,
  },
  CONFIGURATION: { ...CONFIGURATION_ROUTES },
};
