import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";

export function BinFilter(props) {
  const [initialValues] = useState({
    bin: "",
  });
  const validationSchemaObject = {
    bin: Yup.string()
      .matches(/^[0-9]+$/, "Must contain only numbers")
      .length(6, `Must be 6 characters`)
      .matches(/^[1-9]\d*$/, "Cannot start with 0")
      .required("Required"),
  };

  const submit = async (payload) => {
    props.filterFunc(payload.bin);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <AppFormInput
              label="Bin"
              name="bin"
              type="text"
              placeholder="Enter BIN"
              required={true}
            />
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
